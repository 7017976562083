import transferencesActions from "../../actions/clients-transferences/clients-transferences-types";

const initialState = {
  clientsTransferences: null,
  clientsTransferencesRequest: {
    clientsTransferencesRequestPending: false,
    clientsTransferencesRequestError: false,
    clientsTransferencesRequestSuccess: false,
  },

  updatePayment: null,
  updatePaymentRequest: {
    updatePaymentRequestPending: false,
    updatePaymentRequestError: false,
    updatePaymentRequestSuccess: false,
  },
};

function transferencesReducer(state = initialState, action) {
  switch (action.type) {
    case transferencesActions.FETCH_TRANSFERENCES_PENDING:
      return {
        ...state,
        clientsTransferences: null,
        clientsTransferencesRequest: {
          clientsTransferencesRequestPending: true,
          clientsTransferencesRequestError: false,
          clientsTransferencesRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_TRANSFERENCES_ERROR:
      return {
        ...state,
        clientsTransferences: null,
        clientsTransferencesRequest: {
          clientsTransferencesRequestPending: false,
          clientsTransferencesRequestError: true,
          clientsTransferencesRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_TRANSFERENCES_SUCCESS:
      return {
        ...state,
        clientsTransferences: action.payload.bankPayments,
        clientsTransferencesRequest: {
          clientsTransferencesRequestPending: false,
          clientsTransferencesRequestError: false,
          clientsTransferencesRequestSuccess: true,
        },
      };
    case transferencesActions.FETCH_ACCEPT_TRANSFERENCE_PENDING:
    case transferencesActions.FETCH_DELETE_TRANSFERENCE_PENDING:
      return {
        ...state,
        updatePayment: null,
        updatePaymentRequest: {
          updatePaymentRequestPending: true,
          updatePaymentRequestError: false,
          updatePaymentRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_ACCEPT_TRANSFERENCE_ERROR:
    case transferencesActions.FETCH_DELETE_TRANSFERENCE_ERROR:
      return {
        ...state,
        updatePayment: action.payload,
        updatePaymentRequest: {
          updatePaymentRequestPending: false,
          updatePaymentRequestError: true,
          updatePaymentRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_ACCEPT_TRANSFERENCE_SUCCESS:
    case transferencesActions.FETCH_DELETE_TRANSFERENCE_SUCCESS:
      return {
        ...state,
        updatePayment: action.payload,
        updatePaymentRequest: {
          updatePaymentRequestPending: false,
          updatePaymentRequestError: false,
          updatePaymentRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default transferencesReducer;
