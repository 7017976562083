import userActions from "../actions/usertypes";

const initialState = {
  changePasswordRequestrMsg: "",
  changePasswordRequest: {
    changePasswordRequestPending: false,
    changePasswordRequestError: false,
    changePasswordRequestSuccess: false,
  },

  sendRecoverPasswordEmailRequestErrorMessage: "",
  sendRecoverPasswordEmailRequest: {
    sendRecoverPasswordEmailPending: false,
    sendRecoverPasswordEmailError: false,
    sendRecoverPasswordEmailSuccess: false,
  },

  recoverPasswordRequest: {
    recoverPasswordRequestPending: false,
    recoverPasswordRequestError: false,
    recoverPasswordRequestSuccess: false,
  },
};

export default function users(state = initialState, action) {
  switch (action.type) {
    // change password actions
    case userActions.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePasswordRequestErrorMsg: "",
        changePasswordRequest: {
          changePasswordRequestPending: true,
          changePasswordRequestError: false,
          changePasswordRequestSuccess: false,
        },
      };
    case userActions.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordRequestrMsg: action.payload,
        changePasswordRequest: {
          changePasswordRequestPending: false,
          changePasswordRequestError: true,
          changePasswordRequestSuccess: false,
        },
      };
    case userActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordRequestrMsg: action.payload,
        changePasswordRequest: {
          changePasswordRequestPending: false,
          changePasswordRequestError: false,
          changePasswordRequestSuccess: true,
        },
      };
    case userActions.RESET_CHANGE_PASSWORD_REQUEST_STATE:
      return {
        ...state,
        changePasswordRequestErrorMsg: "",
        changePasswordRequest: {
          ...state.changePasswordRequest,
          changePasswordRequestPending: action.payload,
          changePasswordRequestError: action.payload,
          changePasswordRequestSuccess: action.payload,
        },
      };
    // send email for recover password actions
    case userActions.SEND_EMAIL_FOR_REVOVER_PASSWORD_PENDING:
      return {
        ...state,
        sendRecoverPasswordEmailRequest: {
          ...state.sendRecoverPasswordEmailRequest,
          sendRecoverPasswordEmailPending: action.payload,
        },
      };
    case userActions.SEND_EMAIL_FOR_REVOVER_PASSWORD_ERROR:
      return {
        ...state,
        sendRecoverPasswordEmailRequestErrorMessage: action.payload.message,
        sendRecoverPasswordEmailRequest: {
          ...state.sendRecoverPasswordEmailRequest,
          sendRecoverPasswordEmailError: action.payload.status,
        },
      };
    case userActions.SEND_EMAIL_FOR_REVOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        sendRecoverPasswordEmailRequest: {
          ...state.sendRecoverPasswordEmailRequest,
          sendRecoverPasswordEmailSuccess: action.payload,
        },
      };
    // reset  "send email for recover password" request
    case userActions.RESET_SEND_EMAIL_FOR_REVOVER_PASSWORD_REQUEST_STATE:
      return {
        ...state,
        sendRecoverPasswordEmailRequestErrorMessage: "",
        sendRecoverPasswordEmailRequest: {
          ...state.sendRecoverPasswordEmailRequest,
          sendRecoverPasswordEmailPending: action.payload,
          sendRecoverPasswordEmailError: action.payload,
          sendRecoverPasswordEmailSuccess: action.payload,
        },
      };
    // recover password actions
    case userActions.RECOVER_PASSWORD_PENDING:
      return {
        ...state,
        recoverPasswordRequest: {
          ...state.recoverPasswordRequest,
          recoverPasswordRequestPending: action.payload,
        },
      };
    case userActions.RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        recoverPasswordRequest: {
          ...state.recoverPasswordRequest,
          recoverPasswordRequestError: action.payload,
        },
      };
    case userActions.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPasswordRequest: {
          ...state.recoverPasswordRequest,
          recoverPasswordRequestSuccess: action.payload,
        },
      };
    case userActions.RESET_RECOVER_PASSWORD_REQUEST_STATE:
      return {
        ...state,
        recoverPasswordRequest: {
          ...state.recoverPasswordRequest,
          recoverPasswordRequestPending: action.payload,
          recoverPasswordRequestSuccess: action.payload,
          recoverPasswordRequestError: action.payload,
        },
      };
    default:
      return state;
  }
}
