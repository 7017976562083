const type = "STATE_PROMOCODE";

const promocodesActions = {
  GET_PROMOCODE_PENDING: "GET_PROMOCODE_PENDING",
  GET_PROMOCODE_ERROR: "GET_PROMOCODE_ERROR",
  GET_PROMOCODE_SUCCESS: "GET_PROMOCODE_SUCCESS",

  GET_INPUT_PROMOCODE_PENDING: "GET_INPUT_PROMOCODE_PENDING",
  GET_INPUT_PROMOCODE_ERROR: "GET_INPUT_PROMOCODE_ERROR",
  GET_INPUT_PROMOCODE_SUCCESS: "GET_INPUT_PROMOCODE_SUCCESS",

  SET_INVALID_PROMOCODE: "SET_INVALID_PROMOCODE",

  SET_INPUT_PROMOCODE_VALUE: "SET_INPUT_PROMOCODE_VALUE",

  SET_PROMOCODE_APPLY: "SET_PROMOCODE_APPLY",

  RESET_PROMOCODE_STORE: "RESET_PROMOCODE_STORE",

  // **********************

  FETCH_PROMOCODE_INFO_PENDING: `${type}_FETCH_PROMOCODE_INFO_PENDING`,
  FETCH_PROMOCODE_INFO_ERROR: `${type}_FETCH_PROMOCODE_INFO_ERROR`,
  FETCH_PROMOCODE_INFO_SUCCESS: `${type}_FETCH_PROMOCODE_INFO_SUCCESS`,

  FETCH_CHECK_INPUT_PROMOCODE_PENDING: `${type}_FETCH_CHECK_INPUT_PROMOCODE_PENDING`,
  FETCH_CHECK_INPUT_PROMOCODE_ERROR: `${type}_FETCH_CHECK_INPUT_PROMOCODE_ERROR`,
  FETCH_CHECK_INPUT_PROMOCODE_SUCCESS: `${type}_FETCH_CHECK_INPUT_PROMOCODE_SUCCESS`,
};
export default promocodesActions;
