import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ChangeLoanTerms from "../../../../../../account/forms/modals/change-loan-terms/ChangeLoanTerms";
import InfoModal from "../../../../../../common/info-modal/InfoModal";
import RefuseLoanModal from "../get-money-options/refuse-loan-modal/RefuseLoanModal";
import loanStatuses from "../../../../../../../constants/loanStatuses";
import { getRejectReasons } from "../../../../../../../actions/loan/loan";

import "./styles.scss";

const DismissOptions = () => {
  const { loanStatus } = useSelector((state) => ({
    loanStatus: state.loan?.loanInfo?.loanStatus,
  }));

  const dispatch = useDispatch();

  const [changeLoanTermsActive, setChangeLoanTermsActive] = useState(false);
  const [openRefuseLoanModal, setOpenRefuseLoanModal] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getRejectReasons());
  }, [dispatch]);

  const shouldRenderRefuseButton = React.useMemo(
    () =>
      [loanStatuses.UNDER_CONSIDERATION, loanStatuses.BAD_PHOTO].includes(
        loanStatus
      ),
    [loanStatus]
  );

  return (
    <div className="dismiss-bths-wrapper">
      {shouldRenderRefuseButton && (
        <div className="bth-wrapper make-payment-bth-wrapper">
          <button
            className="bth dismiss-request-bth"
            onClick={() => setOpenRefuseLoanModal(true)}
          >
            {t("Dismiss.Request.label")}
          </button>
        </div>
      )}
      <InfoModal
        openModalInfo={openModalInfo}
        closeModalInfo={() => setOpenModalInfo(false)}
        infoText={t("Your.loan.is.already.overdue.label")}
      />
      <ChangeLoanTerms
        openChangeLoanTermsForm={changeLoanTermsActive}
        closeChangeLoanTermsForm={() => setChangeLoanTermsActive(false)}
      />
      <RefuseLoanModal
        openRefuseModal={openRefuseLoanModal}
        closeRefuseModal={() => setOpenRefuseLoanModal(false)}
      />
    </div>
  );
};

export default DismissOptions;
