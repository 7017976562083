import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../assets/close-icon.svg";

import SuccessMessage from "../success-message/SuccessMessage";
import Spinner from "../spinner/Spinner";
import ErrorMessage from "../error-message/ErrorMessage";

function HandlePesponse({
  pending,
  error,
  success,
  pendingText,
  errorText,
  successText,
}: *) {
  return (
    <>
      {pending ? <Spinner text={pendingText} /> : ""}
      {error ? <ErrorMessage text={errorText} /> : ""}
      {success ? <SuccessMessage text={successText} /> : ""}
    </>
  );
}

function ProcessModal({
  openModalProcess,
  closeModalProcess,
  children,
  pendingText,
  successText,
  errorText,

  requestPending,
  requestError,
  requestSuccess,
}: *) {
  return (
    <Dialog
      onClose={closeModalProcess}
      open={openModalProcess}
      className="modal-process-wrapper"
    >
      <DialogContent className="modal-process-content">
        <div className="close-icon-wrapper" onClick={closeModalProcess}>
          <img src={closeIcon} alt="" />
        </div>

        <div className="handle-pesponse-wrapper">
          <HandlePesponse
            pending={requestPending}
            error={requestError}
            success={requestSuccess}
            pendingText={pendingText}
            errorText={errorText}
            successText={successText}
          />

          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ProcessModal;
