import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../../assets/close-icon.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AddCommentModal from "../add-comment-modal/AddCommentModal";

import {
  getCommentsById,
  getCommentsPDF,
} from "../../../../../../../actions/loaners/loaners";
import { getDateByFormat } from "../../../../../../../utils/parser";

import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";

const CommentsTable = ({ comments = [], onSelectRow, loaded }: *) => {
  const { t } = useTranslation();

  return (
    <div className="historical-user-content-wrapper">
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="historical-user-table-head">
            <TableRow>
              <TableCell className="table-head-cell">Fecha / Hora</TableCell>
              <TableCell className="table-head-cell">Comentario</TableCell>
              <TableCell className="table-head-cell">Analista</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="historical-user-table-body">
            {loaded && !comments.length ? (
              <TableRow className="historical-user-body-row">
                <TableCell
                  colSpan={3}
                  className="table-body-cell historical-user-notification"
                >
                  {t("No.comments.message")}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {comments.map((comment, i) => (
                  <TableRow
                    className="historical-user-body-row"
                    onClick={() => onSelectRow(comment)}
                    key={i}
                  >
                    <TableCell className="table-body-cell">
                      {getDateByFormat({
                        date: comment.date,
                        format: "DD/MM/YYYY h:mm:ss a",
                      })}
                    </TableCell>
                    <TableCell className="table-body-cell comment-content">
                      {comment.comment}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {comment.analyst}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const HistoricalUser = ({
  closeModal,
  loanerInfo,
  openContractItem,
  onPressAccept,
}: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showAddModal, setShowAddModal] = React.useState(null);

  React.useEffect(() => {
    dispatch(getCommentsById(loanerInfo?.clientId));
  }, [dispatch, loanerInfo?.clientId]);

  const {
    userComments,
    userCommentsRequestSuccess,
    userCommentsPDF,
  } = useSelector((state) => ({
    userComments: state.loaners.userComments,
    userCommentsRequestSuccess:
      state.loaners.userCommentsRequest.userCommentsRequestSuccess,
    userCommentsPDF: state.loaners.userCommentsPDF,
  }));

  const onAddCommentButton = React.useCallback(() => setShowAddModal(true), []);

  const onCloseAddCommentModal = React.useCallback(
    () => setShowAddModal(false),
    []
  );

  const sendCommentInfo = React.useCallback(
    (data) => {
      onPressAccept({
        ...data,
      });
    },
    [onPressAccept]
  );

  const onDownloadPDF = React.useCallback(() => {
    dispatch(getCommentsPDF(loanerInfo?.clientId));
  }, [dispatch, loanerInfo?.clientId]);

  const onSelectRow = React.useCallback((item) => {
    setSelectedRow(item);
  }, []);

  React.useEffect(() => {
    const button = document.getElementById("dowload-PDF-request-link");
    if (userCommentsPDF && button) {
      document.getElementById("dowload-PDF-request-link").click();
    }
  }, [userCommentsPDF]);

  return (
    <Dialog
      onClose={closeModal}
      open={openContractItem}
      className="historical-user-wrapper"
    >
      <DialogContent className="historical-user-content">
        <div className="header-wrapper">
          <div className="header-title">
            {t("Historical.comment.user.title")}
          </div>
          <div className="user-data-info">
            <div className="user-item-wrapper">
              <div className="user-item-value">{`${loanerInfo?.name} ${loanerInfo?.surname}`}</div>
              <div className="user-item-value">
                {t("DNI.label")}: {loanerInfo?.dni}
              </div>
            </div>
            <div className="user-item-wrapper">
              <div className="user-item-value">
                {t("Email.label")}: {loanerInfo?.email}
              </div>
              <div className="user-item-value">
                {t("Phone.label")}: {loanerInfo?.phoneNumber}
              </div>
            </div>
          </div>

          <div className="close-img-wrapper" onClick={closeModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="comments-list">
            <CommentsTable
              comments={userComments?.comments}
              loaded={userCommentsRequestSuccess}
              onSelectRow={onSelectRow}
            />
          </div>
          <div className="comment-box">
            <div className="textarea-wrapper">
              <textarea
                disabled={!selectedRow?.updatable}
                readOnly={!selectedRow?.updatable}
                value={selectedRow?.comment}
                onChange={(e) =>
                  setSelectedRow({
                    ...selectedRow,
                    comment: e.target.value,
                  })
                }
              />
            </div>

            <div className="comments-actions">
              <div className="bth-content-wrapper">
                <div
                  className={`bth-border-wrapper bth-border-block-loaner-wrapper ${
                    (!selectedRow?.updatable || selectedRow?.comment === "") &&
                    "disabled"
                  }`}
                  onClick={() =>
                    sendCommentInfo({
                      commentId: selectedRow?.commentId,
                      comment: selectedRow?.comment,
                      action: "updateComment",
                      title: t("Update.user.comment.confirmation.message"),
                    })
                  }
                >
                  <div className="bth bth-block-loaner">
                    <span>{t("Update.commet.label")}</span>
                  </div>
                </div>
              </div>
              <div className="bth-content-wrapper">
                <div
                  className="bth-border-wrapper bth-border-block-loaner-wrapper"
                  onClick={onAddCommentButton}
                >
                  <div className="bth bth-block-loaner">
                    <span>{t("Register.commet.label")}</span>
                  </div>
                </div>
              </div>
              <div className="bth-content-wrapper">
                <div
                  className="bth-border-wrapper bth-border-block-loaner-wrapper"
                  onClick={onDownloadPDF}
                >
                  <div className="bth bth-block-loaner">
                    <span>{t("Download.comments.pdf.label")}</span>
                  </div>
                </div>

                <a
                  href={userCommentsPDF}
                  download={t("Your.request.label")}
                  id="dowload-PDF-request-link"
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>

      <AddCommentModal
        closeModal={onCloseAddCommentModal}
        openAddCommentModal={showAddModal}
        onPressAccept={sendCommentInfo}
        loanerInfo={loanerInfo}
      />
    </Dialog>
  );
};

export default HistoricalUser;
