import React, { useEffect, useState } from "react";
import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";
import { getUserPromocode } from "../../../../actions/promocodes/promocodes";
import Spinner from "../../../common/spinner/Spinner";
import ErrorMessage from "../../../common/error-message/ErrorMessage";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { getDateByFormat } from "../../../../utils/parser";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

const PromocodeTab = () => {
  useAnalitycs("UserDashboard PromoCode");

  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();

  const {
    userId,
    getPromocodeRequestPending,
    getPromocodeRequestError,
    promoCodeInfo,
  } = useSelector((state) => ({
    userId: state.auth.user.id,

    getPromocodeRequestPending:
      state.promocodes.getPromoCodeInfoRequest.getPromoCodeInfoRequestPending,
    getPromocodeRequestError:
      state.promocodes.getPromoCodeInfoRequest.getPromoCodeInfoRequestError,

    promoCodeInfo: state.promocodes.promoCodeInfo,
  }));

  useEffect(() => {
    dispatch(getUserPromocode(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const { t } = useTranslation();

  if (getPromocodeRequestPending) {
    return <Spinner text={`${t("Promocode.Data.Loading")}...`} />;
  }

  if (getPromocodeRequestError) {
    return <ErrorMessage text={t("Some.Error.label")} />;
  }

  return (
    <div className="promocode-tab-wrapper">
      <div className="tab-panel-title-wrapper">
        <span>{t("Promocode.label")}</span>
      </div>
      <div className="your-promocode">
        <div className="text">{t("Your.personal.promocode.is.label")}</div>
        <div className="promocode-content-wrapper">
          <div className="promocode-value">{promoCodeInfo?.promoCode}</div>
          <div className="bth-copy-wrapper">
            <CopyToClipboard
              className="bth-copy"
              text={promoCodeInfo?.promoCode}
              onCopy={() => setCopied(true)}
            >
              <span>{t("COPY.label")}</span>
            </CopyToClipboard>
            {copied ? <div className="copy-info">{t("Copied.label")}</div> : ""}
          </div>
        </div>
      </div>
      <div className="instruction-wrapper">
        <div className="title">{t("How.to.use.promocode.label")}</div>
        <div className="main-text">
          {t("Copy.the.promo.code.and.provide.it.to.another.user.label")}
          {t(
            "If.you.close.the.loan.on.time.you.will.receive.a.discount.on.any.next.or.current.loan.label"
          )}
        </div>
      </div>
      <div className="promocode-history-wrapper">
        <div className="title">{t("Promocode.History.label")}</div>
        <div className="promocodes-table-wrapper">
          <div className="header">
            <div className="title">{t("Promo.code.label")}</div>
            <div className="title">
              {t("Promocodes.times.used.by.referrals")}
            </div>
            <div className="title">{t("Promo.active.label")}</div>
          </div>
          {promoCodeInfo?.timesUsedOwnCode.map((item) => (
            <div key={item.code} className="promocode-item">
              <div className="value promocode">{item?.code}</div>
              <div className="value promocode">{item?.quantity}</div>
              <div className="value times-used">
                {item?.active
                  ? t("Promo.isActive.label")
                  : t("Promo.isNotActive.label")}
              </div>
            </div>
          ))}
        </div>
        <div className="promocodes-table-wrapper">
          <div className="header">
            <div className="title full">{t("Promocodes.used.by.friends")}</div>
          </div>
          <div className="promocode-item">
            <div className="value full">
              {t("Promocodes.quantity.available")}
            </div>
            <div className="value promocode">
              {promoCodeInfo?.referralDiscounts}
            </div>
          </div>
        </div>
        <div className="promocodes-table-wrapper">
          <div className="header">
            <div className="title full">{t("Promocodes.used.by.user")}</div>
            <div className="title">{t("Date.label")}</div>
          </div>
          {promoCodeInfo?.usedForeignPromoCodes.map((item) => (
            <div key={item.code} className="promocode-item">
              <div className="value promocode">{item?.code}</div>
              <div className="value promocode">
                {getDateByFormat({ date: item?.date })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromocodeTab;
