import burgerMenuActions from './burger-menu-types';

function actionCreator(actionType, data) {
  return {
    type: burgerMenuActions[actionType],
    payload: data,
  };
}

export const setBurgetMenuState = (value) => {
  return actionCreator('SET_BURGER_MENU_STATE', value);
};
