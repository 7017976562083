import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../../../utils/currency";
import { typesWithCompanyInfo } from "../../../../../constants/jobTypes";
import { getJobTypes } from "../../../../../actions/loan/loan";

const checkavAilabilityProperty = (property) =>
  typeof property === "string" ? property : formatPrice(property);

const CompanyName = ({ userCompanyName }: *) => {
  const { t } = useTranslation();
  return (
    <div className="additional-info-item company-name-info-wrapper">
      <div className="title company-name-title">{t("Company.Name.label")}</div>
      <div className="value company-name-value">
        {checkavAilabilityProperty(userCompanyName)}
      </div>
    </div>
  );
};

const CompanyPhone = ({ userCompanyPhone }: *) => {
  const { t } = useTranslation();
  return (
    <div className="additional-info-item company-phone-info-wrapper">
      <div className="title company-phone-title">
        {t("Company.Phone.label")}
      </div>
      <div className="value company-phone-value">
        {checkavAilabilityProperty(userCompanyPhone)}
      </div>
    </div>
  );
};

const AdditionalInfo = () => {
  const {
    userTypeEmployment,
    userCompanyName,
    userCompanyPhone,
    userNetIncome,
    userAdditionalIncome,
    userOtherLoans,
    jobTypes,
  } = useSelector((state) => ({
    userTypeEmployment: state.auth.user.job.jobType,
    userCompanyName: state.auth.user.job.companyName,
    userCompanyPhone: state.auth.user.job.companyPhone,
    userNetIncome: state.auth.user.job.netIncome,
    userAdditionalIncome: state.auth.user.job.additionalIncome,
    userOtherLoans: state.auth.user.job.otherLoan,
    userInstitutionName: state.auth.user.institution,
    jobTypes: state.loan.jobTypes,
  }));
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getJobTypes());
  }, [dispatch]);

  const usersCompanyInfoRequired = React.useMemo(
    () =>
      jobTypes.filter((item) =>
        typesWithCompanyInfo.includes(item.toLowerCase())
      ),
    [jobTypes]
  );

  const { t } = useTranslation();
  return (
    <div className="additional-info-block-wrapper">
      <div className="title-wrapper">
        <div className="text">{t("Additional.Info.label")}</div>
      </div>
      <div className="additional-info-list">
        <div className="additional-info-item type-employment-info-wrapper">
          <div className="title type-employment-title">
            {t("Type.of.Employment.label")}
          </div>
          <div className="value type-employment-value">
            {userTypeEmployment}
          </div>
        </div>

        {usersCompanyInfoRequired.includes(userTypeEmployment) && (
          <>
            <CompanyName userCompanyName={userCompanyName} />
            <CompanyPhone userCompanyPhone={userCompanyPhone} />
          </>
        )}

        <div className="additional-info-item net-income-info-wrapper">
          <div className="title net-income-title">{t("Net.Income.label")}</div>
          <div className="value net-income-value">
            {checkavAilabilityProperty(parseFloat(userNetIncome))}
          </div>
        </div>
        <div className="additional-info-item additional-income-info-wrapper">
          <div className="title additional-income-title">
            {t("Additional.Income.label")}
          </div>
          <div className="value additional-income-value">
            {checkavAilabilityProperty(parseFloat(userAdditionalIncome))}
          </div>
        </div>
        <div className="additional-info-item loans-credits-info-wrapper">
          <div className="title loans-credits-title">
            {t("Other.Loans.and.Credits.label")}
          </div>
          <div className="value loans-credits-value">
            {checkavAilabilityProperty(parseFloat(userOtherLoans))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
