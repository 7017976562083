import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import React from "react";
import questionIcon from "../../../assets/question-icon.svg";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setNewLoanData } from "../../../actions/loan/loan";
import {
  setLoanFormLayout,
  getCalculatorInfo,
} from "../../../actions/loan/loan";

import {
  applyPromocodeProps,
  handlePesponseProps,
} from "../../../constants/types";

import {
  getInputPromocode,
  setInputPromocodeValue,
} from "../../../actions/promocodes/promocodes";

import {
  formatPrice,
  roundNumber,
  getPercentage,
} from "../../../utils/currency";
import { formatDate } from "../helpers/helpers";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#4957f2",
    border: "5px solid #959eff",
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: -7,
    top: -25,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 3,
    backgroundImage: "linear-gradient(90deg, #2B3DFF -13.54%, #FF409A 108.96%)",
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    backgroundImage: "linear-gradient(90deg, #2B3DFF -13.54%, #FF409A 108.96%)",
  },
  mark: {
    backgroundImage:
      "linear-gradient(to right, #2B3DFF -13.54%, #FF409A 108.96%)",
    height: 17,
    width: 2,
    marginTop: -7,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
  markLabel: {
    marginTop: 10,
  },
})(Slider);

const formatReturnDate = (countDays) => {
  const currentDate = new Date().getTime();
  const daysInSeconds = countDays * 24 * 60 * 60 * 1000;
  const dateReturn = new Date(currentDate + daysInSeconds);
  return { dateReturn, dateReturnToString: `${formatDate(dateReturn)}` };
};

const HandlePesponse = ({
  promoCodeContent,
  pending,
  promocodeInvalid,
  success,
}: handlePesponseProps) => {
  const { t } = useTranslation();
  return (
    <>
      {pending ? (
        <div className="checking-promocode-spinner">
          {t("Checking.Promocode.label")} ....
        </div>
      ) : (
        ""
      )}
      {promocodeInvalid ? (
        <div className="promocode-info">{promoCodeContent?.message}</div>
      ) : (
        ""
      )}
      {success ? (
        <div className="success-info">{`${t("Promocode.Successfully.label")} ${
          promoCodeContent?.validatePromoCode
        }%`}</div>
      ) : (
        ""
      )}
    </>
  );
};

const ApplyPromocode = ({
  error,
  inputPromocodeValue,
  isAuthenticated,
  pending,
  promocodeInvalid,
  promoCodeContent,
  success,
}: applyPromocodeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [promocode, setPromocode] = React.useState(inputPromocodeValue);

  React.useEffect(() => {
    setPromocode(inputPromocodeValue);
  }, [inputPromocodeValue]);

  const history = useHistory();

  return (
    <div className="top-banner-content__calculator__discounts__wrapper">
      <div className="top-banner-content__calculator__description">
        {t("Promocode.label")}
      </div>
      <div className="wrapper">
        <input
          disabled={success}
          className="top-banner-content__calculator__input__promocode"
          type="text"
          placeholder={t("Promocode.label")}
          value={promocode}
          onChange={(e) => dispatch(setInputPromocodeValue(e.target.value))}
        />
        <div
          className={`top-banner-content__calculator__input__bth-apply-promocode__wrapper ${
            promocode && !success ? "active" : "disabled"
          }`}
        >
          <button
            disabled={!promocode || success}
            onClick={() => {
              if (!isAuthenticated) {
                history.push("/login");
              } else {
                dispatch(getInputPromocode(promocode));
              }
            }}
          >
            {t("Apply.label")}
          </button>
        </div>
      </div>

      <div className="handle-response-wrapper">
        <HandlePesponse
          promoCodeContent={promoCodeContent}
          pending={pending}
          error={error}
          success={success}
          promocodeInvalid={promocodeInvalid}
        />
      </div>
    </div>
  );
};

const Calculator = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    concepts,
    data,
    getInputPromocodeRequestPending,
    getInputPromocodeRequestSuccess,
    inputPromocodeValue,
    isAuthenticated,
    isSuperuser,
    isVerified,
    iva,
    legalInterest,
    loanStatus,
    promocodeInvalid,
    promoCodeContent,
    realInterest,
    userStatus,
  } = useSelector((state) => ({
    userStatus: state?.auth?.user?.status,
    loanStatus: state?.auth?.user?.loanStatus,
    isAuthenticated: state.auth.isAuthenticated,
    isSuperuser: state.auth.user.is_superuser,
    isVerified: state.auth.user.is_verified,
    data: state.loan.calculator.range || [],
    iva: state.loan.calculator.iva,
    legalInterest: state.loan.calculator.legalInterest,
    realInterest: state.loan.calculator.realInterest,
    concepts: state.loan.calculator.concepts,
    promocodeInvalid: state.promocodes.promocodeInvalid,

    inputPromocodeValue: state.promocodes.inputPromocodeValue,
    promoCodeContent: state.promocodes?.promoCodeContent,

    getInputPromocodeRequestPending:
      state.promocodes.getInputPromocodeRequest.getInputPromocodeRequestPending,
    getInputPromocodeRequestError:
      state.promocodes.getInputPromocodeRequest.getInputPromocodeRequestError,
    getInputPromocodeRequestSuccess:
      state.promocodes.getInputPromocodeRequest.getInputPromocodeRequestSuccess,
  }));

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(getCalculatorInfo());
    }
  }, [isAuthenticated, dispatch]);

  const [userSum, setUserSum] = React.useState(0);

  const sumMarksState = React.useMemo(() => {
    if (!data?.length) {
      return [];
    }
    const init = data[0]?.amount || 0;
    const finish = data[data.length - 1]?.amount || 0;

    const values = [];

    for (let i = init; i <= finish; i++) {
      if (i % 10 === 0) {
        values.push({
          value: i,
          label: i === init || i === finish || i % 3 === 0 ? `$${i}` : null,
        });
      }
    }

    return values;
  }, [data]);

  const sumMarksMinValue = React.useMemo(() => data[0]?.amount, [data]);

  const sumMarksMaxValue = React.useMemo(() => data[data.length - 1]?.amount, [
    data,
  ]);

  const onAmountChanged = React.useCallback((e, value) => {
    setUserSum(value);
  }, []);

  const dateMarksState = React.useMemo(() => {
    const days =
      data.find((item, index) => {
        const nextItem = data[index + 1] || { amount: 10000 };

        return userSum >= item.amount && userSum < nextItem.amount;
      })?.days || [];

    return days.map((item, index) => ({
      value: item,
      label:
        index === 0 || index === days.length - 1
          ? `${item} ${t("Days.label")}`
          : item,
    }));
  }, [data, userSum, t]);

  const [daysReturnCount, setDaysReturnCount] = React.useState(
    dateMarksState[0]?.value
  );

  const [dateReturn, seDateReturn] = React.useState(
    dateMarksState[0]?.value || 0
  );

  const dateMarksMaxValue = React.useMemo(() => {
    if (!dateMarksState.length) {
      return 0;
    }
    return dateMarksState[dateMarksState.length - 1]?.value;
  }, [dateMarksState]);

  const dateMarksMinValue = React.useMemo(() => {
    if (!dateMarksState.length) {
      return 0;
    }
    return dateMarksState[0]?.value;
  }, [dateMarksState]);

  React.useEffect(() => {
    setDaysReturnCount(dateMarksState[0]?.value);
    seDateReturn(formatReturnDate(dateMarksState[0]?.value || 0));
  }, [dateMarksState]);

  const onDateChange = React.useCallback((e, value) => {
    setDaysReturnCount(value);
    seDateReturn(formatReturnDate(value));
  }, []);

  React.useEffect(() => {
    setUserSum(data[0]?.amount);
  }, [data]);

  const promoCodePercentage = React.useMemo(() => {
    return (100 - promoCodeContent?.validatePromoCode) / 100;
  }, [promoCodeContent?.validatePromoCode]);

  const legalInterestAmount = React.useMemo(
    () => roundNumber(userSum * (legalInterest / 100) * daysReturnCount),
    [daysReturnCount, legalInterest, userSum]
  );

  const realInterestAmount = React.useMemo(
    () => roundNumber(userSum * (realInterest / 100) * daysReturnCount),
    [userSum, realInterest, daysReturnCount]
  );

  const administrativeInterestAmount = React.useMemo(
    () => roundNumber(realInterestAmount - legalInterestAmount),
    [realInterestAmount, legalInterestAmount]
  );

  const mappedConcepts = React.useMemo(() => {
    if (!concepts || !concepts.length) {
      return [];
    }

    return concepts.map((item) => ({
      ...item,
      price:
        getPercentage(item.rate, administrativeInterestAmount) +
        (item?.amount || 0),
    }));
  }, [concepts, administrativeInterestAmount]);

  const totalConceptsAmount = React.useMemo(() => {
    let total = 0;

    mappedConcepts.forEach((concept) => (total += concept.price));

    return total;
  }, [mappedConcepts]);

  const promoCodeAdministrativeInterestAmount = React.useMemo(() => {
    if (!getInputPromocodeRequestSuccess) {
      return 0;
    }

    return totalConceptsAmount * promoCodePercentage;
  }, [
    getInputPromocodeRequestSuccess,
    totalConceptsAmount,
    promoCodePercentage,
  ]);

  const promoCodeMappedConcepts = React.useMemo(() => {
    let values = 0;
    return mappedConcepts.map((item, index) => {
      const isTheLast = index === mappedConcepts.length - 1;

      if (index < mappedConcepts.length - 1) {
        values += item.price * promoCodePercentage;
      }

      return {
        ...item,
        discountedPrice: isTheLast
          ? promoCodeAdministrativeInterestAmount - values
          : item.price * promoCodePercentage,
      };
    });
  }, [
    mappedConcepts,
    promoCodePercentage,
    promoCodeAdministrativeInterestAmount,
  ]);

  const interestRate = legalInterestAmount;

  const earningValue = React.useMemo(
    () => legalInterestAmount + totalConceptsAmount,
    [legalInterestAmount, totalConceptsAmount]
  );

  const promoCodeInterestRate = React.useMemo(() => {
    if (!getInputPromocodeRequestSuccess) {
      return 0;
    }

    return legalInterestAmount * promoCodePercentage;
  }, [
    getInputPromocodeRequestSuccess,
    legalInterestAmount,
    promoCodePercentage,
  ]);

  const ivaValue = React.useMemo(
    () => roundNumber((iva / 100) * earningValue),
    [iva, earningValue]
  );

  const promoCodeIvaValue = React.useMemo(() => {
    if (!getInputPromocodeRequestSuccess) {
      return 0;
    }

    return ivaValue * promoCodePercentage;
  }, [getInputPromocodeRequestSuccess, ivaValue, promoCodePercentage]);

  const history = useHistory();
  const location = useLocation();

  const onGetMoney = () => {
    dispatch(
      setNewLoanData({
        userSum,
        amountReturn: userSum + ivaValue + interestRate,
        interestRate,
        daysReturnCount,
        dateReturn: dateReturn.dateReturn,
      })
    );
    const currentRoute = location.pathname;

    if (!isAuthenticated) {
      history.push("/login");
      return;
    }

    if (isAuthenticated && !isSuperuser && currentRoute !== "/dashboard") {
      history.push("/dashboard");
      return;
    }

    if (isAuthenticated && isSuperuser && currentRoute !== "/dashboard") {
      history.push("/admin");
      return;
    }

    if (
      isAuthenticated &&
      !isSuperuser &&
      !isVerified &&
      currentRoute === "/dashboard"
    ) {
      if (loanStatus === 7 || userStatus > 2) {
        history.push("/request-loan");
        return;
      }

      history.push("/first-loan");
      return;
    }

    if (
      isAuthenticated &&
      !isSuperuser &&
      isVerified &&
      currentRoute === "/dashboard"
    ) {
      dispatch(setLoanFormLayout());
      return;
    }
  };

  if (!data.length) {
    return <div className="top-banner-content__calculator__content"></div>;
  }

  return (
    <div className="top-banner-content__calculator__content">
      <div className="top-banner-content__calculator__item__wrapper">
        <div className="top-banner-content__calculator__item-sum">
          <div className="top-banner-content__calculator__item__desription">
            <div className="top-banner-content__calculator__item__desription-text">
              {t("How.much.do.you.need.label")}
            </div>
            <div className="top-banner-content__calculator__item__desription-value">{`$${userSum}`}</div>
          </div>
          <div className="top-banner-content__calculator__item__slider__wrapper">
            <IOSSlider
              aria-label="ios slider"
              marks={sumMarksState}
              value={userSum || 0}
              valueLabelDisplay="on"
              step={10}
              onChange={onAmountChanged}
              min={sumMarksMinValue}
              max={sumMarksMaxValue}
            />
          </div>
        </div>
      </div>
      <div className="top-banner-content__calculator__item__wrapper">
        <div className="top-banner-content__calculator__item-date">
          <div className="top-banner-content__calculator__item__desription">
            <div className="top-banner-content__calculator__item__desription-text">
              {t("When.you.ready.to.return.label")}
            </div>
            <div className="top-banner-content__calculator__item__desription-value">{`${daysReturnCount} ${t(
              "Days.label"
            )}`}</div>
          </div>
          <div className="top-banner-content__calculator__item__slider__wrapper">
            <IOSSlider
              aria-label="ios slider"
              value={daysReturnCount || 0}
              marks={dateMarksState}
              valueLabelDisplay="on"
              step={null}
              onChange={onDateChange}
              min={dateMarksMinValue}
              max={dateMarksMaxValue}
            />
          </div>
        </div>
      </div>
      <div className="top-banner-content__calculator__bth__wrapper">
        <button onClick={onGetMoney}>{t("Get.money.label")}</button>
      </div>
      <div className="top-banner-content__calculator__report__wrapper">
        <div className="top-banner-content__calculator__report__item sum">
          <div className="top-banner-content__calculator__report__item__description">
            {t("You.get.label")}
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {formatPrice(userSum)}
          </div>
        </div>
        <div className="top-banner-content__calculator__report__item interest">
          <div className="top-banner-content__calculator__report__item__description">
            {t("Interest.Rate.label")}
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {!getInputPromocodeRequestSuccess && formatPrice(interestRate)}
            {getInputPromocodeRequestSuccess && (
              <>
                <span>{formatPrice(interestRate)}</span>
                <span>{formatPrice(promoCodeInterestRate)}</span>
              </>
            )}
          </div>
        </div>
        <div className="top-banner-content__calculator__report__item amount-sum">
          <div className="top-banner-content__calculator__report__item__description">
            <span>{t("Administrative.rate.label")}</span>
            <span className="questionIcon__wrapper">
              <div className="questionInfo">
                {!getInputPromocodeRequestSuccess &&
                  mappedConcepts.map((item, index) => (
                    <div key={`concepts-${index}`} className="concept-item">
                      <span>{item.title}</span>
                      <span>{formatPrice(item.price)}</span>
                    </div>
                  ))}

                {getInputPromocodeRequestSuccess &&
                  promoCodeMappedConcepts.map((item, index) => (
                    <div key={`concepts-${index}`} className="concept-item">
                      <span>{item.title}</span>
                      <div className="concept-item-discounted">
                        <span>{formatPrice(item.price)}</span>
                        <span>{formatPrice(item.discountedPrice)}</span>
                      </div>
                    </div>
                  ))}
              </div>
              <img src={questionIcon} alt="" />
            </span>
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {!getInputPromocodeRequestSuccess &&
              formatPrice(totalConceptsAmount)}
            {getInputPromocodeRequestSuccess && (
              <>
                <span>{formatPrice(totalConceptsAmount)}</span>
                <span>
                  {formatPrice(promoCodeAdministrativeInterestAmount)}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="top-banner-content__calculator__report__item return-date">
          <div className="top-banner-content__calculator__report__item__description">
            {t("IVA.label")}
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {!getInputPromocodeRequestSuccess && formatPrice(ivaValue)}
            {getInputPromocodeRequestSuccess && (
              <>
                <span>{formatPrice(ivaValue)}</span>
                <span>{formatPrice(promoCodeIvaValue)}</span>
              </>
            )}
          </div>
        </div>
        <div className="top-banner-content__calculator__report__item amount-sum">
          <div className="top-banner-content__calculator__report__item__description">
            <span>{t("Amount.to.return.label")}</span>
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {!getInputPromocodeRequestSuccess &&
              formatPrice(
                userSum + interestRate + ivaValue + totalConceptsAmount
              )}
            {getInputPromocodeRequestSuccess && (
              <>
                <span>
                  {formatPrice(
                    userSum + interestRate + ivaValue + totalConceptsAmount
                  )}
                </span>
                <span>
                  {formatPrice(
                    userSum +
                      promoCodeInterestRate +
                      promoCodeIvaValue +
                      promoCodeAdministrativeInterestAmount
                  )}
                </span>
              </>
            )}
          </div>
          <div className="top-banner-content__calculator__report__item__additional-info">
            {t("Commission.for.transfer.label")}
          </div>
        </div>
        <div className="top-banner-content__calculator__report__item return-date">
          <div className="top-banner-content__calculator__report__item__description">
            {t("Return.Date.label")}
          </div>
          <div className="top-banner-content__calculator__report__item__value">
            {dateReturn.dateReturnToString}
          </div>
        </div>
      </div>
      <ApplyPromocode
        promocodeInvalid={promocodeInvalid}
        pending={getInputPromocodeRequestPending}
        success={getInputPromocodeRequestSuccess}
        inputPromocodeValue={inputPromocodeValue}
        isAuthenticated={isAuthenticated}
        promoCodeContent={promoCodeContent}
      />
      <div className="top-banner-content__calculator__help-info">
        <span className="tooltiptext">
          {t(
            "in.case.of.unforeseen.situations.PISTO.are.ready.to.extend.the.loan.agreement.label"
          )}
        </span>
        {t("What.if.I.don’t.get.back.in.time.label")}
      </div>
    </div>
  );
};

export default Calculator;
