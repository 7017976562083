import React from "react";
import PaymentsContent from "./payments-content/PaymentsContent";
import PaymentsHeader from "./payments-header/PaymentsHeader";

import "./styles.scss";

function PaymentsLayout() {
  return (
    <div className="payments-layout-wrapper">
      <PaymentsHeader />
      <PaymentsContent />
    </div>
  );
}

export default PaymentsLayout;
