import paymentsActions from "../../actions/payments/payments-types";

const initialState = {
  paymentOperators: [],
  paymentOperatorsRequest: {
    paymentOperatorsRequestPending: false,
    paymentOperatorsRequestError: false,
    paymentOperatorsRequestSuccess: false,
  },

  paymentFiles: null,
  paymentFilesPagination: null,
  paymentFilesRequest: {
    paymentFilesRequestPending: false,
    paymentFilesRequestError: false,
    paymentFilesRequestSuccess: false,
  },

  uploadPaymentFile: null,
  uploadPaymentFileRequest: {
    uploadPaymentFileRequestPending: false,
    uploadPaymentFileRequestError: false,
    uploadPaymentFileRequestSuccess: false,
  },
};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentsActions.FETCH_PAYMENT_OPERATORS_PENDING:
      return {
        ...state,
        paymentOperators: [],
        paymentOperatorsRequest: {
          paymentOperatorsRequestPending: true,
          paymentOperatorsRequestError: false,
          paymentOperatorsRequestSuccess: false,
        },
      };
    case paymentsActions.FETCH_PAYMENT_OPERATORS_ERROR:
      return {
        ...state,
        paymentOperators: [],
        paymentOperatorsRequest: {
          paymentOperatorsRequestPending: false,
          paymentOperatorsRequestError: true,
          paymentOperatorsRequestSuccess: false,
        },
      };
    case paymentsActions.FETCH_PAYMENT_OPERATORS_SUCCESS:
      return {
        ...state,
        paymentOperators: action.payload,
        paymentOperatorsRequest: {
          paymentOperatorsRequestPending: false,
          paymentOperatorsRequestError: false,
          paymentOperatorsRequestSuccess: true,
        },
      };
    case paymentsActions.FETCH_PAYMENT_FILES_PENDING:
      return {
        ...state,
        paymentFiles: null,
        paymentFilesRequest: {
          paymentFilesRequestPending: true,
          paymentFilesRequestError: false,
          paymentFilesRequestSuccess: false,
        },
      };
    case paymentsActions.FETCH_PAYMENT_FILES_ERROR:
      return {
        ...state,
        paymentFiles: null,
        paymentFilesPagination: null,
        paymentFilesRequest: {
          paymentFilesRequestPending: false,
          paymentFilesRequestError: true,
          paymentFilesRequestSuccess: false,
        },
      };
    case paymentsActions.FETCH_PAYMENT_FILES_SUCCESS:
      return {
        ...state,
        paymentFiles: action.payload.data,
        paymentFilesPagination: action.payload.meta,
        paymentFilesRequest: {
          paymentFilesRequestPending: false,
          paymentFilesRequestError: false,
          paymentFilesRequestSuccess: true,
        },
      };
    case paymentsActions.UPLOAD_PAYMENT_FILE_PENDING:
      return {
        ...state,
        uploadPaymentFile: null,
        uploadPaymentFileRequest: {
          uploadPaymentFileRequestPending: true,
          uploadPaymentFileRequestError: false,
          uploadPaymentFileRequestSuccess: false,
        },
      };
    case paymentsActions.UPLOAD_PAYMENT_FILE_ERROR:
      return {
        ...state,
        uploadPaymentFile: action.payload,
        uploadPaymentFileRequest: {
          uploadPaymentFileRequestPending: false,
          uploadPaymentFileRequestError: true,
          uploadPaymentFileRequestSuccess: false,
        },
      };
    case paymentsActions.UPLOAD_PAYMENT_FILE_SUCCESS:
      return {
        ...state,
        uploadPaymentFile: action.payload,
        uploadPaymentFileRequest: {
          uploadPaymentFileRequestPending: false,
          uploadPaymentFileRequestError: false,
          uploadPaymentFileRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default paymentReducer;
