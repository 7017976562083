import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

function LoanStatusAccepted() {
  const { t } = useTranslation();

  return (
    <div className={`loan-status-wrapper`}>
      <div className="status-indicator-wrapper accepted-loan">
        <span className="dot"></span>
        <span>{t("Loan.was.approved.label")}</span>
      </div>
    </div>
  );
}

export default LoanStatusAccepted;
