import verificationActions from "../../actions/verification/verification-types";
import loanActions from "../../actions/loan/loan-types";

const initialState = {
  frontIdCard: {},
  backIdCard: {},
  selfie: {},
  firstName: "",
  lastName: "",
  gender: "",
  birthDate: null,
  dniNumber: "",
  phone: "",
  street: "",
  city: "",
  exteriorNumber: "",
  colonia: "",
  province: "",
  typeOfEmployment: "",
  companyName: "",
  companyPhone: "",
  netIncome: "",
  additionalIncome: "",
  otherLoans: "",
  loanPurpose: "",
  description: "",
  institutionName: "",
  familiarName: "",
  familiarSurname: "",
  familiarPhone: "",
  friendName: "",
  friendSurname: "",
  friendPhone: "",
};

export default function verificationReducer(state = initialState, action) {
  switch (action.type) {
    case verificationActions.SET_PERSONAL_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case verificationActions.SET_ADDRESS_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case verificationActions.SET_ADDITIONAL_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case verificationActions.SET_PHOTO_FILES_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case loanActions.CREATE_FIRST_LOAN_SUCCESS:
    case loanActions.FETCH_LOAN_REQUEST_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
