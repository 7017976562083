import React from "react";
import LoanersHeader from "./loaners-header/LoanersHeader";
import LoanersList from "./loaners-list/LoanersList";

import "./styles.scss";

function LoanersLayout() {
  return (
    <div className="loaners-layout-wrapper">
      <LoanersHeader />
      <LoanersList />
    </div>
  );
}

export default LoanersLayout;
