export const formatPrice = (amount) => {
  const options = { style: "currency", currency: "USD" };
  const numberFormatter = new Intl.NumberFormat("en-US", options);

  return numberFormatter.format(amount);
};

export const roundNumber = (number) => {
  if (isNaN(number)) {
    return 0;
  }

  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const getPercentage = (percentage, total) => {
  const amount = total * percentage;
  if (isNaN(amount) || amount === 0) {
    return 0;
  }

  return roundNumber((total * percentage) / 100);
};
