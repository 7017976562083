import React from "react";
import "./styles.scss";

function Spinner({ text }: *) {
  return (
    <div className="spinner-wrapper">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="spinner-text">{text}</div>
    </div>
  );
}

export default Spinner;
