import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import closeIcon from "../../../../../../assets/close-icon.svg";

import { useSelector } from "react-redux";

import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

import { formatPrice } from "../../../../../../utils/currency";
import { getTransactionFileName } from "../../../../../../utils/parser";

import cancelIcon from "../../../../../../assets/delete-cancel-svgrepo-com.svg";
import checkIcon from "../../../../../../assets/accept-check-good-mark-ok-tick-svgrepo-com.svg";

const TransferencesTable = ({
  transferences = [],
  onCheckFilter,
  showConfirm,
  checkedTransferences,
}: *) => {
  const { t } = useTranslation();

  return (
    <div className="transferences-content-wrapper">
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="transferences-table-head">
            <TableRow>
              <TableCell className="table-head-cell"></TableCell>
              <TableCell className="table-head-cell">
                {t("Loan.id.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Name.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Second.Name.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Bank.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Bank.account-type.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Account.number.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="transferences-table-body">
            {!transferences.length ? (
              <div className="transferences-notification">
                {t("Data.Empty.label")}
              </div>
            ) : (
              <>
                {transferences.map((transfer, i) => {
                  const checked = checkedTransferences.some(
                    (item) => item.id === transfer.id && item.validate
                  );

                  return (
                    <TableRow className="transferences-body-row" key={i}>
                      <TableCell className="table-body-cell">
                        {!showConfirm && (
                          <div
                            className="checbox-filter-item"
                            key={transfer.id}
                          >
                            <input
                              type="checkbox"
                              id={`checbox-filter-label-${transfer.id}`}
                              value={transfer.id}
                              defaultChecked={checked}
                              onChange={onCheckFilter}
                            />
                          </div>
                        )}

                        {showConfirm && checked && <img src={checkIcon} />}

                        {showConfirm && !checked && <img src={cancelIcon} />}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.contractNumber}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.name}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.surname}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.bank}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.accountType}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {transfer.accountNumber}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {formatPrice(transfer.amount)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const ValidateTransferences = ({
  openModal,
  onCloseModal,
  onFetchValidateTransferences,
}: *) => {
  const [transferences, setTransferences] = React.useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const { t } = useTranslation();

  const {
    filesData,
    filesDataRequestPending,
    filesDataRequestError,
    filesDataRequestSuccess,
  } = useSelector((state) => ({
    filesData: state.transferences.filesData,
    filesDataRequestPending:
      state.transferences.filesDataRequest.filesDataRequestPending,
    filesDataRequestError:
      state.transferences.filesDataRequest.filesDataRequestError,
    filesDataRequestSuccess:
      state.transferences.filesDataRequest.filesDataRequestSuccess,
  }));

  React.useEffect(() => {
    if (filesData?.transfers?.length) {
      setTransferences(
        filesData?.transfers?.map((item) => ({
          id: item.id,
          validate: true,
        }))
      );
    }
  }, [filesData?.transfers]);

  const onValidationModal = React.useCallback(() => {
    if (showConfirm) {
      setShowConfirm(false);
      return;
    }
    onCloseModal();
  }, [onCloseModal, showConfirm]);

  const onValidateTransferences = React.useCallback(() => {
    onFetchValidateTransferences?.({
      bankFileId: filesData?.bankFileId,
      transfers: transferences,
    });
  }, [onFetchValidateTransferences, filesData?.bankFileId, transferences]);

  const onCheckFilter = React.useCallback(
    (event) => {
      const id = parseInt(event.target.value);

      setTransferences(
        transferences.map((item) => {
          if (item.id === id) {
            return {
              id: item.id,
              validate: event.target.checked,
            };
          }

          return item;
        })
      );
    },
    [transferences]
  );

  const confirmTransferences = React.useCallback(
    () => setShowConfirm(true),
    []
  );

  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className="modal-validate-file-wrapper"
    >
      <DialogContent className="validate-file-content">
        <div className="validate-file-header-wrapper">
          <div className="validate-file-actions-wrapper">
            <div className="validate-file-title">
              {t("Validate.transferences.title")}
            </div>
          </div>
          <div className="close-img-wrapper" onClick={onValidationModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          {filesDataRequestPending && (
            <div className="loading-wrapper">
              <Spinner text={`${t("Loading.data.label")}...`} />
            </div>
          )}

          {filesDataRequestError && <ErrorMessage text={filesData?.message} />}

          {filesDataRequestSuccess && (
            <>
              <div className="file-data">
                {!showConfirm && (
                  <>
                    <div>
                      {t("File.name.in.bank")}:
                      <span>
                        {getTransactionFileName(filesData?.bankFileName)}
                      </span>
                    </div>

                    <div>
                      {t("Transferences.quantity")}:{" "}
                      <span>{filesData?.quantityTransfers}</span>
                    </div>

                    <div>
                      {t("Total.amount")}:{" "}
                      <span>{formatPrice(filesData?.totalAmount)}</span>
                    </div>
                  </>
                )}

                {showConfirm && (
                  <div>
                    {t("Validate.selected.transferences.confirmation.message")}:
                    <span>
                      {getTransactionFileName(filesData?.bankFileName)}
                    </span>
                  </div>
                )}
              </div>

              {transferences?.length && (
                <TransferencesTable
                  onCheckFilter={onCheckFilter}
                  transferences={filesData?.transfers}
                  showConfirm={showConfirm}
                  checkedTransferences={transferences}
                />
              )}

              {showConfirm && (
                <div className="confirmation-message">
                  {t("confirmation.message")}
                </div>
              )}

              <div className="buttons-actions">
                {!showConfirm && (
                  <div className="payment-options-wrapper long-button">
                    <div className="payment-bths-wrapper">
                      <div className="bth-wrapper make-payment-bth-wrapper">
                        <button
                          className="bth make-payment-bth"
                          onClick={confirmTransferences}
                        >
                          {t("Validate.selected.transferences")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {showConfirm && (
                  <>
                    <div className="payment-options-wrapper">
                      <div className="payment-bths-wrapper">
                        <div className="bth-wrapper make-payment-bth-wrapper">
                          <button
                            className="bth make-payment-bth"
                            onClick={onValidateTransferences}
                          >
                            {t("confirmation.modal.accept")}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="payment-options-wrapper">
                      <div className="payment-bths-wrapper">
                        <div className="bth-wrapper make-payment-bth-wrapper">
                          <button
                            className="bth make-payment-bth"
                            onClick={onValidationModal}
                          >
                            {t("confirmation.modal.cancel")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ValidateTransferences;
