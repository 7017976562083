import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store";
import { Provider } from "react-redux";

import "./App.scss";
import "./i18n";

import { loadUser } from "./actions/auth/auth";
import Login from "./components/auth/login/Login";
import Register from "./components/auth/register/Register";
import RecoverPassword from "./components/account/RecoverPassword";
import MainPage from "./components/main-page/MainPage";
import UserProfilePage from "./components/user-profile-page/UserProfilePage";
import AdminPage from "./components/admin-page/AdminPage";
import ConfirmNewEmailPage from "./components/confirm-new-email-page/ConfirmNewEmailPage";
import SetRecoverPasswordPage from "./components/set-recover-password-page/SetRecoverPasswordPage";
import PrivateRoute from "./components/common/private-route/PrivateRoute";
import VerificationForm from "./components/account/forms/verification-form/VerifivationForm";
import LoanForm from "./components/account/forms/loan-form/LoanForm";
import UploadPhotoForm from "./components/account/forms/upload-photo-form/UploadPhotoForm";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("accessToken");

    if (token) {
      store.dispatch(loadUser());
    }

    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      debug: process.env.NODE_ENV === "development",
    });

    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      {},
      {
        autoConfig: true,
        debug: process.env.NODE_ENV === "development",
      }
    );
  }

  render() {
    return (
      <Provider store={store}>
        <Fragment>
          <Router>
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/passwordrecover"
                component={RecoverPassword}
              />
              <Route
                exact
                path="/setemail/:uidb64/:token/:email/"
                component={ConfirmNewEmailPage}
              />
              <Route
                exact
                path="/reset-password/:token"
                component={SetRecoverPasswordPage}
              />
              <PrivateRoute
                exact
                path="/first-loan"
                component={VerificationForm}
              />
              <PrivateRoute exact path="/request-loan" component={LoanForm} />
              <PrivateRoute
                exact
                path="/upload-photos"
                component={UploadPhotoForm}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                component={UserProfilePage}
              />
              <PrivateRoute exact path="/admin" component={AdminPage} />
              <Route
                exact
                path="*"
                render={() => <Redirect to={{ pathname: "/" }} />}
              />
            </Switch>
          </Router>
        </Fragment>
      </Provider>
    );
  }
}

export default App;
