const updateEmailActions = {
  SEND_VERIFICATION_EMAIL_PENDING: 'SEND_VERIFICATION_EMAIL_PENDING',
  SEND_VERIFICATION_EMAIL_SUCCESS: 'SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_ERROR: 'SEND_VERIFICATION_EMAIL_ERROR',

  RESET_SEND_VERIFICATION_EMAIL_STORE: 'RESET_SEND_VERIFICATION_EMAIL_STORE',

  ACTIVATE_NEW_EMAIL_PENDING: 'ACTIVATE_NEW_EMAIL_PENDING',
  ACTIVATE_NEW_EMAIL_SUCCESS: 'ACTIVATE_NEW_EMAIL_SUCCESS',
  ACTIVATE_NEW_EMAIL_ERROR: 'ACTIVATE_NEW_EMAIL_ERROR',

  RESET_ACTIVATE_EMAIL_STORE: 'RESET_ACTIVATE_EMAIL_STORE',
};

export default updateEmailActions;
