import React, { useState } from "react";

import logoCharIcon from "../../assets/logo-char-icon.svg";
import logoutIcon from "../../assets/logout-icon.svg";
import inboxIcon from "../../assets/inbox-icon.svg";
import dashboardIcon from "../../assets/dashboard-icon.svg";
import contractsIcon from "../../assets/contracts-icon.svg";
import bankIcon from "../../assets/bank-logo-icon.svg";
import loanersIcon from "../../assets/loaners-icon.svg";
import moneyIcon from "../../assets/money-insert.svg";
import conciliationIcon from "../../assets/money-svgrepo-com.svg";

import "./styles.scss";

import adminNavigationTabs from "../../constants/adminNavigationTabs";
import PannelLayout from "./pannel-layout/PannelLayout";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../actions/auth/auth";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import ConfirmationModal from "../common/confirmation-modal/ConfirmationModal";

const AdminPage = () => {
    const [activeTab, setActiveTab] = useState(1);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [showConfirmationModal, setShowConfirmationModal] = React.useState(
        false
    );

    const { loansInboxList, userAuth } = useSelector((state) => ({
        loansInboxList: state.inbox.inboxList,
        userAuth: state.auth.isAuthenticated,
    }));

    const onLogout = React.useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const onPressLogOut = React.useCallback(() => {
        setShowConfirmationModal(true);
    }, []);

    const closeModal = React.useCallback(() => {
        setShowConfirmationModal(false);
    }, []);

    if (!userAuth) {
        history.push("/login");
    }
    return (
        <div className="admin-page-wrapper">
            <div className="admin-navigation-wrapper">
                <div className="navigation-bths-list-wrapper">
                    <div className="logo-wrapper">
                        <img src={logoCharIcon} alt="Pisto" />
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper navigation-bth-inbox ${
                            activeTab === adminNavigationTabs.INBOX
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={inboxIcon}
                            alt="Inbox"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.INBOX)
                            }
                        />
                        <div className="indicator-inbox-list-count">
                            {loansInboxList.length}
                        </div>
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper navigation-bth-dashboard ${
                            activeTab === adminNavigationTabs.DASHBOARD
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={dashboardIcon}
                            alt="Dashboard"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.DASHBOARD)
                            }
                        />
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper navigation-bth-contracts ${
                            activeTab === adminNavigationTabs.CONTRACTS
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={contractsIcon}
                            alt="Prestamos"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.CONTRACTS)
                            }
                        />
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper navigation-bth-loaners ${
                            activeTab === adminNavigationTabs.LOANERS
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={loanersIcon}
                            alt="Clientes"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.LOANERS)
                            }
                        />
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper custom-icon ${
                            activeTab === adminNavigationTabs.BALANCE
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={moneyIcon}
                            alt="Pagos a clientes"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.BALANCE)
                            }
                        />
                    </div>

                    <div
                        className={`navigation-bth-item-wrapper custom-icon rotate ${
                            activeTab === adminNavigationTabs.PAYMENTS
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={moneyIcon}
                            alt="Pagos de operadores"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.PAYMENTS)
                            }
                        />
                    </div>

                    <div
                        className={`navigation-bth-item-wrapper custom-icon ${
                            activeTab === adminNavigationTabs.CONCILIATION
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={conciliationIcon}
                            alt="Conciliacion"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.CONCILIATION)
                            }
                        />
                    </div>
                    <div
                        className={`navigation-bth-item-wrapper navigation-bth-contracts ${
                            activeTab === adminNavigationTabs.TRANSFERENCES
                                ? "active-tab"
                                : ""
                        }`}
                    >
                        <img
                            src={bankIcon}
                            alt="Transferencias bancarias"
                            onClick={() =>
                                setActiveTab(adminNavigationTabs.TRANSFERENCES)
                            }
                        />
                    </div>
                </div>
                <div className="logout-bth-wrapper" onClick={onPressLogOut}>
                    <img src={logoutIcon} alt="Logout" />
                </div>
            </div>
            <div className="navigation-pannel-wrapper">
                <PannelLayout activeTab={activeTab} />
            </div>

            <ConfirmationModal
                openModal={showConfirmationModal}
                onCloseModal={closeModal}
                confirmationText={t("logout.confirmation.message")}
                onPressAccept={onLogout}
            />
        </div>
    );
};

export default AdminPage;
