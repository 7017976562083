import inboxActions from "../../actions/inbox/inbox-types";

const initialState = {
  inboxList: [],
  inboxListRequest: {
    inboxListRequestPending: false,
    inboxListRequestError: false,
    inboxListRequestSuccess: false,
  },

  loanItemInfo: null,
  loanItemInfoRequest: {
    loanItemInfoRequestPending: false,
    loanItemInfoRequestError: false,
    loanItemInfoRequestSuccess: false,
  },

  updateLoanStatusData: null,
  updateLoanStatusRequest: {
    updateLoanStatusRequestPending: false,
    updateLoanStatusRequestError: false,
    updateLoanStatusRequestSuccess: false,
  },
};

function inboxReducer(state = initialState, action) {
  switch (action.type) {
    case inboxActions.FETCH_INBOX_LOANS_PENDING:
      return {
        ...state,
        inboxList: [],
        inboxListRequest: {
          inboxListRequestPending: true,
          inboxListRequestError: false,
          inboxListRequestSuccess: false,
        },
      };
    case inboxActions.FETCH_INBOX_LOANS_ERROR:
      return {
        ...state,
        inboxList: [],
        inboxListRequest: {
          inboxListRequestPending: false,
          inboxListRequestError: true,
          inboxListRequestSuccess: false,
        },
      };
    case inboxActions.FETCH_INBOX_LOANS_SUCCESS:
      return {
        ...state,
        inboxList: action.payload,
        inboxListRequest: {
          inboxListRequestPending: false,
          inboxListRequestError: false,
          inboxListRequestSuccess: true,
        },
      };
    case inboxActions.FETCH_LOAN_EVALUATION_BY_ID_PENDING:
      return {
        ...state,
        loanItemInfo: null,
        loanItemInfoRequest: {
          loanItemInfoRequestPending: true,
          loanItemInfoRequestError: false,
          loanItemInfoRequestSuccess: false,
        },

        updateLoanStatusData: null,
        updateLoanStatusRequest: {
          updateLoanStatusRequestPending: false,
          updateLoanStatusRequestError: false,
          updateLoanStatusRequestSuccess: false,
        },
      };
    case inboxActions.FETCH_LOAN_EVALUATION_BY_ID_ERROR:
      return {
        ...state,
        loanItemInfo: null,
        loanItemInfoRequest: {
          loanItemInfoRequestPending: false,
          loanItemInfoRequestError: true,
          loanItemInfoRequestSuccess: false,
        },
      };
    case inboxActions.FETCH_LOAN_EVALUATION_BY_ID_SUCCESS:
      return {
        ...state,
        loanItemInfo: action.payload,
        loanItemInfoRequest: {
          loanItemInfoRequestPending: false,
          loanItemInfoRequestError: false,
          loanItemInfoRequestSuccess: true,
        },
      };
    case inboxActions.UPDATE_LOAN_STATUS_PENDING:
      return {
        ...state,
        updateLoanStatusData: null,
        updateLoanStatusRequest: {
          updateLoanStatusRequestPending: true,
          updateLoanStatusRequestError: false,
          updateLoanStatusRequestSuccess: false,
        },
      };
    case inboxActions.UPDATE_LOAN_STATUS_ERROR:
      return {
        ...state,
        updateLoanStatusData: action.payload,
        updateLoanStatusRequest: {
          updateLoanStatusRequestPending: false,
          updateLoanStatusRequestError: true,
          updateLoanStatusRequestSuccess: false,
        },
      };
    case inboxActions.UPDATE_LOAN_STATUS_SUCCESS:
      return {
        ...state,
        updateLoanStatusData: action.payload,
        updateLoanStatusRequest: {
          updateLoanStatusRequestPending: false,
          updateLoanStatusRequestError: false,
          updateLoanStatusRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default inboxReducer;
