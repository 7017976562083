import React, { lazy, Suspense } from "react";
import "./styles.scss";

const LazyHeader = lazy(() => import("../layout/Header"));
const LazyTopBanner = lazy(() => import("./top-banner/TopBanner"));
const LazyPeopleBlock = lazy(() => import("./people-block/PeopleBlock"));
const LazyHowItWorksBlock = lazy(() =>
  import("./how-it-works-block/HowItWorksBlock")
);
const LazyOpportunitiesBlock = lazy(() =>
  import("./opportunities-blocks/OpportunitiesBlock")
);
const LazyCustomersCommentsBlock = lazy(() =>
  import("./customers-comments-block/CustomersCommentsBlock")
);
const LazyLoanBlock = lazy(() => import("./loan-block/LoanBlock"));
const LazyFrequentlyQuestionsBlock = lazy(() =>
  import("./frequently-questions-block/FrequentlyQuestionsBlock")
);
const LazyContentGrowthBlock = lazy(() =>
  import("./content-growth-block/ContentGrowthBlock")
);

const LazyFLoatingButton = lazy(() =>
  import("../common/FloatingButton/FloatingButton")
);
const LazyFooter = lazy(() => import("../layout/Footer"));

import { useAnalitycs } from "../../hooks/useAnalytics";

export const MainPage = () => {
  const renderLoader = () => <p>Loading</p>;

  useAnalitycs("MainPage");

  return React.useMemo(
    () => (
      <div className="landing-page">
        <Suspense fallback={renderLoader}>
          <LazyHeader />
          <LazyTopBanner />
          <LazyPeopleBlock />
          <LazyHowItWorksBlock />
          <LazyOpportunitiesBlock />
          <LazyCustomersCommentsBlock />
          <LazyLoanBlock />
          <LazyFrequentlyQuestionsBlock />
          <LazyContentGrowthBlock />
          <LazyFLoatingButton />
          <LazyFooter />
        </Suspense>
      </div>
    ),
    []
  );
};

export default MainPage;
