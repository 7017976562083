import React from "react";

import { useSelector } from "react-redux";
import loanLayout from "../../../../constants/loanLayout";

import LoanPaymentOptions from "./loan-return-process/loan-payment-options/LoanPaymentOptions";
import LoanGettingPermissionProcess from "./loan-getting-permission-process/LoanGettingPermissionProcess";
import LoanGettingMoneyProcess from "./loan-getting-money-process/LoanGettingMoneyProcess";

import { useAnalitycs } from "../../../../hooks/useAnalytics";

const LoanTabPanelLayout = () => {
  useAnalitycs("UserDashboard MyLoan");

  const { loanLayoutValue } = useSelector((state) => ({
    loanLayoutValue: state?.loan?.loanLayout.layout,
  }));

  switch (loanLayoutValue) {
    // 1 - LOAN PROCESS
    case loanLayout.LOAN_PROCCESS:
      return <LoanGettingPermissionProcess />;
    // 2 - LOAN RETURN
    case loanLayout.LOAN_RETURN:
      return <LoanPaymentOptions />;
    // 3 - LOAN GEY MONEY
    case loanLayout.LOAN_GET_MONEY:
      return <LoanGettingMoneyProcess />;
    default:
      return <LoanGettingPermissionProcess />;
  }
};

export default LoanTabPanelLayout;
