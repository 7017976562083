import axios from "axios";
import transferencesActions from "./clients-transferences-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getTransferences = ({ query = "" }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/verification-bank-payments-step-1${query}`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const acceptPayment = ({ paymentId, amount }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_ACCEPT_TRANSFERENCE_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/verification-bank-payments-step-2`,
      {
        paymentId,
        amount,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_ACCEPT_TRANSFERENCE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_ACCEPT_TRANSFERENCE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const deletePayment = ({ paymentId }) => async (dispatch, getState) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_DELETE_TRANSFERENCE_PENDING,
    });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_SERVER}/admin/delete-bank-payment/${paymentId}`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_DELETE_TRANSFERENCE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_DELETE_TRANSFERENCE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
