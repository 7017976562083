const adminNavigationTabs = {
  INBOX: 1,
  DASHBOARD: 2,
  CONTRACTS: 3,
  TRANSFERENCES: 4,
  LOANERS: 5,
  BALANCE: 6,
  PAYMENTS: 7,
  CONCILIATION: 8,
};

export default adminNavigationTabs;
