import axios from "axios";

import { tokenConfig } from "../auth/auth";
import smsVerificationActions from "./sms-verification-types";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: smsVerificationActions[actionType],
    payload: data,
  };
}

export const sendCodeToPhone = (phone) => async (dispatch, getState) => {
  dispatch({ type: smsVerificationActions.SEND_SMS_TO_PHONE_PENDING });

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/validate-phone-step-1`,
      {
        phone,
      },
      tokenConfig(getState)
    );

    localStorage.setItem("vpcID", data?.data?.requestId);

    dispatch({
      type: smsVerificationActions.SEND_SMS_TO_PHONE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: smsVerificationActions.SEND_SMS_TO_PHONE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const verifyCode = (requestId, verificationCode) => (
  dispatch,
  getState
) => {
  dispatch({ type: smsVerificationActions.VERIFY_CODE_PENDING });

  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/client/validate-phone-step-2`,
      {
        requestId,
        phoneCode: verificationCode,
      },
      tokenConfig(getState)
    )
    .then(() => {
      localStorage.removeItem("edvcL");
      localStorage.removeItem("vpcID");
      dispatch({ type: smsVerificationActions.VERIFY_CODE_SUCCESS });
    })
    .catch((err) => {
      dispatch({
        type: smsVerificationActions.VERIFY_CODE_ERROR,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const resetRequestVerifyCode = () => async (dispatch) => {
  dispatch({
    type: smsVerificationActions.RESET_VERIFY_CODE_REQUEST,
    payload: false,
  });
};

//*****  OLDs ***** */

export const setCodeLiveStatus = (value) => async (dispatch) => {
  dispatch(actionCreator("SET_CODE_LIVE_STATUS", value));
};

export const resetSendCodeToPhoneState = (value) => async (dispatch) => {
  dispatch(actionCreator("RESET_SEND_CODE_TO_PHONE_REQUEST_STATE", value));
};

export const resetRequestIdState = (value) => async (dispatch) => {
  dispatch(actionCreator("RESET_REQUEST_ID_STATE", value));
};

export const setShowExpiredTemplate = (value) => async (dispatch) => {
  dispatch(actionCreator("SET_SHOW_EXPIRED_TEMPLATE", value));
};

export const setRequestId = (data, requestStatus) => async (dispatch) => {
  dispatch(
    actionCreator("SEND_SMS_TO_PHONE_SUCCESS", {
      data,
      requestStatus,
    })
  );
};

// REQUEST VERIFICATION PHONE CODE SEND
export const sendCodeToPhoneS = (phone) => (dispatch, getState) => {
  dispatch(actionCreator("SEND_SMS_TO_PHONE_PENDING", true));
  dispatch(
    actionCreator("SEND_SMS_TO_PHONE_SUCCESS", {
      data: null,
      requestStatus: false,
    })
  );
  dispatch(actionCreator("SEND_SMS_TO_PHONE_ERROR", false));
  dispatch(actionCreator("SET_SHOW_EXPIRED_TEMPLATE", false));
  dispatch(actionCreator("SET_INVALID_NUMBER", false));
  const body = JSON.stringify({
    phone,
  });
  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/account/users/sms-request/`,
      body,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(actionCreator("SEND_SMS_TO_PHONE_PENDING", false));
      dispatch(
        actionCreator("SEND_SMS_TO_PHONE_SUCCESS", {
          data: res.data.request_id[0],
          requestStatus: true,
        })
      );
      localStorage.setItem("vpcID", res.data.request_id[0]);
      dispatch(actionCreator("SET_CODE_LIVE_STATUS", true));
    })
    .catch((err) => {
      dispatch(actionCreator("SEND_SMS_TO_PHONE_PENDING", false));
      if (
        err.response.data.Error[0].includes(
          "Concurrent verifications to the same number are not allowed"
        )
      ) {
        dispatch(
          actionCreator("SEND_SMS_TO_PHONE_SUCCESS", {
            data: localStorage.getItem("vpcID"),
            requestStatus: true,
          })
        );
        dispatch(actionCreator("SET_CODE_LIVE_STATUS", true));
        dispatch(actionCreator("SEND_SMS_TO_PHONE_ERROR", false));
      } else if (
        err.response.data.Error[0].includes("Invalid value for param: number")
      ) {
        dispatch(actionCreator("SET_INVALID_NUMBER", true));
      } else {
        dispatch(actionCreator("SEND_SMS_TO_PHONE_ERROR", true));
      }
    });
};

// VERIFIFY USER CODE
export const verifyCodeS = (requestId, verificationCode) => (
  dispatch,
  getState
) => {
  const body = {
    request_id: requestId,
    verification_code: verificationCode,
  };
  dispatch(actionCreator("VERIFY_CODE_PENDING", true));
  dispatch(actionCreator("VERIFY_CODE_ERROR", false));
  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/account/users/sms-verify/`,
      body,
      tokenConfig(getState)
    )
    .then(() => {
      dispatch(actionCreator("VERIFY_CODE_PENDING", false));
      dispatch(actionCreator("VERIFY_CODE_SUCCESS", true));
    })
    .catch(() => {
      dispatch(actionCreator("VERIFY_CODE_PENDING", false));
      dispatch(actionCreator("VERIFY_CODE_ERROR", true));
    });
};
