import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../assets/close-icon.svg";

function InfoModal({ openModalInfo, closeModalInfo, infoText }: *) {
  return (
    <Dialog
      onClose={closeModalInfo}
      open={openModalInfo}
      className="modal-info-wrapper"
    >
      <DialogContent className="modal-info-content">
        <div className="close-icon-wrapper" onClick={closeModalInfo}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="info-text">{infoText}</div>
      </DialogContent>
    </Dialog>
  );
}

export default InfoModal;
