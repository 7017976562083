import React from "react";
import "./styles.scss";
import UserProfileHeader from "./user-profile-header/UserProfileHeader";
import VerticalTabs from "./tabs/Tabs";
import FLoatingButton from "../common/FloatingButton/FloatingButton";

export function UserProfilePage() {
  return (
    <div className="user-profile-wrapper">
      <div className="left-auxiliary-bg-block"></div>
      <div className="content-container">
        <UserProfileHeader></UserProfileHeader>
        <VerticalTabs></VerticalTabs>
        <FLoatingButton />
      </div>
      <div className="right-auxiliary-bg-block"></div>
    </div>
  );
}

export default UserProfilePage;
