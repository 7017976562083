import burgerMenuActions from "../../actions/burger-menu/burger-menu-types";
import authActions from "../../actions/auth/auth-types";

const initialState = {
  activeBurgerMenu: false,
};

function burgerMenuReducer(state = initialState, action) {
  switch (action.type) {
    case burgerMenuActions.SET_BURGER_MENU_STATE:
      return {
        ...state,
        activeBurgerMenu: action.payload,
      };
    case authActions.FETCH_LOG_OUT_USER_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

export default burgerMenuReducer;
