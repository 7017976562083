import React from "react";
import MuiPhoneInput from "material-ui-phone-number";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import dolarIcon from "../../../../../../assets/dolar-icon.svg";
import { setNewLoanData } from "../../../../../../actions/loan/loan";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import { setAdditionalInfo } from "../../../../../../actions/verification/verification";
import { typesWithCompanyInfo } from "../../../../../../constants/jobTypes";
import { checkOnlyNumbers } from "../../../../../../utils/regex";

const BthsTemplate = ({ continueCb, backCb, aciveBth, values }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="bths-wrapper">
      <div className="bth-action bth-back-wrapper">
        <button type="button" onClick={() => backCb()}>
          {t("Back.label")}
        </button>
      </div>
      <div className="bth-action bth-continue-wrapper">
        <button
          onClick={() => {
            continueCb();
            dispatch(setAdditionalInfo(values));
            dispatch(
              setNewLoanData({
                loanPurpose: values.loanPurpose,
                description: values.description,
              })
            );
          }}
          disabled={!aciveBth}
          className={`bth-continue ${!aciveBth ? "disabled" : "active"}`}
        >
          {t("Continue.label")}
        </button>
      </div>
    </div>
  );
};

const CompanyPhone = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}: *) => {
  const { t } = useTranslation();
  return (
    <fieldset className="form-item company-phone-item">
      <div className="flex-container">
        <label className="label-item" htmlFor="companyPhone">
          {t("Company.Phone.label")}
          <span className="start">&#42;</span>
        </label>
        <MuiPhoneInput
          className="input-item"
          id="companyPhone"
          name="companyPhone"
          type="tel"
          value={values.companyPhone}
          onChange={(value) => setFieldValue("companyPhone", value)}
          defaultCountry="sv"
          countryCodeEditable={false}
          onBlur={() => setFieldTouched("companyPhone", true)}
        />
      </div>
      {errors.companyPhone && touched.companyPhone ? (
        <ErrorMessage text={t(errors.companyPhone)} />
      ) : null}
    </fieldset>
  );
};

const CompanyName = ({ errors, touched }: *) => {
  const { t } = useTranslation();
  return (
    <fieldset className="form-item company-name-item">
      <div className="flex-container">
        <label className="label-item" htmlFor="companyName">
          {t("Company.Name.label")}
          <span className="start">&#42;</span>
        </label>
        <Field
          className="input-item"
          type="text"
          name="companyName"
          id="companyName"
        />
      </div>
      {errors.companyName && touched.companyName ? (
        <ErrorMessage text={t(errors.companyName)} />
      ) : null}
    </fieldset>
  );
};

const UserAdditionalInfo = ({ nextStep, prevStep }: *) => {
  const { t } = useTranslation();

  const { verificationData = {}, purposes, jobTypes } = useSelector(
    (state) => ({
      verificationData: state.verification,
      purposes: state.loan.purposes,
      jobTypes: state.loan.jobTypes,
    })
  );

  const {
    typeOfEmployment,
    netIncome,
    companyName,
    additionalIncome,
    companyPhone,
    otherLoans,
    loanPurpose,
    description,
    institutionName,
    familiarName,
    familiarSurname,
    familiarPhone,
    friendName,
    friendSurname,
    friendPhone,
  } = verificationData;

  const usersCompanyInfoRequired = React.useMemo(
    () =>
      jobTypes.filter((item) =>
        typesWithCompanyInfo.includes(item.toLowerCase())
      ),
    [jobTypes]
  );

  const AdditionalInfoSchema = Yup.object().shape({
    typeOfEmployment: Yup.string().required("Required.label"),
    netIncome: Yup.string().required("Required.label"),
    companyName: Yup.string().when("typeOfEmployment", {
      is: (value) => usersCompanyInfoRequired.includes(value),
      then: Yup.string().required("Required.label"),
    }),
    institutionName: Yup.string().when("typeOfEmployment", {
      is: (value) => value === "6",
      then: Yup.string().required("Required.label"),
    }),
    additionalIncome: Yup.string().required("Required.label"),
    companyPhone: Yup.string().when("typeOfEmployment", {
      is: (value) => usersCompanyInfoRequired.includes(value),
      then: Yup.string().required("Required.label"),
    }),
    otherLoans: Yup.string().required("Required.label"),
    loanPurpose: Yup.string().required("Required.label"),
    description: Yup.string().required("Required.label"),

    familiarName: Yup.string().required("Required.label"),
    familiarSurname: Yup.string().required("Required.label"),
    familiarPhone: Yup.string().required("Required.label"),

    friendName: Yup.string().required("Required.label"),
    friendSurname: Yup.string().required("Required.label"),
    friendPhone: Yup.string().required("Required.label"),
  });

  return (
    <div className="form-step-wrapper additional-info-step">
      <Formik
        initialValues={{
          typeOfEmployment: typeOfEmployment,
          netIncome: netIncome,
          companyName: companyName,
          additionalIncome: additionalIncome,
          companyPhone: companyPhone,
          otherLoans: otherLoans,
          loanPurpose: loanPurpose,
          description: description,
          institutionName: institutionName,
          familiarName: familiarName,
          familiarSurname: familiarSurname,
          familiarPhone: familiarPhone,
          friendName: friendName,
          friendSurname: friendSurname,
          friendPhone: friendPhone,
        }}
        validationSchema={AdditionalInfoSchema}
        validateOnMount={true}
      >
        {({
          errors,
          touched,
          values,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form className="form-step form-additional-info">
            <div className="required-fields">
              {t("Required.fields.label")} <span className="start">&#42;</span>
            </div>
            {/* Type Of Employment */}
            <fieldset className="form-item type-employment-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="typeOfEmployment">
                  {t("Type.of.Employment.label")}
                  <span className="start">&#42;</span>
                </label>
                <FormControl variant="filled">
                  <Select
                    name="typeOfEmployment"
                    id="typeOfEmployment"
                    value={values.typeOfEmployment}
                    onChange={(value) => {
                      setFieldValue("typeOfEmployment", value.target.value);
                    }}
                    onClose={() => setFieldTouched("typeOfEmployment", true)}
                  >
                    <MenuItem value="">
                      <em>{t("Choose.Type.label")}</em>
                    </MenuItem>
                    {jobTypes.map((item) => (
                      <MenuItem value={item} key={item}>
                        {t(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {errors.typeOfEmployment && touched.typeOfEmployment ? (
                <ErrorMessage text={t(errors.typeOfEmployment)} />
              ) : null}
            </fieldset>
            {/* Net Income */}
            <fieldset className="form-item netIncome-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="netIncome">
                  {t("Net.Income.label")}
                  <span className="start">&#42;</span>
                </label>
                <div className="input-item-wrapper">
                  <input
                    className="input-item"
                    type="text"
                    name="netIncome"
                    id="netIncome"
                    value={values?.netIncome}
                    onBlur={() => {
                      setFieldTouched("netIncome", true);
                    }}
                    onChange={(e) => {
                      if (!checkOnlyNumbers(e.target.value)) {
                        e.preventDefault();
                        return;
                      }

                      setFieldValue("netIncome", e.target.value);
                    }}
                  />
                  <img className="dolar-icon" src={dolarIcon} alt="" />
                </div>
              </div>
              {errors.netIncome && touched.netIncome ? (
                <ErrorMessage text={t(errors.netIncome)} />
              ) : null}
            </fieldset>
            {/* Company Name or  Institution Name */}
            {usersCompanyInfoRequired.includes(values.typeOfEmployment) && (
              <CompanyName errors={errors} touched={touched} />
            )}
            {/* Additional Income */}
            <fieldset className="form-item additional-income-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="additionalIncome">
                  {t("Additional.Income.label")}
                  <span className="start">&#42;</span>
                </label>
                <div className="input-item-wrapper">
                  <input
                    className="input-item"
                    type="text"
                    name="additionalIncome"
                    id="additionalIncome"
                    value={values?.additionalIncome}
                    onBlur={() => {
                      setFieldTouched("additionalIncome", true);
                    }}
                    onChange={(e) => {
                      if (!checkOnlyNumbers(e.target.value)) {
                        e.preventDefault();
                        return;
                      }

                      setFieldValue("additionalIncome", e.target.value);
                    }}
                  />
                  <img className="dolar-icon" src={dolarIcon} alt="" />
                </div>
              </div>
              {errors.additionalIncome && touched.additionalIncome ? (
                <ErrorMessage text={t(errors.additionalIncome)} />
              ) : null}
            </fieldset>
            {/* Company Phone */}
            {usersCompanyInfoRequired.includes(values.typeOfEmployment) && (
              <CompanyPhone
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            )}

            {/* Other loans */}
            <fieldset className="form-item other-loans-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="otherLoans">
                  {t("Other.loans.credits.label")}
                  <span className="start">&#42;</span>
                </label>
                <div className="input-item-wrapper">
                  <input
                    className="input-item"
                    type="text"
                    name="otherLoans"
                    id="otherLoans"
                    value={values?.otherLoans}
                    onBlur={() => {
                      setFieldTouched("otherLoans", true);
                    }}
                    onChange={(e) => {
                      if (!checkOnlyNumbers(e.target.value)) {
                        e.preventDefault();
                        return;
                      }

                      setFieldValue("otherLoans", e.target.value);
                    }}
                  />
                  <img className="dolar-icon" src={dolarIcon} alt="" />
                </div>
              </div>
              {errors.otherLoans && touched.otherLoans ? (
                <ErrorMessage text={t(errors.otherLoans)} />
              ) : null}
            </fieldset>
            <div className="grey-line"></div>
            {/*  Loan Purpose */}
            <fieldset className="form-item loan-purpose-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="loanPurpose">
                  {t("Loan.Purpose.label")}
                  <span className="start">&#42;</span>
                </label>
                <FormControl
                  variant="filled"
                  className="loan-purpose-form-control"
                >
                  <Select
                    name="loanPurpose"
                    id="loanPurpose"
                    value={values.loanPurpose}
                    onChange={(event) => {
                      setFieldValue("loanPurpose", event.target.value);
                    }}
                    onClose={() => setFieldTouched("loanPurpose", true)}
                  >
                    <MenuItem value="">
                      <em>{t("Choose.purpose.label")}</em>
                    </MenuItem>
                    purposes
                    {purposes.map((item) => (
                      <MenuItem value={item} key={item}>
                        {t(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {errors.loanPurpose && touched.loanPurpose ? (
                <ErrorMessage text={t(errors.loanPurpose)} />
              ) : null}
            </fieldset>
            {/* Description */}
            <fieldset className="form-item description-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="description">
                  {t("Description.label")}
                  <span className="start">&#42;</span>
                </label>
                <textarea
                  className="input-item"
                  type="textarea"
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={(event) => {
                    setFieldValue("description", event.target.value);
                  }}
                  onBlur={() => setFieldTouched("description", true)}
                ></textarea>
              </div>
              {errors.description && touched.description ? (
                <ErrorMessage text={t(errors.description)} />
              ) : null}
            </fieldset>

            <div className="grey-line"></div>
            <div className="reference-label">{t("References.label")}</div>
            <div className="reference-label secondary">
              {t("Personal.reference.family.label")}
            </div>
            <fieldset className="form-item street-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="familiarName">
                  {t("First.Name.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="familiarName"
                  id="familiarName"
                />
              </div>
              {errors.familiarName && touched.familiarName ? (
                <ErrorMessage text={t(errors.familiarName)} />
              ) : null}
            </fieldset>
            <fieldset className="form-item street-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="familiarSurname">
                  {t("Second.Name.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="familiarSurname"
                  id="familiarSurname"
                />
              </div>
              {errors.familiarSurname && touched.familiarSurname ? (
                <ErrorMessage text={t(errors.familiarSurname)} />
              ) : null}
            </fieldset>

            <fieldset className="form-item company-phone-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="familiarPhone">
                  {t("Phone.number.label")}
                  <span className="start">&#42;</span>
                </label>
                <MuiPhoneInput
                  className="input-item"
                  id="familiarPhone"
                  name="familiarPhone"
                  type="tel"
                  value={values.familiarPhone}
                  onChange={(value) => setFieldValue("familiarPhone", value)}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 189) &&
                    e.preventDefault()
                  }
                  defaultCountry="sv"
                  countryCodeEditable={false}
                  onBlur={() => setFieldTouched("familiarPhone", true)}
                />
              </div>
              {errors.familiarPhone && touched.familiarPhone ? (
                <ErrorMessage text={t(errors.familiarPhone)} />
              ) : null}
            </fieldset>
            <div className="reference-label secondary">
              {t("Personal.reference.friend.label")}
            </div>
            <fieldset className="form-item street-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="friendName">
                  {t("First.Name.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="friendName"
                  id="friendName"
                />
              </div>
              {errors.friendName && touched.friendName ? (
                <ErrorMessage text={t(errors.friendName)} />
              ) : null}
            </fieldset>
            <fieldset className="form-item street-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="friendSurname">
                  {t("Second.Name.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="friendSurname"
                  id="friendSurname"
                />
              </div>
              {errors.friendSurname && touched.friendSurname ? (
                <ErrorMessage text={t(errors.friendSurname)} />
              ) : null}
            </fieldset>

            <fieldset className="form-item company-phone-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="friendPhone">
                  {t("Phone.number.label")}
                  <span className="start">&#42;</span>
                </label>
                <MuiPhoneInput
                  className="input-item"
                  id="friendPhone"
                  name="friendPhone"
                  type="tel"
                  value={values.friendPhone}
                  onChange={(value) => setFieldValue("friendPhone", value)}
                  defaultCountry="sv"
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 189) &&
                    e.preventDefault()
                  }
                  countryCodeEditable={false}
                  onBlur={() => setFieldTouched("friendPhone", true)}
                />
              </div>
              {errors.friendPhone && touched.friendPhone ? (
                <ErrorMessage text={t(errors.friendPhone)} />
              ) : null}
            </fieldset>

            <BthsTemplate
              continueCb={() => nextStep()}
              backCb={() => prevStep()}
              aciveBth={isValid}
              values={values}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserAdditionalInfo;
