import React from "react";
import "./styles.scss";

import LoanTitle from "./loan-title/LoanTitle";
import LoanStatus from "./loan-status/LoanStatus";
import LoanContent from "./loan-content/LoanContent";
import LoanActions from "./loan-actions/LoanActions";

import { useDispatch, useSelector } from "react-redux";
import { getUserLoanInfo } from "../../../../../actions/loan/loan";
import Spinner from "../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../common/error-message/ErrorMessage";
import { useTranslation } from "react-i18next";

const LoanGettingPermissionProcess = () => {
  const dispatch = useDispatch();

  const { loanLoading, loanError } = useSelector((state) => ({
    loanLoading: state.loan.loading,
    loanError: state.loan.error,
  }));

  React.useEffect(() => {
    dispatch(getUserLoanInfo());
  }, [dispatch]);

  const { t } = useTranslation();

  if (loanLoading) {
    return <Spinner text={`${t("Loan.Data.Loading.label")}...`} />;
  }

  if (loanError) {
    return <ErrorMessage text={`${t("Some.Error.label")}`} />;
  }

  return (
    <div className="loan-tab-wrapper">
      <LoanTitle />
      <LoanStatus />
      <LoanContent />
      <LoanActions />
    </div>
  );
};

export default LoanGettingPermissionProcess;
