const gettingMoneyActions = {
  SET_ACTIVE_BANK: 'SET_ACTIVE_BANK',

  UPDATE_BANK_DETAILS_PENDING: 'UPDATE_BANK_DETAILS_PENDING',
  UPDATE_BANK_DETAILS_ERROR: 'UPDATE_BANK_DETAILS_ERROR',
  UPDATE_BANK_DETAILS_SUCCESS: 'UPDATE_BANK_DETAILS_SUCCESS',

  RESET_UPDATE_BANK_DETAILS_REQUEST_STATE:
    'RESET_UPDATE_BANK_DETAILS_REQUEST_STATE',
};

export default gettingMoneyActions;
