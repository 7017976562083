import loanActions from "../../actions/loan/loan-types";
import authActions from "../../actions/auth/auth-types";

const initialState = {
    loanLayoutStatus: 1,
    loading: false,
    error: false,
    calculator: {
        range: [],
    },
    loanInfo: {},
    newLoan: {},
    prolongTermsLoanRequest: {
        prolongTermsLoanRequestPending: false,
        prolongTermsLoanRequestError: false,
        prolongTermsLoanRequestSuccess: false,
    },

    refuseReason: {
        index: null,
        description: null,
    },

    refuseLoanRequestData: null,
    refuseLoanRequest: {
        refuseLoanRequestPending: false,
        refuseLoanRequestError: false,
        refuseLoanRequestSuccess: false,
    },

    newLoanRequest: {
        newLoanRequestPending: false,
        newLoanRequestError: false,
        newLoanRequestSuccess: false,
        message: "",
        error: null,
    },

    jobTypes: [],
    jobTypesRequest: {
        jobTypesRequestPending: false,
        jobTypesRequestError: false,
        jobTypesRequestSuccess: false,
    },

    purposes: [],
    purposesRequest: {
        purposesRequestPending: false,
        purposesRequestError: false,
        purposesRequestSuccess: false,
    },

    loanContract: null,
    bankList: [],
    selectedBank: null,
    loanContractRequest: {
        loanContractRequestPending: false,
        loanContractRequestError: false,
        loanContractRequestSuccess: false,
    },

    rejectReasons: [],
    rejectReasonsRequest: {
        rejectReasonsRequestPending: false,
        rejectReasonsRequestError: false,
        rejectReasonsRequestSuccess: false,
    },

    acceptLoanRequestData: null,
    acceptLoanRequest: {
        acceptLoanPending: false,
        acceptLoanError: false,
        acceptLoanSuccess: false,
    },

    closeLoanRequestData: null,
    closeLoanRequest: {
        closeLoanPending: false,
        closeLoanError: false,
        closeLoanSuccess: false,
    },

    resubmitPhotosRequestData: null,
    resubmitPhotosRequest: {
        resubmitPhotosPending: false,
        resubmitPhotosError: false,
        resubmitPhotosSuccess: false,
    },

    loanContractPDF: null,
    loanContractPDFRequest: {
        loanContractPDFRequestPending: false,
        loanContractPDFRequestError: false,
        loanContractPDFRequestSuccess: false,
    },

    loanRequestPDF: null,
    loanRequestPDFInfo: {
        loanRequestPDFPending: false,
        loanRequestPDFError: false,
        loanRequestPDFSuccess: false,
    },

    promissoryNotePDF: null,
    promissoryNotePDFInfo: {
        promissoryNotePDFPending: false,
        promissoryNotePDFError: false,
        promissoryNotePDFSuccess: false,
    },

    loanLayout: {
        layout: 1,
        layoutStep: 1,
    },
};

function loanReducer(state = initialState, action) {
    switch (action.type) {
        case loanActions.SET_LOAN_LAYOUT:
            return {
                ...state,
                loanLayoutStatus: action.payload,
            };
        case loanActions.SET_NEW_LOAN_DATA:
            return {
                ...state,
                newLoan: {
                    ...state.newLoan,
                    ...action.payload,
                },
            };
        case loanActions.GET_LOAN:
            return {
                ...state,
                currentLoan: action.payload,
            };
        case loanActions.GET_CALCULATOR_LOAN_DATA_FETCH:
            return {
                ...state,
                loading: true,
            };
        case loanActions.SET_CALCULATOR_RANGE:
            return {
                ...state,
                calculator: {
                    ...state.calculator,
                    ...action.payload,
                },
                loading: false,
            };
        case loanActions.GET_CALCULATOR_LOAN_DATA_SUCCESS:
            return {
                ...state,
                calculator: {
                    ...action.payload,
                },
                loading: false,
            };
        case loanActions.GET_CALCULATOR_LOAN_DATA_ERROR:
            return {
                ...state,
                loading: false,
            };
        case loanActions.GET_LOAN_DATA_FETCH:
            return {
                ...state,
                loanInfo: {},
                loading: true,
                error: false,
            };
        case loanActions.GET_LOAN_DATA_SUCCESS:
            return {
                ...state,
                loanInfo: {
                    ...action.payload,
                },
                loading: false,
            };
        case loanActions.GET_LOAN_DATA_ERROR:
            return {
                ...state,
                loanInfo: {},
                loading: false,
                error: true,
            };
        case loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_PENDING:
            return {
                ...state,
                loanContract: null,
                bankList: [],
                selectedBank: null,
                loanContractRequest: {
                    loanContractRequestPending: true,
                    loanContractRequestError: false,
                    loanContractRequestSuccess: false,
                },
            };
        case loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_ERROR:
            return {
                ...state,
                loanContractRequest: {
                    loanContractRequestPending: false,
                    loanContractRequestError: true,
                    loanContractRequestSuccess: false,
                },
            };
        case loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_SUCCESS:
            return {
                ...state,
                loanContract: action.payload.contract,
                bankList: action.payload.banks,
                oldAccountNumber: action.payload.oldAccountNumber,
                oldAccountType: action.payload.oldAccountType,
                oldBankId: action.payload.oldBankId,
                loanContractRequest: {
                    loanContractRequestPending: false,
                    loanContractRequestError: false,
                    loanContractRequestSuccess: true,
                },
            };
        case loanActions.FETCH_REFUSE_LOAN_PENDING:
            return {
                ...state,
                refuseLoanRequest: {
                    refuseLoanRequestPending: true,
                    refuseLoanRequestError: false,
                    refuseLoanRequestSuccess: false,
                },
            };
        case loanActions.FETCH_REFUSE_LOAN_ERROR:
            return {
                ...state,
                refuseLoanRequest: {
                    refuseLoanRequestPending: false,
                    refuseLoanRequestError: true,
                    refuseLoanRequestSuccess: false,
                },
            };
        case loanActions.FETCH_REFUSE_LOAN_SUCCESS:
            return {
                ...state,
                refuseLoanRequestData: {
                    ...action.payload,
                },
                refuseLoanRequest: {
                    refuseLoanRequestPending: false,
                    refuseLoanRequestError: false,
                    refuseLoanRequestSuccess: true,
                },
            };
        case loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_PENDING:
            return {
                ...state,
                acceptLoanRequestData: null,
                acceptLoanRequest: {
                    acceptLoanPending: true,
                    acceptLoanError: false,
                    acceptLoanSuccess: false,
                },
            };
        case loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_ERROR:
            return {
                ...state,
                acceptLoanRequestData: action.payload,
                acceptLoanRequest: {
                    acceptLoanPending: false,
                    acceptLoanError: true,
                    acceptLoanSuccess: false,
                },
            };
        case loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_SUCCESS:
            return {
                ...state,
                acceptLoanRequestData: action.payload,
                acceptLoanRequest: {
                    acceptLoanPending: false,
                    acceptLoanError: false,
                    acceptLoanSuccess: true,
                },
            };
        case loanActions.FETCH_JOB_TYPES_PENDING:
            return {
                ...state,
                jobTypes: [],
                jobTypesRequest: {
                    jobTypesRequestPending: true,
                    jobTypesRequestError: false,
                    jobTypesRequestSuccess: false,
                },
            };
        case loanActions.FETCH_JOB_TYPES_ERROR:
            return {
                ...state,
                jobTypesRequest: {
                    jobTypesRequestPending: false,
                    jobTypesRequestError: true,
                    jobTypesRequestSuccess: false,
                },
            };
        case loanActions.FETCH_JOB_TYPES_SUCCESS:
            return {
                ...state,
                jobTypes: action.payload,
                jobTypesRequest: {
                    jobTypesRequestPending: false,
                    jobTypesRequestError: false,
                    jobTypesRequestSuccess: true,
                },
            };
        case loanActions.FETCH_PURPOSES_PENDING:
            return {
                ...state,
                purposes: [],
                purposesRequest: {
                    purposesRequestPending: true,
                    purposesRequestError: false,
                    purposesRequestSuccess: false,
                },
            };
        case loanActions.FETCH_PURPOSES_ERROR:
            return {
                ...state,
                purposesRequest: {
                    purposesRequestPending: false,
                    purposesRequestError: true,
                    purposesRequestSuccess: false,
                },
            };
        case loanActions.FETCH_PURPOSES_SUCCESS:
            return {
                ...state,
                purposes: action.payload,
                purposesRequest: {
                    purposesRequestPending: false,
                    purposesRequestError: false,
                    purposesRequestSuccess: true,
                },
            };
        case loanActions.FETCH_REJECT_REASONS_PENDING:
            return {
                ...state,
                rejectReasons: [],
                rejectReasonsRequest: {
                    rejectReasonsRequestPending: true,
                    rejectReasonsRequestError: false,
                    rejectReasonsRequestSuccess: false,
                },
            };
        case loanActions.FETCH_REJECT_REASONS_ERROR:
            return {
                ...state,
                rejectReasons: [],
                rejectReasonsRequest: {
                    rejectReasonsRequestPending: false,
                    rejectReasonsRequestError: true,
                    rejectReasonsRequestSuccess: false,
                },
            };
        case loanActions.FETCH_REJECT_REASONS_SUCCESS:
            return {
                ...state,
                rejectReasons: action.payload,
                rejectReasonsRequest: {
                    rejectReasonsRequestPending: false,
                    rejectReasonsRequestError: false,
                    rejectReasonsRequestSuccess: true,
                },
            };
        case loanActions.CREATE_FIRST_LOAN_FETCH:
        case loanActions.CREATE_LOAN_REQUEST_FETCH:
            return {
                ...state,
                newLoanRequest: {
                    message: null,
                    error: null,
                    newLoanRequestPending: true,
                    newLoanRequestError: false,
                    newLoanRequestSuccess: false,
                },
            };
        case loanActions.CREATE_FIRST_LOAN_ERROR:
        case loanActions.CREATE_LOAN_REQUEST_ERROR:
            return {
                ...state,
                newLoanRequest: {
                    error: action.payload.message,
                    newLoanRequestPending: false,
                    newLoanRequestError: true,
                    newLoanRequestSuccess: false,
                },
            };
        case loanActions.CREATE_FIRST_LOAN_SUCCESS:
        case loanActions.CREATE_LOAN_REQUEST_SUCCESS:
            return {
                ...state,
                newLoanRequest: {
                    message: action.payload,
                    error: null,
                    newLoanRequestPending: false,
                    newLoanRequestError: false,
                    newLoanRequestSuccess: true,
                },
            };
        case loanActions.RESET_REFUSE_REASON:
            return {
                ...state,
                refuseReason: {
                    index: null,
                    description: null,
                },
            };
        case loanActions.SET_REFUSE_REASON:
            return {
                ...state,
                refuseReason: {
                    ...action.payload,
                },
            };
        case loanActions.SET_SELECTED_BANK:
            return {
                ...state,
                selectedBank: {
                    ...action.payload,
                },
            };
        case loanActions.LOAN_TAB_LAYOUT:
            return {
                ...state,
                loanLayout: {
                    ...state.loanLayout,
                    layout: action.payload,
                },
            };
        case loanActions.LOAN_TAB_LAYOUT_STEP:
            return {
                ...state,
                loanLayout: {
                    ...state.loanLayout,
                    layoutStep: action.payload,
                },
            };
        case loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_PENDING:
            return {
                ...state,
                resubmitPhotosRequestData: null,
                resubmitPhotosRequest: {
                    resubmitPhotosPending: true,
                    resubmitPhotosError: false,
                    resubmitPhotosSuccess: false,
                },
            };
        case loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_ERROR:
            return {
                ...state,
                resubmitPhotosRequestData: action.payload,
                resubmitPhotosRequest: {
                    resubmitPhotosPending: false,
                    resubmitPhotosError: true,
                    resubmitPhotosSuccess: false,
                },
            };
        case loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_SUCCESS:
            return {
                ...state,
                resubmitPhotosRequestData: action.payload,
                resubmitPhotosRequest: {
                    resubmitPhotosPending: false,
                    resubmitPhotosError: false,
                    resubmitPhotosSuccess: true,
                },
            };
        case loanActions.FETCH_LOAN_CONTRACT_PENDING:
            return {
                ...state,
                loanContractPDF: null,
                loanContractPDFRequest: {
                    loanContractPDFRequestPending: true,
                    loanContractPDFRequestError: false,
                    loanContractPDFRequestSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_CONTRACT_ERROR:
            return {
                ...state,
                loanContractPDF: null,
                loanContractPDFRequest: {
                    loanContractPDFRequestPending: false,
                    loanContractPDFRequestError: true,
                    loanContractPDFRequestSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_CONTRACT_SUCCESS:
            return {
                ...state,
                loanContractPDF: action.payload,
                loanContractPDFRequest: {
                    loanContractPDFRequestPending: false,
                    loanContractPDFRequestError: true,
                    loanContractPDFRequestSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_REQUEST_PENDING:
            return {
                ...state,
                loanRequestPDF: null,
                loanRequestPDFInfo: {
                    loanRequestPDFPending: true,
                    loanRequestPDFError: false,
                    loanRequestPDFSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_REQUEST_ERROR:
            return {
                ...state,
                loanRequestPDF: null,
                loanRequestPDFInfo: {
                    loanRequestPDFPending: false,
                    loanRequestPDFError: true,
                    loanRequestPDFSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_REQUEST_SUCCESS:
            return {
                ...state,
                loanRequestPDF: action.payload,
                loanRequestPDFInfo: {
                    loanRequestPDFPending: false,
                    loanRequestPDFError: false,
                    loanRequestPDFSuccess: true,
                },
            };
        case loanActions.FETCH_LOAN_PROMISSORY_NOTE_PENDING:
            return {
                ...state,
                promissoryNotePDF: null,
                promissoryNotePDFInfo: {
                    promissoryNotePDFPending: true,
                    promissoryNotePDFError: false,
                    promissoryNotePDFSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_PROMISSORY_NOTE_ERROR:
            return {
                ...state,
                promissoryNotePDF: null,
                promissoryNotePDFInfo: {
                    promissoryNotePDFPending: false,
                    promissoryNotePDFError: true,
                    promissoryNotePDFSuccess: false,
                },
            };
        case loanActions.FETCH_LOAN_PROMISSORY_NOTE_SUCCESS:
            return {
                ...state,
                promissoryNotePDF: action.payload,
                promissoryNotePDFInfo: {
                    promissoryNotePDFPending: false,
                    promissoryNotePDFError: false,
                    promissoryNotePDFSuccess: true,
                },
            };
        case loanActions.FETCH_CREDIT_HISTORY_PENDING:
            return {
                ...state,
                creditHistoryPDF: null,
                creditHistoryPDFInfo: {
                    creditHistoryPDFPending: true,
                    creditHistoryPDFError: false,
                    creditHistoryPDFSuccess: false,
                },
            };
        case loanActions.FETCH_CREDIT_HISTORY_ERROR:
            return {
                ...state,
                creditHistoryPDF: null,
                creditHistoryPDFInfo: {
                    creditHistoryPDFPending: false,
                    creditHistoryPDFError: true,
                    creditHistoryPDFSuccess: false,
                },
            };
        case loanActions.FETCH_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                creditHistoryPDF: action.payload,
                creditHistoryPDFInfo: {
                    creditHistoryPDFPending: false,
                    creditHistoryPDFError: false,
                    creditHistoryPDFSuccess: true,
                },
            };
        case loanActions.FETCH_MAIL_PHONE_PENDING:
            return {
                ...state,
                mailPhonePDF: null,
                mailPhonePDFInfo: {
                    mailPhonePDFPending: true,
                    mailPhonePDFError: false,
                    mailPhonePDFSuccess: false,
                },
            };
        case loanActions.FETCH_MAIL_PHONE_ERROR:
            return {
                ...state,
                mailPhonePDF: null,
                mailPhonePDFInfo: {
                    mailPhonePDFPending: false,
                    mailPhonePDFError: true,
                    mailPhonePDFSuccess: false,
                },
            };
        case loanActions.FETCH_MAIL_PHONE_SUCCESS:
            return {
                ...state,
                mailPhonePDF: action.payload,
                mailPhonePDFInfo: {
                    mailPhonePDFPending: false,
                    mailPhonePDFError: false,
                    mailPhonePDFSuccess: true,
                },
            };
        case loanActions.FETCH_CLOSE_LOAN_PENDING:
            return {
                ...state,
                closeLoanRequestData: null,
                closeLoanRequest: {
                    closeLoanPending: true,
                    closeLoanError: false,
                    closeLoanSuccess: false,
                },
            };
        case loanActions.FETCH_CLOSE_LOAN_ERROR:
            return {
                ...state,
                closeLoanRequestData: action.payload,
                closeLoanRequest: {
                    closeLoanPending: false,
                    closeLoanError: true,
                    closeLoanSuccess: false,
                },
            };
        case loanActions.FETCH_CLOSE_LOAN_SUCCESS:
            return {
                ...state,
                closeLoanRequestData: action.payload,
                closeLoanRequest: {
                    closeLoanPending: false,
                    closeLoanError: false,
                    closeLoanSuccess: true,
                },
            };
        case authActions.FETCH_LOG_OUT_USER_SUCCESS:
        case loanActions.CLEAR_DATA:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
}

export default loanReducer;
