import React from "react";
import "./styles.scss";

import arrowIcon from "../../../../../../assets/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptLoanSecondStep,
  setLoanTabLayout,
  setLoanTabLayoutStep,
  setSelectedBank,
} from "../../../../../../actions/loan/loan";

import { resetUpdateBankDetailsRequestState } from "../../../../../../actions/getting-money/getting-money";

import loanLayout from "../../../../../../constants/loanLayout";

import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";

import { useTranslation } from "react-i18next";

const BanksList = () => {
  const dispatch = useDispatch();

  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const [openModalProcess, setOpenModalProcess] = React.useState(false);

  const [
    openAccountConfirmationModal,
    setOpenAccountConfirmationModal,
  ] = React.useState(false);

  openAccountConfirmationModal;

  const {
    banks,
    activeBank,
    oldAccountNumber,
    oldAccountType,
    oldBankId,
    acceptLoanRequestData,
    acceptLoanPending,
    acceptLoanError,
    acceptLoanSuccess,
  } = useSelector((state) => ({
    banks: state.loan.bankList,
    activeBank: state.loan.selectedBank || {},
    oldAccountNumber: state?.loan?.oldAccountNumber,
    oldAccountType: state?.loan?.oldAccountType,
    oldBankId: state?.loan?.oldBankId,
    acceptLoanRequestData: state.loan.acceptLoanRequestData,
    acceptLoanPending: state.loan.acceptLoanRequest.acceptLoanPending,
    acceptLoanError: state.loan.acceptLoanRequest.acceptLoanError,
    acceptLoanSuccess: state.loan.acceptLoanRequest.acceptLoanSuccess,
  }));

  const oldBankName = React.useMemo(
    () => banks.find((bank) => bank.id === oldBankId)?.name,
    [banks, oldBankId]
  );

  React.useEffect(() => {
    if (oldAccountNumber) {
      setOpenConfirmationModal(true);
    }
  }, [oldAccountNumber]);

  const onSelectBank = React.useCallback(
    (bank) => {
      dispatch(setSelectedBank(bank));
    },
    [dispatch]
  );

  const onCloseModal = React.useCallback(
    () => setOpenConfirmationModal(false),
    []
  );

  const closeAccountConfirmationModal = React.useCallback(() => {
    dispatch(setLoanTabLayout(loanLayout.LOAN_PROCCESS));

    onCloseModal();
    setOpenAccountConfirmationModal(false);
  }, [dispatch, onCloseModal]);

  const onCloseProcessModal = React.useCallback(() => {
    if (acceptLoanSuccess) {
      closeAccountConfirmationModal();
    }

    setOpenModalProcess(false);
  }, [closeAccountConfirmationModal, acceptLoanSuccess]);

  const onAcceptOldAccount = React.useCallback(() => {
    setOpenAccountConfirmationModal(true);
  }, []);

  const onLoanAccepted = React.useCallback(() => {
    dispatch(
      acceptLoanSecondStep({
        bankId: oldBankId,
        accountNumber: oldAccountNumber,
        accountType: oldAccountType,
      })
    );
    setOpenModalProcess(true);
  }, [dispatch, oldBankId, oldAccountNumber, oldAccountType]);

  const { t } = useTranslation();
  return (
    <div className="banks-list-content-wrapper">
      <div className="banks-list-block-header">
        <div className="header-decription">
          {t("Choose.preferred.bank.to.get.money.label")}
        </div>
      </div>
      <div className="banks-list-wrapper">
        {banks.map((bank, i) => (
          <div
            key={i}
            className={`bank-item-wrapper ${
              activeBank.id === bank.id ? "active" : "disabled"
            }`}
            onClick={() => onSelectBank(bank)}
          >
            <div className="bank-logo-wrapper">
              <img src={bank.logo} alt="" />
            </div>
            <div className="bank-name">{bank.name}</div>
          </div>
        ))}
      </div>
      <div className="bth-wrapper bth-bank-details-wrapper">
        <button
          className={`bth bank-details-bth ${!activeBank.id ? "disabled" : ""}`}
          disabled={!activeBank.id}
          onClick={() => {
            dispatch(setLoanTabLayoutStep(2));
          }}
        >
          <span>{t("Continue.label")}</span>
          <img className="arrow-icon" src={arrowIcon} alt="" />
        </button>
      </div>

      <ConfirmationModal
        openModal={openConfirmationModal}
        onCloseModal={onCloseModal}
        cancelButtonText="Registrar nueva cuenta"
        acceptButtonText="Transferir a la misma cuenta"
        big={true}
        onPressAccept={onAcceptOldAccount}
      >
        <div className="modal-account-content">
          <div className="modal-header-component">
            <div className="modal-header-title">
              {t("Confirm-previous-bank-account-title")}:
            </div>
          </div>

          <div className="account-details">
            {oldBankName && (
              <>
                <div>
                  {t("Bank.label")}: {oldBankName}
                </div>
                <div>
                  {t("Account.number.label")}: {oldAccountNumber}
                </div>
                <div>
                  {t("Bank.account-type.label")}: {oldAccountType}
                </div>
              </>
            )}
          </div>

          <div className="account-confirmation-message">
            {t("Confirm-previous-bank-account-message")}
          </div>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        openModal={openAccountConfirmationModal}
        onCloseModal={closeAccountConfirmationModal}
        confirmationText={t("Confirm.account.details.label")}
        big={true}
        onPressAccept={onLoanAccepted}
      >
        <div className="bank-account-confirmation">
          <span>{t("Process.bank.details.message.1")}</span>
          <span className="highlighted">{oldAccountType}</span>
          <span>{t("Process.bank.details.message.2")}</span>
          <span className="highlighted">{oldAccountNumber}</span>
          <span>{t("Process.bank.details.message.3")}</span>
          <span className="highlighted">{oldBankName}?</span>
        </div>
      </ConfirmationModal>

      {openModalProcess && (
        <ProcessModal
          openModalProcess={openModalProcess}
          closeModalProcess={onCloseProcessModal}
          requestPending={acceptLoanPending}
          requestError={acceptLoanError}
          requestSuccess={acceptLoanSuccess}
          fnOnDestroy={() => {
            dispatch(resetUpdateBankDetailsRequestState(false));
          }}
          pendingText={`${t("Saving.details.label")}...`}
          errorText={acceptLoanRequestData?.message}
          successText={acceptLoanRequestData?.message}
        />
      )}
    </div>
  );
};

export default BanksList;
