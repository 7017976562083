import authActions from "../actions/auth/auth-types";

const errorMapper = (err, dispatch) => {
  const status = err?.response?.status;

  if (status === 401) {
    dispatch({
      type: authActions.FETCH_LOG_OUT_USER_SUCCESS,
    });

    return;
  }

  const data = err?.response?.data?.errors || [];

  if (!data.length) {
    return {
      message: err,
    };
  }

  let message = data[0]?.message;

  if (status === 500) {
    message = `<div>Error interno del servidor, por favor contactar-nos a <a href="mailto:${process.env.REACT_APP_CONTACT_EMAIL}">${process.env.REACT_APP_CONTACT_EMAIL}</a></div>`;
  }

  return {
    message,
    data: { ...data },
    status: status,
  };
};

export default errorMapper;
