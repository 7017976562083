import React from "react";
import * as echarts from "echarts/core";

export const useGaugeChart = ({ data, title }) => {
  const [charData, setCharData] = React.useState({});

  React.useEffect(() => {
    if ([undefined, null].includes(data)) {
      setCharData({});
      return;
    }

    setCharData({
      textStyle: {
        fontFamily: "Manrope",
      },
      title: {
        text: title,
        left: "center",
        textStyle: {
          color: "#404040",
          fontWeight: "normal",
          fontSize: 14,
        },
      },
      series: [
        {
          type: "gauge",
          center: ["50%", "70%"],
          radius: "110%",
          min: 0,
          max: 100,
          splitNumber: 10,
          startAngle: 180,
          endAngle: 0,
          axisLine: {
            show: false,
            lineStyle: {
              width: 1,
              color: [[1, "rgba(0,0,0,0)"]],
            },
          },
          axisLabel: {
            // Valores (0, 10, 20, etc)
            show: true,
            color: "#4b695e",
            fontSize: 12,
            distance: -20,
            formatter: function (v) {
              return v;
            },
          },
          axisTick: {
            // barras pequeñas entre los valores (0, 10, etc)
            show: true,
            splitNumber: 5,
            lineStyle: {
              color: "#263b35",
              width: 1,
            },
            length: -10,
          },
          splitLine: {
            show: true,
            length: -10,
            lineStyle: {
              color: "#263b35",
              width: 2,
            },
          },
        },
        {
          type: "gauge",
          radius: "100%",
          center: ["50%", "70%"],
          splitNumber: 0,
          startAngle: 180,
          endAngle: 0,
          axisLine: {
            show: true,
            lineStyle: {
              width: 30,
              color: [
                [
                  1,
                  new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "#ff0000",
                    },
                    {
                      offset: 0.5,
                      color: "#FFFF00",
                    },
                    {
                      offset: 1,
                      color: "#4ccb96",
                    },
                  ]),
                ],
              ],
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          pointer: {
            // Mostrar / ocultar aguja
            show: false,
            length: "80%",
            width: "2%",
          },
          title: {
            show: true,
            offsetCenter: [0, "60%"],
            textStyle: {
              fontWeight: "bold",
              color: "#0ab7ff",
              fontSize: 30,
            },
          },
          detail: {
            formatter: function (value) {
              return `${value}%`;
            },
            // Posicion del valor
            show: true,
            offsetCenter: [0, "-20%"],
            color: "#ffffff",
            textStyle: {
              fontSize: 50,
              color: "#000000",
            },
          },
          data: [
            {
              value: data,
            },
          ],
        },
      ],
    });
  }, [data, title]);

  return { data: charData };
};
