import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import "./styles.scss";
import dniFrontSampleIcon from "../../../../../../assets/front-example.png";
import dniBackSampleIcon from "../../../../../../assets/back-example.png";
import dniSelfiSampleIcon from "../../../../../../assets/selfie-example.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import { setPhotoFilesInfo } from "../../../../../../actions/verification/verification";
import { attachmentsUpload } from "../../../../../../actions/user-attachments/user-attachments";
import { useHistory } from "react-router-dom";

const PhotoSchema = Yup.object().shape({
  frontIdCard: Yup.mixed().required("Required.label"),
  backIdCard: Yup.mixed().required("Required.label"),
  selfie: Yup.mixed().required("Required.label"),
});

const BthsTemplate = ({ continueCb, backCb, aciveBth, values }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="bths-wrapper">
      <div className="bth-action bth-back-wrapper">
        <button type="button" onClick={() => backCb()}>
          {t("Back.label")}
        </button>
      </div>
      <div className="bth-action bth-submit-wrapper">
        <button
          type="button"
          onClick={() => {
            continueCb();
            dispatch(attachmentsUpload(Object.values(values)));
          }}
          disabled={!aciveBth}
          className={`bth-submit ${!aciveBth ? "disabled" : "active"}`}
        >
          {t("Send.label")}
        </button>
      </div>
    </div>
  );
};

const DeleteBthTemplate = ({ fileName, setFieldValue }: *) => {
  const { t } = useTranslation();
  return (
    <div className="loaded-photo-info">
      <div className="dni-photo-name">{fileName}</div>
      <div className="bth-action bth-delete-wrapper">
        <button onClick={setFieldValue}>{t("Delete.label")}</button>
      </div>
    </div>
  );
};

const UploadBthTemplate = ({
  fileName,
  setFieldValue,
  setFieldTouched,
  setTempPhotos,
}: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="bth-action bth-upload-wrapper">
      <Button
        component="label"
        className="bth-upload"
        onBlur={() => setFieldTouched(fileName, true)}
      >
        {t("Upload.label")}
        <input
          onChange={(e) => {
            const fileValue = e.target.files[0];
            const pattern = /image-*/;

            if (!fileValue.type.match(pattern)) {
              return;
            }

            const file = { photoName: fileName, file: fileValue };

            setFieldValue(fileName, file);
            setTempPhotos({
              [`${fileName}_TempImage`]: URL.createObjectURL(fileValue),
            });
            dispatch(setPhotoFilesInfo({ [fileName]: fileValue }));
          }}
          name={fileName}
          id={fileName}
          type="file"
          accept="image/*"
          hidden
          multiple={false}
        />
      </Button>
    </div>
  );
};

const PhotoVerification = ({ nextStep }: { nextStep: * }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [tempPhotos, setTempPhotosState] = React.useState({});

  const setTempPhotos = React.useCallback(
    (item) => {
      setTempPhotosState({
        ...tempPhotos,
        ...item,
      });
    },
    [tempPhotos]
  );

  const { verificationData = {} } = useSelector((state) => ({
    verificationData: state.verification,
  }));

  const onPressBack = React.useCallback(() => {
    history.push("login");
  }, [history]);

  const { frontIdCard, backIdCard, selfie } = verificationData;

  return (
    <div className="form-step-wrapper photo-veftification-info-step">
      <Formik
        initialValues={{
          frontIdCard: frontIdCard.file,
          backIdCard: backIdCard.file,
          selfie: selfie.file,
        }}
        validationSchema={PhotoSchema}
        validateOnMount={true}
      >
        {({
          errors,
          touched,
          values,
          isValid,
          setFieldValue,
          setFieldTouched,
          // validateForm,
        }) => (
          <Form className="form-step photo-veftification-info">
            <div className="form-title-wrapper">
              <div className="description">
                {t("VerifyForm.description.label")}
              </div>
            </div>

            <div className="load-photos-block-wrapper">
              {/* DNI FRONT */}
              <div className="load-photo-block-wrapper DNI-front-wrapper">
                <div className="title">
                  {t("DUI.front.label")} <span className="start">&#42;</span>
                </div>
                {!values.frontIdCard ? (
                  <div className="bths-create-photo-wrapper">
                    <UploadBthTemplate
                      fileName="frontIdCard"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setTempPhotos={setTempPhotos}
                    />
                  </div>
                ) : (
                  <DeleteBthTemplate
                    fileName={values.frontIdCard.file.name}
                    setFieldValue={() => setFieldValue("frontIdCard", null)}
                  />
                )}
                {errors.frontIdCard && touched.frontIdCard ? (
                  <ErrorMessage text={t(errors.frontIdCard)} />
                ) : null}
                <div className="img-sample-wrapper img-DniFront">
                  <img
                    alt="DniFront"
                    src={
                      tempPhotos["frontIdCard_TempImage"] || dniFrontSampleIcon
                    }
                  />

                  {!tempPhotos["frontIdCard_TempImage"] && (
                    <div className="overlay">{t("Sample.label")}</div>
                  )}
                </div>
              </div>
              {/* DNI BACK */}
              <div className="load-photo-block-wrapper DNI-back-wrapper">
                <div className="title">
                  {t("DUI.back.label")} <span className="start">&#42;</span>
                </div>
                {!values.backIdCard ? (
                  <div className="bths-create-photo-wrapper">
                    <UploadBthTemplate
                      fileName="backIdCard"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setTempPhotos={setTempPhotos}
                    />
                  </div>
                ) : (
                  <DeleteBthTemplate
                    fileName={values.backIdCard.file.name}
                    setFieldValue={() => setFieldValue("backIdCard", null)}
                  />
                )}
                {errors.backIdCard && touched.backIdCard ? (
                  <ErrorMessage text={t(errors.backIdCard)} />
                ) : null}
                <div className="img-sample-wrapper img-DniBack">
                  <img
                    alt="DniBack"
                    src={
                      tempPhotos["backIdCard_TempImage"] || dniBackSampleIcon
                    }
                  />

                  {!tempPhotos["backIdCard_TempImage"] && (
                    <div className="overlay">{t("Sample.label")}</div>
                  )}
                </div>
              </div>
              {/* DNI SELFIE */}
              <div className="load-photo-block-wrapper DNI-selfie-wrapper">
                <div className="title">
                  {t("Selfie.with.DUI.label")}{" "}
                  <span className="start">&#42;</span>
                </div>
                {!values.selfie ? (
                  <div className="bths-create-photo-wrapper">
                    <UploadBthTemplate
                      fileName="selfie"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setTempPhotos={setTempPhotos}
                    />
                  </div>
                ) : (
                  <DeleteBthTemplate
                    fileName={values.selfie.file.name}
                    setFieldValue={() => setFieldValue("selfie", null)}
                  />
                )}
                {errors.selfie && touched.selfie ? (
                  <ErrorMessage text={t(errors.selfie)} />
                ) : null}
                <div className="img-sample-wrapper img-selfie">
                  <img
                    alt="selfie"
                    src={tempPhotos["selfie_TempImage"] || dniSelfiSampleIcon}
                  />

                  {!tempPhotos["backIdCard_TempImage"] && (
                    <div className="overlay">{t("Sample.label")}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="step-processing-policy">
              {t(
                "By.clicking.on.Send.Request.I.confirm.that.these.photos.belong.to.me.and.that.these.photos.have.not.been.processed.label"
              )}
            </div>
            <BthsTemplate
              continueCb={() => nextStep()}
              backCb={onPressBack}
              aciveBth={isValid}
              values={values}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PhotoVerification;
