import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../assets/close-icon.svg";

import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";

import { useDispatch, useSelector } from "react-redux";

import {
  fetchLoanInfoByDocument,
  registerEmergencyPayment,
} from "../../../../../../actions/conciliations/conciliations";

import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";

const EmergencyPayment = ({ openModal, onCloseModal }: *) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reasonDescription, setReasonDescription] = React.useState("");
  const [clientDui, setClientDui] = React.useState("");
  const [valueAmount, setValueAmount] = React.useState("");
  const [showProcessModal, setShowProcessModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const {
    loanUserInfo,
    loanUserInfoRequestPending,
    loanUserInfoRequestError,
    emergencyPayment,
    emergencyPaymentRequestPending,
    emergencyPaymentRequestError,
    emergencyPaymentRequestSuccess,
  } = useSelector((state) => ({
    loanUserInfo: state.conciliations.loanUserInfo,
    loanUserInfoRequestPending:
      state.conciliations.loanUserInfoRequest.loanUserInfoRequestPending,
    loanUserInfoRequestError:
      state.conciliations.loanUserInfoRequest.loanUserInfoRequestError,
    loanUserInfoRequestSuccess:
      state.conciliations.loanUserInfoRequest.loanUserInfoRequestSuccess,

    emergencyPayment: state.conciliations.emergencyPayment,
    emergencyPaymentRequestPending:
      state.conciliations.emergencyPaymentRequest
        .emergencyPaymentRequestPending,
    emergencyPaymentRequestError:
      state.conciliations.emergencyPaymentRequest.emergencyPaymentRequestError,
    emergencyPaymentRequestSuccess:
      state.conciliations.emergencyPaymentRequest
        .emergencyPaymentRequestSuccess,
  }));

  const onCloseEmergencyModal = React.useCallback(() => {
    setValueAmount("");
    setClientDui("");
    setReasonDescription("");
    onCloseModal();
  }, [onCloseModal]);

  const closeConfirmationModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
    setValueAmount("");
    setClientDui("");
    setReasonDescription("");
  }, []);

  const onCheckLoan = React.useCallback(() => {
    dispatch(fetchLoanInfoByDocument(clientDui));
  }, [dispatch, clientDui]);

  const onRegisterPayment = React.useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const onCloseProcessModal = React.useCallback(() => {
    setShowProcessModal(false);

    if (emergencyPaymentRequestSuccess) {
      setValueAmount("");
      setClientDui("");
      setOpenConfirmationModal(false);
      onCloseModal();
    }
  }, [emergencyPaymentRequestSuccess, onCloseModal]);

  const onProcessPayment = React.useCallback(() => {
    setShowProcessModal(true);

    dispatch(
      registerEmergencyPayment({
        description: reasonDescription,
        loanId: loanUserInfo?.loan?.id,
        amount: valueAmount,
      })
    );
  }, [dispatch, valueAmount, loanUserInfo?.loan?.id, reasonDescription]);

  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className="modal-emergency-item-wrapper"
    >
      <DialogContent className="emergency-item-content">
        <div className="emergency-item-header-wrapper">
          <div className="emergency-item-actions-wrapper">
            <div className="emergency-item-title">
              {t("Emergency.payment.label")}
            </div>
          </div>
          <div className="close-img-wrapper" onClick={onCloseEmergencyModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="input-container">
            <input
              className="input-item"
              placeholder={t("Client.DUI.label")}
              value={clientDui}
              onChange={(e) => {
                const value = e.target.value;

                if (!/^\d*\.?-?\d*$/.test(value) && value !== "") {
                  return;
                }

                setClientDui(e.target.value);
              }}
            />

            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  disabled={!clientDui}
                  onClick={onCheckLoan}
                >
                  {t("Search.loan.label")}
                </button>
              </div>
            </div>
          </div>

          {loanUserInfoRequestPending && (
            <div className="loading-wrapper">
              <Spinner text={`${t("Loading.data.label")}...`} />
            </div>
          )}

          {loanUserInfoRequestError && (
            <ErrorMessage text={loanUserInfo?.message} />
          )}

          {loanUserInfo?.client && (
            <div className="user-loan-info">
              <div className="user-info">
                <div>{t("Client.label")}: </div>
                <span>
                  {t("DNI.label")}: {loanUserInfo?.client?.dui}
                </span>
                <span>
                  {t("Name.label")}: {loanUserInfo?.client?.name}
                </span>
                <span>
                  {t("Phone.label")}: {loanUserInfo?.client?.phoneNumber}
                </span>
              </div>
              <div className="loan-info">
                <div>{t("Loan.label")}:</div>
                <span>Nº: {loanUserInfo?.loan?.contractNumber}</span>
                <span>
                  {t("Loan.amount.label")}:{" "}
                  {formatPrice(loanUserInfo?.loan?.amount)}
                </span>
                <span>
                  {t("Amount.to.return.label")}:{" "}
                  {formatPrice(loanUserInfo?.loan?.totalRefundAmount)}
                </span>
                <span>
                  {t("Date.of.issue.label")}:{" "}
                  {getDateByFormat({ date: loanUserInfo?.loan?.issueDate })}
                </span>
                <span>
                  {t("Date.expiration.label")}:{" "}
                  {getDateByFormat({ date: loanUserInfo?.loan?.maxReturnDate })}
                </span>
                <span>
                  {t("Refinanced.label")}: {loanUserInfo?.loan?.refinancing}
                </span>
                <span>
                  {t("Expired.label")}: {loanUserInfo?.loan?.expired}
                </span>
                <span>
                  {t("Rest.amount.label")}:{" "}
                  {formatPrice(loanUserInfo?.loan?.restAmount)}
                </span>
                <span>
                  {t("Rest.amount.discount.label")}:{" "}
                  {formatPrice(loanUserInfo?.loan?.amountReturnNow)}
                </span>
              </div>
              <div className="payment-info">
                <div>{t("Conciliations.payment.amount.info")}:</div>

                <input
                  className="input-item"
                  placeholder={formatPrice(100)}
                  value={valueAmount}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (!/^\d*\.?\d*$/.test(value) && value !== "") {
                      return;
                    }

                    setValueAmount(e.target.value);
                  }}
                />

                <div className="payment-bths-wrapper">
                  <div className="bth-wrapper make-payment-bth-wrapper">
                    <button
                      className="bth make-payment-bth"
                      disabled={!valueAmount}
                      onClick={onRegisterPayment}
                    >
                      {t("Process.emergency.payment.label")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="payment-info"></div>
            </div>
          )}
        </div>

        <ConfirmationModal
          openModal={openConfirmationModal}
          onCloseModal={closeConfirmationModal}
          big={true}
          onPressAccept={onProcessPayment}
          buttonDisabled={!reasonDescription}
        >
          <div className="modal-header-component">
            <div className="modal-header-title">
              {t("Emergency.payment.message")}
            </div>

            <div className="payments-container">
              <div className="payment-item">
                <span className="payment-item-title">
                  <div>{t("Client.label")}: </div>
                </span>
                <div className="payment-info">
                  <div className="payment-info-item">
                    <div>{t("DNI.label")}</div>
                    <div>{loanUserInfo?.client?.dui}</div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Operator.label")} </div>
                    <div>{loanUserInfo?.client?.name} </div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Amount.label")} </div>
                    <div>{loanUserInfo?.client?.phoneNumber} </div>
                  </div>
                </div>
              </div>

              <div className="payment-item">
                <span className="payment-item-title">
                  <div>{t("Loan.label")}: </div>
                </span>
                <div className="payment-info">
                  <div className="payment-info-item">
                    <div>{t("Loan.amount.label")}: </div>
                    <div> {formatPrice(loanUserInfo?.loan?.amount)}</div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Amount.to.return.label")}: </div>
                    <div>
                      {formatPrice(loanUserInfo?.loan?.totalRefundAmount)}
                    </div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Date.of.issue.label")}: </div>
                    <div>
                      {getDateByFormat({ date: loanUserInfo?.loan?.issueDate })}
                    </div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Date.expiration.label")}: </div>
                    <div>
                      {getDateByFormat({
                        date: loanUserInfo?.loan?.maxReturnDate,
                      })}
                    </div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Refinanced.label")}: </div>
                    <div>{loanUserInfo?.loan?.refinancing}</div>
                  </div>
                  <div className="payment-info-item">
                    <div>{t("Rest.amount.to.return")}: </div>
                    <div> {formatPrice(loanUserInfo?.loan?.restAmount)}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="final-amount">
              {t("Amount.to.register")}:<span>{formatPrice(valueAmount)}</span>
            </div>

            <div className="textarea-content">
              <span>{t("Emergency.payment.description")}</span>
              <textarea
                className="input-item"
                type="textarea"
                name="description"
                id="description"
                value={reasonDescription}
                onChange={(event) => {
                  setReasonDescription(event.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </ConfirmationModal>

        <ProcessModal
          openModalProcess={showProcessModal}
          closeModalProcess={onCloseProcessModal}
          requestPending={emergencyPaymentRequestPending}
          requestError={emergencyPaymentRequestError}
          requestSuccess={emergencyPaymentRequestSuccess}
          fnOnDestroy={() => {
            // dispatch(resetRegisterRequestStore(false));
          }}
          pendingText={`${t("Processing.information")}...`}
          errorText={emergencyPayment?.message}
          successText={emergencyPayment?.message}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EmergencyPayment;
