import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiPhoneInput from "material-ui-phone-number";
import SmsVerify from "../../../modals/verify/SmsVerify";
import "./styles.scss";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import {
  sendCodeToPhone,
  setCodeLiveStatus,
  setRequestId,
} from "../../../../../../actions/sms-verification/sms-verification";
import { setPersonalInfo } from "../../../../../../actions/verification/verification";

const regexSymbol = /^[a-zA-Z\s-zñáéíóúü]*$/;

const dniRegex = /[0-9]{8,10}-{1}[0-9]{1}$/;

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(regexSymbol, "First.name.must.contain.only.letters.label")
    .required("Required.label"),
  lastName: Yup.string()
    .trim()
    .matches(regexSymbol, "Second.name.must.contain.only.letters.label")
    .required("Required.label"),
  gender: Yup.string().required("Required.label"),
  birthDate: Yup.string()
    .nullable()
    .test("Date of Birth", "Input.date.in.format.MM/dd/yyyy", (value) => {
      return moment(value).isValid();
    })
    .test(
      "Date of Birth",
      "Date.should.not.be.before.minimal.date",
      (value) => {
        return !moment(value).isBefore("1900-01-01");
      }
    )
    .test(
      "Date of Birth",
      "You.must.be.at.least.18.years.old.label",
      (value) => {
        return moment().diff(moment(value), "years") >= 18;
      }
    )
    .required("Required.label"),

  dniNumber: Yup.string()
    .matches(dniRegex, "DNI.validation")
    .required("Required.label"),
  phone: Yup.string().required("Required.label"),
});

const BthsTemplate = ({
  isVerifiedPhone,
  codeLive,
  formValid,
  continueCb,
  modalOpenCb,
  phone,
  values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bthContinueTemplate = (
    <div className="bth-action bth-continue-wrapper">
      <button
        type="button"
        className="bth-continue"
        onClick={() => {
          continueCb();
          dispatch(setPersonalInfo(values));
        }}
      >
        {t("Continue.label")}
      </button>
    </div>
  );
  const bthVerifyPhoneTemplate = (
    <div className="bth-action bth-verify-number-wrapper">
      <button
        disabled={!formValid}
        className={`bth-verify-number ${!formValid ? "disabled" : "active"}`}
        type="button"
        onClick={() => {
          modalOpenCb();
          const edvcL = localStorage.getItem("edvcL");
          const vpcID = localStorage.getItem("vpcID");
          if (codeLive || edvcL) {
            dispatch(setCodeLiveStatus(true));
            dispatch(setRequestId(vpcID, true));
          } else {
            dispatch(sendCodeToPhone(phone));
          }
        }}
      >
        {t("Verify.number.label")}
      </button>
    </div>
  );
  const bthTemplate = isVerifiedPhone
    ? bthContinueTemplate
    : bthVerifyPhoneTemplate;

  return bthTemplate;
};

const UserPersonalInfo = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => {},
  prevStep: () => {},
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = React.useState(false);

  const {
    verifyCodeRequestSuccess,
    codeLive,
    verificationData = {},
  } = useSelector((state) => ({
    isVerifiedPhone: state.auth.isVerifiedPhone,
    verifyCodeRequestSuccess:
      state.smsVerification.verifyCodeRequest.verifyCodeRequestSuccess,
    codeLive: state.smsVerification.codeLive,
    verificationData: state.verification,
  }));

  React.useEffect(() => {
    if (verifyCodeRequestSuccess) {
      setOpenModal(false);
    }
  }, [verifyCodeRequestSuccess]);

  const {
    firstName,
    lastName,
    gender,
    birthDate,
    dniNumber,
    phone,
  } = verificationData;

  return (
    <div className="form-step-wrapper personal-info-step">
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          birthDate: birthDate,
          dniNumber: dniNumber,
          phone: phone,
        }}
        validationSchema={PersonalInfoSchema}
        validateOnMount={true}
      >
        {({
          errors,
          touched,
          values,
          isValid,
          setFieldValue,
          setFieldTouched,
        }: *) => (
          <Form className="form-step form-personal-info">
            <div className="required-fields">
              {t("Required.fields.label")} <span className="start">&#42;</span>
            </div>
            {/* First Name */}
            <fieldset className="form-item first-name-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="firstName">
                  {t("First.Name.label")} <span className="start">&#42;</span>
                </label>
                <Field
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="input-item"
                  onChange={(event) =>
                    setFieldValue("firstName", event.target.value)
                  }
                />
              </div>
              {errors.firstName && touched.firstName ? (
                <ErrorMessage text={t(errors.firstName)} />
              ) : null}
            </fieldset>
            {/* Last Name */}
            <fieldset className="form-item last-name-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="lastName">
                  {t("Second.Name.label")} <span className="start">&#42;</span>
                </label>
                <Field
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="input-item"
                  onChange={(event) =>
                    setFieldValue("lastName", event.target.value)
                  }
                />
              </div>
              {errors.lastName && touched.lastName ? (
                <ErrorMessage text={t(errors.lastName)} />
              ) : null}
            </fieldset>
            {/* Gender */}
            <fieldset className="form-item gender-item">
              <div className="flex-container">
                <div className="radio-title">
                  {t("Gender.label")}
                  <span className="start">&#42;</span>
                </div>
                <div className="radio-group-wrapper">
                  <div className="radio-group-item-wrapper female-radio-item">
                    <Field
                      className="input-item"
                      type="radio"
                      id="female"
                      name="gender"
                      value="f"
                    />
                    <label className="label-item" htmlFor="female">
                      {t("Female.label")}
                    </label>
                  </div>
                  <div className="radio-group-item-wrapper female-radio-item">
                    <Field
                      className="input-item"
                      type="radio"
                      id="male"
                      name="gender"
                      value="m"
                    />
                    <label className="label-item" htmlFor="male">
                      {t("Male.label")}
                    </label>
                  </div>
                </div>
              </div>
              {/* {errors.gender ? (
                <ErrorMessage text={t(errors.gender)} />
              ) : null} */}
            </fieldset>
            {/* Birthday */}
            <fieldset className="form-item birthDate-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="birthDate">
                  {t("Birthday.label")}
                  <span className="start">&#42;</span>
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    className="input-item"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    placeholder="20/12/1993"
                    value={values.birthDate}
                    onChange={(value) => setFieldValue("birthDate", value)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disableFuture={true}
                    allowKeyboardControl={true}
                    invalidDateMessage=""
                    minDateMessage=""
                    maxDateMessage=""
                    onBlur={() => {
                      setFieldTouched("birthDate", true);
                    }}
                    onAccept={(value) => {
                      setFieldTouched("birthDate", true);
                      setFieldValue("birthDate", value);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {errors.birthDate && touched.birthDate ? (
                <ErrorMessage text={t(errors.birthDate)} />
              ) : null}
            </fieldset>
            {/* DNI Number */}
            <fieldset className="form-item dniNumber-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="dniNumber">
                  {t("DUI.Number.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="dniNumber"
                  placeholder="12345678-9"
                  id="dniNumber"
                  onChange={(event) =>
                    setFieldValue("dniNumber", event.target.value)
                  }
                />
              </div>
              {errors.dniNumber && touched.dniNumber ? (
                <ErrorMessage text={t(errors.dniNumber)} />
              ) : null}
            </fieldset>
            {/* Phone */}
            <fieldset className="form-item phone-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="phone">
                  {t("Phone.label")}
                  <span className="start">&#42;</span>
                </label>
                <MuiPhoneInput
                  className="input-item"
                  id="phone"
                  name="phone"
                  type="tel"
                  disabled={verifyCodeRequestSuccess}
                  value={values.phone}
                  onChange={(value) => setFieldValue("phone", value)}
                  defaultCountry="sv"
                  countryCodeEditable={false}
                  onBlur={() => setFieldTouched("phone", true)}
                />
              </div>
              {errors.phone && touched.phone ? (
                <ErrorMessage text={t(errors.phone)} />
              ) : null}
            </fieldset>
            <div className="bths-wrapper">
              <div
                className="bth-action bth-back-wrapper"
                onClick={() => prevStep()}
              >
                <button type="button">{t("Back.label")}</button>
              </div>
              <BthsTemplate
                isVerifiedPhone={verifyCodeRequestSuccess}
                formValid={isValid}
                continueCb={() => nextStep()}
                modalOpenCb={() => setOpenModal(true)}
                phone={values.phone}
                codeLive={codeLive}
                values={values}
              />
            </div>
            <div className="step-processing-policy">
              {t(
                "By.clicking.on.the.Verify.Number.I.agree.to.the.terms.of.use.of.the.service.and.the.personal.data.processing.policy.label"
              )}
            </div>
            {openModal ? (
              <SmsVerify
                phone={values.phone}
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserPersonalInfo;
