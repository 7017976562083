import React from "react";
import TransferencesHeader from "./transferences-header/TransferencesHeader";
import TransferencesContent from "./transferences-content/TransferencesContent";

import "./styles.scss";

function TransferencesLayout() {
  return (
    <div className="dashboard-layout-wrapper">
      <TransferencesHeader />
      <TransferencesContent />
    </div>
  );
}

export default TransferencesLayout;
