import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import logo from "../../assets/logo.svg";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const handleClickLink = (currentRoute, history, blockId) => {
  if (currentRoute === "/") {
    document.getElementById(blockId).scrollIntoView();
    return;
  }
  history.push("/");
  setTimeout(() => {
    document.getElementById(blockId).scrollIntoView();
  }, 0);
};

const Header = () => {
  const { t } = useTranslation();
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  return (
    <header className="container-width">
      <Link to="/" className="nav_link">
        <div className="logo__wrapper">
          <img src={logo} alt="Pisto" />
        </div>
      </Link>

      <div className="nav__wrapper">
        <nav>
          <div
            id="burger-menu__wrapper"
            className={openBurgerMenu ? "open" : ""}
            onClick={() => setOpenBurgerMenu((state) => !state)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`list__wrapper ${openBurgerMenu ? "open" : ""}`}>
            <li>
              <div
                className="nav_link"
                onClick={() =>
                  handleClickLink(
                    location.pathname,
                    history,
                    "how-it-works-block__wrapper"
                  )
                }
              >
                {t("How.It.Works.label")}
              </div>
            </li>
            <li>
              <Link to="profile" className="nav_link">
                {t("Return.a.loan.label")}
              </Link>
            </li>
            <li>
              <div
                to="#"
                className="nav_link"
                onClick={() =>
                  handleClickLink(
                    location.pathname,
                    history,
                    "frequently-questions__block__wrapper"
                  )
                }
              >
                {t("FAQ.label")}
              </div>
            </li>
          </ul>
        </nav>
        <div className="bth-login__wrapper">
          <button className="bth bth-login">
            <Link to="/login" className="nav_link">
              {t("Client.Zone.label")}
            </Link>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
