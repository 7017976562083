import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

function ConciliationHeader() {
  const { t } = useTranslation();
  return (
    <div className="payments-header-wrapper">
      <div className="payments-title-wrapper">{t("Conciliation.label")}</div>
    </div>
  );
}

export default ConciliationHeader;
