import axios from "axios";
import conciliationsActions from "./conciliations-types";
import loanActions from "../loan/loan-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getConciliations = ({ query = "" }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_CONCILIATIONS_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/manual-conciliation-step-1${query}`,
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.FETCH_CONCILIATIONS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_CONCILIATIONS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const conciliatePayments = ({
  operatorPaymentId,
  clientPaymentId,
  amount,
  description,
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_CONCILIATE_PAYMENT_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/manual-conciliation-step-2`,
      {
        operatorPaymentId,
        clientPaymentId,
        amount,
        description,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.FETCH_CONCILIATE_PAYMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_CONCILIATE_PAYMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const fetchLoanInfoByDocument = (dni) => async (dispatch, getState) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/emergency-payment-step-1`,
      {
        dui: dni,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const registerEmergencyPayment = ({
  loanId,
  amount,
  description,
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: conciliationsActions.REGISTER_EMERGENCY_PAYMENT_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/emergency-payment-step-2`,
      {
        loanId,
        amount,
        description,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.REGISTER_EMERGENCY_PAYMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.REGISTER_EMERGENCY_PAYMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const fetchLoanInfoByContractNumber = (contractNumber) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/delete-refinancing-loan/step-one/${contractNumber}`,
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const fetchLoanReturnScaleByContractNumber = (contractNumber) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_PENDING,
    });

    const {
      data: { data: info },
    } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/check-loan-return-scales/${contractNumber}`,
      tokenConfig(getState)
    );

    dispatch({ type: loanActions.SET_LOAN_LAYOUT, payload: info.loanStatus });

    dispatch({
      type: loanActions.GET_LOAN_DATA_SUCCESS,
      payload: {
        ...info,
        returnScale: info.actualReturnScale,
      },
    });

    dispatch({
      type: conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const cancelRefinancement = ({ loanId, description }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: conciliationsActions.FETCH_CANCEL_REFINANCEMENT_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/delete-refinancing-loan/step-two`,
      {
        loanId,
        description,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: conciliationsActions.FETCH_CANCEL_REFINANCEMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: conciliationsActions.FETCH_CANCEL_REFINANCEMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const clearPaymentState = () => async (dispatch) => {
  dispatch({
    type: conciliationsActions.CLEAR_STATE,
  });
};
