const type = "STATE_ADMIN_CONCILIATIONS";

const transferencesActions = {
  FETCH_CONCILIATIONS_PENDING: `${type}_FETCH_CONCILIATIONS_PENDING`,
  FETCH_CONCILIATIONS_ERROR: `${type}_FETCH_CONCILIATIONS_ERROR`,
  FETCH_CONCILIATIONS_SUCCESS: `${type}_FETCH_CONCILIATIONS_SUCCESS`,

  FETCH_CONCILIATE_PAYMENT_PENDING: `${type}_FETCH_CONCILIATE_PAYMENT_PENDING`,
  FETCH_CONCILIATE_PAYMENT_ERROR: `${type}_FETCH_CONCILIATE_PAYMENT_ERROR`,
  FETCH_CONCILIATE_PAYMENT_SUCCESS: `${type}_FETCH_CONCILIATE_PAYMENT_SUCCESS`,

  FETCH_LOAN_INFO_BY_DOCUMENT_PENDING: `${type}_FETCH_LOAN_INFO_BY_DOCUMENT_PENDING`,
  FETCH_LOAN_INFO_BY_DOCUMENT_ERROR: `${type}_FETCH_LOAN_INFO_BY_DOCUMENT_ERROR`,
  FETCH_LOAN_INFO_BY_DOCUMENT_SUCCESS: `${type}_FETCH_LOAN_INFO_BY_DOCUMENT_SUCCESS`,

  REGISTER_EMERGENCY_PAYMENT_PENDING: `${type}_REGISTER_EMERGENCY_PAYMENT_PENDING`,
  REGISTER_EMERGENCY_PAYMENT_ERROR: `${type}_REGISTER_EMERGENCY_PAYMENT_ERROR`,
  REGISTER_EMERGENCY_PAYMENT_SUCCESS: `${type}_REGISTER_EMERGENCY_PAYMENT_SUCCESS`,

  FETCH_LOAN_BY_CONTRACT_ID_PENDING: `${type}_FETCH_LOAN_BY_CONTRACT_ID_PENDING`,
  FETCH_LOAN_BY_CONTRACT_ID_ERROR: `${type}_FETCH_LOAN_BY_CONTRACT_ID_ERROR`,
  FETCH_LOAN_BY_CONTRACT_ID_SUCCESS: `${type}_FETCH_LOAN_BY_CONTRACT_ID_SUCCESS`,

  FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_PENDING: `${type}_FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_PENDING`,
  FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_ERROR: `${type}_FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_ERROR`,
  FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_SUCCESS: `${type}_FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_SUCCESS`,

  FETCH_CANCEL_REFINANCEMENT_PENDING: `${type}_FETCH_CANCEL_REFINANCEMENT_PENDING`,
  FETCH_CANCEL_REFINANCEMENT_ERROR: `${type}_FETCH_CANCEL_REFINANCEMENT_ERROR`,
  FETCH_CANCEL_REFINANCEMENT_SUCCESS: `${type}_FETCH_CANCEL_REFINANCEMENT_SUCCESS`,

  CLEAR_STATE: `${type}_CLEAR_STATE`,
};

export default transferencesActions;
