import React from 'react';
import InboxHeader from './inbox-header/InboxHeader';
import InboxList from './inbox-list/InboxList';

import './styles.scss';

function InboxLayout() {
  return (
    <div className="inbox-layout-wrapper">
      <InboxHeader />
      <InboxList />
    </div>
  );
}

export default InboxLayout;
