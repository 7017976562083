import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import eyeIcon from "../../../assets/eye-icon.svg";
import eyeIconStrike from "../../../assets/eye-icon-strike.svg";
import Header from "../../layout/Header";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import {
  login,
  clearVerificationModal,
  resendVerificationEmail,
} from "../../../actions/auth/auth";
import ConfirmationModal from "../../../components/common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../components/common/process-modal/ProcessModal";
import { useTranslation } from "react-i18next";
import { useAnalitycs } from "../../../hooks/useAnalytics";

const Login = () => {
  useAnalitycs("Login");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [username, setusername] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [usernameTouched, setusernameTouched] = React.useState(false);
  const [passwordTouched, setpasswordTouched] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showResendProccess, setShowResendProccess] = React.useState(false);

  const {
    isAuthenticated,
    isAdmin,
    loginRequestErrorMessage,
    loginRequestPending,
    resendVerificationRequestError,
    resendVerificationRequestMsg,
    resendVerificationRequestPending,
    resendVerificationRequestSuccess,
    showVerificationModal,
    user,
  } = useSelector((state) => ({
    loginRequestErrorMessage: state.auth.loginRequestErrorMessage,
    loginRequestPending: state.auth.loginRequest.loginRequestPending,
    showVerificationModal: state.auth.showVerificationModal,
    isAuthenticated: state.auth.isAuthenticated,
    user: state?.auth?.user,

    resendVerificationRequestMsg: state.auth.resendVerificationRequestMsg,
    resendVerificationRequestPending:
      state.auth.resendVerificationRequest.resendVerificationRequestPending,
    resendVerificationRequestError:
      state.auth.resendVerificationRequest.resendVerificationRequestError,
    resendVerificationRequestSuccess:
      state.auth.resendVerificationRequest.resendVerificationRequestSuccess,
  }));

  const closeModal = React.useCallback(() => {
    dispatch(clearVerificationModal());
  }, [dispatch]);

  const toggleVisibilityPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(login(username, password));
  };
  const onResendEmail = () => {
    dispatch(resendVerificationEmail(username));
    setShowResendProccess(true);
  };

  const onShowProccessClosed = React.useCallback(() => {
    setShowResendProccess(false);
    setusername("");
    setusernameTouched(false);
    setpasswordTouched(false);
    setpassword("");
    closeModal();
  }, [closeModal]);

  React.useEffect(() => {
    if (isAuthenticated && user?.isAdmin !== undefined && !user?.isAdmin) {
      history.push("/dashboard");
    }

    if (isAuthenticated && user?.isAdmin !== undefined && user?.isAdmin) {
      history.push("/admin");
    }
  }, [isAuthenticated, history, isAdmin, user]);

  return (
    <div className="login-page-wrapper">
      <Header />
      <div className="container-width">
        <div className="form__wrapper">
          <div className="form-content-wrapper">
            <div className="form-title">
              {t("Log.In.to.your.account.to.request.a.loan.label")}
            </div>
            <div className="form-inputs-wrapper">
              <form className="form" onSubmit={onSubmit}>
                <fieldset className="input-group email-group">
                  <label htmlFor="email">{t("Email.address.label")}</label>
                  <input
                    type="text"
                    id="email"
                    name="username"
                    value={username}
                    onChange={(e) => {
                      setusername(e.target.value);
                      setusernameTouched(true);
                    }}
                  />
                  {username.length === 0 && usernameTouched && (
                    <div className="invalid-email-info">
                      {t("Required.label")}
                    </div>
                  )}
                </fieldset>
                <fieldset className="input-group password-group">
                  <label htmlFor="password">{t("Password.label")}</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                      setpasswordTouched(true);
                    }}
                  />
                  {password.length === 0 && passwordTouched && (
                    <div className="invalid-email-info">
                      {t("Required.label")}
                    </div>
                  )}
                  <div
                    className={`eye-icon__wrapper ${
                      showPassword ? "" : "visible"
                    }`}
                  >
                    <img
                      className="eye-icon"
                      src={showPassword ? eyeIcon : eyeIconStrike}
                      alt=""
                      onClick={toggleVisibilityPassword}
                    />
                  </div>
                </fieldset>
                <button
                  disabled={
                    loginRequestPending ||
                    password.length === 0 ||
                    username.length === 0
                  }
                  className={`submit-bth ${
                    loginRequestPending ||
                    password.length === 0 ||
                    username.length === 0
                      ? "disabled"
                      : "active"
                  }`}
                  type="submit"
                >
                  {t("Log.In.label")}
                </button>
                {loginRequestErrorMessage && (
                  <ErrorMessage text={loginRequestErrorMessage?.message} />
                )}
                {loginRequestPending ? (
                  <div className="login-pending">
                    {t("Wait.A.Minute.label")}
                  </div>
                ) : (
                  ""
                )}
                <div className="register-link-wrapper">
                  <Link to={"/register"}>{t("Dont.have.account.label")}</Link>
                </div>
                <div className="forgot-password-bth__wrapper">
                  <Link to={"/passwordrecover"}>
                    {t("Forgot.your.password.label")}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        openModal={showVerificationModal && !showResendProccess}
        acceptButtonText="Reenviar Correo"
        onCloseModal={closeModal}
        additionalText={t("Resend.email.text")}
        confirmationText={t("Resend.email.title")}
        onPressAccept={onResendEmail}
      />

      <ProcessModal
        openModalProcess={showResendProccess}
        closeModalProcess={onShowProccessClosed}
        requestPending={resendVerificationRequestPending}
        requestError={resendVerificationRequestError}
        requestSuccess={resendVerificationRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetRegisterRequestStore(false));
        }}
        pendingText={`${t("Sending.Email.label")}...`}
        errorText={resendVerificationRequestMsg?.message}
        successText={resendVerificationRequestMsg?.message}
      />
    </div>
  );
};

export default Login;
