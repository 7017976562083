import React, { useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";

import { getFAQS } from "../../../../actions/generals/generals";

import tick2Icon from "../../../../assets/tick2-icon.svg";
import Spinner from "../../../common/spinner/Spinner";
import { useTranslation } from "react-i18next";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

const QuestionAccordion = ({ item }: *) => {
  const { t } = useTranslation();

  const [openAccordion, setOpenAccordion] = useState(false);
  return (
    <div
      className={`accordion-item-wrapper ${
        openAccordion
          ? "accordion-item-wrapper-open"
          : "accordion-item-wrapper-close"
      }`}
    >
      <div
        className="heading-accordion"
        onClick={() => setOpenAccordion((state) => !state)}
      >
        <div className="question">{t(item.question)}</div>
        <div
          className={`accordion-icon-wrapper  ${
            openAccordion
              ? "accordion-icon-wrapper-open"
              : "accordion-icon-wrapper-close"
          }`}
        >
          <img src={tick2Icon} alt="" />
        </div>
      </div>
      <div
        className={`body-accordion ${
          openAccordion ? "body-accordion-open" : "body-accordion-close"
        }`}
      >
        {t(item.answer)}
      </div>
    </div>
  );
};

export const HelpTab = () => {
  useAnalitycs("UserDashboard Help");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { faqs, faqsRequestPending } = useSelector((state) => ({
    faqs: state?.generals?.faqs || [],
    faqsRequestPending: state?.generals?.faqsRequest?.faqsRequestPending,
  }));

  React.useEffect(() => {
    dispatch(getFAQS());
  }, [dispatch]);

  return (
    <div className="help-tab-wrapper">
      <div className="tab-title">{t("Help.label")}</div>

      {faqsRequestPending && <Spinner text="" />}

      <div className="question-accordions__wrapper">
        {faqs.map((item, i) => (
          <QuestionAccordion item={item} key={i}></QuestionAccordion>
        ))}
      </div>
    </div>
  );
};

export default HelpTab;
