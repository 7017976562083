import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../../assets/close-icon.svg";

const AddCommentModal = ({
  closeModal,
  loanerInfo,
  onPressAccept,
  openAddCommentModal,
}: *) => {
  const { t } = useTranslation();
  const [reasonDescription, setReasonDescription] = React.useState("");

  const onChangeDescription = React.useCallback((e) => {
    setReasonDescription(e.target.value);
  }, []);

  const onAcceptButton = React.useCallback(() => {
    onPressAccept({
      clientId: loanerInfo?.clientId,
      comment: reasonDescription,
      action: "addComment",
      title: t("Add.user.comment.confirmation.message"),
    });
  }, [reasonDescription, onPressAccept, loanerInfo?.clientId, t]);

  return (
    <Dialog
      onClose={closeModal}
      open={openAddCommentModal}
      className="modal-add-comment-wrapper"
    >
      <DialogContent className="add-comment-content">
        <div className="add-comment-header-wrapper">
          <div className="modal-title">{t("Add.user.comment.title")}</div>
          <div className="close-img-wrapper" onClick={closeModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="change-limit-subtitle">{`${t(
            "Add.user.comment.message"
          )} ${loanerInfo?.name} ${loanerInfo?.surname} - ${t("DNI.label")} ${
            loanerInfo?.dni
          }`}</div>

          <div className="textarea-wrapper">
            <textarea
              value={reasonDescription}
              onChange={onChangeDescription}
            />
          </div>
        </div>
        <div className="buttons-container">
          <div className="dismiss-bths-wrapper">
            <div className="bth-wrapper make-payment-bth-wrapper">
              <button className="bth dismiss-request-bth" onClick={closeModal}>
                {t("confirmation.modal.cancel")}
              </button>
            </div>
          </div>
          <div className="payment-bths-wrapper">
            <div className="bth-wrapper make-payment-bth-wrapper">
              <button
                className="bth make-payment-bth"
                disabled={reasonDescription === ""}
                onClick={onAcceptButton}
              >
                {t("confirmation.modal.accept")}
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddCommentModal;
