import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import {
  getUserNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../../../actions/notifications/notifications";
import Spinner from "../../common/spinner/Spinner";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import { useTranslation } from "react-i18next";

const FormatTimeNotificationCreatedAgo = ({ dateCreatedString }: *) => {
  const { t } = useTranslation();
  const dateCreatedInMs = new Date(dateCreatedString).getTime();
  const dateCurrentInMs = new Date().getTime();
  const differenceInSeconds = Math.floor(
    (dateCurrentInMs - dateCreatedInMs) / 1000
  );
  let text = "";

  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    text = `${differenceInMinutes} ${t("minutes.label")}`;
  } else {
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
      const minutes = differenceInMinutes % 60;
      text = `${differenceInHours} ${
        differenceInHours === 1 ? t("hour.label") : t("hours.label")
      } ${minutes} ${minutes === 1 ? t("minute.label") : t("minutes.label")}`;
    } else {
      const differenceInDays = Math.trunc(differenceInHours / 24);
      text = `${differenceInDays} ${
        differenceInDays === 1 ? t("day.label") : t("days.label")
      }`;
    }
  }

  return <>{text}</>;
};

const NotificationsItems = ({ userNotifications, pending, error }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const markAsRead = React.useCallback(
    (item) => {
      dispatch(markNotificationAsRead(item.messageId));
    },
    [dispatch]
  );

  if (pending) {
    return <Spinner text={`${t("Loading.Notifications")}...`} />;
  }
  if (error) {
    return <ErrorMessage text={`${t("Loading.Notifications")}...`} />;
  }
  if (!userNotifications.length) {
    return (
      <div className="notification-list-wrapper">
        <div className="info-block-empty">{t("Notifications.empty")}</div>
      </div>
    );
  }

  return (
    <div className="notification-list-wrapper">
      {userNotifications.map((item, i) => (
        <div
          className="notification-item-wrapper"
          key={i}
          style={{ background: i % 2 === 0 ? "transparent" : "#f8f8fa" }}
        >
          <div className="notification-item-content">
            <div
              className="notification-item-indicator"
              style={{ background: item.color }}
            ></div>
            <div
              className={`notification-item-text ${
                item.readAt ? "notification-item-text-read" : ""
              }`}
              role="button"
              onClick={() => markAsRead(item)}
            >
              <div className="notification-item-content-main-text">
                {item.message}
              </div>
              <div className="notification-item-content-hours">
                {
                  <FormatTimeNotificationCreatedAgo
                    dateCreatedString={item.createdAt}
                  />
                }{" "}
                {t("ago.label")}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const NotificationsBlock = ({ activeNotificationBlock }: *) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserNotification());
  }, [dispatch]);

  const {
    userNotifications,
    getUserNotificationsRequestPending,
    getUserNotificationsRequestError,
  } = useSelector((state) => ({
    userNotifications: state.notifications.userNotifications,
    getUserNotificationsRequestPending:
      state.notifications.getUserNotificationsRequest
        .getUserNotificationsRequestPending,
    getUserNotificationsRequestError:
      state.notifications.getUserNotificationsRequest
        .getUserNotificationsRequestError,
  }));

  const { t } = useTranslation();

  return (
    <div
      className={`notifications-block-wrapper ${
        activeNotificationBlock ? "open" : "close"
      }`}
    >
      <div className="block-title">{t("Notifications.label")}</div>

      <NotificationsItems
        userNotifications={userNotifications}
        pending={getUserNotificationsRequestPending}
        error={getUserNotificationsRequestError}
      />

      <button
        className="mark-readed-bth"
        onClick={() => dispatch(markAllNotificationsAsRead())}
        role="button"
      >
        {t("Mark.As.Read")} (
        {userNotifications.filter((item) => !item.readAt).length})
      </button>
    </div>
  );
};

export default NotificationsBlock;
