import verificationActions from "./verification-types";

export const setPersonalInfo = (value) => async (dispatch) => {
  dispatch({ type: verificationActions.SET_PERSONAL_INFO, payload: value });
};

export const setAddressInfo = (value) => async (dispatch) => {
  dispatch({ type: verificationActions.SET_ADDRESS_INFO, payload: value });
};

export const setAdditionalInfo = (value) => async (dispatch) => {
  dispatch({ type: verificationActions.SET_ADDITIONAL_INFO, payload: value });
};

export const setPhotoFilesInfo = (value) => async (dispatch) => {
  dispatch({ type: verificationActions.SET_PHOTO_FILES_INFO, payload: value });
};
