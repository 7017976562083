const type = "STATE_ADMIN_TRANSFERENCES";

const transferencesActions = {
  FETCH_TRANSFERENCES_PENDING: `${type}_FETCH_TRANSFERENCES_PENDING`,
  FETCH_TRANSFERENCES_ERROR: `${type}_FETCH_TRANSFERENCES_ERROR`,
  FETCH_TRANSFERENCES_SUCCESS: `${type}_FETCH_TRANSFERENCES_SUCCESS`,

  FETCH_ACCEPT_TRANSFERENCE_PENDING: `${type}_FETCH_ACCEPT_TRANSFERENCE_PENDING`,
  FETCH_ACCEPT_TRANSFERENCE_ERROR: `${type}_FETCH_ACCEPT_TRANSFERENCE_ERROR`,
  FETCH_ACCEPT_TRANSFERENCE_SUCCESS: `${type}_FETCH_ACCEPT_TRANSFERENCE_SUCCESS`,

  FETCH_DELETE_TRANSFERENCE_PENDING: `${type}_FETCH_DELETE_TRANSFERENCE_PENDING`,
  FETCH_DELETE_TRANSFERENCE_ERROR: `${type}_FETCH_DELETE_TRANSFERENCE_ERROR`,
  FETCH_DELETE_TRANSFERENCE_SUCCESS: `${type}_FETCH_DELETE_TRANSFERENCE_SUCCESS`,
};

export default transferencesActions;
