import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";

import "./dashboardContent.scss";
import { useSelector, useDispatch } from "react-redux";
import { getAnalytics } from "../../../../../actions/analytics/analytics";
import { useTranslation } from "react-i18next";

import calendarIcon from "../../../../../assets/mdi_calendar_today.svg";
import { getDateByFormat } from "../../../../../utils/parser";
import { useFilters } from "../../../../../hooks/useFilters";
import { usePieChart } from "../../../../../hooks/usePieChart";
import { useBarChart } from "../../../../../hooks/useBarChart";
import { useGaugeChart } from "../../../../../hooks/useGaugeChart";
import Chart from "./Chart/Chart/Chart";

const DashboardContent = () => {
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [activeFilters, setActiveFilters] = React.useState({});

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    analyticsData,
    analyticsDataRequestPending,
    //analyticsDataRequestError,
  } = useSelector((state) => ({
    analyticsData: state.analytics?.analyticsData,
    analyticsDataRequestPending:
      state.analytics.analyticsDataRequest.analyticsDataRequestPending,
    analyticsDataRequestError:
      state.analytics.analyticsDataRequest.analyticsDataRequestError,
  }));

  const { data: graph1 } = usePieChart({
    data: analyticsData?.graph1,
    title: t("Dashboard.title.graph.1"),
  });

  const { data: graph2 } = usePieChart({
    data: analyticsData?.graph2,
    title: t("Dashboard.title.graph.2"),
  });

  const { data: graph3 } = useGaugeChart({
    data: analyticsData?.graph3,
    title: t("Dashboard.title.graph.3"),
  });

  const { data: graph4 } = useBarChart({
    data: analyticsData?.graph4,
    title: t("Dashboard.title.graph.4"),
  });

  React.useEffect(() => {
    dispatch(getAnalytics({}));
  }, [dispatch]);

  useFilters({
    activeFilters,
    action: getAnalytics,
  });

  const onFilterDateChanged = React.useCallback(
    (values) => {
      const emptyDates = values.filter((item) => !item);
      if (emptyDates.length === 1) {
        return;
      }

      if (emptyDates.length === 2) {
        setActiveFilters({});
        dispatch(getAnalytics({}));

        return;
      }

      const filters = {
        "filter[initialDate]": getDateByFormat({
          date: values[0],
          format: "YYYY-MM-DD",
        }),
        "filter[finalDate]": getDateByFormat({
          date: values[1],
          format: "YYYY-MM-DD",
        }),
      };

      setActiveFilters({
        ...filters,
      });
    },
    [dispatch]
  );

  // eslint-disable-next-line react/display-name
  const DateInput = React.forwardRef(({ value, onClick }: *, ref) => (
    <div className="input-date-container">
      <input
        ref={ref}
        placeholder={t("Filter.by.date.range.label")}
        className="input-item"
        readOnly
        value={value}
        onClick={onClick}
      />
      {!value && (
        <img className="input-calendar" src={calendarIcon} onClick={onClick} />
      )}
    </div>
  ));

  return (
    <div className="dashboard-content-layout-wrapper">
      <div className="filter-container">
        <div className="filter-date">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            dateFormat="dd/MM/yyyy"
            locale="es"
            endDate={endDate}
            maxDate={new Date()}
            onChange={(update) => {
              onFilterDateChanged(update);
              setDateRange(update);
            }}
            customInput={<DateInput />}
            isClearable={true}
          />
        </div>
      </div>

      <div className="charts">
        <div className="charts-container">
          <div className="chart-item">
            <Chart isLoading={analyticsDataRequestPending} data={graph1} />
          </div>
          <div className="chart-item">
            <Chart isLoading={analyticsDataRequestPending} data={graph2} />
          </div>
        </div>

        <div className="charts-container">
          <div className="chart-item">
            <Chart isLoading={analyticsDataRequestPending} data={graph3} />
          </div>
          <div className="chart-item">
            <Chart isLoading={analyticsDataRequestPending} data={graph4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
