import React from "react";

export const usePieChart = ({ data, title }) => {
  const [charData, setCharData] = React.useState({});

  React.useEffect(() => {
    if (!data) {
      setCharData({});
      return;
    }

    setCharData({
      textStyle: {
        fontFamily: "Manrope",
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        right: 0,
        top: 20,
        bottom: 20,
        data: data.map((item) => item.name),
      },
      title: {
        text: title,
        left: "center",
        textStyle: {
          color: "#404040",
          fontWeight: "normal",
          fontSize: 14,
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "55%",
          center: ["40%", "50%"],
          data: data,
          labelLayout: function (params) {
            const points = params.labelLinePoints;
            points[2][0] = params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points,
            };
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  }, [data, title]);

  return { data: charData };
};
