import axios from "axios";
import contractsActions from "./contracts-types";
import loanActions from "../loan/loan-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getAllContracts = ({ query = "", url } = {}) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: contractsActions.FETCH_LOANS_PENDING,
        });

        const finalURL =
            url || `${process.env.REACT_APP_API_SERVER}/admin/loans${query}`;
        const token = await tokenConfig(getState);
        const { data } = await axios.get(finalURL, token);

        dispatch({
            type: contractsActions.FETCH_LOANS_SUCCESS,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: contractsActions.FETCH_LOANS_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getContractById = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: contractsActions.FETCH_LOAN_BY_ID_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/loans/${loanId}`,
            token
        );

        dispatch({
            type: contractsActions.FETCH_LOAN_BY_ID_SUCCESS,
            payload: data?.data,
        });
    } catch (err) {
        dispatch({
            type: contractsActions.FETCH_LOAN_BY_ID_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getContractRequest = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: loanActions.FETCH_LOAN_REQUEST_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/download-loan-request/${loanId}`,
            token
        );

        dispatch({
            type: loanActions.FETCH_LOAN_REQUEST_SUCCESS,
            payload: data?.data,
        });
    } catch (err) {
        dispatch({
            type: loanActions.FETCH_LOAN_REQUEST_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getContractPDF = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: loanActions.FETCH_LOAN_CONTRACT_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/download-loan-contract/${loanId}`,
            token
        );

        dispatch({
            type: loanActions.FETCH_LOAN_CONTRACT_SUCCESS,
            payload: data?.data,
        });
    } catch (err) {
        dispatch({
            type: loanActions.FETCH_LOAN_CONTRACT_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getPromissoryNotePDF = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: loanActions.FETCH_LOAN_PROMISSORY_NOTE_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/download-promissory-note/${loanId}`,
            token
        );

        dispatch({
            type: loanActions.FETCH_LOAN_PROMISSORY_NOTE_SUCCESS,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: loanActions.FETCH_LOAN_PROMISSORY_NOTE_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getCreditHistoryPDF = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: loanActions.FETCH_CREDIT_HISTORY_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/download-signed-authorization-credit-history/${loanId}`,
            token
        );

        dispatch({
            type: loanActions.FETCH_CREDIT_HISTORY_SUCCESS,
            payload: data?.data,
        });
    } catch (err) {
        dispatch({
            type: loanActions.FETCH_CREDIT_HISTORY_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};

export const getSignedMailPhonePDF = (loanId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: loanActions.FETCH_MAIL_PHONE_PENDING,
        });

        const token = await tokenConfig(getState);
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_SERVER}/admin/download-signed-authorization-mail/${loanId}`,
            token
        );

        dispatch({
            type: loanActions.FETCH_MAIL_PHONE_SUCCESS,
            payload: data?.data,
        });
    } catch (err) {
        dispatch({
            type: loanActions.FETCH_MAIL_PHONE_ERROR,
            payload: {
                ...errorMapper(err, dispatch),
            },
        });
    }
};
