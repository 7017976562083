import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EmergencyPayment from "./emergency-payment/EmergencyPayment";
import CancelRefinancement from "./cancel-refinancement/CancelRefinancement";
import CheckLoanTime from "./check-loan-time/CheckLoanTime";

import { getDateByFormat } from "../../../../../utils/parser";
import { formatPrice } from "../../../../../utils/currency";

import calendarIcon from "../../../../../assets/mdi_calendar_today.svg";

import "./styles.scss";

import {
  getConciliations,
  conciliatePayments,
  clearPaymentState,
} from "../../../../../actions/conciliations/conciliations";

import { clearData } from "../../../../../actions/loan/loan";

import { useDispatch, useSelector } from "react-redux";

import "date-fns";
import ProcessModal from "../../../../common/process-modal/ProcessModal";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";
import { useFilters } from "../../../../../hooks/useFilters";

registerLocale("es", es);

const defaultFilters = {
  initialDate: getDateByFormat({
    date: moment().subtract(2, "days"),
    format: "YYYY-MM-DD",
  }),
  finalDate: getDateByFormat({ date: moment(), format: "YYYY-MM-DD" }),
};

const ConciliationContent = () => {
  const dispatch = useDispatch();
  const [openProcessModal, setOpenProcessModal] = React.useState(false);
  const [openEmergencyModal, setOpenEmergencyModal] = React.useState(false);
  const [openCancelRefinancement, setOpenCancelRefinancement] = React.useState(
    false
  );
  const [openCheckLoanTime, setOpenCheckLoanTime] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [operatorPayment, setOperatorPayment] = React.useState(null);
  const [clientPayment, setClientPayment] = React.useState(null);
  const [valueInput, setValueInput] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [activeFilters, setActiveFilters] = React.useState({
    ...defaultFilters,
  });

  const {
    operatorPayments,
    clientPayments,
    conciliatePayment,
    conciliatePaymentRequestPending,
    conciliatePaymentRequestError,
    conciliatePaymentRequestSuccess,
  } = useSelector((state) => ({
    operatorPayments: state.conciliations.operatorPayments,
    clientPayments: state.conciliations.clientPayments,
    conciliationsRequestPending:
      state.conciliations.conciliationsRequest.conciliationsRequestPending,
    conciliationsRequestError:
      state.conciliations.conciliationsRequest.conciliationsRequestError,
    conciliationsRequestSuccess:
      state.conciliations.conciliationsRequest.conciliationsRequestSuccess,

    conciliatePayment: state.conciliations.conciliatePayment,
    conciliatePaymentRequestPending:
      state.conciliations.conciliatePaymentRequest
        .conciliatePaymentRequestPending,
    conciliatePaymentRequestError:
      state.conciliations.conciliatePaymentRequest
        .conciliatePaymentRequestError,
    conciliatePaymentRequestSuccess:
      state.conciliations.conciliatePaymentRequest
        .conciliatePaymentRequestSuccess,
  }));

  const { t } = useTranslation();

  useFilters({
    activeFilters,
    action: getConciliations,
  });

  const onCloseModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
    setDateRange([null, null]);
    setDescription("");
  }, []);

  const onCloseProcessModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (conciliatePaymentRequestSuccess) {
      onCloseModal();
      setActiveFilters({
        ...defaultFilters,
      });
    }
  }, [conciliatePaymentRequestSuccess, onCloseModal]);

  const onFilterDateChanged = React.useCallback((values) => {
    const emptyDates = values.filter((item) => !item);
    if (emptyDates.length === 1) {
      return;
    }

    if (emptyDates.length === 2) {
      setActiveFilters({
        ...defaultFilters,
      });

      return;
    }

    const filters = {
      initialDate: getDateByFormat({
        date: values[0],
        format: "YYYY-MM-DD",
      }),
      finalDate: getDateByFormat({
        date: values[1],
        format: "YYYY-MM-DD",
      }),
    };

    setActiveFilters({
      ...filters,
    });
  }, []);

  const onConciliatePayment = React.useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const onEmergencyPayment = React.useCallback(() => {
    setOpenEmergencyModal(true);
  }, []);

  const onCancelRefinancement = React.useCallback(() => {
    setOpenCancelRefinancement(true);
  }, []);

  const onCheckLoanTime = React.useCallback(() => {
    setOpenCheckLoanTime(true);
  }, []);

  const onAcceptConciliate = React.useCallback(() => {
    setOpenProcessModal(true);

    dispatch(
      conciliatePayments({
        operatorPaymentId: operatorPayment?.id,
        clientPaymentId: clientPayment?.id,
        amount: valueInput,
        description,
      })
    );
  }, [
    dispatch,
    operatorPayment?.id,
    clientPayment?.id,
    valueInput,
    description,
  ]);

  const onCloseEmergencyModal = React.useCallback(() => {
    setOpenEmergencyModal(false);
    setDateRange([null, null]);
    dispatch(clearPaymentState());

    setActiveFilters({
      ...defaultFilters,
    });
  }, [dispatch]);

  const onCloseCancelRefinancement = React.useCallback(() => {
    setOpenCancelRefinancement(false);
    setDateRange([null, null]);
    dispatch(clearPaymentState());

    setActiveFilters({
      ...defaultFilters,
    });
  }, [dispatch]);

  const onCloseCheckLoanTime = React.useCallback(() => {
    setOpenCheckLoanTime(false);
    setDateRange([null, null]);
    dispatch(clearData());
    dispatch(clearPaymentState());

    setActiveFilters({
      ...defaultFilters,
    });
  }, [dispatch]);

  // eslint-disable-next-line react/display-name
  const DateInput = React.forwardRef(({ value, onClick }: *, ref) => (
    <div className="input-date-container">
      <input
        ref={ref}
        placeholder={t("Filter.by.date.range.label")}
        className="input-item"
        readOnly
        value={value}
        onClick={onClick}
      />
      {!value && (
        <img className="input-calendar" src={calendarIcon} onClick={onClick} />
      )}
    </div>
  ));

  return (
    <div className="conciliation-content-wrapper">
      <div className="filter-container">
        <div className="filter-date">
          <DatePicker
            selectsRange={true}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            locale="es"
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              onFilterDateChanged(update);
              setDateRange(update);
            }}
            customInput={<DateInput />}
            isClearable={true}
          />
        </div>
      </div>

      <div className="content-container">
        <div className="main-title">{`${t("From.label")} ${getDateByFormat({
          date: activeFilters.initialDate,
        })} ${t("To.label")} ${getDateByFormat({
          date: activeFilters.finalDate,
        })}`}</div>
        <div className="operations-container">
          <div className="operations-item">
            <div className="operations-title">
              {t("Operators.payments.conciliation.title")}
            </div>
            <div className="operations-list">
              <TableContainer
                className={`table-container ${
                  !operatorPayments?.length ? "empty-table" : ""
                }`}
              >
                <Table aria-label="customized table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell className="table-head-cell"></TableCell>
                      <TableCell className="table-head-cell">
                        {t("DNI.label")}
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("Operator.label")} </span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("Amount.label")} </span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        {t("Date.label")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {operatorPayments &&
                      operatorPayments.map((item) => (
                        <TableRow className="table-body-row" key={item.id}>
                          <TableCell className="table-body-cell">
                            <input
                              className="radio-item"
                              type="radio"
                              name="operatorPayment"
                              value={item.id}
                              onChange={() => {
                                setValueInput(parseFloat(item.amount));
                                setOperatorPayment(item);
                              }}
                            />
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.dui}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.operator}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {formatPrice(item.amount)}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {getDateByFormat({ date: item.date })}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="input-actions">
              <div className="input-container">
                <span>{t("Amount.to.register")}</span>
                <input
                  className="input-item"
                  value={valueInput}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (!/^-?\d*\.?\d*$/.test(value) && value !== "") {
                      return;
                    }

                    setValueInput(e.target.value);
                  }}
                />
              </div>

              <div className="payment-options-wrapper">
                <div className="payment-bths-wrapper">
                  <div className="bth-wrapper make-payment-bth-wrapper">
                    <button
                      className="bth make-payment-bth"
                      disabled={
                        !clientPayment || !operatorPayment || !valueInput
                      }
                      onClick={onConciliatePayment}
                    >
                      {t("Conciliate.register")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="payment-bths-wrapper check-button">
                <div className="bth-wrapper make-payment-bth-wrapper">
                  <button
                    className="bth make-payment-bth"
                    onClick={onCheckLoanTime}
                  >
                    {t("Check.loan.time")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="operations-item">
            <div className="operations-title">
              {t("Clients.payments.conciliation.title")}
            </div>
            <div className="operations-list">
              <TableContainer
                className={`table-container ${
                  !clientPayments?.length ? "empty-table" : ""
                }`}
              >
                <Table aria-label="customized table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell className="table-head-cell"></TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("DNI.label")}</span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("Operator.label")} </span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("Amount.label")} </span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span> {t("Date.label")}</span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span> {t("Name.label")}</span>
                      </TableCell>
                      <TableCell className="table-head-cell">
                        <span>{t("Phone.label")}</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {clientPayments &&
                      clientPayments.map((item) => (
                        <TableRow className="table-body-row" key={item.id}>
                          <TableCell className="table-body-cell">
                            <input
                              className="radio-item"
                              type="radio"
                              name="clientPayment"
                              value={item.id}
                              onChange={() => setClientPayment(item)}
                            />
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.dui}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.operator}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {formatPrice(item.amount)}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {getDateByFormat({ date: item.date })}
                          </TableCell>
                          <TableCell className="table-body-cell name-cell">
                            {item.name}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.phone}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="input-actions emergency">
              <div className="payment-options-wrapper">
                <div className="payment-bths-wrapper">
                  <div className="bth-wrapper make-payment-bth-wrapper">
                    <button
                      className="bth make-payment-bth"
                      onClick={onCancelRefinancement}
                    >
                      {t("Cancel.refinancement.label")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="payment-options-wrapper">
                <div className="payment-bths-wrapper">
                  <div className="bth-wrapper make-payment-bth-wrapper">
                    <button
                      className="bth make-payment-bth"
                      onClick={onEmergencyPayment}
                    >
                      {t("Register.emergency.payment")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmergencyPayment
        openModal={openEmergencyModal}
        onCloseModal={onCloseEmergencyModal}
      />

      <CancelRefinancement
        openModal={openCancelRefinancement}
        onCloseModal={onCloseCancelRefinancement}
      />

      <CheckLoanTime
        openModal={openCheckLoanTime}
        onCloseModal={onCloseCheckLoanTime}
      />

      <ProcessModal
        openModalProcess={openProcessModal}
        closeModalProcess={onCloseProcessModal}
        requestPending={conciliatePaymentRequestPending}
        requestError={conciliatePaymentRequestError}
        requestSuccess={conciliatePaymentRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetLoadCsvDataStore(false));
        }}
        pendingText={`${t("Saving.Data.label")}`}
        errorText={conciliatePayment?.message}
        successText={conciliatePayment?.message}
      />

      <ConfirmationModal
        openModal={openConfirmationModal}
        onCloseModal={onCloseModal}
        buttonDisabled={!description}
        big={true}
        onPressAccept={onAcceptConciliate}
      >
        <div className="modal-header-component">
          <div className="modal-header-title">
            {t("Conciliations.proccess.message")}
          </div>

          <div className="payments-container">
            <div className="payment-item">
              <span className="payment-item-title">
                {t("Conciliations.payment.operator.info")}
              </span>
              <div className="payment-info">
                <div className="payment-info-item">
                  <div>{t("DNI.label")}</div>
                  <div>{operatorPayment?.dui}</div>
                </div>
                <div className="payment-info-item">
                  <div>{t("Operator.label")} </div>
                  <div>{operatorPayment?.operator} </div>
                </div>
                <div className="payment-info-item">
                  <div>{t("Amount.label")} </div>
                  <div>{formatPrice(operatorPayment?.amount)} </div>
                </div>
                <div className="payment-info-item">
                  <div> {t("Date.label")}</div>
                  <div>
                    {operatorPayment?.date &&
                      getDateByFormat({ date: operatorPayment?.date })}
                  </div>
                </div>
              </div>
            </div>

            <div className="payment-item">
              <span className="payment-item-title">
                {t("Conciliations.payment.client.info")}
              </span>
              <div className="payment-info">
                <div className="payment-info-item">
                  <div>{t("DNI.label")}</div>
                  <div>{clientPayment?.dui}</div>
                </div>
                <div className="payment-info-item">
                  <div>{t("Operator.label")} </div>
                  <div>{clientPayment?.operator} </div>
                </div>
                <div className="payment-info-item">
                  <div>{t("Amount.label")} </div>
                  <div>{formatPrice(clientPayment?.amount)} </div>
                </div>
                <div className="payment-info-item">
                  <div> {t("Date.label")}</div>
                  <div>
                    {clientPayment?.date &&
                      getDateByFormat({ date: clientPayment?.date })}
                  </div>
                </div>
                <div className="payment-info-item">
                  <div> {t("Name.label")}</div>
                  <div> {clientPayment?.name}</div>
                </div>
                <div className="payment-info-item">
                  <div> {t("Phone.label")}</div>
                  <div> {clientPayment?.phone}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="final-amount">
            {t("Conciliations.payment.amount.info")}:{" "}
            <span>{formatPrice(valueInput)}</span>
          </div>

          <div className="textarea-content">
            <span>{t("Conciliations.payment.description")}</span>
            <textarea
              className="input-item"
              type="textarea"
              name="description"
              id="description"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            ></textarea>
          </div>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default ConciliationContent;
