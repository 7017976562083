import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoanHistoryItemModal from "./loan-history-item-modal/LoanHistoryItemModal";
import { formatPrice } from "../../../../../utils/currency";
import { getDateByFormat } from "../../../../../utils/parser";
import "./styles.scss";

const LoansHistoryTable = ({ setLoanHistoryItemActive, setElectLoan }: *) => {
  const { t } = useTranslation();

  const { loansHistory } = useSelector((state) => ({
    loansHistory: state.loansHistory.loansHistory,
  }));

  return (
    <div className="loan-history-table-mobile-version-wrapper">
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead className="loan-history-table-head">
            <TableRow>
              <TableCell className="table-head-cell">№</TableCell>
              <TableCell className="table-head-cell">
                {t("You.get.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Return.Date.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="loan-history-table-body">
            {loansHistory.map((loan, i) => (
              <TableRow
                className="loan-history-table-body-row"
                key={i}
                onClick={() => {
                  setLoanHistoryItemActive(true);
                  setElectLoan(loan);
                }}
              >
                <TableCell className="table-body-cell">
                  {loan.contractNumber}
                </TableCell>
                <TableCell className="table-body-cell">
                  {formatPrice(loan.loanAmount)}
                </TableCell>
                <TableCell className="table-body-cell">
                  {getDateByFormat({ date: loan.returnDate })}
                </TableCell>
                <TableCell className="table-body-cell">
                  {formatPrice(loan.returnAmount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const DesktopLayout = ({ loansHistory }: *) => {
  const [loanHistoryItemActive, setLoanHistoryItemActive] = React.useState(
    false
  );
  const [electLoan, setElectLoan] = React.useState(null);

  return (
    <>
      <LoansHistoryTable
        loansHistory={loansHistory}
        setLoanHistoryItemActive={setLoanHistoryItemActive}
        setElectLoan={setElectLoan}
      />

      {loanHistoryItemActive ? (
        <LoanHistoryItemModal
          openLoanHistoryItem={loanHistoryItemActive}
          closeLoanHistoryItem={() => setLoanHistoryItemActive(false)}
          electLoan={electLoan}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DesktopLayout;
