import React from "react";
import "./styles.scss";

import ContactInfo from "./contact-info/ContactInfo";
import PersonalInfo from "./personal-info/PersonalInfo";
import AdditionalInfo from "./additional-info/AdditionalInfo";
import Address from "./address/Address";
import { useTranslation } from "react-i18next";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

export function ProfileSettingsTab() {
  useAnalitycs("UserDashboard Settings");

  const { t } = useTranslation();
  return (
    <div className="profile-settings-tab-wrapper">
      <div className="left-block-wrapper">
        <div className="tab-title">{t("Profile.Settings.label")}</div>
        <ContactInfo />
        <PersonalInfo />
        <AdditionalInfo />
      </div>
      <div className="right-block-wrapper">
        <Address />
      </div>
    </div>
  );
}

export default ProfileSettingsTab;
