import "./styles.scss";
import React from "react";
import Slider from "@material-ui/core/Slider";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getDateByFormat } from "../../../utils/parser";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../utils/currency";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#4957f2",
    border: "5px solid #959eff",
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: -7,
    top: -25,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 3,
    backgroundImage: "linear-gradient(90deg, #2B3DFF -13.54%, #FF409A 108.96%)",
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    backgroundImage: "linear-gradient(90deg, #2B3DFF -13.54%, #FF409A 108.96%)",
  },
  mark: {
    backgroundImage:
      "linear-gradient(to right, #2B3DFF -13.54%, #FF409A 108.96%)",
    height: "17px !important",
    width: 2,
    marginTop: -7,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
  markLabel: {
    marginTop: 10,
  },
})(Slider);

const RemainingBalanceSlider = ({ isAdmin }: { isAdmin: boolean }) => {
  console.log("TCL: RemainingBalanceSlider -> isAdmin", isAdmin);
  const { t } = useTranslation();

  const {
    sumToCloseToday,
    returnScale,
    amountReturnNow,
    amountSavedReturnNow,
    initialReturnScale,
  } = useSelector((state) => ({
    loanId: state.loan.loanInfo.contractNumber,
    dateIssue: state.loan.loanInfo.loanDate,
    dateReturn: state.loan.loanInfo.loanReturnDate,
    sumToCloseToday: state.loan.loanInfo.amountReturnNow,
    returnScale: state.loan.loanInfo.returnScale,
    initialReturnScale: state.loan.loanInfo.initialReturnScale,
    amountReturnNow: state.loan.loanInfo.amountReturnNow,
    amountSavedReturnNow: state.loan.loanInfo.amountSavedReturnNow,
  }));

  const scale = isAdmin ? initialReturnScale : returnScale;
  console.log("TCL: RemainingBalanceSlider -> scale", scale);

  const marks = React.useMemo(() => {
    let items = [];

    if (!scale || !scale.length) {
      return items;
    }

    let value = 1;
    scale.forEach((item) => {
      items.push({
        value,
        label: getDateByFormat({ date: item.date, format: "DD-MM" }),
      });

      value += 1;

      if (item.amount) {
        items.push({
          value,
          label: formatPrice(item.amount),
        });

        value += 1;
      }
    });

    return items;
  }, [scale]);

  if (!sumToCloseToday && !isAdmin) {
    return "";
  }
  return (
    <div className="remaining-balance-slider-wrapper">
      {!isAdmin && (
        <div className="current-sum-return-wrapper">
          {t("Return.today.label-1")}{" "}
          <span>{formatPrice(amountReturnNow)}</span>
          {t("Return.today.label-2")}{" "}
          <span>{formatPrice(amountSavedReturnNow)}</span>
        </div>
      )}

      {marks.length && (
        <IOSSlider
          track={false}
          defaultValue={null}
          marks={marks}
          value={null}
          step={marks.length}
          min={1}
          max={marks.length}
          disabled
        />
      )}
    </div>
  );
};

export default RemainingBalanceSlider;
