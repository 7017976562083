import React from "react";
import "./styles.scss";
import pdfIcon from "../../../../assets/pdf-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserLoanHistory } from "../../../../actions/loan-history/loan-history";
import {
  getPersonalDataPolicy,
  getTermsOfService,
} from "../../../../actions/generals/generals";
import { useTranslation } from "react-i18next";
import DesktopLayout from "./desktop-layout/DesktopLayout";
import MobileLayout from "./mobile-layout/MobileLayout";
import ErrorMessage from "../../../common/error-message/ErrorMessage";
import Spinner from "../../../common/spinner/Spinner";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

const Content = () => {
  return (
    <>
      <DesktopLayout />
      <MobileLayout />
    </>
  );
};

const LoanHistoryTabLayout = () => {
  useAnalitycs("UserDashboard LoanHistory");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    dataPolicyPDF,
    termsOfService,
    loansHistory,
    loansHistoryRequestError,
    loansHistoryRequestPending,
    setActiveTabPanel,
  } = useSelector((state) => ({
    dataPolicyPDF: state?.generals?.dataPolicy?.url,
    termsOfService: state?.generals?.termsOfService,
    loansHistory: state.loansHistory.loansHistory,
    loansHistoryRequestPending:
      state.loansHistory.loansHistoryRequest.loansHistoryRequestPending,
    loansHistoryRequestError:
      state.loansHistory.loansHistoryRequest.loansHistoryRequestError,
    loansHistoryRequestSuccess:
      state.loansHistory.loansHistoryRequest.loansHistoryRequestSuccess,

    userFirstName: state.auth.user.first_name,
    userSecondName: state.auth.user.last_name,
  }));

  React.useEffect(() => {
    dispatch(getUserLoanHistory());
    dispatch(getPersonalDataPolicy());
    dispatch(getTermsOfService());
  }, [dispatch]);

  if (loansHistoryRequestPending) {
    return <Spinner text={`${t("Loan.Data.Loading.label")}...`} />;
  }

  if (loansHistoryRequestError) {
    return <ErrorMessage text={t("Some.Error.label")} />;
  }

  if (!loansHistory.length) {
    return (
      <div className="loans-empty-wrapper">
        <div className="description">{t("Loan-history.empty")}</div>
        <div className="bth-wrapper new-loan-bth-wrapper">
          <button
            className="bth new-loan-bth"
            onClick={() => setActiveTabPanel(1)}
          >
            {t("Get.first.loan.label")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="loan-history-tab-wrapper">
      <div className="tab-title">{t("Loan.History.label")}</div>
      <Content />
      <div className="documents-block-wrapper">
        <div className="title">{t("Loaner.Documents.label")}</div>
        <div className="list-documents-wrapper">
          <div className="document-item-wrapper document-terms-of-service-use">
            <div className="desription">{t("Terms.of.Service.Use.label")}</div>
            <a
              download={termsOfService}
              href={termsOfService}
              className="bth-download"
              rel="noreferrer"
              target="_blank"
            >
              <img src={pdfIcon} alt="" />
              <span>{t("Download.label")}</span>
            </a>
          </div>
          <div className="document-item-wrapper document-personal-processing-data-policy">
            <div className="desription">
              {t("Personal.Processing.data.policy.label")}
            </div>
            <a
              className="bth-download"
              download={dataPolicyPDF}
              href={dataPolicyPDF}
              rel="noreferrer"
              target="_blank"
            >
              <img src={pdfIcon} alt="" />
              <span>{t("Download.label")}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanHistoryTabLayout;
