import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { activateNewEmail } from "../../actions/update-email/update-email";
import Spinner from "../common/spinner/Spinner";
import "./styles.scss";
import ErrorMessage from "../common/error-message/ErrorMessage";
import SuccessMessage from "../common/success-message/SuccessMessage";
import { connect } from "react-redux";
import { logout } from "../../actions/auth/auth";
import { useTranslation } from "react-i18next";

function HandlePesponseSwitch({ pending, error, success }: *) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      {pending ? <Spinner text={t("We.Activate.Your.Mail")} /> : ""}
      {error ? (
        <>
          <ErrorMessage
            text={t("The.activation.link.is.invalid.or.has.expired")}
          />
          <div className="bth-wrapper main-page-bth-wrapper">
            <button
              type="button"
              className="bth main-page-bth"
              onClick={() => history.push("/login")}
            >
              {t("Go.to.profile.label")}
            </button>
          </div>
        </>
      ) : (
        ""
      )}
      {success ? (
        <>
          <SuccessMessage
            text={t("Your.Email.Is.Successfuly.Activated.label")}
          />
          <div className="bth-wrapper continue-bth-wrapper">
            <button
              type="button"
              className="bth continue-bth"
              onClick={() => history.push("/login")}
            >
              {t("Go.to.profile.label")}
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

function ConfirmNewEmailPage({
  activateNewEmailRequestPending,
  activateNewEmailRequestError,
  activateNewEmailRequestSuccess,

  currentUserEmail,
}: *) {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const token = params.token;
    const uidb64 = params.uidb64;
    dispatch(activateNewEmail(token, uidb64, currentUserEmail));
  }, [currentUserEmail, dispatch, params?.token, params?.uidb64]);

  useEffect(() => {
    if (activateNewEmailRequestSuccess) {
      dispatch(logout());
    }
  }, [activateNewEmailRequestSuccess, dispatch]);
  return (
    <div className="update-email-page-wrapper">
      <div className="content">
        <div className="activation-title">{t("Update.Email.Page")}</div>
        <HandlePesponseSwitch
          pending={activateNewEmailRequestPending}
          error={activateNewEmailRequestError}
          success={activateNewEmailRequestSuccess}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activateNewEmailRequestPending:
      state.updateEmail.activateNewEmailRequest.activateNewEmailRequestPending,
    activateNewEmailRequestError:
      state.updateEmail.activateNewEmailRequest.activateNewEmailRequestError,
    activateNewEmailRequestSuccess:
      state.updateEmail.activateNewEmailRequest.activateNewEmailRequestSuccess,

    currentUserEmail: state.auth.user.email,
  };
};

export default connect(mapStateToProps, null)(ConfirmNewEmailPage);
