import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import * as moment from "moment";
import es from "date-fns/locale/es";
registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import calendarIcon from "../../../../../assets/mdi_calendar_today.svg";

import { getDateByFormat } from "../../../../../utils/parser";
import { formatPrice } from "../../../../../utils/currency";

import "./transferencesContent.scss";

import {
  getTransferences,
  acceptPayment,
  deletePayment,
} from "../../../../../actions/clients-transferences/client-transferences";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../common/spinner/Spinner";

import ProcessModal from "../../../../common/process-modal/ProcessModal";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";
import { useFilters } from "../../../../../hooks/useFilters";

const defaultFilters = {
  initialDate: getDateByFormat({
    date: moment("2010-01-01"),
    format: "YYYY-MM-DD",
  }),
  finalDate: getDateByFormat({ date: moment(), format: "YYYY-MM-DD" }),
};

const TransferencesContent = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [openProcessModal, setOpenProcessModal] = React.useState(false);
  const [amounts, setAmounts] = React.useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [payment, setPayment] = React.useState(null);
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [confirmationMessage, setConfirmationMessage] = React.useState({});

  const [activeFilters, setActiveFilters] = React.useState({
    ...defaultFilters,
  });

  const {
    clientsTransferences,
    clientsTransferencesRequestPending,
    clientsTransferencesRequestSuccess,
    updatePaymentData,
    updatePaymentRequestPending,
    updatePaymentRequestError,
    updatePaymentRequestSuccess,
  } = useSelector((state) => ({
    clientsTransferences: state.clientsTransferences.clientsTransferences,
    clientsTransferencesPagination:
      state.clientsTransferences.clientsTransferencesPagination,
    clientsTransferencesRequestPending:
      state.clientsTransferences.clientsTransferencesRequest
        .clientsTransferencesRequestPending,
    clientsTransferencesRequestError:
      state.clientsTransferences.clientsTransferencesRequest
        .clientsTransferencesRequestError,
    clientsTransferencesRequestSuccess:
      state.clientsTransferences.clientsTransferencesRequest
        .clientsTransferencesRequestSuccess,

    updatePaymentData: state.clientsTransferences.updatePayment,
    updatePaymentRequestPending:
      state.clientsTransferences.updatePaymentRequest
        .updatePaymentRequestPending,
    updatePaymentRequestError:
      state.clientsTransferences.updatePaymentRequest.updatePaymentRequestError,
    updatePaymentRequestSuccess:
      state.clientsTransferences.updatePaymentRequest
        .updatePaymentRequestSuccess,
  }));

  React.useEffect(() => {
    if (clientsTransferences?.length) {
      const values = clientsTransferences.map((item) => item.amount);

      setAmounts(values);
    }
  }, [clientsTransferences]);

  const { t } = useTranslation();

  useFilters({
    activeFilters,
    action: getTransferences,
  });

  const onCloseProcessModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (updatePaymentRequestSuccess) {
      setActiveFilters({
        ...defaultFilters,
      });

      setPayment(null);
    }
  }, [updatePaymentRequestSuccess]);

  const onFilterDateChanged = React.useCallback(
    (values) => {
      const emptyDates = values.filter((item) => !item);
      if (emptyDates.length === 1) {
        return;
      }

      if (emptyDates.length === 2) {
        setActiveFilters({
          ...defaultFilters,
        });

        dispatch(getTransferences({}));

        return;
      }

      const filters = {
        initialDate: getDateByFormat({
          date: values[0],
          format: "YYYY-MM-DD",
        }),
        finalDate: getDateByFormat({
          date: values[1],
          format: "YYYY-MM-DD",
        }),
      };

      setActiveFilters({
        ...filters,
      });
    },
    [dispatch]
  );

  const onCloseModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
  }, []);

  const onSelectPayment = React.useCallback(
    (data, isRemove) => {
      setOpenConfirmationModal(true);
      setPayment(data);

      if (isRemove) {
        setConfirmationMessage({
          additionalText: t("Process.transfer.message.6"),
          confirmationText: `${t("Process.transfer.message.1")} ${
            data?.name
          } ${t("Process.transfer.message.2")} ${getDateByFormat({
            date: data?.date,
          })} ${t("Process.transfer.message.3")} ${formatPrice(
            data?.amount
          )} ${t("Process.transfer.message.4")} ${data?.reference} ${t(
            "Process.transfer.message.5"
          )} ${data?.bank}`,
        });

        setSelectedAction("deletePayment");

        return;
      }

      setConfirmationMessage({
        additionalText: t("Process.remove.transfer.message.6"),
        confirmationText: `${t("Process.remove.transfer.message.1")} ${
          data?.name
        } ${t("Process.remove.transfer.message.2")} ${getDateByFormat({
          date: data?.date,
        })} ${t("Process.remove.transfer.message.3")} ${formatPrice(
          data?.amountToRegister
        )} ${t("Process.remove.transfer.message.4")} ${data?.reference} ${t(
          "Process.remove.transfer.message.5"
        )} ${data?.bank}`,
      });

      setSelectedAction("acceptPayment");
    },
    [t]
  );

  const onAccepAction = React.useCallback(() => {
    setOpenProcessModal(true);
    setOpenConfirmationModal(false);

    const action =
      selectedAction === "deletePayment" ? deletePayment : acceptPayment;

    if (selectedAction) {
      dispatch(
        action({
          paymentId: payment?.id,
          amount: payment?.amountToRegister,
        })
      );
    }
  }, [dispatch, payment, selectedAction]);

  // eslint-disable-next-line react/display-name
  const DateInput = React.forwardRef(({ value, onClick, onChange }: *, ref) => (
    <div className="input-date-container">
      <input
        ref={ref}
        placeholder={t("Filter.by.date.label")}
        className="input-item"
        readOnly
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
      />
      {!value && (
        <img className="input-calendar" src={calendarIcon} onClick={onClick} />
      )}
    </div>
  ));

  const onAmountChange = React.useCallback(
    (index, value) => {
      let newArr = [...amounts];
      newArr[index] = value;

      setAmounts(newArr);
    },
    [amounts]
  );

  const renderBankInfo = React.useCallback(() => {
    const filesList = clientsTransferences;

    return (
      <div className="bank-info-wrapper">
        <div className="filter-container">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            dateFormat="dd/MM/yyyy"
            locale="es"
            endDate={endDate}
            maxDate={new Date()}
            onChange={(update) => {
              onFilterDateChanged(update);
              setDateRange(update);
            }}
            customInput={<DateInput />}
            isClearable={true}
          />
        </div>
        <div className="main-title">{`${t("From.label")} ${getDateByFormat({
          date: activeFilters.initialDate,
        })} ${t("To.label")} ${getDateByFormat({
          date: activeFilters.finalDate,
        })}`}</div>

        <TableContainer
          className={`table-container ${
            !filesList?.length ? "empty-table" : ""
          }`}
        >
          <Table aria-label="customized table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-head-cell">
                  {t("First.Name.label")}
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("IFE/INC.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  {t("Phone.label")}
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Bank.Name.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Date.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Reference.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Total.amount")} </span>
                </TableCell>
                <TableCell className="table-head-cell"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {clientsTransferencesRequestPending && (
                <TableRow className="table-body-row" rowSpan="7">
                  <TableCell className="table-body-cell" colSpan="7">
                    <Spinner text={`${t("Loading.data.label")}...`} />
                  </TableCell>
                </TableRow>
              )}

              {clientsTransferencesRequestSuccess && !filesList?.length && (
                <TableRow className="table-body-row" rowSpan="7">
                  <TableCell className="table-body-cell" colSpan="7">
                    <div className="empty-data">
                      {t("No.Data.To.Dispaly.label")}
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {clientsTransferencesRequestSuccess && filesList?.length > 0 && (
                <>
                  {filesList.map((item, index) => (
                    <TableRow className="table-body-row" key={item.id}>
                      <TableCell className="table-body-cell">
                        {item.name}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.dui}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.phone}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.bank}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {getDateByFormat({ date: item.date })}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.reference}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        <input
                          className="input-item small"
                          value={amounts[index]}
                          onKeyDown={(e) =>
                            (e.keyCode === 69 || e.keyCode === 189) &&
                            e.preventDefault()
                          }
                          onChange={(e) => {
                            const value = e.target.value;

                            if (!/^-?\d*\.?\d*$/.test(value) && value !== "") {
                              return;
                            }

                            onAmountChange(index, e.target.value);
                          }}
                        />
                      </TableCell>
                      <TableCell className="table-body-cell">
                        <div className="action-buttons">
                          <div className="buttons-container">
                            <div className="payment-options-wrapper">
                              <div className="payment-bths-wrapper">
                                <div className="bth-wrapper make-payment-bth-wrapper">
                                  <button
                                    className="bth make-payment-bth"
                                    disabled={
                                      amounts[index] == 0 ||
                                      amounts[index] == ""
                                    }
                                    onClick={() =>
                                      onSelectPayment({
                                        ...item,
                                        amountToRegister: amounts[index],
                                      })
                                    }
                                  >
                                    {t("Validate.payment.label")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="buttons-container">
                            <div className="payment-options-wrapper">
                              <div className="payment-bths-wrapper">
                                <div className="bth-wrapper make-payment-bth-wrapper">
                                  <button
                                    className="bth make-payment-bth"
                                    onClick={() => onSelectPayment(item, true)}
                                  >
                                    {t("Delete.payment.label")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }, [
    t,
    clientsTransferencesRequestSuccess,
    clientsTransferencesRequestPending,
    activeFilters,
    clientsTransferences,
    amounts,
    onAmountChange,
    onSelectPayment,
    onFilterDateChanged,
    endDate,
    startDate,
  ]);

  return (
    <div className="client-transferences-content-wrapper">
      <div>{renderBankInfo()}</div>

      <ProcessModal
        openModalProcess={openProcessModal}
        closeModalProcess={onCloseProcessModal}
        requestPending={updatePaymentRequestPending}
        requestError={updatePaymentRequestError}
        requestSuccess={updatePaymentRequestSuccess}
        fnOnDestroy={() => {}}
        pendingText={`${t("Saving.Data.label")}`}
        errorText={updatePaymentData?.message}
        successText={updatePaymentData?.message}
      />

      <ConfirmationModal
        openModal={openConfirmationModal}
        onCloseModal={onCloseModal}
        additionalText={confirmationMessage?.additionalText}
        confirmationText={confirmationMessage?.confirmationText}
        onPressAccept={onAccepAction}
      />
    </div>
  );
};

export default TransferencesContent;
