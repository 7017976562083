const loanStatusesColorMap = new Map([
  [null, "#CCCCCC"],
  ["yellow", "#F2C94C"],
  ["green", "#219653"],
  ["red", "#FF0000"],
  ["orange", "#FF8000"],
  [4, "#EB5757"],
  [5, "#219653"],
  [6, "#219653"],
  [7, "#EB5757"],
  [8, "#EB5757"],
  [9, "#219653"],
]);

export default loanStatusesColorMap;
