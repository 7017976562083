import loansHistoryActions from "../../actions/loan-history/loan-history-types";

const initialState = {
  loansHistory: [],
  loansHistoryRequest: {
    loansHistoryRequestPending: false,
    loansHistoryRequestError: false,
    loansHistoryRequestSuccess: false,
  },

  currentLoanHistory: [],
  loansHistoryItemRequest: {
    loansHistoryItemRequestPending: false,
    loansHistoryItemRequestError: false,
    loansHistoryItemRequestSuccess: false,
  },
};

function loansHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case loansHistoryActions.FETCH_LOAN_HISTORY_PENDING:
      return {
        ...state,
        loansHistory: [],
        loansHistoryRequest: {
          loansHistoryRequestPending: true,
          loansHistoryRequestError: false,
          loansHistoryRequestSuccess: false,
        },
      };
    case loansHistoryActions.FETCH_LOAN_HISTORY_ERROR:
      return {
        ...state,
        loansHistory: [],
        loansHistoryRequest: {
          loansHistoryRequestPending: false,
          loansHistoryRequestError: true,
          loansHistoryRequestSuccess: false,
        },
      };
    case loansHistoryActions.FETCH_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        loansHistory: action.payload,
        loansHistoryRequest: {
          loansHistoryRequestPending: false,
          loansHistoryRequestError: false,
          loansHistoryRequestSuccess: true,
        },
      };
    case loansHistoryActions.FETCH_LOAN_DETAILS_PENDING:
      return {
        ...state,
        currentLoanHistory: [],
        loansHistoryItemRequest: {
          loansHistoryItemRequestPending: true,
          loansHistoryItemRequestError: false,
          loansHistoryItemRequestSuccess: false,
        },
      };
    case loansHistoryActions.FETCH_LOAN_DETAILS_ERROR:
      return {
        ...state,
        currentLoanHistory: [],
        loansHistoryItemRequest: {
          loansHistoryItemRequestPending: false,
          loansHistoryItemRequestError: true,
          loansHistoryItemRequestSuccess: false,
        },
      };
    case loansHistoryActions.FETCH_LOAN_DETAILS_SUCCESS:
      return {
        ...state,
        currentLoanHistory: action.payload,
        loansHistoryItemRequest: {
          loansHistoryItemRequestPending: false,
          loansHistoryItemRequestError: false,
          loansHistoryItemRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default loansHistoryReducer;
