import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

function BalanceHeader() {
  const { t } = useTranslation();
  return (
    <div className="balance-header-wrapper">
      <div className="balance-title-wrapper">{t("Transferences.label")}</div>
    </div>
  );
}

export default BalanceHeader;
