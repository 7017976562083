import { regexEmail } from "../constants/regexEmail";

export const checkPasswordLength = (inputValue) => {
  return inputValue.length >= 8;
};

export const checkPasswordContainsNumbers = (inputValue) => {
  const hasNumber = /\d/;
  return hasNumber.test(inputValue);
};

export const checkPasswordContainsLowerLetters = (inputValue) => {
  return !!inputValue.match(/([a-z-zñáéíóúü])+([ -~])*/);
};

export const checkPasswordContainsUpperLetters = (inputValue) => {
  return !!inputValue.match(/([A-Z])+([ -~])*/);
};

export const checkPasswordContainsSpecialCharacter = (inputValue) => {
  return !!inputValue.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
};

export const comparePasswordInputs = (password, passwordConfirmation) => {
  return password === passwordConfirmation;
};

export const compareOldAndNewPasswords = (oldPassword, newPassword) => {
  return oldPassword === newPassword;
};

export const compareNewPasswordsConfirmation = (
  newPassword,
  newPasswordConfirmation
) => {
  return newPassword === newPasswordConfirmation;
};

export const validateEmail = (email) => {
  return regexEmail.test(String(email).toLowerCase());
};

export const checkOnlyNumbers = (value) => {
  return /^[0-9]*$/.test(value);
};
