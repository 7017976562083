import transferencesActions from "../../actions/transferences/transferences-types";

const initialState = {
  counterTranferencesData: null,
  counterTranferencesDataRequest: {
    counterTranferencesDataRequestPending: false,
    counterTranferencesDataRequestError: false,
    counterTranferencesDataRequestSuccess: false,
  },

  userTranferencesPagination: null,
  userTranferencesData: null,
  userTranferencesDataRequest: {},

  filesData: null,
  filesDataRequest: {
    filesDataRequestPending: false,
    filesDataRequestError: false,
    filesDataRequestSuccess: false,
  },

  validateTransferences: null,
  validateTransferencesRequest: {
    validateTransferencesRequestPending: false,
    validateTransferencesRequestError: false,
    validateTransferencesRequestSuccess: false,
  },
};

function transferencesReducer(state = initialState, action) {
  switch (action.type) {
    case transferencesActions.FETCH_TRANSFERENCES_COUNTERS_PENDING:
      return {
        ...state,
        counterTranferencesData: null,
        counterTranferencesDataRequest: {
          counterTranferencesDataRequestPending: true,
          counterTranferencesDataRequestError: false,
          counterTranferencesDataRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_TRANSFERENCES_COUNTERS_ERROR:
      return {
        ...state,
        counterTranferencesData: null,
        counterTranferencesDataRequest: {
          counterTranferencesDataRequestPending: false,
          counterTranferencesDataRequestError: true,
          counterTranferencesDataRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_TRANSFERENCES_COUNTERS_SUCCESS:
      return {
        ...state,
        counterTranferencesData: action.payload,
        counterTranferencesDataRequest: {
          counterTranferencesDataRequestPending: false,
          counterTranferencesDataRequestError: false,
          counterTranferencesDataRequestSuccess: true,
        },
      };
    case transferencesActions.FETCH_GENERATE_FILE_PENDING:
    case transferencesActions.FETCH_VALIDATE_FILE_PENDING:
      return {
        ...state,
        filesData: null,
        filesDataRequest: {
          filesDataRequestPending: true,
          filesDataRequestError: false,
          filesDataRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_GENERATE_FILE_ERROR:
    case transferencesActions.FETCH_VALIDATE_FILE_ERROR:
      return {
        ...state,
        filesData: action.payload,
        filesDataRequest: {
          filesDataRequestPending: false,
          filesDataRequestError: true,
          filesDataRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_GENERATE_FILE_SUCCESS:
    case transferencesActions.FETCH_VALIDATE_FILE_SUCCESS:
      return {
        ...state,
        filesData: action.payload,
        filesDataRequest: {
          filesDataRequestPending: false,
          filesDataRequestError: false,
          filesDataRequestSuccess: true,
        },
      };
    case transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_PENDING:
      return {
        ...state,
        validateTransferences: null,
        validateTransferencesRequest: {
          validateTransferencesRequestPending: true,
          validateTransferencesRequestError: false,
          validateTransferencesRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_ERROR:
      return {
        ...state,
        validateTransferences: action.payload,
        validateTransferencesRequest: {
          validateTransferencesRequestPending: false,
          validateTransferencesRequestError: true,
          validateTransferencesRequestSuccess: false,
        },
      };
    case transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_SUCCESS:
      return {
        ...state,
        validateTransferences: action.payload,
        validateTransferencesRequest: {
          validateTransferencesRequestPending: false,
          validateTransferencesRequestError: false,
          validateTransferencesRequestSuccess: true,
        },
      };
    case transferencesActions.FETCH_USER_TRANSFERENCES_PENDING:
      return {
        ...state,
        userTranferencesData: {
          ...(state.userTranferencesData
            ? {
                ...state.userTranferencesData,
                [action.payload.bankId]: action.payload[action.payload.bankId],
              }
            : {
                [action.payload.bankId]: action.payload[action.payload.bankId],
              }),
        },
        userTranferencesDataRequest: {
          ...state.userTranferencesDataRequest,
          [action.payload.bankId]: {
            userTranferencesDataRequestPending: true,
            userTranferencesDataRequestError: false,
            userTranferencesDataRequestSuccess: false,
          },
        },
      };
    case transferencesActions.FETCH_USER_TRANSFERENCES_ERROR:
      return {
        ...state,
        userTranferencesData: {
          ...(state.userTranferencesData
            ? {
                ...state.userTranferencesData,
                [action.payload.bankId]:
                  action.payload?.[action.payload.bankId]?.data,
              }
            : {
                [action.payload.bankId]:
                  action.payload?.[action.payload.bankId]?.data,
              }),
        },
        userTranferencesDataRequest: {
          ...state.userTranferencesDataRequest,
          [action.payload.bankId]: {
            userTranferencesDataRequestPending: false,
            userTranferencesDataRequestError: true,
            userTranferencesDataRequestSuccess: false,
          },
        },
      };
    case transferencesActions.FETCH_USER_TRANSFERENCES_SUCCESS:
      return {
        ...state,
        userTranferencesData: {
          ...(state.userTranferencesData
            ? {
                ...state.userTranferencesData,
                [action.payload.bankId]:
                  action.payload[action.payload.bankId].data,
              }
            : {
                [action.payload.bankId]:
                  action.payload[action.payload.bankId].data,
              }),
        },
        userTranferencesPagination: {
          ...(state.userTranferencesPagination
            ? {
                ...state.userTranferencesPagination,
                [action.payload.bankId]:
                  action.payload[action.payload.bankId].meta,
              }
            : {
                [action.payload.bankId]:
                  action.payload[action.payload.bankId].meta,
              }),
        },
        userTranferencesDataRequest: {
          ...state.userTranferencesDataRequest,
          [action.payload.bankId]: {
            userTranferencesDataRequestPending: false,
            userTranferencesDataRequestError: false,
            userTranferencesDataRequestSuccess: true,
          },
        },
      };
    default:
      return state;
  }
}

export default transferencesReducer;
