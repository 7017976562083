import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../assets/close-icon.svg";
import userIcon2 from "../../../../../../assets/user-icon2.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import LoanerItem from "../../../loaners-layout/loaners-list/loaner-item/LoanerItem";

import { useSelector, useDispatch } from "react-redux";

import {
    getContractById,
    getContractPDF,
    getContractRequest,
    getPromissoryNotePDF,
    getCreditHistoryPDF,
    getSignedMailPhonePDF,
} from "../../../../../../actions/contracts/contracts";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import Spinner from "../../../../../common/spinner/Spinner";
import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";

import DownloadLoanPdfs from "../../../../../user-profile-page/tabs/loan-history-tab-panel/download-loan-pdfs/DownloadLoanPdfs";

function ContractItem({
    closeContractItem,
    openContractItem,
    contractInfo,
    allowShowUserDetail = false,
}: *) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [openLoanerItem, setOpenLoanerItem] = React.useState(false);

    const {
        contractDetail,
        contractDetailRequestPending,
        contractDetailRequestError,
        user,
    } = useSelector((state) => ({
        contractDetail: state.contracts.contractDetail,
        user: state?.auth?.user,
        contractDetailRequestPending:
            state.contracts.contractDetailRequest.contractDetailRequestPending,
        contractDetailRequestError:
            state.contracts.contractDetailRequest.contractDetailRequestError,
    }));

    const onShowUserDetail = React.useCallback(() => {
        if (!allowShowUserDetail) {
            return;
        }

        setOpenLoanerItem(true);
    }, [allowShowUserDetail]);

    const onCloseUserDetail = React.useCallback(() => {
        setOpenLoanerItem(false);
    }, []);

    React.useEffect(() => {
        dispatch(getContractById(contractInfo.loanId));
        dispatch(getContractPDF(contractInfo.loanId));
        dispatch(getContractRequest(contractInfo.loanId));

        if (user?.isAdmin) {
            dispatch(getPromissoryNotePDF(contractInfo.loanId));
        }

        dispatch(getCreditHistoryPDF(contractInfo.loanId));
        dispatch(getSignedMailPhonePDF(contractInfo.loanId));
    }, [contractInfo, dispatch, user]);

    if (contractDetailRequestError) {
        return (
            <Dialog
                onClose={closeContractItem}
                open={openContractItem}
                className="modal-contract-item-wrapper"
            >
                <DialogContent className="contract-item-content">
                    <div className="contract-item-header-wrapper">
                        <div className="contract-id">
                            {t("Loan.label")} №{contractInfo.contractNumber}
                        </div>
                        <div className="user-name-wrapper">
                            <div className="user-name">
                                {contractInfo?.client}
                            </div>
                            <div className="user-name-icon-wrapper">
                                <img src={userIcon2} alt="" />
                            </div>
                        </div>
                        <div
                            className="close-img-wrapper"
                            onClick={closeContractItem}
                        >
                            <img className="close-img" src={closeIcon} alt="" />
                        </div>
                    </div>
                    <ErrorMessage text={t("Some.Error.label")} />
                </DialogContent>
            </Dialog>
        );
    }

    if (contractDetailRequestPending) {
        return (
            <Dialog
                onClose={closeContractItem}
                open={openContractItem}
                className="modal-contract-item-wrapper"
            >
                <DialogContent className="contract-item-content">
                    <div className="contract-item-header-wrapper">
                        <div className="contract-id">
                            {t("Loan.label")} №{contractInfo.contractNumber}
                        </div>
                        <div className="user-name-wrapper">
                            <div className="user-name">
                                {contractInfo.client}
                            </div>
                            <div className="user-name-icon-wrapper">
                                <img src={userIcon2} alt="" />
                            </div>
                        </div>
                        <div
                            className="close-img-wrapper"
                            onClick={closeContractItem}
                        >
                            <img
                                role="button"
                                className="close-img"
                                src={closeIcon}
                                alt=""
                            />
                        </div>
                    </div>
                    <Spinner text={`${t("Loading.data.label")}...`} />
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Dialog
            onClose={closeContractItem}
            open={openContractItem}
            className="modal-contract-item-wrapper"
        >
            <DialogContent className="contract-item-content">
                <div className="contract-item-header-wrapper">
                    <div className="contract-id">
                        {t("Loan.label")} №{contractDetail?.contractNumber}
                    </div>
                    <div className="user-name-wrapper">
                        <div
                            role={allowShowUserDetail ? "button" : ""}
                            onClick={onShowUserDetail}
                            className="user-name"
                        >
                            {contractDetail?.client}
                        </div>
                        <div className="user-name-icon-wrapper">
                            <img src={userIcon2} alt="" />
                        </div>
                    </div>
                    <div
                        className="close-img-wrapper"
                        onClick={closeContractItem}
                    >
                        <img
                            role="button"
                            className="close-img"
                            src={closeIcon}
                            alt=""
                        />
                    </div>
                </div>
                <div className="main-content-wrapper">
                    <div className="contract-history-item-table-wrapper">
                        <TableContainer>
                            <Table aria-label="customized table">
                                <TableHead className="contract-history-table-head">
                                    <TableRow>
                                        <TableCell className="table-head-cell">
                                            {t("Date.Time.label")}
                                        </TableCell>
                                        <TableCell className="table-head-cell">
                                            {t("Operation.label")}
                                        </TableCell>
                                        <TableCell className="table-head-cell">
                                            {t("Amount.label")}
                                        </TableCell>
                                        <TableCell className="table-head-cell">
                                            {t("Balance.label")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="contract-history-table-body">
                                    {!contractDetail?.operations?.length ? (
                                        <div className="empty-data-notifaction">
                                            {t("No.Data.To.Dispaly.label")}
                                        </div>
                                    ) : (
                                        <>
                                            {" "}
                                            {contractDetail?.operations.map(
                                                (item, i) => (
                                                    <TableRow
                                                        className="contract-history-table-body-row"
                                                        key={i}
                                                    >
                                                        <TableCell className="table-body-cell">
                                                            {getDateByFormat({
                                                                date: item.date,
                                                            })}
                                                        </TableCell>
                                                        <TableCell className="table-body-cell">
                                                            {item.operation}
                                                        </TableCell>
                                                        <TableCell className="table-body-cell">
                                                            {formatPrice(
                                                                item.amount
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="table-body-cell">
                                                            {formatPrice(
                                                                item.balance
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="bth-actions-wrapper">
                        <DownloadLoanPdfs
                            loanHistoryItemContractPath={
                                contractInfo.loan_contract
                            }
                            loanPurpose={contractInfo.loan_purpose}
                            loanDescription={contractInfo.description}
                        />
                    </div>
                    {user?.isAdmin && (
                        <div className="extra-info-section">
                            {contractDetail?.bank && (
                                <div className="bank-account-info">
                                    <div>
                                        <span>{t("Bank.label")}: </span>{" "}
                                        {contractDetail?.bank}
                                    </div>
                                    <div>
                                        <span>{t("Account.type.label")}: </span>
                                        {contractDetail?.accountType}
                                    </div>
                                    <div>
                                        <span>
                                            {t("Account.number.label")}:{" "}
                                        </span>
                                        {contractDetail?.accountNumber}
                                    </div>
                                </div>
                            )}

                            {contractDetail?.familiarName && (
                                <div className="bank-account-info references-info">
                                    <div className="title-section">
                                        {t("References.label")}
                                    </div>
                                    <div>
                                        <span>{t("Family.label")}: </span>{" "}
                                        {contractDetail?.familiarName}
                                        <span>{t("Phone.label")}: </span>{" "}
                                        {contractDetail?.familiarPhone}
                                    </div>
                                    <div>
                                        <span>{t("Friend.label")}: </span>{" "}
                                        {contractDetail?.friendName}
                                        <span>{t("Phone.label")}: </span>{" "}
                                        {contractDetail?.friendPhone}
                                    </div>
                                </div>
                            )}

                            {contractDetail?.observations && (
                                <div className="observations-section">
                                    <div className="section-title">
                                        {t("Comments.label")}
                                    </div>
                                    <div
                                        className="section-content"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                contractDetail?.observations,
                                        }}
                                    ></div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </DialogContent>

            {openLoanerItem && (
                <LoanerItem
                    allowLoanDetail={false}
                    closeLoanerItem={onCloseUserDetail}
                    loanerInfo={{ clientId: contractDetail.userId }}
                    openLoanerItem={openLoanerItem}
                />
            )}
        </Dialog>
    );
}

export default ContractItem;
