const type = "STATE_CONTRACTS";

const contractsActions = {
  FETCH_LOANS_PENDING: `${type}_FETCH_LOANS_PENDING`,
  FETCH_LOANS_ERROR: `${type}_FETCH_LOANS_ERROR`,
  FETCH_LOANS_SUCCESS: `${type}_FETCH_LOANS_SUCCESS`,

  FETCH_LOAN_BY_ID_PENDING: `${type}_FETCH_LOAN_BY_ID_PENDING`,
  FETCH_LOAN_BY_ID_ERROR: `${type}_FETCH_LOAN_BY_ID_ERROR`,
  FETCH_LOAN_BY_ID_SUCCESS: `${type}_FETCH_LOAN_BY_ID_SUCCESS`,
};

export default contractsActions;
