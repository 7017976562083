import React from "react";
import { useDispatch } from "react-redux";

import UserAdditionalInfo from "../verification-form/form-step/user-additional-info-step/UserAdditionalInfo";
import PhotoVerification from "./form-step/photo-verification-step/PhotoVerification";
import ActivationSuccess from "./form-step/activation-success-step/ActivationSuccess";
import step3FormIcon from "../../../../assets/step3-form-icon.svg";
import step4FormIcon from "../../../../assets/step4-form-icon.svg";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import VerificationPageHeader from "./header/VerificationPageHeader";
import InformativeStep from "./form-step/informative-step/InformativeStep";
import { getJobTypes, getPurposes } from "../../../../actions/loan/loan";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

const formSteps = [
  {
    stepOrder: "Step.label",
    stepTitle: "Additional.Info.label",
    srcIcon: step3FormIcon,
  },
  {
    stepOrder: "Step.label",
    stepTitle: "Verification.label",
    srcIcon: step4FormIcon,
  },
];

const FormHeaderStepTemplate = ({ activeStep }: *) => {
  const { t } = useTranslation();
  return (
    <div className="steps-form-header-wrapper">
      {formSteps.map((step, i) => (
        <div
          key={step.stepTitle}
          className={`step-form-header-item  ${
            i === activeStep ? "current-step" : "not-current-step"
          } `}
        >
          <div
            className={`${
              i <= activeStep ? "step-fileded" : "step-not-fileded"
            } header-item-content`}
          >
            <div className="step-img-wrapper">
              <img src={step.srcIcon} alt="" />
            </div>
            <div className="step-text">
              <div className="step-order">
                {t(step.stepOrder)} {i + 1}
              </div>
              <div className="step-title">{t(step.stepTitle)}</div>
            </div>
          </div>
        </div>
      ))}
      <div className="step-order-counter">{activeStep + 1}/4</div>
    </div>
  );
};

const FormStepTemplate = ({ step, nextStep, prevStep }) => {
  switch (step) {
    case 1:
      return (
        <div className="step-form-wrapper form-personal-info-wrapper">
          <InformativeStep nextStep={() => nextStep()} />
        </div>
      );
    case 2:
      return (
        <div className="step-form-wrapper form-address-wrapper">
          <FormHeaderStepTemplate activeStep={2} />
          <UserAdditionalInfo
            nextStep={() => nextStep()}
            prevStep={() => prevStep()}
          />
        </div>
      );
    case 3:
      return (
        <div className="step-form-wrapper">
          <FormHeaderStepTemplate activeStep={3} />
          <PhotoVerification
            nextStep={() => nextStep()}
            prevStep={() => prevStep()}
          />
        </div>
      );
    case 4:
      return <ActivationSuccess prevStep={() => prevStep()} />;
    default:
      return "";
  }
};

const LoanForm = () => {
  useAnalitycs("RequestLoanOldUser");

  const [step, setStep] = React.useState(1);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getJobTypes());
    dispatch(getPurposes());
  }, [dispatch]);

  React.useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  return (
    <div className="verificate-user-page-wrapper ">
      <VerificationPageHeader />
      <FormStepTemplate
        step={step}
        nextStep={() => setStep((value) => value + 1)}
        prevStep={() => setStep((value) => value - 1)}
      />
    </div>
  );
};

export default LoanForm;
