import authActions from "../../actions/auth/auth-types";

const initialState = {
  accessToken: localStorage.getItem("accessToken"),

  isAuthenticated: false,
  isLoading: false,
  isPending: false,
  user: {},
  activated: false,
  isVerifiedPhone: false,

  showVerificationModal: false,

  verificationDataForm: {
    verificationDataFormPending: false,
    verificationDataFormError: false,
    verificationDataFormSuccess: false,
  },

  activationEmail: {
    activationEmailPending: false,
    activationEmailError: false,
    activationEmailSuccess: false,
  },

  verificationPhoneIdRequest: null,
  verificationPhoneCodeRequest: {
    verificationPhoneCodeRequestPending: false,
    verificationPhoneCodeRequestError: false,
    verificationPhoneCodeRequestSuccess: false,
  },

  registerRequestErrorMessage: "",
  registerRequestSuccessMessage: "",
  registerRequest: {
    registerRequestPending: false,
    registerRequestError: false,
    registerRequestSuccess: false,
  },

  loginRequestErrorMessage: "",
  loginRequest: {
    loginRequestPending: false,
    loginRequestError: false,
    loginRequestSuccess: false,
  },

  sendRecoverPasswordEmailRequestErrorMessage: "",
  sendRecoverPasswordEmailRequestSuccessMessage: "",
  sendRecoverPasswordEmailRequest: {
    sendRecoverPasswordEmailPending: false,
    sendRecoverPasswordEmailError: false,
    sendRecoverPasswordEmailSuccess: false,
  },

  recoverPasswordRequestErrorMessage: "",
  recoverPasswordRequestSuccessMessage: "",
  recoverPasswordRequest: {
    recoverPasswordRequestPending: false,
    recoverPasswordRequestError: false,
    recoverPasswordRequestSuccess: false,
  },

  updatePhoneData: null,
  updatePhoneRequest: {
    updatePhoneRequestPending: false,
    updatePhoneRequestError: false,
    updatePhoneRequestSuccess: false,
  },

  updateAddressData: null,
  updateAddressRequest: {
    updateAddressRequestPending: false,
    updateAddressRequestError: false,
    updateAddressRequestSuccess: false,
  },

  changePasswordRequestMsg: "",
  changePasswordRequest: {
    changePasswordRequestPending: false,
    changePasswordRequestError: false,
    changePasswordRequestSuccess: false,
  },

  resendVerificationRequestMsg: "",
  resendVerificationRequest: {
    resendVerificationRequestPending: false,
    resendVerificationRequestError: false,
    resendVerificationRequestSuccess: false,
  },
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case authActions.FETCH_REGISTER_PENDING:
      return {
        ...state,
        registerRequestErrorMessage: "",
        registerRequestSuccessMessage: "",
        registerRequest: {
          registerRequestPending: true,
          registerRequestError: false,
          registerRequestSuccess: false,
        },
      };
    case authActions.FETCH_REGISTER_FAILED:
      return {
        ...state,
        registerRequestErrorMessage: payload.message,
        registerRequestSuccessMessage: "",
        registerRequest: {
          registerRequestPending: false,
          registerRequestError: true,
          registerRequestSuccess: false,
        },
      };
    case authActions.FETCH_REGISTER_SUCCESS:
      return {
        ...state,
        registerRequestErrorMessage: "",
        registerRequestSuccessMessage: payload.message,
        registerRequest: {
          registerRequestPending: false,
          registerRequestError: false,
          registerRequestSuccess: true,
        },
      };
    case authActions.FETCH_LOGIN_PENDING:
      return {
        ...state,
        showVerificationModal: false,
        loginRequestErrorMessage: "",
        loginRequest: {
          loginRequestPending: true,
          loginRequestError: false,
          loginRequestSuccess: false,
        },
      };
    case authActions.FETCH_LOGIN_FAILED:
      return {
        ...state,
        showVerificationModal: payload.showVerificationModal,
        loginRequestErrorMessage: {
          message: payload.message,
          data: payload.data,
        },
        loginRequest: {
          loginRequestPending: false,
          loginRequestError: true,
          loginRequestSuccess: false,
        },
      };
    case authActions.FETCH_LOGIN_SUCCESS:
      localStorage.setItem("accessToken", payload.accessToken);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        showVerificationModal: false,
        accessToken: payload.accessToken,
        loginRequestErrorMessage: "",
        loginRequest: {
          loginRequestPending: false,
          loginRequestError: false,
          loginRequestSuccess: true,
        },
      };
    case authActions.RESET_SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        showVerificationModal: false,
      };
    case authActions.FETCH_USER_INFO_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case authActions.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: { ...payload },
      };
    case authActions.FETCH_RESEND_VERIFICATION_EMAIL_PENDING:
      return {
        ...state,
        resendVerificationRequestMsg: "",
        resendVerificationRequest: {
          resendVerificationRequestPending: true,
          resendVerificationRequestError: false,
          resendVerificationRequestSuccess: false,
        },
      };
    case authActions.FETCH_RESEND_VERIFICATION_EMAIL_FAILED:
      return {
        ...state,
        resendVerificationRequestMsg: payload,
        resendVerificationRequest: {
          resendVerificationRequestPending: false,
          resendVerificationRequestError: true,
          resendVerificationRequestSuccess: false,
        },
      };
    case authActions.FETCH_RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendVerificationRequestMsg: payload,
        loginRequestErrorMessage: "",
        resendVerificationRequest: {
          resendVerificationRequestPending: false,
          resendVerificationRequestError: false,
          resendVerificationRequestSuccess: true,
        },
      };
    case authActions.FETCH_LOG_OUT_USER_SUCCESS:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        ...initialState,
      };
    case authActions.FETCH_RECOVERY_PENDING:
      return {
        ...state,
        sendRecoverPasswordEmailRequestErrorMessage: "",
        sendRecoverPasswordEmailRequestSuccessMessage: "",
        sendRecoverPasswordEmailRequest: {
          sendRecoverPasswordEmailPending: true,
          sendRecoverPasswordEmailError: false,
          sendRecoverPasswordEmailSuccess: false,
        },
      };
    case authActions.FETCH_RECOVERY_FAILED:
      return {
        ...state,
        sendRecoverPasswordEmailRequestErrorMessage: payload.message,
        sendRecoverPasswordEmailRequestSuccessMessage: "",
        sendRecoverPasswordEmailRequest: {
          sendRecoverPasswordEmailPending: false,
          sendRecoverPasswordEmailError: true,
          sendRecoverPasswordEmailSuccess: false,
        },
      };
    case authActions.FETCH_RECOVERY_SUCCESS:
      return {
        ...state,
        sendRecoverPasswordEmailRequestErrorMessage: "",
        sendRecoverPasswordEmailRequestSuccessMessage: payload.message,
        sendRecoverPasswordEmailRequest: {
          sendRecoverPasswordEmailPending: false,
          sendRecoverPasswordEmailError: false,
          sendRecoverPasswordEmailSuccess: true,
        },
      };
    case authActions.FETCH_RECOVERY_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        recoverPasswordRequestErrorMessage: "",
        recoverPasswordRequestSuccessMessage: "",
        recoverPasswordRequest: {
          recoverPasswordRequestPending: true,
          recoverPasswordRequestError: false,
          recoverPasswordRequestSuccess: false,
        },
      };
    case authActions.FETCH_RECOVERY_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        recoverPasswordRequestErrorMessage: payload.message,
        recoverPasswordRequestSuccessMessage: "",
        recoverPasswordRequest: {
          recoverPasswordRequestPending: false,
          recoverPasswordRequestError: true,
          recoverPasswordRequestSuccess: false,
        },
      };
    case authActions.FETCH_RECOVERY_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPasswordRequestErrorMessage: "",
        recoverPasswordRequestSuccessMessage: payload.message,
        recoverPasswordRequest: {
          recoverPasswordRequestPending: false,
          recoverPasswordRequestError: false,
          recoverPasswordRequestSuccess: true,
        },
      };
    case authActions.FETCH_UPDATE_USER_PHONE_CLEAR:
      return {
        ...state,
        updatePhoneData: null,
        updatePhoneRequest: {
          updatePhoneRequestPending: false,
          updatePhoneRequestError: false,
          updatePhoneRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_PHONE_PENDING:
      return {
        ...state,
        updatePhoneData: null,
        updatePhoneRequest: {
          updatePhoneRequestPending: true,
          updatePhoneRequestError: false,
          updatePhoneRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_PHONE_FAILED:
      return {
        ...state,
        updatePhoneData: payload,
        updatePhoneRequest: {
          updatePhoneRequestPending: false,
          updatePhoneRequestError: true,
          updatePhoneRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_PHONE_SUCCESS:
      return {
        ...state,
        updatePhoneData: payload,
        updatePhoneRequest: {
          updatePhoneRequestPending: false,
          updatePhoneRequestError: false,
          updatePhoneRequestSuccess: true,
        },
      };
    case authActions.FETCH_UPDATE_USER_ADDRESS_CLEAR:
      return {
        ...state,
        updateAddressData: null,
        updateAddressRequest: {
          updateAddressRequestPending: false,
          updateAddressRequestError: false,
          updateAddressRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_ADDRESS_PENDING:
      return {
        ...state,
        updateAddressData: null,
        updateAddressRequest: {
          updateAddressRequestPending: true,
          updateAddressRequestError: false,
          updateAddressRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_ADDRESS_FAILED:
      return {
        ...state,
        updateAddressData: payload,
        updateAddressRequest: {
          updateAddressRequestPending: false,
          updateAddressRequestError: true,
          updateAddressRequestSuccess: false,
        },
      };
    case authActions.FETCH_UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        updateAddressData: payload,
        updateAddressRequest: {
          updateAddressRequestPending: false,
          updateAddressRequestError: false,
          updateAddressRequestSuccess: true,
        },
      };
    case authActions.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordRequestMsg: "",
        changePasswordRequest: {
          changePasswordRequestPending: false,
          changePasswordRequestError: false,
          changePasswordRequestSuccess: false,
        },
      };
    case authActions.FETCH_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePasswordRequestMsg: "",
        changePasswordRequest: {
          changePasswordRequestPending: true,
          changePasswordRequestError: false,
          changePasswordRequestSuccess: false,
        },
      };
    case authActions.FETCH_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordRequestMsg: payload,
        changePasswordRequest: {
          changePasswordRequestPending: false,
          changePasswordRequestError: true,
          changePasswordRequestSuccess: false,
        },
      };
    case authActions.FETCH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordRequestMsg: payload,
        changePasswordRequest: {
          changePasswordRequestPending: false,
          changePasswordRequestError: false,
          changePasswordRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}
