import analyticsActions from "../../actions/analytics/analytics-types";

const initialState = {
  analyticsData: null,
  analyticsDataRequest: {
    analyticsDataRequestPending: false,
    analyticsDataRequestError: false,
    analyticsDataRequestSuccess: false,
  },
};

function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case analyticsActions.FETCH_ANALYTICS_PENDING:
      return {
        ...state,
        analyticsData: null,
        analyticsDataRequest: {
          analyticsDataRequestPending: true,
          analyticsDataRequestError: false,
          analyticsDataRequestSuccess: false,
        },
      };
    case analyticsActions.FETCH_ANALYTICS_ERROR:
      return {
        ...state,
        analyticsData: null,
        analyticsDataRequest: {
          analyticsDataRequestPending: false,
          analyticsDataRequestError: true,
          analyticsDataRequestSuccess: false,
        },
      };
    case analyticsActions.FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: action.payload,
        analyticsDataRequest: {
          analyticsDataRequestPending: false,
          analyticsDataRequestError: false,
          analyticsDataRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default analyticsReducer;
