import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./styles.scss";

function LoanStatusBadPhoto() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="loan-status-wrapper">
      <div className="status-indicator-wrapper bad-photo-loan">
        <span className="dot"></span>
        <span>
          {t(
            "We.were.not.able.to.make.a.decision.on.the.loan.due.to.photo.mismatch.or.poor.quality.label"
          )}
        </span>
      </div>
      <div className="bth-wrapper bth-ulpoad-photos-wrapper">
        <button
          className="bth-ulpoad-photos"
          type="button"
          onClick={() => {
            history.push("/upload-photos");
          }}
        >
          {t("Upload.photos.label")}
        </button>
      </div>
      <div className="bad-photo-notification-wrapper">
        <div className="notification-text">{t("Upload.the.photo.warning")}</div>
      </div>
    </div>
  );
}

export default LoanStatusBadPhoto;
