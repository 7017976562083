import React, { useState } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";

import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../../../assets/close-icon.svg";

import { useSelector, useDispatch } from "react-redux";

import ProcessModal from "../../../../../../../common/process-modal/ProcessModal";

import {
  setRefuseReason,
  resetRefuseReason,
  refuseLoan,
} from "../../../../../../../../actions/loan/loan";

import { useTranslation } from "react-i18next";

const RefuseLoanModal = ({ openRefuseModal, closeRefuseModal }: *) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    rejectReasons,
    refuseLoanRequestPending,
    refuseLoanRequestError,
    refuseLoanRequestSuccess,
  } = useSelector((state) => ({
    rejectReasons: state.loan.rejectReasons,
    refuseLoanRequestPending:
      state.loan.refuseLoanRequest.refuseLoanRequestPending,
    refuseLoanRequestError: state.loan.refuseLoanRequest.refuseLoanRequestError,
    refuseLoanRequestSuccess:
      state.loan.refuseLoanRequest.refuseLoanRequestSuccess,
  }));

  const [selectedReason, setSelectedReason] = useState({});

  const onDestroyModal = React.useCallback(() => {
    dispatch(resetRefuseReason());
    closeRefuseModal();
  }, [closeRefuseModal, dispatch]);

  const onReasonSelected = React.useCallback(
    (item) => {
      setSelectedReason(item);
      dispatch(setRefuseReason(item));
    },
    [dispatch]
  );

  const onCloseModal = React.useCallback(() => {
    if (refuseLoanRequestSuccess) {
      history.push("/login");
    }

    setOpenModalProcess(false);
  }, [history, refuseLoanRequestSuccess]);

  const onRefuseLoan = React.useCallback(() => {
    setOpenModalProcess(true);
    dispatch(refuseLoan(selectedReason?.description));
  }, [dispatch, selectedReason?.description]);

  const [openModalProcess, setOpenModalProcess] = useState(false);

  const { t } = useTranslation();

  return (
    <Dialog
      onClose={closeRefuseModal}
      open={openRefuseModal}
      className="refuse-loan-wrapper"
    >
      <DialogContent className="refuse-loan-content">
        <div className="close-icon-wrapper" onClick={closeRefuseModal}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="main-content-wrapper">
          <div className="title">{t("Refuse.loan.label")}</div>
          <div className="sub-title">
            <p>
              {t(
                "We.are.sorry.to.hear.that.you.decided.to.cancel.the.loan.label"
              )}
            </p>
            <p>
              {t(
                "We.will.be.happy.to.hear.about.the.reason.for.the.refusal.and.improve.our.service.label"
              )}
            </p>
          </div>
          <div className="refuse-loan-reasons-wrapper">
            {rejectReasons.map((item, i) => (
              <div
                key={i}
                className={`refuse-loan-reason-item  ${
                  selectedReason.index === i ? "active" : "disabled"
                }`}
                onClick={() => {
                  onReasonSelected({
                    index: i,
                    description: item,
                  });
                }}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="bth-wrapper refuse-loan-bth-wrapper">
            <button
              disabled={!selectedReason.description}
              className={`bth refuse-loan-bth ${
                selectedReason.description ? "active" : "disabled"
              }`}
              onClick={onRefuseLoan}
            >
              {t("Refuse.label")}
            </button>
          </div>

          {openModalProcess ? (
            <ProcessModal
              openModalProcess={openModalProcess}
              closeModalProcess={onCloseModal}
              requestPending={refuseLoanRequestPending}
              requestError={refuseLoanRequestError}
              requestSuccess={refuseLoanRequestSuccess}
              fnOnDestroy={onDestroyModal}
              pendingText={`${t("Refusing.loan.label")}...`}
              errorText={`${t("Some.Error.label")}`}
              successText={`${t("Loan.was.refused.label")}`}
            />
          ) : (
            ""
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RefuseLoanModal;
