import React from "react";

const colors = {
  0: "#0f8eff",
  1: "#21ff11",
  2: "#12239e",
  3: "#ff12ee",
  4: "#000000",
  5: "#ff8302",
  6: "#ff1110",
  7: "#6a5acd",
  8: "#3cb371",
  9: "#ffe600",
  10: "#e2ff10",
};

export const useBarChart = ({ data, title }) => {
  const [charData, setCharData] = React.useState({});

  React.useEffect(() => {
    if (!data) {
      setCharData({});
      return;
    }

    setCharData({
      textStyle: {
        fontFamily: "Manrope",
      },
      title: {
        text: title,
        left: "center",
        textStyle: {
          color: "#404040",
          fontWeight: "normal",
          fontSize: 14,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: data.map((item) => item.name),
      },
      series: [
        {
          type: "bar",
          data: data.map((item) => item.value),
          itemStyle: {
            color: function (param) {
              return colors[param.dataIndex];
            },
          },
        },
      ],
    });
  }, [data, title]);

  return { data: charData };
};
