import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function DashboardHeader() {
  const { t } = useTranslation();
  return (
    <div className="dashboard-header-wrapper">
      <div className="dashboard-title-wrapper">{t('Dashboard.label')}</div>
    </div>
  );
}

export default DashboardHeader;
