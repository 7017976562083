import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";

import { closeUserLoan } from "../../../../../../../actions/loan/loan";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ProcessModal from "../../../../../../common/process-modal/ProcessModal";

const ChooseAnotherLoanOption = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openProcessModal, setOpenProcessModal] = React.useState(false);

  const {
    closeLoanError,
    closeLoanPending,
    closeLoanRequestData,
    closeLoanSuccess,
  } = useSelector((state) => ({
    closeLoanRequestData: state?.loan?.closeLoanRequestData,
    closeLoanPending: state?.loan?.closeLoanRequest?.closeLoanPending,
    closeLoanError: state?.loan?.closeLoanRequest?.closeLoanError,
    closeLoanSuccess: state?.loan?.closeLoanRequest?.closeLoanSuccess,
  }));

  const onCloseModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (closeLoanSuccess) {
      history.push("/login");
    }
  }, [history, closeLoanSuccess]);

  const onCloseLoan = React.useCallback(() => {
    setOpenProcessModal(true);

    dispatch(closeUserLoan());
  }, [dispatch]);

  const { t } = useTranslation();
  return (
    <div className="bth-wrapper choose-another-loan-bth-wrapper">
      <button className="bth choose-another-loan-bth" onClick={onCloseLoan}>
        {t("Choose.another.loan.label")}
      </button>

      <ProcessModal
        openModalProcess={openProcessModal}
        closeModalProcess={onCloseModal}
        requestPending={closeLoanPending}
        requestError={closeLoanError}
        requestSuccess={closeLoanSuccess}
        fnOnDestroy={() => {
          // dispatch(reseSendRecoverPasswordEmailRequestState(false));
        }}
        pendingText={`${t("Closing.user.loan")}...`}
        errorText={closeLoanRequestData?.message}
        successText={closeLoanRequestData?.message}
      />
    </div>
  );
};

export default ChooseAnotherLoanOption;
