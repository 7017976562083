import React from "react";
import "./styles.scss";

import LoanStatusAccepted from "./LoanStatusAccepted/LoanStatusAccepted";
import LoanStatusBadPhoto from "./LoanStatusBadPhoto/LoanStatusBadPhoto";
import LoanStatusUnderConsideration from "./LoanStatusUnderConsideration/LoanStatusUnderConsideration";
import LoanStatusActive from "./LoanStatusActive/LoanStatusActive";
import LoanStatusRefused from "./LoanStatusRefused/LoanStatusRefused";
import LoanStatusBlocked from "./LoanStatusBlocked/LoanStatusBlocked";
import LoanStatusOverdue from "./LoanStatusOverdue/LoanStatusOverdue";
import LoanStatusSuccessfullyClosed from "./LoanStatusSuccessfullyClosed/LoanStatusSuccessfullyClosed";

import { useSelector } from "react-redux";

import loanStatuses from "../../../../../../constants/loanStatuses";
import userStatuses from "../../../../../../constants/userStatuses";

const LoanStatus = () => {
  const { loanLayoutStatus, userStatus } = useSelector((state) => ({
    loanLayoutStatus: state.loan?.loanLayoutStatus,
    userStatus: state.auth?.user?.status,
  }));

  const isSuspended = userStatus === userStatuses.SUSPENDED;
  const isBlocked = userStatus === userStatuses.BLOCKED;

  const value = isSuspended
    ? loanStatuses.SUSPENDED
    : isBlocked
    ? loanStatuses.BLOCKED
    : loanLayoutStatus;

  switch (value) {
    // 2 - UNDER_CONSIDERATION
    case loanStatuses.UNDER_CONSIDERATION:
      return <LoanStatusUnderConsideration />;
    // 3 - BAD_PHOTO
    case loanStatuses.BAD_PHOTO:
      return <LoanStatusBadPhoto />;
    // 4 - ACCEPTED
    case loanStatuses.ACCEPTED:
      return <LoanStatusAccepted />;
    // 5 - ACTIVE
    case loanStatuses.ACTIVE:
    case loanStatuses.ACTIVE_SENT:
      return <LoanStatusActive />;

    // 3 - DENIED
    case loanStatuses.SUSPENDED:
      return <LoanStatusRefused />;

    case loanStatuses.BLOCKED:
      return <LoanStatusBlocked />;
    // 7 - OVERDUE
    case loanStatuses.OVERDUE:
      return <LoanStatusOverdue />;
    // 5- PAID
    case loanStatuses.PAID:
      return <LoanStatusSuccessfullyClosed />;
    default:
      return "";
  }
};

export default LoanStatus;
