const type = "STATE_NOTIFICATIONS";

const notificationsActions = {
  NOTIFICATION_EMAIL_SWITCH_PENDING: "NOTIFICATION_EMAIL_SWITCH_PENDING",
  NOTIFICATION_EMAIL_SWITCH_SUCCESS: "NOTIFICATION_EMAIL_SWITCH_SUCCESS",
  NOTIFICATION_EMAIL_SWITCH_FAILED: "NOTIFICATION_EMAIL_SWITCH_FAILED",

  NOTIFICATION_SMS_SWITCH_PENDING: "NOTIFICATION_SMS_SWITCH_PENDING",
  NOTIFICATION_SMS_SWITCH_SUCCESS: "NOTIFICATION_SMS_SWITCH_SUCCESS",
  NOTIFICATION_SMS_SWITCH_FAILED: "NOTIFICATION_SMS_SWITCH_FAILED",

  GET_USER_NOTIFICATIONS_PENDING: "GET_USER_NOTIFICATIONS_PENDING",
  GET_USER_NOTIFICATIONS_ERROR: "GET_USER_NOTIFICATIONS_ERROR",
  GET_USER_NOTIFICATIONS_SUCCESS: "GET_USER_NOTIFICATIONS_SUCCESS",

  UPDATE_NOTIFICATIONS_READED_STATUS: "UPDATE_NOTIFICATIONS_READED_STATUS",

  // **********************
  FETCH_MARK_ALL_AS_READ_PENDING: `${type}_MARK_ALL_NOTIFICATIONS_READ`,
  FETCH_MARK_ALL_AS_READ_SUCCESS: `${type}_MARK_ALL_NOTIFICATIONS_READ`,
  FETCH_MARK_ALL_AS_READ_FAILED: `${type}_MARK_ALL_NOTIFICATIONS_READ`,

  FETCH_MARK_NOTIFICATION_AS_READ_PENDING: `${type}_MARK_NOTIFICATION_READ`,
  FETCH_MARK_NOTIFICATION_AS_READ_SUCCESS: `${type}_MARK_NOTIFICATION_READ`,
  FETCH_MARK_NOTIFICATION_AS_READ_FAILED: `${type}_MARK_NOTIFICATION_READ`,

  FETCH_USER_NOTIFICATIONS_PENDING: `${type}_FETCH_USER_NOTIFICATIONS_PENDING`,
  FETCH_USER_NOTIFICATIONS_FAILED: `${type}_FETCH_USER_NOTIFICATIONS_FAILED`,
  FETCH_USER_NOTIFICATIONS_SUCCESS: `${type}_FETCH_USER_NOTIFICATIONS_SUCCESS`,
};

export default notificationsActions;
