import conciliationsActions from "../../actions/conciliations/conciliations-types";

const initialState = {
  operatorPayments: null,
  clientPayments: null,
  conciliationsRequest: {
    conciliationsRequestPending: false,
    conciliationsRequestError: false,
    conciliationsRequestSuccess: false,
  },

  conciliatePayment: null,
  conciliatePaymentRequest: {
    conciliatePaymentRequestPending: false,
    conciliatePaymentRequestError: false,
    conciliatePaymentRequestSuccess: false,
  },

  loanUserInfo: null,
  loanUserInfoRequest: {
    loanUserInfoRequestPending: false,
    loanUserInfoRequestError: false,
    loanUserInfoRequestSuccess: false,
  },

  emergencyPayment: null,
  emergencyPaymentRequest: {
    emergencyPaymentRequestPending: false,
    emergencyPaymentRequestError: false,
    emergencyPaymentRequestSuccess: false,
  },

  refinancedLoanData: null,
  refinancedLoanDataRequest: {
    refinancedLoanDataRequestPending: false,
    refinancedLoanDataRequestError: false,
    refinancedLoanDataRequestSuccess: false,
  },

  cancelRefinancement: null,
  cancelRefinancementRequest: {
    cancelRefinancementRequestPending: false,
    cancelRefinancementRequestError: false,
    cancelRefinancementRequestSuccess: false,
  },

  checkReturnScale: null,
  checkReturnScaleRequest: {
    checkReturnScaleRequestPending: false,
    checkReturnScaleRequestError: false,
    checkReturnScaleRequestSuccess: false,
  },
};

function conciliationsReducer(state = initialState, action) {
  switch (action.type) {
    case conciliationsActions.FETCH_CONCILIATIONS_PENDING:
      return {
        ...state,
        operatorPayments: null,
        clientPayments: null,
        conciliationsRequest: {
          conciliationsRequestPending: true,
          conciliationsRequestError: false,
          conciliationsRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_CONCILIATIONS_ERROR:
      return {
        ...state,
        operatorPayments: null,
        clientPayments: null,
        conciliationsRequest: {
          conciliationsRequestPending: false,
          conciliationsRequestError: true,
          conciliationsRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_CONCILIATIONS_SUCCESS:
      return {
        ...state,
        operatorPayments: action.payload.operatorPayments,
        clientPayments: action.payload.clientPayments,
        conciliationsRequest: {
          conciliationsRequestPending: false,
          conciliationsRequestError: false,
          conciliationsRequestSuccess: true,
        },
      };
    case conciliationsActions.FETCH_CONCILIATE_PAYMENT_PENDING:
      return {
        ...state,
        conciliatePayment: null,
        conciliatePaymentRequest: {
          conciliatePaymentRequestPending: true,
          conciliatePaymentRequestError: false,
          conciliatePaymentRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_CONCILIATE_PAYMENT_ERROR:
      return {
        ...state,
        conciliatePayment: action.payload,
        conciliatePaymentRequest: {
          conciliatePaymentRequestPending: false,
          conciliatePaymentRequestError: true,
          conciliatePaymentRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_CONCILIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        conciliatePayment: action.payload,
        conciliatePaymentRequest: {
          conciliatePaymentRequestPending: false,
          conciliatePaymentRequestError: false,
          conciliatePaymentRequestSuccess: true,
        },
      };
    case conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_PENDING:
      return {
        ...state,
        loanUserInfo: null,
        loanUserInfoRequest: {
          loanUserInfoRequestPending: true,
          loanUserInfoRequestError: false,
          loanUserInfoRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_ERROR:
      return {
        ...state,
        loanUserInfo: action.payload,
        loanUserInfoRequest: {
          loanUserInfoRequestPending: false,
          loanUserInfoRequestError: true,
          loanUserInfoRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_INFO_BY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loanUserInfo: action.payload,
        loanUserInfoRequest: {
          loanUserInfoRequestPending: false,
          loanUserInfoRequestError: false,
          loanUserInfoRequestSuccess: true,
        },
      };
    case conciliationsActions.REGISTER_EMERGENCY_PAYMENT_PENDING:
      return {
        ...state,
        emergencyPayment: null,
        emergencyPaymentRequest: {
          emergencyPaymentRequestPending: true,
          emergencyPaymentRequestError: false,
          emergencyPaymentRequestSuccess: false,
        },
      };
    case conciliationsActions.REGISTER_EMERGENCY_PAYMENT_ERROR:
      return {
        ...state,
        emergencyPayment: action.payload,
        emergencyPaymentRequest: {
          emergencyPaymentRequestPending: false,
          emergencyPaymentRequestError: true,
          emergencyPaymentRequestSuccess: false,
        },
      };
    case conciliationsActions.REGISTER_EMERGENCY_PAYMENT_SUCCESS:
      return {
        ...state,
        emergencyPayment: action.payload,
        emergencyPaymentRequest: {
          emergencyPaymentRequestPending: false,
          emergencyPaymentRequestError: false,
          emergencyPaymentRequestSuccess: true,
        },
      };
    case conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_PENDING:
      return {
        ...state,
        refinancedLoanData: null,
        refinancedLoanDataRequest: {
          refinancedLoanDataRequestPending: true,
          refinancedLoanDataRequestError: false,
          refinancedLoanDataRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_ERROR:
      return {
        ...state,
        refinancedLoanData: action.payload,
        refinancedLoanDataRequest: {
          refinancedLoanDataRequestPending: false,
          refinancedLoanDataRequestError: true,
          refinancedLoanDataRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        refinancedLoanData: action.payload,
        refinancedLoanDataRequest: {
          refinancedLoanDataRequestPending: false,
          refinancedLoanDataRequestError: false,
          refinancedLoanDataRequestSuccess: true,
        },
      };
    case conciliationsActions.FETCH_CANCEL_REFINANCEMENT_PENDING:
      return {
        ...state,
        cancelRefinancement: null,
        cancelRefinancementRequest: {
          cancelRefinancementRequestPending: true,
          cancelRefinancementRequestError: false,
          cancelRefinancementRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_CANCEL_REFINANCEMENT_ERROR:
      return {
        ...state,
        cancelRefinancement: action.payload,
        cancelRefinancementRequest: {
          cancelRefinancementRequestPending: false,
          cancelRefinancementRequestError: true,
          cancelRefinancementRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_PENDING:
      return {
        ...state,
        checkReturnScaleRequest: {
          checkReturnScaleRequestPending: true,
          checkReturnScaleRequestError: false,
          checkReturnScaleRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_ERROR:
      return {
        ...state,
        checkReturnScale: action.payload,
        checkReturnScaleRequest: {
          checkReturnScaleRequestPending: false,
          checkReturnScaleRequestError: true,
          checkReturnScaleRequestSuccess: false,
        },
      };
    case conciliationsActions.FETCH_LOAN_RETURN_SCALE_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        checkReturnScaleRequest: {
          checkReturnScaleRequestPending: false,
          checkReturnScaleRequestError: false,
          checkReturnScaleRequestSuccess: true,
        },
      };
    case conciliationsActions.FETCH_CANCEL_REFINANCEMENT_SUCCESS:
      return {
        ...state,
        cancelRefinancement: action.payload,
        cancelRefinancementRequest: {
          cancelRefinancementRequestPending: false,
          cancelRefinancementRequestError: false,
          cancelRefinancementRequestSuccess: true,
        },
      };
    case conciliationsActions.CLEAR_STATE:
      return {
        ...state,
        loanUserInfo: null,
        loanUserInfoRequest: {
          loanUserInfoRequestPending: false,
          loanUserInfoRequestError: false,
          loanUserInfoRequestSuccess: false,
        },
        emergencyPayment: null,
        emergencyPaymentRequest: {
          emergencyPaymentRequestPending: false,
          emergencyPaymentRequestError: false,
          emergencyPaymentRequestSuccess: false,
        },
        refinancedLoanData: null,
        refinancedLoanDataRequest: {
          refinancedLoanDataRequestPending: false,
          refinancedLoanDataRequestError: false,
          refinancedLoanDataRequestSuccess: false,
        },

        cancelRefinancement: null,
        cancelRefinancementRequest: {
          cancelRefinancementRequestPending: false,
          cancelRefinancementRequestError: false,
          cancelRefinancementRequestSuccess: false,
        },

        checkReturnScale: null,
        checkReturnScaleRequest: {
          checkReturnScaleRequestPending: false,
          checkReturnScaleRequestError: false,
          checkReturnScaleRequestSuccess: false,
        },
      };
    default:
      return state;
  }
}

export default conciliationsReducer;
