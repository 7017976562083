import React from "react";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import tick2Icon from "../../../../../assets/tick2-icon.svg";
import searchIcon from "../../../../../assets/search-icon.svg";
import Pagination from "@material-ui/lab/Pagination";

import { useSelector } from "react-redux";

import lockIcon from "../../../../../assets/lock-svgrepo-com.svg";
import { getLoaners } from "../../../../../actions/loaners/loaners";

import loanStatusesColorMap from "../../../../../constants/loanStatusesColorMap";
import { useFilters } from "../../../../../hooks/useFilters";

const statuses = [
  { value: 1, title: "Sin confirmación de email", color: "yellow" },
  { value: "2,3,4,5,6", title: "Activos", color: "green" },
  { value: 7, title: "Suspendidos", color: "red" },
  { value: 8, title: "Bloqueados", color: "black" },
];

const CheckboxFilters = ({
  onCheckboxChange,
  usedFilters,
}: {
  onCheckboxChange: *,
  usedFilters: Array<Number>,
}) => {
  const [checkedValues, setCheckedValues] = React.useState(usedFilters);
  const { t } = useTranslation();

  const onCheckFilter = React.useCallback(
    (e) => {
      const isChecked = e.target.checked;
      const value = e.target.value;
      let newValues;

      if (isChecked) {
        newValues = [...checkedValues, value];
        setCheckedValues(newValues);
      } else {
        newValues = checkedValues.filter((item) => item !== value);
        setCheckedValues(newValues);
      }

      onCheckboxChange({
        filter: "filter[status]",
        value: newValues,
      });
    },
    [checkedValues, onCheckboxChange]
  );

  return (
    <>
      {statuses.map((item) => (
        <div className="checbox-filter-item" key={item.value}>
          <input
            type="checkbox"
            id={`checbox-filter-label-${item.value}`}
            value={item.value}
            defaultChecked={checkedValues.includes(item.value.toString())}
            onChange={onCheckFilter}
          />
          <label htmlFor={`checbox-filter-label-${item.value}`}>
            <span className="indicator-wrapper">
              {item.color === "black" ? (
                <img src={lockIcon} alt={t("Block.label")} className="icon" />
              ) : (
                <span
                  className="indicator"
                  style={{
                    background: loanStatusesColorMap.get(item.color),
                  }}
                ></span>
              )}
            </span>
            <span>{item.title}</span>
          </label>
        </div>
      ))}
    </>
  );
};

const LoanersHeader = () => {
  const [showCheckboxFilters, setShowCheckboxFilters] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState({
    "page[size]": 25,
    "page[number]": 1,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [usedFilters, setUsedFilters] = React.useState([]);
  const timeOut = React.useRef(null);

  const { pagination } = useSelector((state) => ({
    pagination: state.loaners.loanersPagination,
  }));

  useFilters({
    activeFilters,
    action: getLoaners,
  });

  const onChangeFilter = React.useCallback(
    ({ filter, value }) => {
      const resetFilters = {};

      if (filter !== "page[number]") {
        resetFilters["page[number]"] = 1;
      }

      setActiveFilters({
        ...activeFilters,
        ...resetFilters,
        [filter]: value,
      });
    },
    [activeFilters]
  );

  const onCheckboxChange = React.useCallback(
    ({ filter, value }) => {
      setUsedFilters(value);

      onChangeFilter({ filter, value: value.length ? value.join(",") : null });
    },
    [onChangeFilter]
  );

  const onTypeSearch = React.useCallback(
    (e) => {
      const value = e.target.value;
      setSearchValue(value);

      clearTimeout(timeOut.current);

      timeOut.current = setTimeout(() => {
        onChangeFilter({
          filter: "filter[search]",
          value,
        });
      }, 1000);
    },
    [onChangeFilter]
  );

  const onPaginationChanged = React.useCallback(
    (e, value) => {
      onChangeFilter({
        filter: "page[number]",
        value,
      });
    },
    [onChangeFilter]
  );

  React.useEffect(() => {
    function handleClickOutside(event) {
      const checboxFiltersWrapper = event.target.closest(
        ".checbox-filters-wrapper"
      );
      const filterBthStatus = event.target.closest(".filter-bth-status");
      if (!checboxFiltersWrapper && !filterBthStatus) {
        setShowCheckboxFilters(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { t } = useTranslation();
  return (
    <div className="loaners-header-wrapper">
      <div className="loaners-title-wrapper">{t("Loaners.label")}</div>
      <div className="loaners-table-filters-wrapper">
        <div className="loaners-table-filter-item-wrapper status-filter">
          <span
            className="filter-bth-status"
            onClick={() => setShowCheckboxFilters((state) => !state)}
          >
            <span>{t("Status.label")} </span>
            <img src={tick2Icon} className="sort-icon" alt="Sort" />
          </span>

          {showCheckboxFilters && (
            <div className="checbox-filters-wrapper">
              <CheckboxFilters
                usedFilters={usedFilters}
                onCheckboxChange={onCheckboxChange}
              />
            </div>
          )}
        </div>
        <div className="loaners-table-filter-item-wrapper search-filter">
          <img src={searchIcon} alt="Search" className="search-icon" />
          <input
            value={searchValue}
            type="text"
            placeholder={t("Search.label")}
            className={`search-input`}
            onChange={onTypeSearch}
          />
        </div>
      </div>
      <div className="pagination-wrapper">
        <Pagination
          count={pagination?.last_page}
          defaultPage={1}
          variant="outlined"
          color="secondary"
          onChange={onPaginationChanged}
        />
      </div>
    </div>
  );
};

export default LoanersHeader;
