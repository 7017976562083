const type = "STATE_ADMIN_INBOX";

const inboxActions = {
  GET_INBOX_LOANS_PENDING: "GET_INBOX_LOANS_PENDING",
  GET_INBOX_LOANS_ERROR: "GET_INBOX_LOANS_ERROR",
  GET_INBOX_LOANS_SUCCESS: "GET_INBOX_LOANS_SUCCESS",

  GET_INBOX_ITEM_LOAN_HISTORY_PENDING: "GET_INBOX_ITEM_LOAN_HISTORY_PENDING",
  GET_INBOX_ITEM_LOAN_HISTORY_ERROR: "GET_INBOX_ITEM_LOAN_HISTORY_ERROR",
  GET_INBOX_ITEM_LOAN_HISTORY_SUCCESS: "GET_INBOX_ITEM_LOAN_HISTORY_SUCCESS",
  // CLEAR_INBOX_ITEM_LOAN_HISTORY: 'CLEAR_INBOX_ITEM_LOAN_HISTORY',

  SET_CURRENT_INBOX_ITEM_ID: "SET_CURRENT_INBOX_ITEM_ID",

  UPDATE_INBOX_ITEM_LOAN_PENDING: "UPDATE_INBOX_ITEM_LOAN_PENDING",
  UPDATE_INBOX_ITEM_LOAN_ERROR: "UPDATE_INBOX_ITEM_LOAN_ERROR",
  UPDATE_INBOX_ITEM_LOAN_SUCCESS: "UPDATE_INBOX_ITEM_LOAN_SUCCESS",

  UPDATE_INBOX_ITEM_LOAN_STATUS_REQUEST_SUCCESS_STATE:
    "UPDATE_INBOX_ITEM_LOAN_STATUS_REQUEST_SUCCESS_STATE",

  GET_DNI_FRONT_IMG_PENDING: "GET_DNI_FRONT_IMG_PENDING",
  GET_DNI_FRONT_IMG_SUCCESS: "GET_DNI_FRONT_IMG_SUCCESS",
  GET_DNI_FRONT_IMG_ERROR: "GET_DNI_FRONT_IMG_ERROR",
  SET_DNI_FRONT_IMG: "SET_DNI_FRONT_IMG",

  GET_DNI_BACK_IMG_PENDING: "GET_DNI_BACK_IMG_PENDING",
  GET_DNI_BACK_IMG_SUCCESS: "GET_DNI_BACK_IMG_SUCCESS",
  GET_DNI_BACK_IMG_ERROR: "GET_DNI_BACK_IMG_ERROR",
  SET_DNI_BACK_IMG: "SET_DNI_BACK_IMG",

  GET_DNI_SELFIE_IMG_PENDING: "GET_DNI_SELFIE_IMG_PENDING",
  GET_DNI_SELFIE_IMG_SUCCESS: "GET_DNI_SELFIE_IMG_SUCCESS",
  GET_DNI_SELFIE_IMG_ERROR: "GET_DNI_SELFIE_IMG_ERROR",
  SET_DNI_SELFIE_IMG: "SET_DNI_SELFIE_IMG",

  RESET_DOWNLOAD_PHOTOS_REQUES_STORE: "RESET_DOWNLOAD_PHOTOS_REQUES_STORE",

  // *************************
  FETCH_INBOX_LOANS_PENDING: `${type}_FETCH_INBOX_LOANS_PENDING`,
  FETCH_INBOX_LOANS_ERROR: `${type}_FETCH_INBOX_LOANS_ERROR`,
  FETCH_INBOX_LOANS_SUCCESS: `${type}_FETCH_INBOX_LOANS_SUCCESS`,

  FETCH_LOAN_EVALUATION_BY_ID_PENDING: `${type}_FETCH_LOAN_EVALUATION_BY_ID_PENDING`,
  FETCH_LOAN_EVALUATION_BY_ID_ERROR: `${type}_FETCH_LOAN_EVALUATION_BY_ID_ERROR`,
  FETCH_LOAN_EVALUATION_BY_ID_SUCCESS: `${type}_FETCH_LOAN_EVALUATION_BY_ID_SUCCESS`,

  UPDATE_LOAN_STATUS_PENDING: `${type}_UPDATE_LOAN_STATUS_PENDING`,
  UPDATE_LOAN_STATUS_ERROR: `${type}_UPDATE_LOAN_STATUS_ERROR`,
  UPDATE_LOAN_STATUS_SUCCESS: `${type}_UPDATE_LOAN_STATUS_SUCCESS`,
};

export default inboxActions;
