import axios from "axios";
import authActions from "./auth-types";
import errorMapper from "../../utils/errorMapper";

//  CHECK TOKEN & LOAD USER

export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.accessToken;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
      Language: "es",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: authActions.FETCH_USER_INFO_PENDING });
  axios
    .get(
      `${process.env.REACT_APP_API_SERVER}/client/data`,
      tokenConfig(getState)
    )
    .then(({ data: { data: info } }) => {
      dispatch({
        type: authActions.FETCH_USER_INFO_SUCCESS,
        payload: info,
      });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_USER_INFO_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const register = (email, password, passwordConfirmation) => (
  dispatch
) => {
  dispatch({ type: authActions.FETCH_REGISTER_PENDING });

  const body = {
    email,
    password,
    password_confirmation: passwordConfirmation,
  };

  axios
    .post(`${process.env.REACT_APP_API_SERVER}/register`, body)
    .then(({ data: { data: info } }) => {
      dispatch({ type: authActions.FETCH_REGISTER_SUCCESS, payload: info });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_REGISTER_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const resetRegisterRequestStore = (value) => (dispatch) => {
  dispatch({ type: authActions.RESET_REGISTER_REQUEST_STORE, payload: value });
};

export const resetChangePassword = () => (dispatch) => {
  dispatch({ type: authActions.RESET_CHANGE_PASSWORD });
};

export const login = (username, password) => (dispatch) => {
  const body = { email: username, password };
  dispatch({ type: authActions.FETCH_LOGIN_PENDING });

  axios
    .post(`${process.env.REACT_APP_API_SERVER}/login`, body)
    .then(async ({ data: { data: info } }) => {
      const timer = setTimeout(() => {
        dispatch(loadUser());
        clearTimeout(timer);
      }, 0);

      dispatch({ type: authActions.FETCH_LOGIN_SUCCESS, payload: info });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_LOGIN_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
          showVerificationModal: err?.response?.status === 403,
        },
      });
    });
};

export const logout = () => (dispatch, getState) => {
  dispatch({
    type: authActions.FETCH_LOG_OUT_USER_PENDING,
  });

  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/logout`,
      {},
      tokenConfig(getState)
    )
    .then(() => {
      dispatch({
        type: authActions.FETCH_LOG_OUT_USER_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_LOG_OUT_USER_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const changePassword = ({
  oldPassword,
  newPassword,
  newPasswordConfirmation,
}) => async (dispatch, getState) => {
  dispatch({ type: authActions.FETCH_CHANGE_PASSWORD_PENDING });
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/update-password`,
      {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      },
      tokenConfig(getState)
    );

    dispatch({
      type: authActions.FETCH_CHANGE_PASSWORD_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: authActions.FETCH_CHANGE_PASSWORD_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const sendRecoverPasswordEmail = (email) => (dispatch) => {
  dispatch({ type: authActions.FETCH_RECOVERY_PENDING });
  axios
    .post(`${process.env.REACT_APP_API_SERVER}/forgot-password`, {
      email: email,
    })
    .then(({ data: { data: info } }) => {
      dispatch({ type: authActions.FETCH_RECOVERY_SUCCESS, payload: info });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_RECOVERY_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const updateUserPhone = (phoneNumber) => async (dispatch, getState) => {
  dispatch({ type: authActions.FETCH_UPDATE_USER_PHONE_PENDING });

  try {
    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/update-phone`,
      {
        phoneNumber: phoneNumber.replace("-", ""),
      },
      token
    );

    dispatch({
      type: authActions.FETCH_UPDATE_USER_PHONE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: authActions.FETCH_UPDATE_USER_PHONE_FAILED,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const updateUserAddress = (addressInfo) => async (
  dispatch,
  getState
) => {
  dispatch({ type: authActions.FETCH_UPDATE_USER_ADDRESS_PENDING });

  try {
    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/update-address`,
      addressInfo,
      token
    );

    await dispatch(loadUser());

    dispatch({
      type: authActions.FETCH_UPDATE_USER_ADDRESS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: authActions.FETCH_UPDATE_USER_ADDRESS_FAILED,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const updateUserPassword = (passwordInfo) => async (
  dispatch,
  getState
) => {
  dispatch({ type: authActions.FETCH_UPDATE_USER_PASSWORD_PENDING });

  try {
    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/update-password`,
      passwordInfo,
      token
    );

    await dispatch(loadUser());

    dispatch({
      type: authActions.FETCH_UPDATE_USER_PASSWORD_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: authActions.FETCH_UPDATE_USER_PASSWORD_FAILED,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const clearVerificationModal = () => async (dispatch) => {
  dispatch({
    type: authActions.RESET_SHOW_VERIFICATION_MODAL,
  });
};

export const resendVerificationEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: authActions.FETCH_RESEND_VERIFICATION_EMAIL_PENDING });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/email/verification-notification`,
      {
        email,
      }
    );

    dispatch({
      type: authActions.FETCH_RESEND_VERIFICATION_EMAIL_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: authActions.FETCH_RESEND_VERIFICATION_EMAIL_FAILED,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const clearUpdatePhoneState = () => async (dispatch) => {
  dispatch({
    type: authActions.FETCH_UPDATE_USER_PHONE_CLEAR,
  });
};

export const recoverPassword = ({
  email,
  token,
  password,
  passwordConfirmation,
}) => (dispatch) => {
  dispatch({ type: authActions.FETCH_RECOVERY_CHANGE_PASSWORD_PENDING });
  axios
    .post(`${process.env.REACT_APP_API_SERVER}/reset-password`, {
      token,
      email,
      password,
      password_confirmation: passwordConfirmation,
    })
    .then(({ data: { data: info } }) => {
      dispatch({
        type: authActions.FETCH_RECOVERY_CHANGE_PASSWORD_SUCCESS,
        payload: info,
      });
    })
    .catch((err) => {
      dispatch({
        type: authActions.FETCH_RECOVERY_CHANGE_PASSWORD_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

// +++++++++++++++++++++++**********************

// LOGOUT

// Setup config with token - helper func

// REQUEST VERIFICATION CODE

function actionCreator(actionType, data) {
  return {
    type: authActions[actionType],
    payload: data,
  };
}

//  LOGIN USER VIA FACEBOOK
export const loginFB = (access_token, provider) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ access_token, provider });
  dispatch(actionCreator("FBLOGIN_FAILED", false));
  dispatch(actionCreator("FBLOGIN_PENDING", true));
  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/account/social-auth/`,
      body,
      config
    )
    .then((res) => {
      dispatch(
        actionCreator("FBLOGIN_SUCCESS", { data: res.data, status: true })
      );
      dispatch(actionCreator("FBLOGIN_PENDING", false));
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch(
          actionCreator("FBLOGIN_FAILED", {
            data: "Invalid.Login.Or.Password.label",
            status: true,
          })
        );
      } else {
        dispatch(
          actionCreator("FBLOGIN_FAILED", {
            data: "Some.Error.label",
            status: true,
          })
        );
      }
      dispatch(actionCreator("FBLOGIN_PENDING", false));
    });
};
