import promocodesActions from "../../actions/promocodes/promocodes-types";
import authActions from "../../actions/auth/auth-types";

const initialState = {
  inputPromocodeValue: "",
  promocodeInvalid: false,
  promocodeApply: false,
  promocodeId: null,
  discount: null,

  promoCodeInfo: null,
  getPromoCodeInfoRequest: {
    getPromoCodeInfoRequestPending: false,
    getPromoCodeInfoRequestError: false,
    getPromoCodeInfoRequestSuccess: false,
  },

  promoCodeContent: null,
  getInputPromocodeRequest: {
    getInputPromocodeRequestPending: false,
    getInputPromocodeRequestError: false,
    getInputPromocodeRequestSuccess: false,
  },
};

function promocodesReducer(state = initialState, action) {
  switch (action.type) {
    // get promocode actions
    case promocodesActions.FETCH_PROMOCODE_INFO_PENDING:
      return {
        ...state,
        promoCodeInfo: null,
        getPromoCodeInfoRequest: {
          getPromoCodeInfoRequestPending: true,
          getPromoCodeInfoRequestError: false,
          getPromoCodeInfoRequestSuccess: false,
        },
      };
    case promocodesActions.FETCH_PROMOCODE_INFO_ERROR:
      return {
        promoCodeInfo: action.payload,
        getPromoCodeInfoRequest: {
          getPromoCodeInfoRequestPending: false,
          getPromoCodeInfoRequestError: true,
          getPromoCodeInfoRequestSuccess: false,
        },
      };
    case promocodesActions.FETCH_PROMOCODE_INFO_SUCCESS:
      return {
        ...state,
        promoCodeInfo: action.payload,
        getPromoCodeInfoRequest: {
          getPromoCodeInfoRequestPending: false,
          getPromoCodeInfoRequestError: false,
          getPromoCodeInfoRequestSuccess: true,
        },
      };
    // get input promocode actions
    case promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_PENDING:
      return {
        ...state,
        promocodeInvalid: false,
        promoCodeContent: null,
        getInputPromocodeRequest: {
          getInputPromocodeRequestPending: true,
          getInputPromocodeRequestError: false,
          getInputPromocodeRequestSuccess: false,
        },
      };
    case promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_ERROR:
      return {
        ...state,
        promocodeInvalid: true,
        promoCodeContent: action.payload,
        getInputPromocodeRequest: {
          getInputPromocodeRequestPending: false,
          getInputPromocodeRequestError: true,
          getInputPromocodeRequestSuccess: false,
        },
      };
    case promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_SUCCESS:
      return {
        ...state,
        promocodeInvalid: false,
        promocodeApply: true,
        promoCodeContent: action.payload,
        getInputPromocodeRequest: {
          getInputPromocodeRequestPending: false,
          getInputPromocodeRequestError: false,
          getInputPromocodeRequestSuccess: true,
        },
      };
    // set input promocode actions
    case promocodesActions.SET_INPUT_PROMOCODE_VALUE:
      return {
        ...state,
        inputPromocodeValue: action.payload,
      };
    // set  promocode apply actions
    case promocodesActions.SET_PROMOCODE_APPLY:
      return {
        ...state,
        promocodeApply: action.payload.status,
        promocodeId: action.payload.promocodeId,
        discount: action.payload.discount,
      };
    // reset store
    case promocodesActions.RESET_PROMOCODE_STORE:
    case authActions.FETCH_LOG_OUT_USER_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

export default promocodesReducer;
