import smsVerificationActions from "../../actions/sms-verification/sms-verification-types";
import authActions from "../../actions/auth/auth-types";

const initialState = {
  invalidNumber: false,
  codeLive: false,
  requestId: null,
  showExpiredTemplate: false,
  verificationCode: "",

  sendCodeToPhoneRequest: {
    sendCodeToPhoneRequestPending: false,
    sendCodeToPhoneRequestError: false,
    sendCodeToPhoneRequestSuccess: false,
  },

  verifyCodeRequest: {
    verifyCodeRequestPending: false,
    verifyCodeRequestError: false,
    verifyCodeRequestSuccess: false,
  },
};

export default function smsVerificationReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case smsVerificationActions.SEND_SMS_TO_PHONE_PENDING:
      return {
        ...state,
        invalidNumber: false,
        showExpiredTemplate: false,
        sendCodeToPhoneRequest: {
          sendCodeToPhoneRequestPending: true,
          sendCodeToPhoneRequestError: false,
          sendCodeToPhoneRequestSuccess: false,
        },
        codeLive: false,
      };
    case smsVerificationActions.SEND_SMS_TO_PHONE_ERROR:
      return {
        ...state,
        sendCodeToPhoneRequest: {
          sendCodeToPhoneRequestPending: false,
          sendCodeToPhoneRequestError: true,
          sendCodeToPhoneRequestSuccess: false,
        },
        codeLive: true,
      };
    case smsVerificationActions.SEND_SMS_TO_PHONE_SUCCESS:
      return {
        ...state,
        ...payload,
        sendCodeToPhoneRequest: {
          sendCodeToPhoneRequestPending: false,
          sendCodeToPhoneRequestError: false,
          sendCodeToPhoneRequestSuccess: true,
        },
        codeLive: true,
      };
    case smsVerificationActions.VERIFY_CODE_PENDING:
      return {
        ...state,
        verifyCodeRequest: {
          verifyCodeRequestPending: true,
          verifyCodeRequestError: false,
          verifyCodeRequestSuccess: false,
        },
      };
    case smsVerificationActions.VERIFY_CODE_ERROR:
      return {
        ...state,
        verifyCodeRequest: {
          verifyCodeRequestPending: false,
          verifyCodeRequestError: true,
          verifyCodeRequestSuccess: false,
        },
      };
    case smsVerificationActions.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        requestId: null,
        codeLive: false,
        verifyCodeRequest: {
          verifyCodeRequestPending: false,
          verifyCodeRequestError: false,
          verifyCodeRequestSuccess: true,
        },
      };

    case authActions.FETCH_LOG_OUT_USER_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    // OLDS
    // send code to phone acti
    // reset request send code to phone actions
    case smsVerificationActions.RESET_SEND_CODE_TO_PHONE_REQUEST_STATE:
      return {
        ...state,
        sendCodeToPhoneRequest: {
          ...state.sendCodeToPhoneRequest,
          sendCodeToPhoneRequestPending: action.payload,
          sendCodeToPhoneRequestError: action.payload,
          sendCodeToPhoneRequestSuccess: action.payload,
        },
      };
    // reset request id actions
    case smsVerificationActions.RESET_REQUEST_ID_STATE:
      return {
        ...state,
        requestId: action.payload,
      };
    // code live set actions
    case smsVerificationActions.SET_CODE_LIVE_STATUS:
      return {
        ...state,
        codeLive: action.payload,
      };
    // show expired template action
    case smsVerificationActions.SET_SHOW_EXPIRED_TEMPLATE:
      return {
        ...state,
        showExpiredTemplate: action.payload,
      };
    // verify code  actions

    // reset request verify code actions
    case smsVerificationActions.RESET_VERIFY_CODE_REQUEST:
      return {
        ...state,
        verifyCodeRequest: {
          ...state.verifyCodeRequest,
          verifyCodeRequestSuccess: action.payload,
          verifyCodeRequestError: action.payload,
          verifyCodeRequestPending: action.payload,
        },
      };
    // set invalid message
    case smsVerificationActions.SET_INVALID_NUMBER:
      return {
        ...state,
        invalidNumber: action.payload,
      };
    default:
      return state;
  }
}
