export const formatDate = (date) => {
  const dateAction = new Date(date);
  let day = dateAction.getDate();
  let month = dateAction.getMonth() + 1;

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  const year = dateAction.getFullYear();

  return `${day}-${month}-${year}`;
};

export const formatDateNoYear = (date) => {
  const dateAction = new Date(date);
  let day = dateAction.getDate();
  let month = dateAction.getMonth() + 1;

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  const currentYear = new Date().getFullYear();
  const year = dateAction.getFullYear();

  if (currentYear !== year) {
    return `${day}.${month}.${year}`;
  }

  return `${day}.${month}`;
};

export const formatTime = (date) => {
  const dateAction = new Date(date);
  const hours = dateAction.getHours();
  let minutes = dateAction.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes}`;
};
