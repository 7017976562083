const type = "STATE_SMS";

const smsVerificationActions = {
  SEND_SMS_TO_PHONE_PENDING: `${type}_SEND_SMS_TO_PHONE_PENDING`,
  SEND_SMS_TO_PHONE_ERROR: `${type}_SEND_SMS_TO_PHONE_ERROR`,
  SEND_SMS_TO_PHONE_SUCCESS: `${type}_SEND_SMS_TO_PHONE_SUCCESS`,

  SET_CODE_LIVE_STATUS: `${type}_SET_CODE_LIVE_STATUS`,

  RESET_SEND_CODE_TO_PHONE_REQUEST_STATE: `${type}_RESET_SEND_CODE_TO_PHONE_REQUEST_STATE`,

  RESET_REQUEST_ID_STATE: `${type}_RESET_REQUEST_ID_STATE`,

  SET_SHOW_EXPIRED_TEMPLATE: `${type}_SET_SHOW_EXPIRED_TEMPLATE`,

  VERIFY_CODE_PENDING: `${type}_VERIFY_CODE_PENDING`,
  VERIFY_CODE_ERROR: `${type}_VERIFY_CODE_ERROR`,
  VERIFY_CODE_SUCCESS: `${type}_VERIFY_CODE_SUCCESS`,

  RESET_VERIFY_CODE_REQUEST: `${type}_RESET_VERIFY_CODE_REQUEST`,

  SET_INVALID_NUMBER: `${type}_SET_INVALID_NUMBER`,
};

export default smsVerificationActions;
