import contactFormActions from "../../actions/contact-form/contact-form-types";

const initialState = {
  sendContactFormDataRequestData: null,
  sendContactFormDataRequest: {
    sendContactFormDataRequestPending: false,
    sendContactFormDataRequestError: false,
    sendContactFormDataRequestSuccess: false,
  },
};

export default function contactFormReducer(state = initialState, action) {
  switch (action.type) {
    // send form data actions
    case contactFormActions.SEND_CONTACT_FORM_DATA_PENDING:
      return {
        ...state,
        sendContactFormDataRequestData: null,
        sendContactFormDataRequest: {
          sendContactFormDataRequestPending: true,
          sendContactFormDataRequestError: false,
          sendContactFormDataRequestSuccess: false,
        },
      };
    case contactFormActions.SEND_CONTACT_FORM_DATA_ERROR:
      return {
        ...state,
        sendContactFormDataRequestData: action.payload,
        sendContactFormDataRequest: {
          sendContactFormDataRequestPending: false,
          sendContactFormDataRequestError: true,
          sendContactFormDataRequestSuccess: false,
        },
      };
    case contactFormActions.SEND_CONTACT_FORM_DATA_SUCCESS:
      return {
        ...state,
        sendContactFormDataRequestData: action.payload,
        sendContactFormDataRequest: {
          sendContactFormDataRequestPending: false,
          sendContactFormDataRequestError: false,
          sendContactFormDataRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}
