const type = "STATE_LOAN";

const loanActions = {
    GET_LOAN: "GET_LOAN",
    LOAN_LOADING: "LOAN_LOADING",
    LOAN_ERROR: "LOAN_ERROR",
    NEW_LOAN_CALCULATOR: "NEW_LOAN_CALCULATOR",
    NEW_LOAN_FORM: "NEW_LOAN_FORM",
    SET_LOAN_LAYOUT_STATUS: "SET_LOAN_LAYOUT_STATUS",
    SET_LOAN_REQUEST_STATUS: "SET_LOAN_REQUEST_STATUS",
    UPDATE_LOAN_INFO: "UPDATE_LOAN_INFO",
    LOAN_RETURN_LAYOUT: "LOAN_RETURN_LAYOUT",
    LOAN_TAB_LAYOUT: "LOAN_TAB_LAYOUT",
    PREV_LOAN_TAB_LAYOUT: "PREV_LOAN_TAB_LAYOUT",
    LOAN_TAB_LAYOUT_STEP: "LOAN_TAB_LAYOUT_STEP",

    UPDATE_CURRENT_LOAN_STATE: "UPDATE_CURRENT_LOAN_STATE",

    PROLONG_TERMS_LOAN_PENDING: "PROLONG_TERMS_LOAN_PENDING",
    PROLONG_TERMS_LOAN_ERROR: "PROLONG_TERMS_LOAN_ERROR",
    PROLONG_TERMS_LOAN_SUCCESS: "PROLONG_TERMS_LOAN_SUCCESS",

    RESET_PROLONG_TERMS_LOAN_REQUEST_STATE:
        "RESET_PROLONG_TERMS_LOAN_REQUEST_STATE",

    // New Actions
    SET_CALCULATOR_RANGE: `${type}_SET_CALCULATOR_RANGE`,
    SET_LOAN_LAYOUT: `${type}_SET_LOAN_LAYOUT`,

    SET_NEW_LOAN_DATA: `${type}_SET_NEW_LOAN_DATA`,
    CLEAR_NEW_LOAN_DATA: `${type}_CLEAR_NEW_LOAN_DATA`,
    CLEAR_DATA: `${type}_CLEAR_LOAN_DATA`,

    GET_CALCULATOR_LOAN_DATA_FETCH: `${type}_GET_CALCULATOR_LOAN_DATA_FETCH`,
    GET_CALCULATOR_LOAN_DATA_SUCCESS: `${type}_GET_CALCULATOR_LOAN_DATA_SUCCESS`,
    GET_CALCULATOR_LOAN_DATA_ERROR: `${type}_GET_CALCULATOR_LOAN_DATA_ERROR`,

    GET_LOAN_DATA_FETCH: `${type}_GET_LOAN_DATA_FETCH`,
    GET_LOAN_DATA_SUCCESS: `${type}_GET_LOAN_DATA_SUCCESS`,
    GET_LOAN_DATA_ERROR: `${type}_GET_LOAN_DATA_ERROR`,

    CREATE_FIRST_LOAN_FETCH: `${type}_CREATE_FIRST_LOAN_FETCH`,
    CREATE_FIRST_LOAN_SUCCESS: `${type}_CREATE_FIRST_LOAN_SUCCESS`,
    CREATE_FIRST_LOAN_ERROR: `${type}_CREATE_FIRST_LOAN_ERROR`,

    CREATE_LOAN_REQUEST_FETCH: `${type}_CREATE_LOAN_REQUEST_FETCH`,
    CREATE_LOAN_REQUEST_SUCCESS: `${type}_CREATE_LOAN_REQUEST_SUCCESS`,
    CREATE_LOAN_REQUEST_ERROR: `${type}_CREATE_LOAN_REQUEST_ERROR`,

    FETCH_REFUSE_LOAN_PENDING: `${type}_REFUSE_LOAN_PENDING`,
    FETCH_REFUSE_LOAN_ERROR: `${type}_REFUSE_LOAN_ERROR`,
    FETCH_REFUSE_LOAN_SUCCESS: `${type}_REFUSE_LOAN_SUCCESS`,

    FETCH_JOB_TYPES_PENDING: `${type}_JOB_TYPES_PENDING`,
    FETCH_JOB_TYPES_ERROR: `${type}_JOB_TYPES_ERROR`,
    FETCH_JOB_TYPES_SUCCESS: `${type}_JOB_TYPES_SUCCESS`,

    FETCH_PURPOSES_PENDING: `${type}_PURPOSES_PENDING`,
    FETCH_PURPOSES_ERROR: `${type}_PURPOSES_ERROR`,
    FETCH_PURPOSES_SUCCESS: `${type}_PURPOSES_SUCCESS`,

    FETCH_ACCEPT_LOAN_FIRST_STEP_PENDING: `${type}_ACCEPT_LOAN_FIRST_STEP_PENDING`,
    FETCH_ACCEPT_LOAN_FIRST_STEP_ERROR: `${type}_ACCEPT_LOAN_FIRST_STEP_ERROR`,
    FETCH_ACCEPT_LOAN_FIRST_STEP_SUCCESS: `${type}_ACCEPT_LOAN_FIRST_STEP_SUCCESS`,

    FETCH_ACCEPT_LOAN_SECOND_STEP_PENDING: `${type}_ACCEPT_LOAN_SECOND_STEP_PENDING`,
    FETCH_ACCEPT_LOAN_SECOND_STEP_ERROR: `${type}_ACCEPT_LOAN_SECOND_STEP_ERROR`,
    FETCH_ACCEPT_LOAN_SECOND_STEP_SUCCESS: `${type}_ACCEPT_LOAN_SECOND_STEP_SUCCESS`,

    FETCH_RESUBMIT_LOAN_PHOTOS_PENDING: `${type}_RESUBMIT_LOAN_PHOTOS_PENDING`,
    FETCH_RESUBMIT_LOAN_PHOTOS_ERROR: `${type}_RESUBMIT_LOAN_PHOTOS_ERROR`,
    FETCH_RESUBMIT_LOAN_PHOTOS_SUCCESS: `${type}_RESUBMIT_LOAN_PHOTOS_SUCCESS`,

    FETCH_REJECT_REASONS_PENDING: `${type}_REJECT_REASONS_PENDING`,
    FETCH_REJECT_REASONS_ERROR: `${type}_REJECT_REASONS_ERROR`,
    FETCH_REJECT_REASONS_SUCCESS: `${type}_REJECT_REASONS_SUCCESS`,

    FETCH_LOAN_HISTORY_PENDING: `${type}_LOAN_HISTORY_PENDING`,
    FETCH_LOAN_HISTORY_ERROR: `${type}_LOAN_HISTORY_ERROR`,
    FETCH_LOAN_HISTORY_SUCCESS: `${type}_LOAN_HISTORY_SUCCESS`,

    FETCH_CLOSE_LOAN_PENDING: `${type}_CLOSE_LOAN_PENDING`,
    FETCH_CLOSE_LOAN_ERROR: `${type}_CLOSE_LOAN_ERROR`,
    FETCH_CLOSE_LOAN_SUCCESS: `${type}_CLOSE_LOAN_SUCCESS`,

    FETCH_LOAN_CONTRACT_PENDING: `${type}_LOAN_CONTRACT_PENDING`,
    FETCH_LOAN_CONTRACT_ERROR: `${type}_LOAN_CONTRACT_ERROR`,
    FETCH_LOAN_CONTRACT_SUCCESS: `${type}_LOAN_CONTRACT_SUCCESS`,

    FETCH_LOAN_PROMISSORY_NOTE_PENDING: `${type}_LOAN_PROMISSORY_NOTE_PENDING`,
    FETCH_LOAN_PROMISSORY_NOTE_ERROR: `${type}_LOAN_PROMISSORY_NOTE_ERROR`,
    FETCH_LOAN_PROMISSORY_NOTE_SUCCESS: `${type}_LOAN_PROMISSORY_NOTE_SUCCESS`,

    FETCH_LOAN_REQUEST_PENDING: `${type}_LOAN_REQUEST_PENDING`,
    FETCH_LOAN_REQUEST_ERROR: `${type}_LOAN_REQUEST_ERROR`,
    FETCH_LOAN_REQUEST_SUCCESS: `${type}_LOAN_REQUEST_SUCCESS`,

    SET_REFUSE_REASON: `${type}_SET_REFUSE_REASON`,
    SET_SELECTED_BANK: `${type}_SET_SELECTED_BANK`,
    RESET_REFUSE_REASON: `${type}_RESET_REFUSE_REASON`,

    FETCH_CREDIT_HISTORY_PENDING: `${type}_CREDIT_HISTORY_PENDING`,
    FETCH_CREDIT_HISTORY_ERROR: `${type}_CREDIT_HISTORY_ERROR`,
    FETCH_CREDIT_HISTORY_SUCCESS: `${type}_CREDIT_HISTORY_SUCCESS`,

    FETCH_MAIL_PHONE_PENDING: `${type}_MAIL_PHONE_PENDING`,
    FETCH_MAIL_PHONE_ERROR: `${type}_MAIL_PHONE_ERROR`,
    FETCH_MAIL_PHONE_SUCCESS: `${type}_MAIL_PHONE_SUCCESS`,
};

export default loanActions;
