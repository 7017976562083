const loanStatuses = {
  NEW_LOAN_CALCULATOR: 1,
  UNDER_CONSIDERATION: 2,
  BAD_PHOTO: 3,
  ACCEPTED: 4,
  ACTIVE: 5,
  ACTIVE_SENT: 8,
  OVERDUE: 6,
  PAID: 7,
  SUSPENDED: "suspended",
  BLOCKED: "blocked",

  // OLDs
  // ACCEPTED: 100,
  // ACTIVE: 6,
  // UNDER_CONSIDERATION: 1,
  DENIED: 101,
  // BAD_PHOTO: 8,
  // CANCELED: 4,
  // OVERDUE: 102,
  // PAID: 5,
  NEW_LOAN_FORM: 11,
  LOAN_REQUEST: 12,

  LOAN_RETURN: 13,
  LOAN_GET_MONEY: 14,
};

export default loanStatuses;
