import axios from "axios";

import transferencesActions from "./transferences-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const transferencesCounter = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_COUNTERS_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/client-transfers`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_COUNTERS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_TRANSFERENCES_COUNTERS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const clientTransferences = ({ bankId, query }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_USER_TRANSFERENCES_PENDING,
      payload: {
        bankId,
        [bankId]: null,
      },
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/client-transfers-historic/${bankId}${query}`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_USER_TRANSFERENCES_SUCCESS,
      payload: {
        bankId,
        [bankId]: {
          ...data,
        },
      },
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_USER_TRANSFERENCES_ERROR,
      payload: {
        bankId,
        [bankId]: {
          ...errorMapper(err, dispatch),
        },
      },
    });
  }
};

export const generateFile = (bankId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_GENERATE_FILE_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/generate-transfer-file/${bankId}`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_GENERATE_FILE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_GENERATE_FILE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const validateFile = (fileId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_VALIDATE_FILE_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/validate-transfer-bank-file/step-one/${fileId}`,
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_VALIDATE_FILE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_VALIDATE_FILE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const fetchValidateTransferences = ({ bankFileId, transfers }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_PENDING,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/validate-transfer-bank-file/step-two`,
      { bankFileId, transfers },
      tokenConfig(getState)
    );

    dispatch({
      type: transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: transferencesActions.FETCH_VALIDATE_SELECTED_TRANSFERENCES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
