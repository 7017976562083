import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../../assets/close-icon.svg";

import { useSelector } from "react-redux";

import { formatPrice } from "../../../../../../../utils/currency";

const ContractItem = ({
  closeModal,
  loanerInfo,
  onPressAccept,
  openContractItem,
}: *) => {
  const { t } = useTranslation();
  const [limitValue, setLimitValue] = React.useState(null);
  const [reasonDescription, setReasonDescription] = React.useState("");

  const { creditLimits } = useSelector((state) => ({
    creditLimits: state.generals.creditLimits,
  }));

  const filteredLimits = React.useMemo(() => {
    if (!creditLimits.length) {
      return [];
    }

    return creditLimits.filter((item) => item !== loanerInfo?.creditLimit);
  }, [loanerInfo, creditLimits]);

  const onLimitChange = React.useCallback((e) => {
    setLimitValue(e.target.value);
  }, []);

  const onChangeDescription = React.useCallback((e) => {
    setReasonDescription(e.target.value);
  }, []);

  const onAcceptButton = React.useCallback(() => {
    onPressAccept({
      description: reasonDescription,
      amount: limitValue,
    });
  }, [reasonDescription, limitValue, onPressAccept]);

  return (
    <Dialog
      onClose={closeModal}
      open={openContractItem}
      className="modal-modify-limit-wrapper"
    >
      <DialogContent className="modify-limit-content">
        <div className="modify-limit-header-wrapper">
          <div className="user-name-wrapper">
            <div className="user-name">{loanerInfo?.client}</div>
          </div>
          <div className="user-name-wrapper">
            <div className="user-name">{t("Modify.credit.limit.title")}</div>
          </div>
          <div className="close-img-wrapper" onClick={closeModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="change-limit-title">{`${t(
            "Current.loan.credit.label"
          )} ${formatPrice(loanerInfo?.creditLimit)}`}</div>
          <div className="change-limit-subtitle">{`${t(
            "Select.new.loan.credit.limit.label"
          )}:`}</div>
          <div></div>
          <div className="radio-buttons-container">
            <div className="radio-group-wrapper">
              {filteredLimits.map((item) => (
                <div key={item} className="radio-group-item-wrapper">
                  <input
                    className="input-item"
                    type="radio"
                    onChange={onLimitChange}
                    name="limitValue"
                    value={item}
                  />
                  <label className="label-item" htmlFor={formatPrice(item)}>
                    {formatPrice(item)}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="textarea-wrapper">
            <textarea
              value={reasonDescription}
              onChange={onChangeDescription}
            />
          </div>
        </div>
        <div className="buttons-container">
          <div className="dismiss-bths-wrapper">
            <div className="bth-wrapper make-payment-bth-wrapper">
              <button className="bth dismiss-request-bth" onClick={closeModal}>
                {t("confirmation.modal.cancel")}
              </button>
            </div>
          </div>
          <div className="payment-bths-wrapper">
            <div className="bth-wrapper make-payment-bth-wrapper">
              <button
                className="bth make-payment-bth"
                disabled={reasonDescription === "" || !limitValue}
                onClick={onAcceptButton}
              >
                {t("confirmation.modal.accept")}
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContractItem;
