import React from "react";
import * as Yup from "yup";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import MuiPhoneInput from "material-ui-phone-number";
import { Dialog, DialogContent } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import ErrorMessage from "../../../../../../common/error-message/ErrorMessage";
import dolarIcon from "../../../../../../../assets/dolar-icon.svg";
import { typesWithCompanyInfo } from "../../../../../../../constants/jobTypes";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { getDeparments } from "../../../../../../../actions/generals/generals";
import { getJobTypes } from "../../../../../../../actions/loan/loan";

import closeIcon from "../../../../../../../assets/close-icon.svg";

import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";

const regexSymbol = /^[a-zA-Z\s-zñáéíóúü]*$/;

const dniRegex = /[0-9]{8,10}-{1}[0-9]{1}$/;

function validateCompany(value) {
  let error;
  if (!value) {
    error = "Required";
  }
  return error;
}

const ContractItem = ({
  closeModal,
  loanerInfo,
  onPressAccept,
  openContractItem,
}: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [jobType, setJobtype] = React.useState(loanerInfo?.jobType);

  React.useEffect(() => {
    dispatch(getDeparments());
    dispatch(getJobTypes());
  }, [dispatch]);

  const { departments, jobTypes } = useSelector((state) => ({
    jobTypes: state.loan.jobTypes,
    departments: state.generals.departments,
  }));

  const needsCompanyInfo = React.useMemo(
    () => typesWithCompanyInfo.includes(jobType?.toLowerCase()),
    [jobType]
  );

  const UserInfoSchema = Yup.object().shape({
    typeOfEmployment: Yup.string().required("Required.label"),
    netIncome: Yup.string().required("Required.label"),
    additionalIncome: Yup.string().required("Required.label"),
    otherLoans: Yup.string().required("Required.label"),
    street: Yup.string().trim().required("Required.label"),
    city: Yup.string().trim().required("Required.label"),
    exteriorNumber: Yup.string().trim().required("Required.label"),
    colonia: Yup.string().trim().required("Required.label"),
    department: Yup.string().trim().required("Required.label"),
    firstName: Yup.string()
      .trim()
      .matches(regexSymbol, "First.name.must.contain.only.letters.label")
      .required("Required.label"),
    lastName: Yup.string()
      .trim()
      .matches(regexSymbol, "Second.name.must.contain.only.letters.label")
      .required("Required.label"),
    gender: Yup.string().required("Required.label"),
    birthDate: Yup.string()
      .nullable()
      .test("Date of Birth", "Input.date.in.format.MM/dd/yyyy", (value) => {
        return moment(value).isValid();
      })
      .test(
        "Date of Birth",
        "Date.should.not.be.before.minimal.date",
        (value) => {
          return !moment(value).isBefore("1900-01-01");
        }
      )
      .test(
        "Date of Birth",
        "You.must.be.at.least.18.years.old.label",
        (value) => {
          return moment().diff(moment(value), "years") >= 18;
        }
      )
      .required("Required.label"),

    dniNumber: Yup.string()
      .matches(dniRegex, "DNI.validation")
      .required("Required.label"),
    phoneNumber: Yup.string().required("Required.label"),
  });

  const initialValues = React.useMemo(() => {
    const values = {
      typeOfEmployment: loanerInfo?.jobType,
      netIncome: loanerInfo?.netIncome,
      // companyName: !needsCompanyInfo ? null : loanerInfo?.companyName,
      additionalIncome: loanerInfo?.additionalIncome,
      companyPhone: loanerInfo?.companyPhone,
      otherLoans: loanerInfo?.otherLoan,
      street: loanerInfo?.street,
      city: loanerInfo?.municipality,
      exteriorNumber: loanerInfo?.streetNumber,
      colonia: loanerInfo?.suburb,
      department: loanerInfo?.department,
      firstName: loanerInfo?.name,
      lastName: loanerInfo?.surname,
      gender: loanerInfo?.gender,
      birthDate: loanerInfo?.birthDate,
      dniNumber: loanerInfo?.dni,
      phoneNumber: loanerInfo?.phoneNumber,
    };

    if (needsCompanyInfo) {
      values.companyName = loanerInfo?.companyName;
    }

    return values;
  }, [loanerInfo, needsCompanyInfo]);

  const onAcceptButton = React.useCallback(
    (values) => {
      onPressAccept({
        ...values,
      });
    },
    [onPressAccept]
  );

  return (
    <Dialog
      onClose={closeModal}
      open={openContractItem}
      className="modal-edit-user-wrapper"
    >
      <DialogContent className="edit-user-content">
        <div className="modify-limit-header-wrapper">
          <div className="user-name-wrapper">
            <div className="user-name">{`${loanerInfo?.name} ${loanerInfo?.surname}`}</div>
            <div className="user-name">{loanerInfo?.email}</div>
          </div>
          <div className="user-name-wrapper">
            <div className="user-name">{t("Modify.user.data.title")}</div>
          </div>
          <div className="close-img-wrapper" onClick={closeModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="form-wrapper">
            <Formik
              initialValues={initialValues || {}}
              validationSchema={UserInfoSchema}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({
                errors,
                touched,
                values,
                isValid,
                setFieldValue,
                setFieldTouched,
              }) => (
                <div>
                  <Form className="form-edit-user">
                    <div className="left-section">
                      <div className="form-section">
                        <div className="form-section-title">
                          {t("Personal.Info.label")}
                        </div>
                        {/* First Name */}
                        <fieldset className="form-item first-name-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="firstName">
                              {t("First.Name.label")}{" "}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="input-item"
                              onChange={(event) =>
                                setFieldValue("firstName", event.target.value)
                              }
                            />
                          </div>
                          {errors.firstName && touched.firstName ? (
                            <ErrorMessage text={t(errors.firstName)} />
                          ) : null}
                        </fieldset>
                        {/* Last Name */}
                        <fieldset className="form-item last-name-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="lastName">
                              {t("Second.Name.label")}{" "}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="input-item"
                              onChange={(event) =>
                                setFieldValue("lastName", event.target.value)
                              }
                            />
                          </div>
                          {errors.lastName && touched.lastName ? (
                            <ErrorMessage text={t(errors.lastName)} />
                          ) : null}
                        </fieldset>
                        {/* Gender */}
                        <fieldset className="form-item">
                          <div className="flex-container">
                            <div className="radio-title">
                              {t("Gender.label")}
                              <span className="start">&#42;</span>
                            </div>
                            <div className="radio-group-wrapper">
                              <div className="radio-group-item-wrapper female-radio-item">
                                <Field
                                  className="input-item"
                                  type="radio"
                                  id="female"
                                  name="gender"
                                  value="f"
                                />
                                <label className="label-item" htmlFor="female">
                                  {t("Female.label")}
                                </label>
                              </div>
                              <div className="radio-group-item-wrapper female-radio-item">
                                <Field
                                  className="input-item"
                                  type="radio"
                                  id="male"
                                  name="gender"
                                  value="m"
                                />
                                <label className="label-item" htmlFor="male">
                                  {t("Male.label")}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* {errors.gender ? (
                <ErrorMessage text={t(errors.gender)} />
              ) : null} */}
                        </fieldset>
                        {/* Birthday */}
                        <fieldset className="form-item birthDate-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="birthDate">
                              {t("Birthday.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                className="input-item"
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                placeholder="20/12/1993"
                                value={values.birthDate}
                                onChange={(value) =>
                                  setFieldValue("birthDate", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                                allowKeyboardControl={true}
                                invalidDateMessage=""
                                minDateMessage=""
                                maxDateMessage=""
                                onBlur={() => {
                                  setFieldTouched("birthDate", true);
                                }}
                                onAccept={(value) => {
                                  setFieldTouched("birthDate", true);
                                  setFieldValue("birthDate", value);
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          {errors.birthDate && touched.birthDate ? (
                            <ErrorMessage text={t(errors.birthDate)} />
                          ) : null}
                        </fieldset>
                        {/* DNI Number */}
                        <fieldset className="form-item dniNumber-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="dniNumber">
                              {t("DUI.Number.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              className="input-item"
                              type="text"
                              name="dniNumber"
                              placeholder="12345678-9"
                              id="dniNumber"
                              onChange={(event) =>
                                setFieldValue("dniNumber", event.target.value)
                              }
                            />
                          </div>
                          {errors.dniNumber && touched.dniNumber ? (
                            <ErrorMessage text={t(errors.dniNumber)} />
                          ) : null}
                        </fieldset>
                      </div>

                      <div className="form-section">
                        <div className="form-section-title">
                          {t("Address.label")}
                        </div>
                        {/* Street */}
                        <fieldset className="form-item street-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="street">
                              {t("Street.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              className="input-item"
                              type="text"
                              name="street"
                              id="street"
                            />
                          </div>
                          {errors.street && touched.street ? (
                            <ErrorMessage text={t(errors.street)} />
                          ) : null}
                        </fieldset>
                        {/* City */}
                        <fieldset className="form-item city-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="city">
                              {t("City.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              className="input-item"
                              type="text"
                              name="city"
                              id="city"
                            />
                          </div>
                          {errors.city && touched.city ? (
                            <ErrorMessage text={t(errors.city)} />
                          ) : null}
                        </fieldset>
                        {/* Exterior Number */}
                        <fieldset className="form-item exteriorNumber-item">
                          <div className="flex-container">
                            <label
                              className="label-item"
                              htmlFor="exteriorNumber"
                            >
                              {t("Exterior.number.label")}{" "}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              className="input-item"
                              type="text"
                              name="exteriorNumber"
                              id="exteriorNumber"
                            />
                          </div>
                          {errors.exteriorNumber && touched.exteriorNumber ? (
                            <ErrorMessage text={t(errors.exteriorNumber)} />
                          ) : null}
                        </fieldset>
                        {/* Colonia */}
                        <fieldset className="form-item colonia-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="colonia">
                              {t("Colonia.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <Field
                              className="input-item"
                              type="text"
                              name="colonia"
                              id="colonia"
                            />
                          </div>
                          {errors.colonia && touched.colonia ? (
                            <ErrorMessage text={t(errors.colonia)} />
                          ) : null}
                        </fieldset>
                        {/* Province */}
                        <fieldset className="form-item province-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="province">
                              {t("Province.label")}{" "}
                              <span className="start">&#42;</span>
                            </label>
                            <FormControl variant="filled">
                              <Select
                                name="department"
                                id="department"
                                value={values.department}
                                onChange={(value) => {
                                  setFieldValue(
                                    "department",
                                    value.target.value
                                  );
                                }}
                                onClose={() =>
                                  setFieldTouched("departmen", true)
                                }
                              >
                                <MenuItem value="">
                                  <em>{t("Choose.Type.label")}</em>
                                </MenuItem>
                                {departments.map((item) => (
                                  <MenuItem value={item} key={item}>
                                    {t(item)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          {errors.province && touched.province ? (
                            <ErrorMessage text={t(errors.province)} />
                          ) : null}
                        </fieldset>
                      </div>
                    </div>
                    <div className="rigth-section">
                      <div className="form-section">
                        <div className="form-section-title">
                          {t("Contact.Info.label")}
                        </div>

                        <fieldset className="form-item company-phone-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="phoneNumber">
                              {t("Phone.label")}
                            </label>
                            <MuiPhoneInput
                              className="input-item"
                              id="phoneNumber"
                              name="phoneNumber"
                              type="tel"
                              value={values.phoneNumber}
                              onChange={(value) =>
                                setFieldValue("phoneNumber", value)
                              }
                              defaultCountry="sv"
                              // onlyCountries={['sv']}
                              countryCodeEditable={false}
                              onBlur={() =>
                                setFieldTouched("phoneNumber", true)
                              }
                            />
                          </div>
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <ErrorMessage text={t(errors.phoneNumber)} />
                          ) : null}
                        </fieldset>
                      </div>
                      <div className="form-section">
                        <div className="form-section-title">
                          {t("Additional.Info.label")}
                        </div>
                        {/* Type Of Employment */}
                        <fieldset className="form-item type-employment-item">
                          <div className="flex-container">
                            <label
                              className="label-item"
                              htmlFor="typeOfEmployment"
                            >
                              {t("Type.of.Employment.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <FormControl variant="filled">
                              <Select
                                name="typeOfEmployment"
                                id="typeOfEmployment"
                                value={values.typeOfEmployment}
                                onChange={(value) => {
                                  setFieldValue(
                                    "typeOfEmployment",
                                    value.target.value
                                  );
                                  setJobtype(value.target.value);
                                }}
                                onClose={() =>
                                  setFieldTouched("typeOfEmployment", true)
                                }
                              >
                                <MenuItem value="">
                                  <em>{t("Choose.Type.label")}</em>
                                </MenuItem>
                                {jobTypes.map((item) => (
                                  <MenuItem value={item} key={item}>
                                    {t(item)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          {errors.typeOfEmployment &&
                          touched.typeOfEmployment ? (
                            <ErrorMessage text={t(errors.typeOfEmployment)} />
                          ) : null}
                        </fieldset>
                        {/* Net Income */}
                        <fieldset className="form-item netIncome-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="netIncome">
                              {t("Net.Income.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <div className="input-item-wrapper">
                              <Field
                                className="input-item"
                                type="number"
                                name="netIncome"
                                id="netIncome"
                                onKeyDown={(e) =>
                                  (e.keyCode === 69 ||
                                    e.keyCode === 190 ||
                                    e.keyCode === 189) &&
                                  e.preventDefault()
                                }
                              />
                              <img
                                className="dolar-icon"
                                src={dolarIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.netIncome && touched.netIncome ? (
                            <ErrorMessage text={t(errors.netIncome)} />
                          ) : null}
                        </fieldset>
                        {/* Company Name or  Institution Name */}
                        {needsCompanyInfo && (
                          <fieldset className="form-item company-name-item">
                            <div className="flex-container">
                              <label
                                className="label-item"
                                htmlFor="companyName"
                              >
                                {t("Company.Name.label")}
                                <span className="start">&#42;</span>
                              </label>
                              <Field
                                className="input-item"
                                type="text"
                                name="companyName"
                                id="companyName"
                                validate={validateCompany}
                              />
                            </div>
                            {errors.companyName && touched.companyName ? (
                              <ErrorMessage text={t(errors.companyName)} />
                            ) : null}
                          </fieldset>
                        )}
                        {/* Additional Income */}
                        <fieldset className="form-item additional-income-item">
                          <div className="flex-container">
                            <label
                              className="label-item"
                              htmlFor="additionalIncome"
                            >
                              {t("Additional.Income.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <div className="input-item-wrapper">
                              <Field
                                className="input-item"
                                type="number"
                                name="additionalIncome"
                                id="additionalIncome"
                                onKeyDown={(e) =>
                                  (e.keyCode === 69 ||
                                    e.keyCode === 190 ||
                                    e.keyCode === 189) &&
                                  e.preventDefault()
                                }
                              />
                              <img
                                className="dolar-icon"
                                src={dolarIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.additionalIncome &&
                          touched.additionalIncome ? (
                            <ErrorMessage text={t(errors.additionalIncome)} />
                          ) : null}
                        </fieldset>
                        {/* Company Phone */}
                        {needsCompanyInfo && (
                          <fieldset className="form-item company-phone-item">
                            <div className="flex-container">
                              <label
                                className="label-item"
                                htmlFor="companyPhone"
                              >
                                {t("Company.Phone.label")}
                              </label>
                              <MuiPhoneInput
                                className="input-item"
                                id="companyPhone"
                                name="companyPhone"
                                type="tel"
                                value={values.companyPhone}
                                onChange={(value) =>
                                  setFieldValue("companyPhone", value)
                                }
                                defaultCountry="sv"
                                // onlyCountries={['sv']}
                                countryCodeEditable={false}
                                onBlur={() =>
                                  setFieldTouched("companyPhone", true)
                                }
                              />
                            </div>
                            {errors.companyPhone && touched.companyPhone ? (
                              <ErrorMessage text={t(errors.companyPhone)} />
                            ) : null}
                          </fieldset>
                        )}

                        {/* Other loans */}
                        <fieldset className="form-item other-loans-item">
                          <div className="flex-container">
                            <label className="label-item" htmlFor="otherLoans">
                              {t("Other.loans.credits.label")}
                              <span className="start">&#42;</span>
                            </label>
                            <div className="input-item-wrapper">
                              <Field
                                className="input-item"
                                type="number"
                                name="otherLoans"
                                id="otherLoans"
                                onKeyDown={(e) =>
                                  (e.keyCode === 69 ||
                                    e.keyCode === 190 ||
                                    e.keyCode === 189) &&
                                  e.preventDefault()
                                }
                              />
                              <img
                                className="dolar-icon"
                                src={dolarIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.otherLoans && touched.otherLoans ? (
                            <ErrorMessage text={t(errors.otherLoans)} />
                          ) : null}
                        </fieldset>
                      </div>
                    </div>
                  </Form>

                  <div className="buttons-container">
                    <div className="dismiss-bths-wrapper">
                      <div className="bth-wrapper make-payment-bth-wrapper">
                        <button
                          className="bth dismiss-request-bth"
                          onClick={closeModal}
                        >
                          {t("confirmation.modal.cancel")}
                        </button>
                      </div>
                    </div>
                    <div className="payment-bths-wrapper">
                      <div className="bth-wrapper make-payment-bth-wrapper">
                        <button
                          className="bth make-payment-bth"
                          disabled={!isValid}
                          onClick={() => onAcceptButton(values)}
                        >
                          {t("confirmation.modal.accept")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContractItem;
