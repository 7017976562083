import React from 'react';
import BalanceContent from './balance-content/BalanceContent';
import BalanceHeader from './balance-header/BalanceHeader';

import './styles.scss';

function BalanceLayout() {
  return (
    <div className="balance-layout-wrapper">
      <BalanceHeader />
      <BalanceContent />
    </div>
  );
}

export default BalanceLayout;
