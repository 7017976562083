import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import cancelIcon from "../../../../../assets/delete-cancel-svgrepo-com.svg";
import checkIcon from "../../../../../assets/accept-check-good-mark-ok-tick-svgrepo-com.svg";
import removeIcon from "../../../../../assets/trash-svgrepo-com.svg";
import calendarIcon from "../../../../../assets/mdi_calendar_today.svg";

import {
  getTransactionFileName,
  getDateByFormat,
} from "../../../../../utils/parser";

import "./styles.scss";

import {
  getPaymentOperators,
  getPaymentFiles,
  processPaymentFile,
} from "../../../../../actions/payments/payments";

import { formatPrice } from "../../../../../utils/currency";

import downloadIcon from "../../../../../assets/download-svgrepo-com.svg";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../common/spinner/Spinner";

import ProcessModal from "../../../../common/process-modal/ProcessModal";
import ConfirmationModal from "../../../../common/confirmation-modal/ConfirmationModal";
import { useFilters } from "../../../../../hooks/useFilters";

const defaultFilters = {
  "page[size]": 10,
  "page[number]": 1,
};

const PaymentsContent = () => {
  const dispatch = useDispatch();
  const [openProcessModal, setOpenProcessModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [filterDate, setFilterDate] = React.useState(null);
  const [currentOperator, setCurrentOperator] = React.useState(null);
  const [paymentFile, setPaymentFile] = React.useState(null);
  const inputFile = React.useRef(null);

  const [activeFilters, setActiveFilters] = React.useState({
    "page[size]": 10,
    "page[number]": 1,
  });

  const {
    paymentFiles,
    paymentFilesPagination,
    paymentFilesRequestPending,
    paymentFilesRequestSuccess,
    paymentOperators,
    uploadPaymentFile,
    uploadPaymentFileRequestPending,
    uploadPaymentFileRequestError,
    uploadPaymentFileRequestSuccess,
  } = useSelector((state) => ({
    counterTranferencesData: state.transferences.counterTranferencesData,
    userTranferencesData: state.transferences.userTranferencesData,
    userTranferencesPagination: state.transferences.userTranferencesPagination,

    paymentOperators: state.adminPayments.paymentOperators,
    paymentOperatorsRequestPending:
      state.adminPayments.paymentOperatorsRequest
        .paymentOperatorsRequestPending,
    paymentOperatorsRequestError:
      state.adminPayments.paymentOperatorsRequest.paymentOperatorsRequestError,
    paymentOperatorsRequestSuccess:
      state.adminPayments.paymentOperatorsRequest
        .paymentOperatorsRequestSuccess,

    paymentFiles: state.adminPayments.paymentFiles,
    paymentFilesPagination: state.adminPayments.paymentFilesPagination,
    paymentFilesRequestPending:
      state.adminPayments.paymentFilesRequest.paymentFilesRequestPending,
    paymentFilesRequestError:
      state.adminPayments.paymentFilesRequest.paymentFilesRequestError,
    paymentFilesRequestSuccess:
      state.adminPayments.paymentFilesRequest.paymentFilesRequestSuccess,

    uploadPaymentFile: state.adminPayments.uploadPaymentFile,
    uploadPaymentFileRequestPending:
      state.adminPayments.uploadPaymentFileRequest
        .uploadPaymentFileRequestPending,
    uploadPaymentFileRequestError:
      state.adminPayments.uploadPaymentFileRequest
        .uploadPaymentFileRequestError,
    uploadPaymentFileRequestSuccess:
      state.adminPayments.uploadPaymentFileRequest
        .uploadPaymentFileRequestSuccess,
  }));

  React.useEffect(() => {
    dispatch(getPaymentOperators());
  }, [dispatch]);

  const { t } = useTranslation();

  useFilters({
    activeFilters,
    action: getPaymentFiles,
  });

  const onChangeFilter = React.useCallback(
    ({ filter, value }) => {
      let resetFilters = {};

      if (filter !== "page[number]") {
        resetFilters = defaultFilters;
      }

      setActiveFilters({
        ...activeFilters,
        ...resetFilters,
        [filter]: value,
      });
    },
    [activeFilters]
  );

  const onClearInputFile = React.useCallback(() => {
    inputFile.current.value = "";
    setPaymentFile(null);
  }, []);

  const onCloseProcessModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (uploadPaymentFileRequestSuccess) {
      setActiveFilters({
        ...defaultFilters,
      });

      onClearInputFile();
      setCurrentOperator(null);
    }
  }, [uploadPaymentFileRequestSuccess, onClearInputFile]);

  const onPaginationChanged = React.useCallback(
    ({ value }) => {
      onChangeFilter({
        filter: "page[number]",
        value,
      });
    },
    [onChangeFilter]
  );

  const onFilterDateChanged = React.useCallback(
    ({ value }) => {
      const date = value
        ? getDateByFormat({
            date: value,
            format: "YYYY-MM-DD",
          })
        : "";

      setFilterDate(value);

      onChangeFilter({
        filter: "filter[date]",
        value: date,
      });
    },
    [onChangeFilter]
  );

  const onCloseModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
  }, []);

  const onOperatorSelected = React.useCallback((item) => {
    setCurrentOperator(item);
  }, []);

  const onAcceptProcessFile = React.useCallback(() => {
    setOpenProcessModal(true);
    setOpenConfirmationModal(false);

    dispatch(
      processPaymentFile({
        operator: currentOperator.id,
        file: paymentFile,
      })
    );
  }, [dispatch, paymentFile, currentOperator?.id]);

  const onProcessFile = React.useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const onFileClicked = React.useCallback((file) => {
    const fileName = file.substring(file.lastIndexOf("/") + 1);

    axios({
      url: file, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }, []);

  // eslint-disable-next-line react/display-name
  const DateInput = React.forwardRef(({ value, onClick, onChange }: *, ref) => (
    <div className="input-date-container">
      <input
        ref={ref}
        placeholder={t("Filter.by.date.label")}
        className="input-item"
        readOnly
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
      />
      {!value && (
        <img className="input-calendar" src={calendarIcon} onClick={onClick} />
      )}
    </div>
  ));

  const renderBankInfo = React.useCallback(() => {
    const filesList = paymentFiles;
    const pagination = paymentFilesPagination;

    return (
      <div className="bank-info-wrapper">
        <div className="filter-container">
          <DatePicker
            selectsRange={false}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            locale="es"
            selected={filterDate}
            onChange={(value) => onFilterDateChanged({ value })}
            startDate={filterDate}
            customInput={<DateInput />}
            isClearable={true}
          />
        </div>
        <TableContainer
          className={`table-container ${
            !filesList?.length ? "empty-table" : ""
          }`}
        >
          <Table aria-label="customized table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-head-cell">
                  {t("File.name.in.bank")}
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Operator.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  {t("Loaded.date.label")}
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Operations.quantity.label")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  <span>{t("Total.amount")} </span>
                </TableCell>
                <TableCell className="table-head-cell">
                  {t("Download.label")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {paymentFilesRequestPending && (
                <TableRow className="table-body-row" rowSpan="6">
                  <TableCell className="table-body-cell" colSpan="6">
                    <Spinner text={`${t("Loading.data.label")}...`} />
                  </TableCell>
                </TableRow>
              )}

              {paymentFilesRequestSuccess && !filesList?.length && (
                <TableRow className="table-body-row" rowSpan="6">
                  <TableCell className="table-body-cell" colSpan="6">
                    <div className="empty-data">
                      {t("No.Data.To.Dispaly.label")}
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {paymentFilesRequestSuccess && filesList?.length > 0 && (
                <>
                  {filesList.map((item) => (
                    <TableRow className="table-body-row" key={item.id}>
                      <TableCell className="table-body-cell">
                        {getTransactionFileName(item.name)}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.operator}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {getDateByFormat({ date: item.date })}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.quantity}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {formatPrice(item.amount)}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        <div className="transaction-status-icon">
                          <img
                            role="button"
                            src={downloadIcon}
                            onClick={() => onFileClicked(item.name)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="pagination-wrapper">
          <Pagination
            count={pagination?.last_page}
            defaultPage={1}
            variant="outlined"
            color="secondary"
            onChange={(e, value) => onPaginationChanged({ value })}
          />
        </div>
      </div>
    );
  }, [
    t,
    onFileClicked,
    filterDate,
    paymentFilesRequestSuccess,
    paymentFilesRequestPending,
    paymentFiles,
    onFilterDateChanged,
    onPaginationChanged,
    paymentFilesPagination,
  ]);

  return (
    <div className="balance-content-wrapper">
      <div className="payment-operators-container">
        <div className="operators-list-container">
          <div className="title-section">{t("Select.payment.operator")}</div>
          <div className="operators-list">
            {paymentOperators.map((item) => (
              <div
                className={`operator-item ${
                  currentOperator?.id === item.id && "active"
                }`}
                key={item.id}
                onClick={() => onOperatorSelected(item)}
              >
                <span>{item.name}</span>
                <img src={item.logo} alt={item.bame} />
              </div>
            ))}
          </div>
        </div>

        <div className="selection-file-container">
          <div className="title-section">{t("Select.payment.file")}</div>
          <div className="file-container">
            <div className="file-item">
              <label htmlFor="files">
                {paymentFile?.name && (
                  <>
                    <img src={checkIcon} />
                    <span className="text">{t("Selected.file")}</span>

                    <span className="file-name">
                      {getTransactionFileName(paymentFile?.name)}
                    </span>
                  </>
                )}

                {!paymentFile && (
                  <>
                    <img src={cancelIcon} />
                    <span className="text">{t("No.file.selected")}</span>
                    <span className="text">{t("Select.file")}</span>
                  </>
                )}
              </label>
              <input
                onChange={(e) => {
                  const file = e.target.files[0];
                  const pattern = /.+(\.csv)$/;

                  if (!file?.name?.match(pattern)) {
                    setPaymentFile(null);
                    return;
                  }

                  setPaymentFile(e.target.files[0]);
                }}
                ref={inputFile}
                type="file"
                hidden
                id="files"
                name="files"
              />

              {!paymentFile && (
                <div className="warning-message">
                  <span>{t("Important.label")}:</span>
                  <span>{t("Warning.message")}</span>
                </div>
              )}

              {paymentFile?.name && (
                <>
                  <button
                    type="button"
                    className="remove-file-button"
                    onClick={onClearInputFile}
                  >
                    <img src={removeIcon} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="action-button-container">
          <div className="title-section">{t("Process.payment.file")}</div>
          <div className="button-container">
            <div className="payment-options-wrapper">
              <div className="payment-bths-wrapper">
                <div className="bth-wrapper make-payment-bth-wrapper">
                  <button
                    className="bth make-payment-bth"
                    disabled={!paymentFile || !currentOperator}
                    onClick={onProcessFile}
                  >
                    {t("Process.payment.label")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>{renderBankInfo()}</div>

      <ProcessModal
        openModalProcess={openProcessModal}
        closeModalProcess={onCloseProcessModal}
        requestPending={uploadPaymentFileRequestPending}
        requestError={uploadPaymentFileRequestError}
        requestSuccess={uploadPaymentFileRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetLoadCsvDataStore(false));
        }}
        pendingText={`${t("Saving.Data.label")}`}
        errorText={uploadPaymentFile?.message}
        successText={uploadPaymentFile?.message}
      />

      <ConfirmationModal
        openModal={openConfirmationModal}
        onCloseModal={onCloseModal}
        additionalText={t("Process.payment.file.message.2")}
        confirmationText={`${t("Process.payment.file.message.1")} ${
          currentOperator?.name
        }: ${paymentFile?.name}`}
        onPressAccept={onAcceptProcessFile}
      />
    </div>
  );
};

export default PaymentsContent;
