import axios from "axios";
import paymentActions from "./payment-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getPaymentProcessors = () => async (dispatch, getState) => {
  try {
    dispatch({ type: paymentActions.FETCH_PAYMENT_PROCESSORS_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/payment-operators`,
      token
    );

    const list = data?.data?.filter((item) => item.active);

    dispatch({
      type: paymentActions.FETCH_PAYMENT_PROCESSORS_SUCCESS,
      payload: list,
    });
  } catch (err) {
    dispatch({
      type: paymentActions.FETCH_PAYMENT_PROCESSORS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const processPayment = (payment) => async (dispatch, getState) => {
  try {
    dispatch({ type: paymentActions.FETCH_PROCESS_PAYMENT_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/payment`,
      payment,
      token
    );

    dispatch({
      type: paymentActions.FETCH_PROCESS_PAYMENT_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: paymentActions.FETCH_PROCESS_PAYMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
