import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import closeIcon from "../../../assets/close-icon.svg";

const AlertModal = ({
  openModal,
  content,
  onCloseModal,
  title,
  onPressAccept,
}: {
  content: *,
  onCloseModal: () => {},
  openModal: Boolean,
  onPressAccept: () => {},
  title: String,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className="alert-modal-wrapper"
    >
      <DialogContent className="alert-content">
        <div className="close-icon-wrapper" onClick={onCloseModal}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="info-text">{title}</div>

        <div className="modal-text-content">{content}</div>
        <div className="buttons-container">
          <div className="payment-options-wrapper">
            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  onClick={onPressAccept}
                >
                  {t("confirmation.modal.accept")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertModal;
