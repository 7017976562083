import axios from "axios";
import analyticsActions from "./analytics-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getAnalytics = ({ query = "" }) => async (dispatch, getState) => {
  try {
    dispatch({
      type: analyticsActions.FETCH_ANALYTICS_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/control-panel${query}`,
      tokenConfig(getState)
    );

    dispatch({
      type: analyticsActions.FETCH_ANALYTICS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: analyticsActions.FETCH_ANALYTICS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
