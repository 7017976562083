import React from "react";
import "./styles.scss";
import pdfIcon from "./../../../../../assets/pdf-icon.svg";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

const DownloadLoanPdfs = () => {
    const {
        loanContractPDF,
        loanRequestPDF,
        promissoryNotePDF,
        creditHistoryPDF,
        mailPhonePDF,
    } = useSelector((state) => ({
        loanContractPDF: state?.loan?.loanContractPDF?.url,
        loanRequestPDF: state?.loan?.loanRequestPDF?.url,
        promissoryNotePDF: state?.loan?.promissoryNotePDF,
        creditHistoryPDF: state?.loan?.creditHistoryPDF?.url,
        mailPhonePDF: state?.loan?.mailPhonePDF?.url,
    }));

    const { t } = useTranslation();
    return (
        <div className="download-pdf-bths-wrapper">
            <div className="download-pdf-content">
                <div
                    className={`download-pdf-bth-wrapper download-loan-contract ${
                        !loanContractPDF ? "disabled" : "active"
                    }`}
                    onClick={() => {
                        document
                            .getElementById("dowload-loan-contract-link")
                            .click();
                    }}
                >
                    <img src={pdfIcon} alt="" />
                    <span className="text">
                        {t("Download.Loan.Contract.label")}
                    </span>
                </div>

                <a
                    href={loanContractPDF}
                    download={t("Loan.contract.label")}
                    id="dowload-loan-contract-link"
                    target="_blank"
                    rel="noreferrer"
                ></a>
            </div>

            <div className="download-pdf-content">
                <div
                    className={`download-pdf-bth-wrapper download-loan-contract ${
                        !loanRequestPDF ? "disabled" : "active"
                    }`}
                    onClick={() => {
                        document
                            .getElementById("dowload-loan-request-link")
                            .click();
                    }}
                >
                    <img src={pdfIcon} alt="" />
                    <span className="text">
                        {t("Downlod.Loan.Request.label")}
                    </span>
                </div>

                <a
                    href={loanRequestPDF}
                    download={t("Your.request.label")}
                    id="dowload-loan-request-link"
                    target="_blank"
                    rel="noreferrer"
                ></a>
            </div>

            {promissoryNotePDF && (
                <div className="download-pdf-content">
                    <div
                        className={`download-pdf-bth-wrapper download-loan-contract ${
                            !promissoryNotePDF ? "disabled" : "active"
                        }`}
                        onClick={() => {
                            document
                                .getElementById(
                                    "dowload-loan-promissory-note-link"
                                )
                                .click();
                        }}
                    >
                        <img src={pdfIcon} alt="" />
                        <span className="text">
                            {t("Download.Loan.Promissory.note.label")}
                        </span>
                    </div>

                    <a
                        href={promissoryNotePDF}
                        download={t("Loan.contract.label")}
                        id="dowload-loan-promissory-note-link"
                        target="_blank"
                        rel="noreferrer"
                    ></a>
                </div>
            )}

            {creditHistoryPDF && (
                <div className="download-pdf-content">
                    <div
                        className={`download-pdf-bth-wrapper download-credit-history ${
                            !creditHistoryPDF ? "disabled" : "active"
                        }`}
                        onClick={() => {
                            document
                                .getElementById("dowload-credit-history-link")
                                .click();
                        }}
                    >
                        <img src={pdfIcon} alt="" />
                        <span className="text">
                            {t("Download.Credit.history.label")}
                        </span>
                    </div>

                    <a
                        href={creditHistoryPDF}
                        download={t("Download.Credit.history.label")}
                        id="dowload-credit-history-link"
                        target="_blank"
                        rel="noreferrer"
                    ></a>
                </div>
            )}

            {mailPhonePDF && (
                <div className="download-pdf-content">
                    <div
                        className={`download-pdf-bth-wrapper download-mail-phone ${
                            !mailPhonePDF ? "disabled" : "active"
                        }`}
                        onClick={() => {
                            document
                                .getElementById("dowload-mail-phone-link")
                                .click();
                        }}
                    >
                        <img src={pdfIcon} alt="" />
                        <span className="text">
                            {t("Download.Mail.phone.label")}
                        </span>
                    </div>

                    <a
                        href={mailPhonePDF}
                        download={t("Download.Mail.phone.label")}
                        id="dowload-mail-phone-link"
                        target="_blank"
                        rel="noreferrer"
                    ></a>
                </div>
            )}
        </div>
    );
};

export default DownloadLoanPdfs;
