import React from "react";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ContractItem from "./contract-item/ContractItem";
import { useSelector } from "react-redux";

import Spinner from "../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../common/error-message/ErrorMessage";

import loanStatusesColorMap from "../../../../../constants/loanStatusesColorMap";
import { formatPrice } from "../../../../../utils/currency";
import { getDateByFormat } from "../../../../../utils/parser";

const ContractsList = () => {
  const {
    contracts,
    contractsRequestPending,
    contractsRequestError,
  } = useSelector((state) => ({
    searchString: state.contracts.searchString,
    statusCodeFilters: state.contracts.statusCodeFilters,

    contracts: state.contracts?.contracts,

    contractsRequestPending:
      state.contracts.contractsRequest.contractsRequestPending,
    contractsRequestError:
      state.contracts.contractsRequest.contractsRequestError,
    contractsRequestSuccess:
      state.contracts.contractsRequest.contractsRequestSuccess,

    prolongReturnDateRequestResponse:
      state.contracts.prolongReturnDateRequestResponse,
  }));
  const [openContractItem, setContractItemState] = React.useState(false);
  const { t } = useTranslation();
  const [contractInfo, setContarctInfo] = React.useState(null);

  if (contractsRequestError) {
    return (
      <div className="contracts-table-wrapper">
        <ErrorMessage text={t("Some.Error.label")} />
      </div>
    );
  }

  if (contractsRequestPending) {
    return (
      <div className="contracts-table-wrapper">
        <Spinner text={`${t("Loading.data.label")}...`} />
      </div>
    );
  }

  return (
    <div className="contracts-table-wrapper">
      {contractInfo && (
        <ContractItem
          closeContractItem={() => setContractItemState(false)}
          openContractItem={openContractItem}
          contractInfo={contractInfo}
          allowShowUserDetail={true}
        />
      )}

      <TableContainer
        className={`table-container ${!contracts.length ? "empty-table" : ""}`}
      >
        <Table aria-label="customized table">
          <TableHead className="contracts-table-head">
            <TableRow>
              <TableCell className="table-head-cell"></TableCell>
              <TableCell className="table-head-cell">
                {t("Loan.label")} №
              </TableCell>
              <TableCell className="table-head-cell">
                <span>{t("Date.label")} </span>
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                <span>{t("Status.label")} </span>
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Client.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                <span>{t("Return.Date.label")} </span>
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Refinanced.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="contracts-table-body">
            {!contracts.length ? (
              <div className="contracts-empty">
                {t("No.Data.To.Dispaly.label")}
              </div>
            ) : (
              <>
                {contracts.map((loan, i) => (
                  <TableRow
                    className="contracts-table-body-row"
                    key={i}
                    onClick={() => {
                      setContractItemState(true);
                      setContarctInfo(loan);
                    }}
                  >
                    <TableCell className="table-body-cell">
                      <span
                        className="indicator"
                        style={{
                          background: loanStatusesColorMap.get(loan.color),
                        }}
                      ></span>
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.contractNumber}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: loan.dateRequest })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.loanAmount)}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.status}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.client}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan?.dateReturn
                        ? getDateByFormat({ date: loan.dateReturn })
                        : ""}
                    </TableCell>
                    <TableCell
                      className={`table-body-cell loan-refinanced-${loan?.refinancing?.toLowerCase()}`}
                    >
                      {loan?.refinancing}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ContractsList;
