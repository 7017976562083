import React from "react";
import "./styles.scss";
import pencilIcon from "../../../../../assets/pencil-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAddress } from "../../../../../actions/auth/auth";
import { getDeparments } from "../../../../../actions/generals/generals";
import ProcessModal from "../../../../common/process-modal/ProcessModal";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function checkavAilabilityProperty(property) {
  return property || "-";
}

function getLastValidValue(currentValue, lastValue) {
  return currentValue || lastValue || "-";
}

function checkIsInputValue(value) {
  if (value === null) return true;
  return !!value?.length;
}

function checkInputValueWithPrevValue(currentValue, prevValue) {
  return currentValue !== prevValue;
}

const Address = () => {
  const { t } = useTranslation();

  const {
    departments,
    updateAddressData,
    updateAdressInfoRequestError,
    updateAdressInfoRequestPending,
    updateAdressInfoRequestSuccess,
    userCity,
    userColonia,
    userExteriorNumber,
    userProvince,
    userStreet,
  } = useSelector((state) => ({
    departments: state.generals.departments,
    userStreet: state?.auth?.user?.profile?.street,
    userCity: state?.auth?.user?.profile?.municipality,
    userExteriorNumber: state?.auth?.user?.profile.streetNumber,
    userColonia: state?.auth?.user?.profile?.suburb,
    userProvince: state?.auth?.user?.profile?.department,

    updateAddressData: state?.auth?.updateAddressData,

    updateAdressInfoRequestPending:
      state?.auth?.updateAddressRequest?.updateAddressRequestPending,
    updateAdressInfoRequestError:
      state?.auth?.updateAddressRequest?.updateAddressRequestError,
    updateAdressInfoRequestSuccess:
      state?.auth?.updateAddressRequest?.updateAddressRequestSuccess,

    userId: state.auth.user.id,
  }));

  const [activeChangeAdressInfo, setActiveChangeAdressInfo] = React.useState(
    false
  );

  const [
    openAdressInfoModalProcess,
    setOpenAdressInfoModalProcess,
  ] = React.useState(false);

  const [streetValue, setStreetValue] = React.useState(
    checkavAilabilityProperty(userStreet)
  );
  const [cityValue, setCityValue] = React.useState(
    checkavAilabilityProperty(userCity)
  );
  const [exteriorNumberValue, setExteriorNumberValue] = React.useState(
    checkavAilabilityProperty(userExteriorNumber)
  );
  const [userColoniaValue, setUserColoniaValue] = React.useState(
    checkavAilabilityProperty(userColonia)
  );
  const [userProvinceValue, setUserProvinceValue] = React.useState(
    checkavAilabilityProperty(userProvince)
  );
  const [canUpdateInfo, setCanUpdateInfo] = React.useState(
    (checkIsInputValue(streetValue) &&
      checkInputValueWithPrevValue(streetValue, userStreet)) ||
      (checkIsInputValue(cityValue) &&
        checkInputValueWithPrevValue(cityValue, userCity)) ||
      (checkIsInputValue(exteriorNumberValue) &&
        checkInputValueWithPrevValue(
          exteriorNumberValue,
          userExteriorNumber
        )) ||
      (checkIsInputValue(userColoniaValue) &&
        checkInputValueWithPrevValue(userColoniaValue, userColonia)) ||
      (checkIsInputValue(userProvinceValue) &&
        checkInputValueWithPrevValue(userProvinceValue, userProvince))
  );

  React.useEffect(() => {
    setCanUpdateInfo(
      (checkIsInputValue(streetValue) &&
        checkInputValueWithPrevValue(streetValue, userStreet)) ||
        (checkIsInputValue(cityValue) &&
          checkInputValueWithPrevValue(cityValue, userCity)) ||
        (checkIsInputValue(exteriorNumberValue) &&
          checkInputValueWithPrevValue(
            exteriorNumberValue,
            userExteriorNumber
          )) ||
        (checkIsInputValue(userColoniaValue) &&
          checkInputValueWithPrevValue(userColoniaValue, userColonia)) ||
        (checkIsInputValue(userProvinceValue) &&
          checkInputValueWithPrevValue(userProvinceValue, userProvince))
    );
  }, [
    streetValue,
    cityValue,
    exteriorNumberValue,
    userColoniaValue,
    userProvinceValue,

    userStreet,
    userCity,
    userExteriorNumber,
    userColonia,
    userProvince,
  ]);

  React.useEffect(() => {
    setStreetValue(userStreet);
    setCityValue(checkavAilabilityProperty(userCity));
    setExteriorNumberValue(checkavAilabilityProperty(userExteriorNumber));
    setUserColoniaValue(checkavAilabilityProperty(userColonia));
    setUserProvinceValue(checkavAilabilityProperty(userProvince));
  }, [
    activeChangeAdressInfo,
    userStreet,
    userCity,
    userExteriorNumber,
    userColonia,
    userProvince,
  ]);

  const onCloseModal = React.useCallback(() => {
    if (updateAdressInfoRequestSuccess) {
      setActiveChangeAdressInfo(false);
    }

    setOpenAdressInfoModalProcess(false);
  }, [updateAdressInfoRequestSuccess]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDeparments());
  }, [dispatch]);

  return (
    <div className="address-info-block-wrapper">
      <div className="title-wrapper">
        <div className="text">{t("Address.label")}</div>
        <button
          className="change-bth"
          onClick={() => setActiveChangeAdressInfo((state) => !state)}
        >
          <span className="bth-text">{t("Change.label")}</span>
          <img src={pencilIcon} alt="" />
        </button>
      </div>
      <div className="address-info-list">
        <div className="address-info-item street-info-wrapper">
          <div className="title street-title">{t("Street.label")}</div>
          <input
            type="text"
            value={streetValue}
            onChange={(e) => setStreetValue(e.target.value)}
            className={`value -value ${
              !activeChangeAdressInfo ? "disabled" : "active"
            }`}
            onBlur={(e) =>
              setStreetValue(getLastValidValue(e.target.value, userStreet))
            }
          />
          {!checkIsInputValue(streetValue) ? (
            <div className="info-empty">
              {t("Value.Can.Not.Be.Empty.label")}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="address-info-item city-info-wrapper">
          <div className="title city-title">{t("City.label")}</div>
          <input
            type="text"
            value={cityValue}
            onChange={(e) => setCityValue(e.target.value)}
            className={`value city-value ${
              !activeChangeAdressInfo ? "disabled" : "active"
            }`}
            onBlur={(e) =>
              setCityValue(getLastValidValue(e.target.value, userCity))
            }
          />
          {!checkIsInputValue(cityValue) ? (
            <div className="info-empty">
              {t("Value.Can.Not.Be.Empty.label")}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="address-info-item exterior-number-info-wrapper">
          <div className="title exterior-number-title">
            {" "}
            {t("Exterior.number.label")}
          </div>
          <input
            type="text"
            value={exteriorNumberValue}
            onChange={(e) => setExteriorNumberValue(e.target.value)}
            className={`value exterior-number-value ${
              !activeChangeAdressInfo ? "disabled" : "active"
            }`}
            onBlur={(e) =>
              setExteriorNumberValue(
                getLastValidValue(e.target.value, userExteriorNumber)
              )
            }
          />
          {!checkIsInputValue(exteriorNumberValue) ? (
            <div className="info-empty">
              {t("Value.Can.Not.Be.Empty.label")}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="address-info-item colonia-info-wrapper">
          <div className="title colonia-title">{t("Colonia.label")}</div>
          <input
            type="text"
            value={userColoniaValue}
            onChange={(e) => setUserColoniaValue(e.target.value)}
            className={`value colonia-value ${
              !activeChangeAdressInfo ? "disabled" : "active"
            }`}
            onBlur={(e) =>
              setUserColoniaValue(
                getLastValidValue(e.target.value, userColonia)
              )
            }
          />
          {!checkIsInputValue(userColoniaValue) ? (
            <div className="info-empty">
              {t("Value.Can.Not.Be.Empty.label")}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="address-info-item province-info-wrapper">
          <div className="title province-title">{t("Province.label")}</div>
          <Select
            name="typeOfEmployment"
            id="typeOfEmployment"
            className={`value province-value ${
              !activeChangeAdressInfo ? "disabled" : "active"
            }`}
            value={userProvinceValue}
            onChange={(e) => {
              setUserProvinceValue(e.target.value);
            }}
          >
            <MenuItem value="">
              <em>{t("Choose.Type.label")}</em>
            </MenuItem>
            {departments.map((item) => (
              <MenuItem value={item} key={item}>
                {t(item)}
              </MenuItem>
            ))}
          </Select>

          {!checkIsInputValue(userProvinceValue) ? (
            <div className="info-empty">
              {t("Value.Can.Not.Be.Empty.label")}
            </div>
          ) : (
            ""
          )}
        </div>

        {activeChangeAdressInfo && (
          <div className="save-new-adress-info-bth-wrapper">
            <div
              className={`bth-border-wrapper ${
                !canUpdateInfo ? "disabled" : "active"
              }`}
            >
              <button
                disabled={!canUpdateInfo}
                onClick={() => {
                  setOpenAdressInfoModalProcess(true);
                  dispatch(
                    updateUserAddress({
                      street: streetValue || userStreet,
                      municipality: cityValue || userCity,
                      street_number: exteriorNumberValue || userExteriorNumber,
                      suburb: userColoniaValue || userColonia,
                      department: userProvinceValue || userProvince,
                    })
                  );
                }}
              >
                {t("Save.Changes.label")}
              </button>
            </div>
          </div>
        )}

        {openAdressInfoModalProcess ? (
          <ProcessModal
            openModalProcess={openAdressInfoModalProcess}
            closeModalProcess={onCloseModal}
            requestPending={updateAdressInfoRequestPending}
            requestError={updateAdressInfoRequestError}
            requestSuccess={updateAdressInfoRequestSuccess}
            fnOnDestroy={() => {
              // dispatch(resetUpdateAdressInfoRequestState(false));
            }}
            pendingText={`${t("Updating.Adress.Info.label")}...`}
            errorText={updateAddressData?.message}
            successText={updateAddressData?.message}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Address;
