import React, { useEffect, useState } from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../../../../assets/close-icon.svg";
import { useDispatch, connect } from "react-redux";
import { blockLoaner } from "../../../../../../actions/loaners/loaners";

const countDaysBlock = 20;

function caculateBlockDate(countDays) {
  const currentDateInMiliseconds = new Date().getTime();
  const daysInMiliseconds = countDays * 24 * 60 * 60 * 1000;
  const dateBlock = new Date(currentDateInMiliseconds + daysInMiliseconds);
  return dateBlock;
}

function LoanerReasonBlock({
  openLoanerReasonBlockModal,
  closeLoanerReasonBlockModal,
  loanerId,

  blockLoanerRequestPending,
  blockLoanerRequestError,
  blockLoanerRequestSuccess,
}: *) {
  const dispatch = useDispatch();
  const [reasonDescription, setReasonDescription] = useState();

  useEffect(() => {
    if (blockLoanerRequestSuccess) {
      closeLoanerReasonBlockModal();
      setReasonDescription("");
    }
  }, [blockLoanerRequestSuccess, closeLoanerReasonBlockModal]);
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={closeLoanerReasonBlockModal}
      open={openLoanerReasonBlockModal}
      className="modal-loaner-reason-block-wrapper"
    >
      <DialogContent className="loaner-reason-block-content">
        <div
          className="close-icon-wrapper"
          onClick={closeLoanerReasonBlockModal}
        >
          <img src={closeIcon} alt="" />
        </div>
        <div className="main-content-wrapper">
          <div className="textarea-wrapper">
            <div className="textarea-title">{t("Reason.of.Block.label")}</div>
            <textarea
              value={reasonDescription}
              onChange={(e) => setReasonDescription(e.target.value)}
            />
          </div>
          <div className="bth-actions-wrapper">
            <button
              className={`bth bth-block ${
                reasonDescription ? "active" : "disabled"
              }`}
              onClick={() => {
                dispatch(
                  blockLoaner({
                    dateBlock: caculateBlockDate(countDaysBlock),
                    loanerId,
                    reason: reasonDescription,
                  })
                );
              }}
            >
              {t("Block.label")}
            </button>
          </div>
          {blockLoanerRequestPending ? (
            <div className="text-spinner blocking-spinner">
              {t("Blocking.label")}...
            </div>
          ) : (
            ""
          )}
          {blockLoanerRequestError ? (
            <div className="text-error blocking-spinner">
              {t("Some.Error.label")}
            </div>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = () => {
  return {
    blockLoanerRequestPending: false,
    blockLoanerRequestError: false,
    blockLoanerRequestSuccess: false,
  };
};
export default connect(mapStateToProps, null)(LoanerReasonBlock);
