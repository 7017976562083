import App from "./App";
import { hydrate, render } from "react-dom";
import React, { Suspense } from "react";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
    rootElement
  );
} else {
  render(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
    rootElement
  );
}
