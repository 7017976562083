import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  resetRegisterRequestStore,
} from "../../../actions/auth/auth";
import { useTranslation } from "react-i18next";
import eyeIcon from "../../../assets/eye-icon.svg";
import eyeIconStrike from "../../../assets/eye-icon-strike.svg";
import arrowBackIcon from "../../../assets/arrow-back-icon.svg";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import "./styles.scss";
import PasswordValidatorMessage from "../../common/password-validator-message/PasswordValidatorMessage";
import ProcessModal from "../../common/process-modal/ProcessModal";

import {
  checkPasswordLength,
  checkPasswordContainsNumbers,
  checkPasswordContainsLowerLetters,
  checkPasswordContainsUpperLetters,
  checkPasswordContainsSpecialCharacter,
  comparePasswordInputs,
  validateEmail,
} from "../../../utils/regex";

import { useAnalitycs } from "../../../hooks/useAnalytics";

const Register = () => {
  useAnalitycs("Register");

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // email
  const [email, setEmail] = React.useState("");
  const [emailTouched, setEmailTouched] = React.useState(email);
  const [validEmail, setValidEmail] = React.useState(false);

  // password
  const [password, setPassword] = React.useState("");
  const [passwordTouched, setPasswordTouched] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  // password confirmation
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordComparing, setPasswordComparing] = React.useState(null);

  // form
  const [validForm, setValidForm] = React.useState(false);
  const [
    showPasswordValidatorMessage,
    setShowPasswordValidatorMessage,
  ] = React.useState(null);
  const [openModalProcess, setOpenModalProcess] = React.useState(false);

  const {
    isAuthenticated,
    registerRequestPending,
    registerRequestSuccess,
    registerRequestError,
    registerRequestErrorMessage,
    registerRequestSuccessMessage,
  } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userAlreadyRegister: state.auth.userAlreadyRegister,
    registerRequestPending: state.auth.registerRequest.registerRequestPending,
    registerRequestSuccess: state.auth.registerRequest.registerRequestSuccess,
    registerRequestError: state.auth.registerRequest.registerRequestError,
    registerRequestErrorMessage: state.auth.registerRequestErrorMessage,
    registerRequestSuccessMessage: state.auth.registerRequestSuccessMessage,
  }));

  React.useEffect(() => {
    if (passwordTouched) {
      setShowPasswordValidatorMessage(() => {
        const validPasswordLength = checkPasswordLength(password);
        const containsNumbers = checkPasswordContainsNumbers(password);
        const containsSpecialCharacter = checkPasswordContainsSpecialCharacter(
          password
        );
        const containsLowerLetters = checkPasswordContainsLowerLetters(
          password
        );
        const containsUpperLetters = checkPasswordContainsUpperLetters(
          password
        );
        return (
          validPasswordLength &&
          containsNumbers &&
          containsLowerLetters &&
          containsUpperLetters &&
          containsSpecialCharacter
        );
      });
    }
  }, [password, passwordTouched]);

  React.useEffect(() => {
    setValidForm(() => {
      return showPasswordValidatorMessage && passwordComparing && validEmail;
    });
  }, [
    email,
    passwordConfirmation,
    showPasswordValidatorMessage,
    passwordComparing,
    validEmail,
  ]);

  const onRegisterClick = React.useCallback(() => {
    setOpenModalProcess(true);
    dispatch(register(email, password, passwordConfirmation));
  }, [email, password, passwordConfirmation, setOpenModalProcess, dispatch]);

  const onEmailChange = React.useCallback((e) => {
    setEmail(e.target.value);
    setValidEmail(validateEmail(e.target.value));
    setEmailTouched(true);
  }, []);

  const onPasswordChange = React.useCallback(
    (e) => {
      setPasswordTouched(true);
      setPassword(() => {
        setPasswordComparing(
          comparePasswordInputs(e.target.value, passwordConfirmation)
        );
        return e.target.value;
      });
    },
    [passwordConfirmation]
  );

  const onCloseModal = React.useCallback(() => {
    setOpenModalProcess(false);

    if (registerRequestSuccess) {
      history.push("/");
    }
  }, [registerRequestSuccess, history]);

  const onConfirmationChange = React.useCallback(() => {
    setShowConfirmPassword((state) => !state);
  }, []);

  const emptyEmail = React.useMemo(() => email.length === 0, [email]);

  const emptyPassword = React.useMemo(() => password.length === 0, [password]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="register-page-wrapper">
      <Header />
      <div className="container-width">
        <div className="form__wrapper">
          <div className="back-bth-wrapper">
            <Link to={"/login"}>
              <img src={arrowBackIcon} alt="" />
              <span className="description">{t("Back.label")}</span>
            </Link>
          </div>
          <div className="form-content-wrapper">
            <div className="form-title">{t("Register.label")}</div>
            <div className="form-inputs-wrapper">
              <form className="form">
                <fieldset className="input-group email-group">
                  <label htmlFor="email">{t("Email.address.label")}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={onEmailChange}
                  />
                  {emptyEmail && emailTouched && (
                    <div className="info-compare-passwords-block">
                      {t("Required.label")}
                    </div>
                  )}
                  {!validEmail && !emptyEmail && emailTouched && (
                    <div className="info-compare-passwords-block">
                      {t("Invalid.email.label")}
                    </div>
                  )}
                </fieldset>
                <fieldset className="input-group password-group">
                  <label htmlFor="password">{t("Password.label")}</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={onPasswordChange}
                  />
                  {emptyPassword && passwordTouched && (
                    <div className="info-compare-passwords-block">
                      {t("Required.label")}
                    </div>
                  )}
                  <div
                    className={`eye-icon__wrapper ${
                      showPassword ? "" : "visible"
                    }`}
                  >
                    <img
                      className="eye-icon"
                      src={showPassword ? eyeIcon : eyeIconStrike}
                      alt=""
                      onClick={() => {
                        setShowPassword((state) => !state);
                      }}
                    />
                  </div>
                  {showPasswordValidatorMessage === false ? (
                    <PasswordValidatorMessage
                      minLength={checkPasswordLength(password)}
                      containNumbers={checkPasswordContainsNumbers(password)}
                      containSpecialCaracters={checkPasswordContainsSpecialCharacter(
                        password
                      )}
                      containUpperLetters={checkPasswordContainsUpperLetters(
                        password
                      )}
                      containLowerLetters={checkPasswordContainsLowerLetters(
                        password
                      )}
                    />
                  ) : (
                    ""
                  )}
                </fieldset>
                <fieldset className="input-group password-group confirm-password">
                  <label htmlFor="passwordConfirmation">
                    {t("Confirm.password.label")}
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="passwordConfirmation"
                    value={passwordConfirmation}
                    name="passwordConfirmation"
                    onChange={(e) => {
                      setPasswordConfirmation(() => {
                        setPasswordComparing(
                          comparePasswordInputs(password, e.target.value)
                        );
                        return e.target.value;
                      });
                    }}
                  />
                  <div
                    className={`eye-icon__wrapper ${
                      showConfirmPassword ? "" : "visible"
                    }`}
                  >
                    <img
                      className="eye-icon"
                      src={showConfirmPassword ? eyeIcon : eyeIconStrike}
                      alt=""
                      onClick={onConfirmationChange}
                    />
                  </div>
                  {passwordComparing === false ? (
                    <div className="info-compare-passwords-block">
                      {t("Your.Password.Does.Not.Match")}
                    </div>
                  ) : (
                    ""
                  )}
                </fieldset>
                <button
                  onClick={onRegisterClick}
                  disabled={!validForm}
                  className={`submit-bth ${validForm ? "active" : "disabled"}`}
                  type="button"
                >
                  {t("Register.label")}
                </button>
                {openModalProcess && (
                  <ProcessModal
                    openModalProcess={openModalProcess}
                    closeModalProcess={onCloseModal}
                    requestPending={registerRequestPending}
                    requestError={registerRequestError}
                    requestSuccess={registerRequestSuccess}
                    fnOnDestroy={() => {
                      dispatch(resetRegisterRequestStore(false));
                    }}
                    pendingText={`${t("Register.You.label")}...`}
                    errorText={t(registerRequestErrorMessage)}
                    successText={registerRequestSuccessMessage}
                  >
                    {registerRequestSuccess && (
                      <div className="register-reminder">
                        {t("register.spam.message")}
                      </div>
                    )}
                  </ProcessModal>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
