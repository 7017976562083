import React from "react";
import { useTranslation } from "react-i18next";
import bankLogoIcon from "../../../../../../assets/bank-logo-icon.svg";
import idCardIcon from "../../../../../../assets/id-card-icon.svg";
import smartphoneIcon from "../../../../../../assets/smartphone-icon.svg";

import "./styles.scss";

function InformativeStep({ nextStep }: *) {
  const { t } = useTranslation();
  return (
    <div className="form-step-wrapper informative-step">
      <div className="title-wrappper">
        <p>{t("Important!.label")}</p>
        <p>{t("To.carry.out.the.process.have.at.hand.label")}</p>
      </div>
      <div className="necessary-items-wrappper">
        <div className="necessary-item">
          <div className="necessary-item-title desktop">
            {t("Bank.account.label")}
          </div>
          <div className="necessary-item-icon-wrapper">
            <img src={bankLogoIcon} alt="" />
          </div>
          <div className="necessary-item-description desktop">
            {t("It will be needed to get money.label")}
          </div>
          <div className="text-mobile">
            <div className="necessary-item-title">
              {t("Bank.account.label")}
            </div>
            <div className="necessary-item-description">
              {t("It will be needed to get money.label")}
            </div>
          </div>
        </div>
        <div className="necessary-item">
          <div className="necessary-item-title desktop">{t("DNI.label")}</div>
          <div className="necessary-item-icon-wrapper">
            <img src={idCardIcon} alt="" />
          </div>
          <div className="necessary-item-description desktop">
            {t("Original.or.copy.label")}
          </div>
          <div className="text-mobile">
            <div className="necessary-item-title">{t("DNI.label")}</div>
            <div className="necessary-item-description">
              {t("Original.or.copy.label")}
            </div>
          </div>
        </div>
        <div className="necessary-item">
          <div className="necessary-item-title desktop">
            {t("Phone.number.label")}
          </div>
          <div className="necessary-item-icon-wrapper">
            <img src={smartphoneIcon} alt="" />
          </div>
          <div className="necessary-item-description desktop">
            {t("Personal.with.active.line.label")}
          </div>
          <div className="text-mobile">
            <div className="necessary-item-title">
              {t("Phone.number.label")}
            </div>
            <div className="necessary-item-description">
              {t("Personal.with.active.line.label")}
            </div>
          </div>
        </div>
      </div>
      <div className="additional-message">
        {t("Loan.for.independants.message")}
      </div>
      <div className="decription">
        {t("Whithout.them.you.will.not.have.a.credit.label")}
      </div>
      <div className="bth-action bth-continue-wrapper">
        <button className="bth-continue" onClick={() => nextStep()}>
          {t("Continue.label")}
        </button>
      </div>
    </div>
  );
}

export default InformativeStep;
