import React from "react";
import "./styles.scss";

function ErrorMessage({ text }: *) {
  return (
    <div className="error-wrapper">
      <div
        className="error-text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
}

export default ErrorMessage;
