import React from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import { TIME_LOAN_DECISION } from "../../../../../../../constants/time-termins";
import { useTranslation } from "react-i18next";

const calculateEndDecisionDate = (issueDate) => {
  const timeLoanDecisionInMiliseconds = TIME_LOAN_DECISION * 60 * 1000;
  const issueDateInMiliseconds = new Date(issueDate).getTime();
  const endLoanDecisionDate =
    issueDateInMiliseconds + timeLoanDecisionInMiliseconds;
  return endLoanDecisionDate;
};

const calculateRestOfЕime = (endDecisionDate) => {
  const restOfTimeInMiliseconds = endDecisionDate - new Date().getTime();
  const restOfTimeInMinutes = Math.floor(restOfTimeInMiliseconds / 1000 / 60);
  const quotient = Math.floor(restOfTimeInMinutes / 60);
  const remainder = restOfTimeInMinutes % 60;
  return {
    quotient,
    remainder,
  };
};

const checkTimerOf = (issueDate) => {
  const issueDateInMiliseconds = new Date(issueDate).getTime();
  const currentDateInMiliseconds = new Date().getTime();
  return issueDateInMiliseconds > currentDateInMiliseconds;
};

const LoanStatusUnderConsideration = () => {
  const { loanDateIssue } = useSelector((state) => ({
    loanDateIssue: state.loan?.loanInfo?.loanDate,
  }));

  const [quotient, setQuotient] = React.useState(() => {
    const endDecisionDate = calculateEndDecisionDate(loanDateIssue);
    const { quotient } = calculateRestOfЕime(endDecisionDate);
    return quotient;
  });

  const [remainder, setRemainder] = React.useState(() => {
    const endDecisionDate = calculateEndDecisionDate(loanDateIssue);
    const { remainder } = calculateRestOfЕime(endDecisionDate);
    return remainder;
  });

  const [timerOff, setTimerOff] = React.useState(false);

  React.useEffect(() => {
    let updateTimer;
    const endDecisionDate = calculateEndDecisionDate(loanDateIssue);
    const timerOf = checkTimerOf(endDecisionDate);
    if (timerOf) {
      updateTimer = setInterval(() => {
        const { quotient, remainder } = calculateRestOfЕime(endDecisionDate);
        setQuotient(quotient);
        setRemainder(remainder);
        if (quotient <= 0 && remainder <= 0) {
          setTimerOff(true);
          clearInterval(updateTimer);
          setQuotient(0);
          setRemainder(0);
        }
      }, 1000);
    } else {
      clearInterval(updateTimer);
      setTimerOff(true);
      setQuotient(0);
      setRemainder(0);
    }

    return () => {
      clearInterval(updateTimer);
      setTimerOff(true);
      setQuotient(0);
      setRemainder(0);
    };
  }, [loanDateIssue]);
  const { t } = useTranslation();

  return (
    <div className="loan-status-wrapper">
      <div className="status-indicator-wrapper consideration-loan">
        <span className="dot"></span>
        <span>{t("Loan.Under.Consideration.label")}</span>
      </div>
      <div className="consideration-notification-wrapper">
        <div className="notification-text">
          {t("The.decision.will.be.made.within.2.hours.label")}{" "}
          {t(
            "If.the.decision.is.not.made.in.this.time.contact.technical.support.label"
          )}
        </div>
        <div className="notification-timer-wrapper">
          <div className="notification-timer">{`${quotient}h ${remainder}m`}</div>
          {timerOff ? (
            <div className="timer-info">{t("Time.off.label")}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanStatusUnderConsideration;
