import React from "react";

import "./styles.scss";

import Table from "@material-ui/core/Table";
import { useTranslation } from "react-i18next";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import tick2Icon from "../../../../../assets/tick2-icon.svg";
import lockIcon from "../../../../../assets/lock-svgrepo-com.svg";

import { useSelector } from "react-redux";

import LoanerItem from "./loaner-item/LoanerItem";
import Spinner from "../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../common/error-message/ErrorMessage";

import loanStatusesColorMap from "../../../../../constants/loanStatusesColorMap";

const LoanersList = () => {
  const [openLoanerItem, setOpenLoanerItem] = React.useState(false);
  const { t } = useTranslation();

  const [loanerInfo, setLoanerInfo] = React.useState(null);

  const { loanersRequestError, loanersRequestPending, loaners } = useSelector(
    (state) => ({
      loanersRequestError: state.loaners.loanersRequest.loanersRequestError,
      loanersRequestPending: state.loaners.loanersRequest.loanersRequestPending,
      loaners: state.loaners.loanersList,
    })
  );

  const onClientClicked = React.useCallback((loaner) => {
    setLoanerInfo(loaner);
    setOpenLoanerItem(true);
  }, []);

  const onCloseClientModal = React.useCallback(() => {
    setOpenLoanerItem(false);
  }, []);

  if (loanersRequestError) {
    return (
      <div className="loaners-table-wrapper">
        <ErrorMessage text={t("Some.Error.label")} />
      </div>
    );
  }

  if (loanersRequestPending) {
    return (
      <div className="loaners-table-wrapper">
        <Spinner text={`${t("Loading.data.label")}...`} />
      </div>
    );
  }

  return (
    <div className="loaners-table-wrapper">
      {loanerInfo && (
        <LoanerItem
          closeLoanerItem={onCloseClientModal}
          openLoanerItem={openLoanerItem}
          loanerInfo={loanerInfo}
          allowLoanDetail={true}
        />
      )}

      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="loaners-table-head">
            <TableRow>
              <TableCell className="table-head-cell"></TableCell>
              <TableCell className="table-head-cell">
                {t("Status.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Reputation.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                <span>{t("Name.label")}</span>
                <img src={tick2Icon} className="sort-icon" alt="Sort" />
              </TableCell>
              <TableCell className="table-head-cell">
                {t("DNI.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Phone.number.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Email.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="loaners-table-body">
            {!loaners?.length ? (
              <div className="loaners-empty">
                {t("No.Data.To.Dispaly.label")}
              </div>
            ) : (
              <>
                {loaners.map((loaner, i) => (
                  <TableRow
                    className="loaners-table-body-row"
                    key={i}
                    onClick={() => onClientClicked(loaner)}
                  >
                    <TableCell className="table-body-cell">
                      <input type="checkbox" />
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loaner.color === "black" ? (
                        <img
                          src={lockIcon}
                          alt={t("Block.label")}
                          className="icon"
                        />
                      ) : (
                        <span
                          className="indicator"
                          style={{
                            background: loanStatusesColorMap.get(loaner.color),
                          }}
                        ></span>
                      )}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      <span
                        className="indicator"
                        style={{
                          background: loanStatusesColorMap.get(
                            loaner.reputation
                          ),
                        }}
                      ></span>
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loaner.client}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loaner.dni}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loaner.phoneNumber}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loaner.email}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LoanersList;
