import loanActions from "./loan-types";
import axios from "axios";
import { resetPromocodeStore } from "../promocodes/promocodes";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";
import * as moment from "moment";

function actionCreator(actionType, data) {
  return {
    type: loanActions[actionType],
    payload: data,
  };
}

const showSpinner = () => {
  return { type: loanActions.LOAN_LOADING, payload: true };
};

const hideSpinner = () => {
  return { type: loanActions.LOAN_LOADING, payload: false };
};

const setLoanErrorStatus = (value) => {
  return { type: loanActions.LOAN_ERROR, payload: value };
};

export const setNewLoanData = (data) => {
  return { type: loanActions.SET_NEW_LOAN_DATA, payload: data };
};

export const clearData = () => (dispatch) =>
  dispatch({ type: loanActions.CLEAR_DATA });

export const getCalculatorInfo = () => async (dispatch) => {
  dispatch({ type: loanActions.GET_CALCULATOR_LOAN_DATA_FETCH });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/calculator`
    );

    dispatch({
      type: loanActions.GET_CALCULATOR_LOAN_DATA_SUCCESS,
      payload: {
        interest: data.interest,
        iva: data.iva,
        range: data.calculator,
        realInterest: data.pistoInterest,
        legalInterest: data.interest,
        concepts: data.concepts,
      },
    });
  } catch (err) {
    dispatch({
      type: loanActions.GET_CALCULATOR_LOAN_DATA_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getUserLoanInfo = () => async (dispatch, getState) => {
  dispatch({ type: loanActions.GET_LOAN_DATA_FETCH });

  try {
    const {
      data: { data: info },
    } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/my-loan`,
      tokenConfig(getState)
    );

    dispatch({
      type: loanActions.GET_CALCULATOR_LOAN_DATA_SUCCESS,
      payload: {
        interest: info?.calculator?.interest,
        iva: info?.calculator?.iva,
        range: info?.calculator?.values,
        realInterest: info?.calculator?.pistoInterest,
        legalInterest: info?.calculator?.interest,
        concepts: info?.calculator?.concepts,
      },
    });

    dispatch({ type: loanActions.SET_LOAN_LAYOUT, payload: info.loanStatus });

    dispatch({
      type: loanActions.GET_LOAN_DATA_SUCCESS,
      payload: {
        ...info,
      },
    });
  } catch (err) {
    dispatch({
      type: loanActions.GET_LOAN_DATA_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const updateLoanLayout = (value) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanActions.LOAN_TAB_LAYOUT,
      payload: value,
    });

    const {
      loan: { loanInfo },
    } = getState();

    dispatch({
      type: loanActions.GET_LOAN_DATA_SUCCESS,
      payload: {
        ...loanInfo,
        loanStatus: 1,
      },
    });
  } catch (err) {
    console.error("TCL: updateLoanStatus -> err", err);
  }
};

export const setRefuseReason = (value) => async (dispatch) => {
  dispatch({ type: loanActions.SET_REFUSE_REASON, payload: value });
};

export const resetRefuseReason = (value) => async (dispatch) => {
  dispatch({
    type: loanActions.RESET_REFUSE_REASON,
    payload: value,
  });
};

export const refuseLoan = (reason) => async (dispatch, getState) => {
  try {
    dispatch({ type: loanActions.FETCH_REFUSE_LOAN_PENDING });
    const token = await tokenConfig(getState);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/refuse-loan`,
      {
        reason,
      },
      token
    );

    dispatch({
      type: loanActions.FETCH_REFUSE_LOAN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_REFUSE_LOAN_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const acceptLoanSecondStep = (accountInfo) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_PENDING });
    const token = await tokenConfig(getState);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/accept-loan-step-2`,
      { ...accountInfo },
      token
    );

    dispatch({
      type: loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_ACCEPT_LOAN_SECOND_STEP_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const acceptLoanFirstStep = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_PENDING });
    const token = await tokenConfig(getState);

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/accept-loan-step-1`,
      token
    );

    dispatch({
      type: loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_ACCEPT_LOAN_FIRST_STEP_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const resubmitLoanPhotos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_PENDING });
    const token = await tokenConfig(getState);

    const {
      userAttachments: { verificationFormFiles },
    } = getState();

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/reload-images`,
      {
        imageFrontIdCard: verificationFormFiles?.srcUserPhotos?.frontIdCard,
        imageBackIdCard: verificationFormFiles?.srcUserPhotos?.backIdCard,
        imageSelfie: verificationFormFiles?.srcUserPhotos?.selfie,
      },
      token
    );

    dispatch({
      type: loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_RESUBMIT_LOAN_PHOTOS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const createFirstLoan = () => async (dispatch, getState) => {
  const promocodeApply = getState().promocodes.promocodeApply;
  const promoCode = getState().promocodes.inputPromocodeValue;

  const {
    userAttachments: { verificationFormFiles },
    verification,
    loan: { newLoan },
  } = getState();

  const birthDate = new Date(verification.birthDate);

  const body = {
    amount: newLoan.userSum,
    additionalIncome: verification.additionalIncome,
    birthDate: moment(birthDate).format("YYYY-MM-DD"),
    companyName: verification.companyName,
    companyPhone: verification.companyPhone,
    deadline: newLoan.daysReturnCount,
    department: verification.province,
    dni: verification.dniNumber,
    gender: verification.gender,
    imageBackIdCard: verificationFormFiles?.srcUserPhotos?.backIdCard,
    imageFrontIdCard: verificationFormFiles?.srcUserPhotos?.frontIdCard,
    imageSelfie: verificationFormFiles?.srcUserPhotos?.selfie,
    jobType: verification.typeOfEmployment,
    municipality: verification.city,
    name: verification.firstName,
    netIncome: verification.netIncome,
    otherLoan: verification.otherLoans,
    phoneNumber: verification.phone,
    promoCode: promocodeApply ? promoCode : null,
    purpose: verification.loanPurpose,
    purposeDescription: verification.description,
    street: verification.street,
    streetNumber: verification.exteriorNumber,
    suburb: verification.colonia,
    surname: verification.lastName,

    familiarName: verification.familiarName,
    familiarSurname: verification.familiarSurname,
    familiarPhone: verification.familiarPhone,
    friendName: verification.friendName,
    friendSurname: verification.friendSurname,
    friendPhone: verification.friendPhone,
  };

  try {
    dispatch({ type: loanActions.CREATE_FIRST_LOAN_FETCH });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/request-first-loan`,
      body,
      token
    );

    dispatch({
      type: loanActions.CREATE_FIRST_LOAN_SUCCESS,
      payload: data?.data?.message,
    });

    dispatch(getUserLoanInfo());
    dispatch(resetPromocodeStore());
  } catch (err) {
    dispatch({
      type: loanActions.CREATE_FIRST_LOAN_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const createLoan = () => async (dispatch, getState) => {
  const promocodeApply = getState().promocodes.promocodeApply;
  const promoCode = getState().promocodes.inputPromocodeValue;

  const {
    userAttachments: { verificationFormFiles },
    verification,
    loan: { newLoan },
  } = getState();

  const body = {
    amount: newLoan.userSum,
    deadline: newLoan.daysReturnCount,
    purpose: verification.loanPurpose,
    purposeDescription: verification.description,
    imageFrontIdCard: verificationFormFiles?.srcUserPhotos?.frontIdCard,
    imageBackIdCard: verificationFormFiles?.srcUserPhotos?.backIdCard,
    imageSelfie: verificationFormFiles?.srcUserPhotos?.selfie,
    promoCode: promocodeApply ? promoCode : null,

    companyName: verification.companyName,
    companyPhone: verification.companyPhone,

    additionalIncome: verification.additionalIncome,
    jobType: verification.typeOfEmployment,
    netIncome: verification.netIncome,
    otherLoan: verification.otherLoans,

    familiarName: verification.familiarName,
    familiarSurname: verification.familiarSurname,
    familiarPhone: verification.familiarPhone,
    friendName: verification.friendName,
    friendSurname: verification.friendSurname,
    friendPhone: verification.friendPhone,
  };

  try {
    dispatch({ type: loanActions.CREATE_LOAN_REQUEST_FETCH });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/request-loan`,
      body,
      token
    );

    dispatch({
      type: loanActions.CREATE_LOAN_REQUEST_SUCCESS,
      payload: data?.data?.message,
    });

    dispatch(getUserLoanInfo());
  } catch (err) {
    dispatch({
      type: loanActions.CREATE_LOAN_REQUEST_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getJobTypes = () => async (dispatch, getState) => {
  dispatch({ type: loanActions.FETCH_JOB_TYPES_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/jobs`,
      tokenConfig(getState)
    );

    dispatch({
      type: loanActions.FETCH_JOB_TYPES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_JOB_TYPES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const closeUserLoan = () => async (dispatch, getState) => {
  dispatch({ type: loanActions.FETCH_CLOSE_LOAN_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/close-loan`,
      tokenConfig(getState)
    );

    return dispatch({
      type: loanActions.FETCH_CLOSE_LOAN_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_CLOSE_LOAN_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getPurposes = () => async (dispatch, getState) => {
  dispatch({ type: loanActions.FETCH_PURPOSES_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/purposes`,
      tokenConfig(getState)
    );

    dispatch({
      type: loanActions.FETCH_PURPOSES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_PURPOSES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getRejectReasons = () => async (dispatch, getState) => {
  dispatch({ type: loanActions.FETCH_REJECT_REASONS_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/reject-reasons`,
      tokenConfig(getState)
    );

    dispatch({
      type: loanActions.FETCH_REJECT_REASONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_REJECT_REASONS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const setLoanTabLayout = (value) => {
  return { type: loanActions.LOAN_TAB_LAYOUT, payload: value };
};

export const setSelectedBank = (value) => {
  return { type: loanActions.SET_SELECTED_BANK, payload: value };
};

export const getLoanContract = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: loanActions.FETCH_LOAN_CONTRACT_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/download-loan-contract/${id}`,
      token
    );

    dispatch({
      type: loanActions.FETCH_LOAN_CONTRACT_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_LOAN_CONTRACT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getLoanRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: loanActions.FETCH_LOAN_REQUEST_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/download-loan-request/${id}`,
      token
    );

    dispatch({
      type: loanActions.FETCH_LOAN_REQUEST_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanActions.FETCH_LOAN_REQUEST_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

// *************** OLD *******************
const setLoanLayoutStatus = (value) => {
  return { type: loanActions.SET_LOAN_LAYOUT_STATUS, payload: value };
};

export const setLoanLayoutRequest = (value) => {
  return { type: loanActions.SET_LOAN_REQUEST_STATUS, payload: value };
};

export const setCalculatorLayout = () => {
  return { type: loanActions.SET_LOAN_LAYOUT, payload: 1 };
};

export const setLoanFormLayout = () => {
  return { type: loanActions.NEW_LOAN_FORM, payload: 11 };
};

export const setLoanReturnLayout = () => {
  return { type: loanActions.LOAN_RETURN_LAYOUT, payload: 13 };
};

export const updateLoanInfo = (loan) => (dispatch) => {
  dispatch({ type: loanActions.UPDATE_LOAN_INFO, payload: loan });
};

export const setPrevLoanTabLayout = (prevLoanLayout, prevLoanLayoutStep) => {
  return {
    type: loanActions.PREV_LOAN_TAB_LAYOUT,
    payload: { prevLoanLayout, prevLoanLayoutStep },
  };
};

export const setLoanTabLayoutStep = (layoutStep) => {
  return {
    type: loanActions.LOAN_TAB_LAYOUT_STEP,
    payload: layoutStep,
  };
};

export const getLoan = () => async (dispatch, getState) => {
  dispatch(showSpinner());
  dispatch(setLoanErrorStatus(false));
  try {
    const token = await tokenConfig(getState);
    const res = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/loan/`,
      token
    );
    if (!res.data.results.length) {
      dispatch(hideSpinner());
      dispatch(setCalculatorLayout());
      return;
    }
    //find last loan
    const loan = res.data.results.reduce((prev, current) =>
      +prev.id > +current.id ? prev : current
    );

    dispatch(setLoanLayoutStatus(loan.status));
    dispatch({ type: loanActions.GET_LOAN, payload: loan });
    dispatch(hideSpinner());
  } catch (err) {
    dispatch(hideSpinner());
    dispatch(setLoanErrorStatus(true));
  }
};

export const updateCurrentLoanState = (value) => async (dispatch) => {
  dispatch({ type: loanActions.UPDATE_CURRENT_LOAN_STATE, payload: value });
};

export const prolongTermsLoan = (id) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("PROLONG_TERMS_LOAN_PENDING", true));
    dispatch(actionCreator("PROLONG_TERMS_LOAN_ERROR", false));
    const token = await tokenConfig(getState);
    await axios.post(
      `${process.env.REACT_APP_API_SERVER}/loan/${id}/prolong_terms/`,
      id,
      token
    );
    dispatch(actionCreator("PROLONG_TERMS_LOAN_PENDING", false));
    dispatch(actionCreator("PROLONG_TERMS_LOAN_SUCCESS", true));
  } catch (err) {
    dispatch(actionCreator("PROLONG_TERMS_LOAN_PENDING", false));
    dispatch(actionCreator("PROLONG_TERMS_LOAN_ERROR", true));
  }
};

export const resetProlongTermsLoanRequestStore = (value) => async (
  dispatch
) => {
  dispatch(actionCreator("RESET_PROLONG_TERMS_LOAN_REQUEST_STATE", value));
};

export const getRemainingBalance = (loanId) => async (dispatch, getState) => {
  try {
    const token = await tokenConfig(getState);
    const currentLoanData = await getState().loan.currentLoan;
    const res = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/loan/${loanId}/check_remaining_balance/`,
      token
    );
    dispatch(
      actionCreator("UPDATE_LOAN_INFO", { ...currentLoanData, ...res.data })
    );
  } catch (err) {
    console.error("TCL: getRemainingBalance -> err", err);
  }
};
