import React, { useState } from "react";
import "./styles.scss";

import { useDispatch } from "react-redux";
import AcceptPolicyModal from "./accept-policy-modal/AcceptPolicyModal";
import RefuseLoanModal from "./refuse-loan-modal/RefuseLoanModal";
import { useTranslation } from "react-i18next";

import { getRejectReasons } from "../../../../../../../actions/loan/loan";

const GetMoneyOptions = () => {
  const { t } = useTranslation();
  const [openAcceptPolicyModal, setOpenAcceptPolicyModal] = useState(false);
  const [openRefuseLoanModal, setOpenRefuseLoanModal] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getRejectReasons());
  }, [dispatch]);

  return (
    <div className="get-money-options-wrapper">
      <div className="get-money-bths-wrapper">
        <div className="bth-wrapper get-money-bth-wrapper">
          <button
            className="bth get-money-bth"
            onClick={() => {
              setOpenAcceptPolicyModal(true);
            }}
          >
            {t("Get.Money.Now.label")}
          </button>
        </div>
        <div className="bth-wrapper refuse-loan-bth-wrapper">
          <button
            onClick={() => setOpenRefuseLoanModal(true)}
            className="bth refuse-loan-bth"
          >
            {t("Refuse.loan.label")}
          </button>
        </div>
      </div>
      <AcceptPolicyModal
        openAcceptPolicyModal={openAcceptPolicyModal}
        closeAcceptPolicyModal={() => setOpenAcceptPolicyModal(false)}
      />
      <RefuseLoanModal
        openRefuseModal={openRefuseLoanModal}
        closeRefuseModal={() => setOpenRefuseLoanModal(false)}
      />
    </div>
  );
};

export default GetMoneyOptions;
