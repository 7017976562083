import axios from "axios";

import { tokenConfig } from "../auth/auth";
import updateEmailActions from "./update-email-types";

function actionCreator(actionType, data) {
  return {
    type: updateEmailActions[actionType],
    payload: data,
  };
}

export const resetUpdateEmailRequestState = (value) => async (dispatch) => {
  dispatch(actionCreator("RESET_SEND_VERIFICATION_EMAIL_STORE", value));
};

export const sendVerificationEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("SEND_VERIFICATION_EMAIL_PENDING", true));
    dispatch(actionCreator("SEND_VERIFICATION_EMAIL_ERROR", false));
    await axios.post(
      `${process.env.REACT_APP_API_SERVER}/account/users/request_change_email/`,
      { email },
      tokenConfig(getState)
    );

    dispatch(actionCreator("SEND_VERIFICATION_EMAIL_PENDING", false));
    dispatch(actionCreator("SEND_VERIFICATION_EMAIL_SUCCESS", true));
  } catch (err) {
    if (err.response.status === 400) {
      dispatch(
        actionCreator("SEND_VERIFICATION_EMAIL_ERROR", {
          data: "User.with.given.email.exists.label",
          status: true,
        })
      );
    } else {
      dispatch(
        actionCreator("SEND_VERIFICATION_EMAIL_ERROR", {
          data: "Some.Error.label",
          status: true,
        })
      );
    }
    dispatch(actionCreator("SEND_VERIFICATION_EMAIL_PENDING", false));
  }
};

export const activateNewEmail = (token, uidb64, email) => async (
  dispatch,
  getState
) => {
  const body = JSON.stringify({ token, uidb64, email });
  try {
    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_PENDING", true));
    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_ERROR", false));
    await axios.post(
      `${process.env.REACT_APP_API_SERVER}/account/users/confirm_change_email/`,
      body,
      tokenConfig(getState)
    );

    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_PENDING", false));
    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_SUCCESS", true));
  } catch (err) {
    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_PENDING", false));
    dispatch(actionCreator("ACTIVATE_NEW_EMAIL_ERROR", true));
  }
};
