import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { sendRecoverPasswordEmail } from "../../actions/auth/auth";

import { Link } from "react-router-dom";
import arrowBackIcon from "../../assets/arrow-back-icon.svg";

import { useTranslation } from "react-i18next";

import "./styles.scss";
import Header from "../layout/Header";
import ProcessModal from "../common/process-modal/ProcessModal";

import { validateEmail } from "../../utils/regex";
import { useAnalitycs } from "../../hooks/useAnalytics";

const RecoverPassword = () => {
  useAnalitycs("RecoveryPassword");

  const [email, setEmail] = React.useState("");
  const [emailTouched, setEmailTouched] = React.useState(email);
  const [validEmail, setValidEmail] = React.useState(validateEmail(email));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openProcessModal, setOpenProcessModal] = React.useState(false);

  const {
    sendRecoverPasswordEmailPending,
    sendRecoverPasswordEmailError,
    sendRecoverPasswordEmailSuccess,
    sendRecoverPasswordEmailRequestErrorMessage,
    sendRecoverPasswordEmailRequestSuccessMessage,
  } = useSelector((state) => ({
    sendRecoverPasswordEmailPending:
      state.auth.sendRecoverPasswordEmailRequest
        .sendRecoverPasswordEmailPending,
    sendRecoverPasswordEmailError:
      state.auth.sendRecoverPasswordEmailRequest.sendRecoverPasswordEmailError,
    sendRecoverPasswordEmailSuccess:
      state.auth.sendRecoverPasswordEmailRequest
        .sendRecoverPasswordEmailSuccess,

    sendRecoverPasswordEmailRequestErrorMessage:
      state.auth.sendRecoverPasswordEmailRequestErrorMessage,
    sendRecoverPasswordEmailRequestSuccessMessage:
      state.auth.sendRecoverPasswordEmailRequestSuccessMessage,
  }));

  const onClickSubmit = React.useCallback(() => {
    setOpenProcessModal(true);
    dispatch(sendRecoverPasswordEmail(email));
  }, [dispatch, email]);

  const onInputChange = React.useCallback((e) => {
    setEmailTouched(true);
    setEmail(e.target.value);
  }, []);

  React.useEffect(() => {
    if (sendRecoverPasswordEmailSuccess) {
      setEmail("");
      setEmailTouched(false);
    }
  }, [sendRecoverPasswordEmailSuccess]);

  React.useEffect(() => {
    setValidEmail(validateEmail(email));
  }, [email]);

  const emptyEmail = React.useMemo(() => email.length === 0, [email]);

  return (
    <div className="password-recover-page-wrapper">
      <Header />
      <div className="container-width">
        <div className="form__wrapper">
          <div className="back-bth-wrapper">
            <Link to={"/login"}>
              <img src={arrowBackIcon} alt="" />
              <span className="description">{t("Back.label")}</span>
            </Link>
          </div>
          <div className="form-content-wrapper">
            <div className="form-text-wrapper">
              <div className="form-title">{t("Recovery.password.label")}</div>
              <div className="form-description">
                {t("To.recover.provide.email.Address.label")}
              </div>
            </div>
            <div className="form-inputs-wrapper">
              <form className="form">
                <fieldset className="input-group email-group">
                  <label htmlFor="email">
                    {t("To.recover.provide.email.Address.label")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={onInputChange}
                  />
                  {emptyEmail && emailTouched && (
                    <div className="invalid-email-info">
                      {t("Required.label")}
                    </div>
                  )}
                  {!validEmail && !emptyEmail && emailTouched && (
                    <div className="invalid-email-info">
                      {t("Invalid.email.label")}
                    </div>
                  )}
                </fieldset>

                <button
                  className={`submit-bth ${
                    email && validEmail ? "active" : "disabled"
                  }`}
                  type="button"
                  disabled={!email || !validEmail}
                  onClick={onClickSubmit}
                >
                  {t("Recovery.password.label")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {openProcessModal ? (
        <ProcessModal
          openModalProcess={openProcessModal}
          closeModalProcess={() => setOpenProcessModal(false)}
          requestPending={sendRecoverPasswordEmailPending}
          requestError={sendRecoverPasswordEmailError}
          requestSuccess={sendRecoverPasswordEmailSuccess}
          fnOnDestroy={() => {
            // dispatch(reseSendRecoverPasswordEmailRequestState(false));
          }}
          pendingText={`${t("Sending.Email.label")}...`}
          errorText={t(sendRecoverPasswordEmailRequestErrorMessage)}
          successText={sendRecoverPasswordEmailRequestSuccessMessage}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default RecoverPassword;
