const loanInterest = {
  7: {
    INTERVAL: [1, 7],
    INTEREST: 7,
    IVA: 13,
  },
  14: {
    INTERVAL: [8, 14],
    INTEREST: 14,
    IVA: 13,
  },
  22: {
    INTERVAL: [15, 22],
    INTEREST: 22,
    IVA: 13,
  },
  28: {
    INTERVAL: [23, 29],
    INTEREST: 29,
    IVA: 13,
  },
  35: {
    INTERVAL: [30, 34],
    INTEREST: 34,
    IVA: 13,
  },
  40: {
    INTERVAL: [35, 45],
    INTEREST: 45,
    IVA: 13,
  },
};

export default loanInterest;
