import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.scss";

const LoanStatusSuccessfullyClosed = () => {
  const { t } = useTranslation();

  const { specialNotification } = useSelector((state) => ({
    specialNotification: state.loan?.loanInfo?.specialNotification,
  }));

  return (
    <div className="loan-status-wrapper  successfully-closed-loan">
      <div className="status-indicator-wrapper">
        <span className="dot"></span>
        <span>{t("Loan.successfully.closed.label")}</span>
      </div>
      <div className="consideration-notification-wrapper">
        <div className="notification-text">{specialNotification}</div>
      </div>
    </div>
  );
};

export default LoanStatusSuccessfullyClosed;
