const type = "STATE_ADMIN_PAYMENTS";

const transferencesActions = {
  FETCH_PAYMENT_OPERATORS_PENDING: `${type}_FETCH_PAYMENT_OPERATORS_PENDING`,
  FETCH_PAYMENT_OPERATORS_ERROR: `${type}_FETCH_PAYMENT_OPERATORS_ERROR`,
  FETCH_PAYMENT_OPERATORS_SUCCESS: `${type}_FETCH_PAYMENT_OPERATORS_SUCCESS`,

  FETCH_PAYMENT_FILES_PENDING: `${type}_FETCH_PAYMENT_FILES_PENDING`,
  FETCH_PAYMENT_FILES_ERROR: `${type}_FETCH_PAYMENT_FILES_ERROR`,
  FETCH_PAYMENT_FILES_SUCCESS: `${type}_FETCH_PAYMENT_FILES_SUCCESS`,

  UPLOAD_PAYMENT_FILE_PENDING: `${type}_UPLOAD_PAYMENT_FILE_PENDING`,
  UPLOAD_PAYMENT_FILE_ERROR: `${type}_UPLOAD_PAYMENT_FILE_ERROR`,
  UPLOAD_PAYMENT_FILE_SUCCESS: `${type}_UPLOAD_PAYMENT_FILE_SUCCESS`,
};

export default transferencesActions;
