import axios from "axios";
import loanersActions from "./loaners-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: loanersActions[actionType],
    payload: data,
  };
}

export const getLoaners = ({ query = "", url } = {}) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.FETCH_CLIENTS_PENDING,
    });

    const finalURL =
      url || `${process.env.REACT_APP_API_SERVER}/admin/clients${query}`;

    const token = await tokenConfig(getState);
    const { data } = await axios.get(finalURL, token);

    dispatch({
      type: loanersActions.FETCH_CLIENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_CLIENTS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getClientById = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanersActions.FETCH_CLIENT_BY_ID_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/client-detail/${clientId}`,
      token
    );

    dispatch({
      type: loanersActions.FETCH_CLIENT_BY_ID_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_CLIENT_BY_ID_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getClientReputationById = (clientId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.FETCH_CLIENT_REPUTATION_PENDING,
    });

    const token = await tokenConfig(getState);

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/client-reputation/${clientId}`,
      token
    );

    dispatch({
      type: loanersActions.FETCH_CLIENT_REPUTATION_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_CLIENT_REPUTATION_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const changeClientReputation = ({
  clientId,
  reputation,
  observations,
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanersActions.UPDATE_CLIENT_REPUTATION_PENDING,
    });

    const token = await tokenConfig(getState);

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_SERVER}/admin/update-client-reputation/${clientId}`,
      {
        observations,
        reputation,
      },
      token
    );

    dispatch({
      type: loanersActions.UPDATE_CLIENT_REPUTATION_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.UPDATE_CLIENT_REPUTATION_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getCommentsById = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanersActions.FETCH_COMMENTS_BY_ID_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/client-record/${clientId}`,
      token
    );

    dispatch({
      type: loanersActions.FETCH_COMMENTS_BY_ID_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_COMMENTS_BY_ID_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const saveCommentForUser = ({ clientId, comment }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.FETCH_SAVE_COMMENT_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/client-record/store`,
      {
        clientId,
        comment,
      },
      token
    );

    dispatch({
      type: loanersActions.FETCH_SAVE_COMMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_SAVE_COMMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const updateCommentForUser = ({ commentId, comment }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.UPDATE_COMMENT_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_SERVER}/admin/client-record/update`,
      {
        commentId,
        comment,
      },
      token
    );

    dispatch({
      type: loanersActions.UPDATE_COMMENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.UPDATE_COMMENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getCommentsPDF = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanersActions.FETCH_COMMENTS_PDF_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/download-client-record/${clientId}`,
      token
    );

    dispatch({
      type: loanersActions.FETCH_COMMENTS_PDF_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_COMMENTS_PDF_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const blockClientById = ({ clientId, reason }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.FETCH_BLOCK_CLIENT_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/block-user-detail`,
      {
        clientId,
        reason,
      },
      token
    );

    dispatch({
      type: loanersActions.FETCH_BLOCK_CLIENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_BLOCK_CLIENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const unblockClientById = ({ clientId, reason }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.FETCH_UNBLOCK_CLIENT_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/unblock-user`,
      {
        clientId,
        reason,
      },
      token
    );

    dispatch({
      type: loanersActions.FETCH_UNBLOCK_CLIENT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.FETCH_UNBLOCK_CLIENT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const changeLoanLimit = ({
  clientId,
  oldLimit,
  newLimit,
  changeReason,
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: loanersActions.CHANGE_LOAN_CREDIT_LIMIT_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/change-credit-limit`,
      {
        clientId,
        oldLimit,
        newLimit,
        reason: changeReason,
      },
      token
    );

    dispatch({
      type: loanersActions.CHANGE_LOAN_CREDIT_LIMIT_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.CHANGE_LOAN_CREDIT_LIMIT_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const removeSuspension = ({ clientId, reason }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: loanersActions.REMOVE_USER_SUSPENSION_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/unsuspend-user`,
      {
        clientId,
        reason,
      },
      token
    );

    dispatch({
      type: loanersActions.REMOVE_USER_SUSPENSION_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: loanersActions.REMOVE_USER_SUSPENSION_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const editUserInfo = (userData) => (dispatch, getState) => {
  dispatch({
    type: loanersActions.UPDATE_USER_DATA_PENDING,
  });

  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/admin/edit-client`,
      {
        clientId: userData.clientId,
        name: userData.firstName,
        surname: userData.lastName,
        gender: userData.gender,
        birthDate: userData.birthDate,
        dni: userData.dniNumber,
        phoneNumber: userData.phoneNumber,
        street: userData.street,
        streetNumber: userData.exteriorNumber,
        municipality: userData.city,
        suburb: userData.colonia,
        department: userData.department,
        jobType: userData.typeOfEmployment,
        companyName: userData.companyName,
        companyPhone: userData.companyPhone,
        netIncome: userData.netIncome,
        additionalIncome: userData.additionalIncome,
        otherLoan: userData.otherLoans,
        editReason: userData.reason,
      },
      tokenConfig(getState)
    )
    .then(({ data }) => {
      dispatch({
        type: loanersActions.UPDATE_USER_DATA_SUCCESS,
        payload: data?.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: loanersActions.UPDATE_USER_DATA_ERROR,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const getLoanerLoans = (loanerId) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("GET_LOANER_LOANS_PENDING", true));
    dispatch(actionCreator("GET_LOANER_LOANS_ERROR", false));
    const token = await tokenConfig(getState);
    const loanersLoansData = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/loan/${loanerId}/get_user_loan_list/`,
      token
    );

    dispatch(actionCreator("GET_LOANER_LOANS_PENDING", false));
    dispatch(actionCreator("GET_LOANER_LOANS_SUCCESS", loanersLoansData.data));
  } catch (err) {
    dispatch(actionCreator("GET_LOANER_LOANS_PENDING", false));
    dispatch(actionCreator("GET_LOANER_LOANS_ERROR", true));
  }
};

export const blockLoaner = (data) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("BLOCK_LOANER_PENDING", true));
    dispatch(actionCreator("BLOCK_LOANER_ERROR", false));
    dispatch(actionCreator("BLOCK_LOANER_SUCCESS", false));
    const body = {
      block_time: data.dateBlock,
      user: data.loanerId,
      reason: data.reason,
    };
    const token = await tokenConfig(getState);
    await axios.post(
      `${process.env.REACT_APP_API_SERVER}/account/blocked-users/`,
      body,
      token
    );

    dispatch(actionCreator("BLOCK_LOANER_PENDING", false));
    dispatch(actionCreator("BLOCK_LOANER_SUCCESS", true));
  } catch (err) {
    dispatch(actionCreator("BLOCK_LOANER_PENDING", false));
    dispatch(actionCreator("BLOCK_LOANER_ERROR", true));
  }
};

export const unblockLoaner = (loanerId) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("UNBLOCK_LOANER_PENDING", true));
    dispatch(actionCreator("UNBLOCK_LOANER_ERROR", false));
    dispatch(actionCreator("UNBLOCK_LOANER_SUCCESS", false));
    const token = await tokenConfig(getState);
    await axios.delete(
      `${process.env.REACT_APP_API_SERVER}/account/blocked-users/${loanerId}/`,
      token
    );

    dispatch(actionCreator("UNBLOCK_LOANER_PENDING", false));
    dispatch(actionCreator("UNBLOCK_LOANER_SUCCESS", true));
  } catch (err) {
    dispatch(actionCreator("UNBLOCK_LOANER_PENDING", false));
    dispatch(actionCreator("UNBLOCK_LOANER_ERROR", true));
  }
};

export const getLoanerLoanHistory = (loanId) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("LOANER_LOANER_HISTORY_PENDING", true));
    dispatch(actionCreator("LOANER_LOANER_HISTORY_ERROR", false));
    const token = await tokenConfig(getState);
    const loanerLoanHistory = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/loan-history/${loanId}/get_list_loan_history/`,
      token
    );

    dispatch(actionCreator("LOANER_LOANER_HISTORY_PENDING", false));
    dispatch(
      actionCreator("LOANER_LOANER_HISTORY_SUCCESS", loanerLoanHistory.data)
    );
  } catch (err) {
    dispatch(actionCreator("LOANER_LOANER_HISTORY_PENDING", false));
    dispatch(actionCreator("LOANER_LOANER_HISTORY_ERROR", true));
  }
};
