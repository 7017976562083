import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Spinner from "../../common/spinner/Spinner";

const PrivateRoute = ({ component: Component, ...rest }: *) => {
  const [isReady, setIsReady] = React.useState(false);
  const timer = React.useRef(null);
  const { t } = useTranslation();

  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }));

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      setIsReady(true);
      clearTimeout(timer.current);
    }, 500);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  if (!isReady) {
    return (
      <div className="general-loading">
        <Spinner text={`${t("Loading.label")}...`} />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
