import { combineReducers } from "redux";
import authReducer from "./auth-reducer/auth-reducer";
import users from "./users";

import analyticsReducer from "./analytics-reducer/analytics-reducer";
import clientsTransferencesReducer from "./clients-transferences-reducer/clients-transferences-reducer";
import burgerMenuReducer from "./burger-menu-reducer/burger-menu-reducer";
import conciliationsReducer from "./conciliations-reducer/conciliations-reducer";
import contactFormReducer from "./contact-form-reducer/contact-form-reducer";
import contractsReducer from "./contracts-reducer/contracts-reducer";
import generalsReducer from "./generals-reducer/generals-reducer";
import inboxReducer from "./inbox-reducer/inbox-reducer";
import loanersReducer from "./loaners-reducer/loaners-reducer";
import loanReducer from "./loan-reducer/loan-reducer";
import loansHistoryReducer from "./loans-history-reducer/loans-history-reducer";
import notificationsReducer from "./notifications-reducer/notifications-reducer";
import paymentReducer from "./payment-reducer/payment-reducer";
import paymentsReducer from "./payments-reducer/payments-reducer";
import promocodesReducer from "./promocode-reducer/promocode-reducer";
import smsVerificationReducer from "./sms-verification-reducer/sms-verification-reducer";
import transferencesReducer from "./transferences-reducer/transferences-reducer";
import userAttachmentsReducer from "./user-attachments-reducer/user-attachments-reducer";
import verificationReducer from "./verification-reducer/verification-reducer";

export default combineReducers({
  adminPayments: paymentsReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  burgerMenu: burgerMenuReducer,
  clientsTransferences: clientsTransferencesReducer,
  conciliations: conciliationsReducer,
  contactForm: contactFormReducer,
  contracts: contractsReducer,
  generals: generalsReducer,
  inbox: inboxReducer,
  loan: loanReducer,
  loaners: loanersReducer,
  loansHistory: loansHistoryReducer,
  notifications: notificationsReducer,
  payment: paymentReducer,
  promocodes: promocodesReducer,
  smsVerification: smsVerificationReducer,
  transferences: transferencesReducer,
  userAttachments: userAttachmentsReducer,
  users,
  verification: verificationReducer,
});
