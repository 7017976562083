import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";

import "./styles.scss";
import tick2Icon from "../../../../../assets/tick2-icon.svg";
import searchIcon from "../../../../../assets/search-icon.svg";

import { useSelector } from "react-redux";

import loanStatusesColorMap from "../../../../../constants/loanStatusesColorMap";
import { getAllContracts } from "../../../../../actions/contracts/contracts";
import { useFilters } from "../../../../../hooks/useFilters";

const statuses = [
  { value: 1, title: "En consideración", color: "yellow" },
  { value: 2, title: "Foto rechazada", color: "red" },
  { value: 3, title: "Negado", color: "red" },
  { value: 4, title: "Negado con bloqueo de usuario", color: "red" },
  { value: 5, title: "Aprobado a la espera de aceptación", color: "green" },
  { value: 6, title: "Rechazado por el cliente", color: "red" },
  { value: 7, title: "Aceptado por el cliente", color: "green" },
  { value: 8, title: "Activo - Dinero transferido", color: "green" },
  { value: 9, title: "En mora ", color: "red" },
  { value: 10, title: "Pagado a la espera de ser cerrado", color: "green" },
  { value: 11, title: "Cerrado", color: "green" },
];

const CheckboxFilters = ({
  onCheckboxChange,
  usedFilters,
}: {
  onCheckboxChange: *,
  usedFilters: Array<Number>,
}) => {
  const [checkedValues, setCheckedValues] = React.useState(usedFilters);

  const onCheckFilter = React.useCallback(
    (e) => {
      const isChecked = e.target.checked;
      const value = e.target.value;
      let newValues;

      if (isChecked) {
        newValues = [...checkedValues, value];
        setCheckedValues(newValues);
      } else {
        newValues = checkedValues.filter((item) => item !== value);
        setCheckedValues(newValues);
      }

      onCheckboxChange({
        filter: "filter[status]",
        value: newValues,
      });
    },
    [checkedValues, onCheckboxChange]
  );

  return (
    <>
      {statuses.map((item) => (
        <div className="checbox-filter-item" key={item.value}>
          <input
            type="checkbox"
            id={`checbox-filter-label-${item.value}`}
            value={item.value}
            defaultChecked={checkedValues.includes(item.value.toString())}
            onChange={onCheckFilter}
          />
          <label htmlFor={`checbox-filter-label-${item.value}`}>
            <span className="indicator-wrapper">
              <span
                className="indicator"
                style={{
                  background: loanStatusesColorMap.get(item.color),
                }}
              ></span>
            </span>
            <span>{item.title}</span>
          </label>
        </div>
      ))}
    </>
  );
};

const ContractsHeader = () => {
  const [showCheckboxFilters, setShowCheckboxFilters] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState({
    "page[size]": 25,
    "page[number]": 1,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [usedFilters, setUsedFilters] = React.useState([]);
  const timeOut = React.useRef(null);

  const { pagination } = useSelector((state) => ({
    pagination: state.contracts.contractsPagination,
  }));

  useFilters({
    activeFilters,
    action: getAllContracts,
  });

  const onChangeFilter = React.useCallback(
    ({ filter, value }) => {
      const resetFilters = {};

      if (filter !== "page[number]") {
        resetFilters["page[number]"] = 1;
      }

      setActiveFilters({
        ...activeFilters,
        ...resetFilters,
        [filter]: value,
      });
    },
    [activeFilters]
  );

  const onCheckboxChange = React.useCallback(
    ({ filter, value }) => {
      setUsedFilters(value);

      onChangeFilter({ filter, value: value.length ? value.join(",") : null });
    },
    [onChangeFilter]
  );

  const onRefinancedChecked = React.useCallback(
    (e) => {
      onChangeFilter({
        filter: "filter[refinancing]",
        value: e.target.checked,
      });
    },
    [onChangeFilter]
  );

  const onTypeSearch = React.useCallback(
    (e) => {
      const value = e.target.value;
      setSearchValue(value);

      clearTimeout(timeOut.current);

      timeOut.current = setTimeout(() => {
        onChangeFilter({
          filter: "filter[search]",
          value,
        });
      }, 1000);
    },
    [onChangeFilter]
  );

  const onPaginationChanged = React.useCallback(
    (e, value) => {
      onChangeFilter({
        filter: "page[number]",
        value,
      });
    },
    [onChangeFilter]
  );

  React.useEffect(() => {
    function handleClickOutside(event) {
      const checboxFiltersWrapper = event.target.closest(
        ".checbox-filters-wrapper"
      );
      const filterBthStatus = event.target.closest(".filter-bth-status");
      if (!checboxFiltersWrapper && !filterBthStatus) {
        setShowCheckboxFilters(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { t } = useTranslation();
  return (
    <div className="contracts-header-wrapper">
      <div className="contracts-title-wrapper">{t("Contracts.label")}</div>
      <div className="contracts-filter-container">
        <div className="contracts-table-filters-wrapper">
          <div className="contracts-table-filter-item-wrapper status-filter">
            <span
              className="filter-bth-status"
              onClick={() => setShowCheckboxFilters((state) => !state)}
            >
              <span>{t("Status.label")} </span>
              <img src={tick2Icon} className="sort-icon" alt="Sort" />
            </span>

            {showCheckboxFilters && (
              <div className="checbox-filters-wrapper">
                <CheckboxFilters
                  usedFilters={usedFilters}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
            )}
          </div>
          <div className="contracts-table-filter-item-wrapper search-filter">
            <img src={searchIcon} alt="Search" className="search-icon" />
            <input
              value={searchValue}
              type="text"
              placeholder={t("Search.label")}
              className={`search-input`}
              onChange={onTypeSearch}
            />
          </div>
        </div>
        <div className="checbox-filter-item header">
          <input type="checkbox" onChange={onRefinancedChecked} />
          <label htmlFor="checbox-filter-label-refinanced">
            <span>{t("Admin.only.refinanced.loans")}</span>
          </label>
        </div>
      </div>

      <div className="pagination-wrapper">
        <Pagination
          count={pagination?.last_page}
          defaultPage={1}
          variant="outlined"
          color="secondary"
          onChange={onPaginationChanged}
        />
      </div>
    </div>
  );
};

export default ContractsHeader;
