const type = "STATE_VERIFICATION";

const verificationActions = {
  SET_PERSONAL_INFO: `${type}_SET_PERSONAL_INFO`,
  SET_ADDRESS_INFO: `${type}_SET_ADDRESS_INFO`,
  SET_ADDITIONAL_INFO: `${type}_SET_ADDITIONAL_INFO`,
  SET_PHOTO_FILES_INFO: `${type}_SET_PHOTO_FILES_INFO`,
};

export default verificationActions;
