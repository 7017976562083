import contractsActions from "../../actions/contracts/contracts-types";

const initialState = {
  searchString: "",

  statusCodeFilters: [],

  contracts: [],
  contractsPagination: null,
  contractsRequest: {
    contractsRequestPending: false,
    contractsRequestError: false,
    contractsRequestSuccess: false,
  },

  contractDetail: null,
  contractDetailRequest: {
    contractDetailRequestPending: false,
    contractDetailRequestError: false,
    contractDetailRequestSuccess: false,
  },
};

function contractsReducer(state = initialState, action) {
  switch (action.type) {
    case contractsActions.FETCH_LOANS_PENDING:
      return {
        ...state,
        contracts: [],
        contractsRequest: {
          contractsRequestPending: true,
          contractsRequestError: false,
          contractsRequestSuccess: false,
        },
      };
    case contractsActions.FETCH_LOANS_ERROR:
      return {
        ...state,
        contracts: [],
        contractsPagination: null,
        contractsRequest: {
          contractsRequestPending: false,
          contractsRequestError: true,
          contractsRequestSuccess: false,
        },
      };
    case contractsActions.FETCH_LOANS_SUCCESS:
      return {
        ...state,
        contracts: action.payload.data,
        contractsPagination: action.payload.meta,
        contractsRequest: {
          contractsRequestPending: false,
          contractsRequestError: false,
          contractsRequestSuccess: true,
        },
      };
    case contractsActions.FETCH_LOAN_BY_ID_PENDING:
      return {
        ...state,
        contractDetail: null,
        contractDetailRequest: {
          contractDetailRequestPending: true,
          contractDetailRequestError: false,
          contractDetailRequestSuccess: false,
        },
      };
    case contractsActions.FETCH_LOAN_BY_ID_ERROR:
      return {
        ...state,
        contractDetail: action.payload,
        contractDetailRequest: {
          contractDetailRequestPending: false,
          contractDetailRequestError: true,
          contractDetailRequestSuccess: false,
        },
      };
    case contractsActions.FETCH_LOAN_BY_ID_SUCCESS:
      return {
        ...state,
        contractDetail: action.payload,
        contractDetailRequest: {
          contractDetailRequestPending: false,
          contractDetailRequestError: false,
          contractDetailRequestSuccess: true,
        },
      };

    default:
      return state;
  }
}

export default contractsReducer;
