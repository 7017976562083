import React from "react";
import "./styles.scss";

import LoanTabPanelLayout from "./loan-tab-panel/LoanTabPanelLayout";
import LoanHistoryTabLayout from "./loan-history-tab-panel/LoanHistoryTabLayout";
import PromocodeTab from "./promocode-tab-panel/PromocodeTab";
import ProfileSettingsTab from "./profile-settings-tab-panel/ProfileSettingsTab";
import HelpTab from "./help-tab-panel/HelpTab";

import myLoanTabIcon from "../../../assets/my-loan-tab-icon.svg";
import loanHistoryTabIcon from "../../../assets/loan-history-tab-icon.svg";
import promocodeTabIcon from "../../../assets/promocode-tab-icon.svg";
import profilSettingsTabIcon from "../../../assets/profile-settings-tab-icon.svg";
import helpTabIcon from "../../../assets/help-tab-icon.svg";
import border from "../../../assets/border.svg";

import { setBurgetMenuState } from "../../../actions/burger-menu/burger-menu";
import { setLoanTabLayout } from "../../../actions/loan/loan";
import loanLayout from "../../../constants/loanLayout";

import emailIcon from "../../../assets/email-icon.svg";

import ContactUs from "../../../components/account/forms/modals/contact-us/ContactUs";

import { useDispatch, useSelector } from "react-redux";

import UserHeaderInfo from "../user-profile-header/user-header-info/UserHeaderInfo";
import { useTranslation } from "react-i18next";

const GetTabPanels = () => {
  const { t } = useTranslation();

  return [
    {
      titleTab: t("My.Loan.label"),
      srcIconTab: myLoanTabIcon,
      tabPanel: <LoanTabPanelLayout />,
    },
    {
      titleTab: t("Loan.History.label"),
      srcIconTab: loanHistoryTabIcon,
      tabPanel: <LoanHistoryTabLayout />,
    },
    {
      titleTab: t("Promocode.label"),
      srcIconTab: promocodeTabIcon,
      tabPanel: <PromocodeTab />,
    },
    {
      titleTab: t("Profile.Settings.label"),
      srcIconTab: profilSettingsTabIcon,
      tabPanel: <ProfileSettingsTab />,
    },
    {
      titleTab: t("Help.label"),
      srcIconTab: helpTabIcon,
      tabPanel: <HelpTab />,
    },
  ];
};

// eslint-disable-next-line no-unused-vars
const TabPanel = ({ activeTabPanel, setActiveTabPanel }) => {
  switch (activeTabPanel) {
    // 1 - Loan
    case 1:
      return (
        <div className="tab-panel-wrapper">
          <LoanTabPanelLayout />
        </div>
      );
    // 1 - Loans History
    case 2:
      return (
        <div className="tab-panel-wrapper">
          <LoanHistoryTabLayout setActiveTabPanel={setActiveTabPanel} />
        </div>
      );
    // 3 - Promocode
    case 3:
      return (
        <div className="tab-panel-wrapper">
          <PromocodeTab />
        </div>
      );
    // 4 - Profile setting
    case 4:
      return (
        <div className="tab-panel-wrapper">
          <ProfileSettingsTab />
        </div>
      );
    // 5 - Profile setting
    case 5:
      return (
        <div className="tab-panel-wrapper">
          <HelpTab />
        </div>
      );
    default:
      return "";
  }
};

const VerticalTabs = () => {
  const dispatch = useDispatch();

  const [contactUsActive, setContactUsActive] = React.useState(false);
  const [activeTabPanel, setActiveTabPanel] = React.useState(1);

  const { burgerMenuState } = useSelector((state) => ({
    burgerMenuState: state.burgerMenu.activeBurgerMenu,
  }));

  const onClickHandler = React.useCallback(
    (value) => {
      setActiveTabPanel(value + 1);

      if (value === 0) {
        dispatch(setLoanTabLayout(loanLayout.LOAN_PROCCESS));
      }

      dispatch(setBurgetMenuState(false));
    },
    [dispatch]
  );

  const { t } = useTranslation();
  return (
    <div className="tabs-wrapper">
      <div className={`drawer-container ${burgerMenuState ? "open" : ""}`}>
        <div
          className={`left-block-wrapper-nav ${burgerMenuState ? "open" : ""}`}
        >
          <div className="navigation-tabs-wrapper">
            {GetTabPanels().map((tabPanel, i) => (
              <div
                key={i}
                className={`navigation-tab-item ${
                  activeTabPanel === i + 1 ? "active" : "disabled"
                }`}
                onClick={() => onClickHandler(i)}
              >
                <img
                  className={`${
                    activeTabPanel === i + 1 ? "activeBorder" : "disabledBorder"
                  }`}
                  src={border}
                />
                <img
                  className="navigation-tab-item-icon"
                  src={tabPanel.srcIconTab}
                />
                <span className="navigation-tab-item-title">
                  {tabPanel.titleTab}
                </span>
              </div>
            ))}
          </div>

          <div className="bth-wrapper bth-contact-us-wrapper">
            <button
              className="bth-contact-us"
              type="button"
              onClick={() => {
                dispatch(setBurgetMenuState(false));
                setContactUsActive(true);
              }}
            >
              <img src={emailIcon} alt="" />
              <span>{t("Contact.Us.label")}</span>
            </button>
          </div>
          <div className="user-header-info-wrapper">
            <UserHeaderInfo />
          </div>
        </div>
      </div>

      <div className="tab-panels-wrapper">
        <TabPanel
          activeTabPanel={activeTabPanel}
          setActiveTabPanel={(value) => setActiveTabPanel(value)}
        />
      </div>

      {contactUsActive && (
        <ContactUs
          openContactForm={contactUsActive}
          closeContactForm={() => setContactUsActive(false)}
        />
      )}
    </div>
  );
};

export default VerticalTabs;
