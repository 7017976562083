import React from "react";
import "./styles.scss";

import whatsappLogo from "../../../assets/whatsapp.png";

const FloatingButton = () => {
  return (
    <div className="button-wrapper">
      <a
        href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}`}
        rel="noreferrer"
        target="_blank"
      >
        <img src={whatsappLogo} alt="WhatsApp Pisto" />
      </a>
    </div>
  );
};

export default FloatingButton;
