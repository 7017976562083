import React from "react";
import "./styles.scss";

import daggerIcon from "../../../../../../../assets/dagger-icon.svg";
import loanStatuses from "../../../../../../../constants/loanStatuses";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateByFormat } from "../../../../../../../utils/parser";

const LoanStatusBlocked = () => {
  const { t } = useTranslation();

  const { lockDate, loanStatus } = useSelector((state) => ({
    lockDate: state.loan?.loanInfo?.lockDate,
    loanStatus: state.loan?.loanInfo?.loanStatus,
  }));

  return (
    <div className="loan-status-wrapper">
      <div className="status-indicator-wrapper blocked-loan">
        <div className="header-content">
          <span className="dagger-wrapper">
            <img src={daggerIcon} alt="" />
          </span>
          <span className="title-status">Usuario Bloqueado</span>
        </div>
        <div className="message">
          {`${t("Blocked.message-1")} ${getDateByFormat({
            date: lockDate,
          })}, ${t("Blocked.message-2")}`}
        </div>

        {[loanStatuses.ACTIVE, loanStatuses.OVERDUE].includes(loanStatus) && (
          <div className="aditional-message">{t("Blocked.message-3")}</div>
        )}
      </div>
    </div>
  );
};

export default LoanStatusBlocked;
