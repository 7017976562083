import React from "react";
import { useTranslation } from "react-i18next";
import arrowBackIcon from "../../../../../../assets/arrow-back-icon.svg";
import { getHistoryLoanById } from "../../../../../../actions/loan-history/loan-history";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoanContract,
  getLoanRequest,
} from "../../../../../../actions/loan/loan";
import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";
import DownloadLoanPdfs from "../../download-loan-pdfs/DownloadLoanPdfs";

const LoanHistoryItem = ({ setActiveLayout, electLoan }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    currentLoanHistory,
    loansHistoryItemRequestPending,
    loansHistoryItemRequestError,
  } = useSelector((state) => ({
    currentLoanHistory: state.loansHistory.currentLoanHistory,
    loansHistoryItemRequestPending:
      state.loansHistory.loansHistoryItemRequest.loansHistoryItemRequestPending,
    loansHistoryItemRequestError:
      state.loansHistory.loansHistoryItemRequest.loansHistoryItemRequestError,
  }));

  React.useEffect(() => {
    dispatch(getHistoryLoanById(electLoan.loanId));
    dispatch(getLoanContract(electLoan.loanId));
    dispatch(getLoanRequest(electLoan.loanId));
  }, [dispatch, electLoan?.loanId]);

  if (loansHistoryItemRequestPending) {
    return (
      <div className="loans-history-table-item-mobile-version-wrapper">
        <div className="back-bth" onClick={() => setActiveLayout(1)}>
          <img src={arrowBackIcon} alt="" />
          <span className="text">{t("Back.label")}</span>
        </div>
        <Spinner text={t("Loan.Data.Loading.label")} />
      </div>
    );
  }

  if (loansHistoryItemRequestError) {
    return (
      <div className="loans-history-table-item-mobile-version-wrapper">
        <div className="back-bth" onClick={() => setActiveLayout(1)}>
          <img src={arrowBackIcon} alt="" />
          <span className="text">{t("Back.label")}</span>
        </div>
        <ErrorMessage text={t("Some.Error.label")} />
      </div>
    );
  }

  return (
    <div className="loans-history-table-item-mobile-version-wrapper">
      <div className="back-bth" onClick={() => setActiveLayout(1)}>
        <img src={arrowBackIcon} alt="" />
        <span className="text">{t("Back.label")}</span>
      </div>

      <div className="table-wrapper">
        {currentLoanHistory.map((loan, i) => (
          <div className="table-item-wrapper" key={i}>
            <div className="table-row-wrapper header">
              <div className="title">
                {getDateByFormat({ date: loan.date })}
              </div>
            </div>
            <div className="table-row-wrapper">
              <div className="value">{loan.operation}</div>
              <div className="value">
                {loan.amount ? formatPrice(loan.amount) : "-"}
              </div>
              <div className="value">
                {loan.balance ? formatPrice(loan.balance) : "-"}
              </div>
            </div>
          </div>
        ))}
      </div>

      <DownloadLoanPdfs />
    </div>
  );
};

export default LoanHistoryItem;
