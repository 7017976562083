import React from "react";
import "./styles.scss";

function SuccessMessage({ text }: *) {
  return (
    <div className="success-wrapper">
      <div className="success-text">{text}</div>
    </div>
  );
}

export default SuccessMessage;
