import axios from "axios";

import { tokenConfig } from "../auth/auth";
import promocodesActions from "./promocodes-types";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: promocodesActions[actionType],
    payload: data,
  };
}

export const setInputPromocodeValue = (value) => (dispatch) => {
  dispatch(actionCreator("SET_INPUT_PROMOCODE_VALUE", value));
};

export const resetPromocodeStore = () => (dispatch) => {
  dispatch({
    type: promocodesActions.RESET_PROMOCODE_STORE,
  });
};

export const getUserPromocode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: promocodesActions.FETCH_PROMOCODE_INFO_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/promo-code`,
      token
    );

    dispatch({
      type: promocodesActions.FETCH_PROMOCODE_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: promocodesActions.FETCH_PROMOCODE_INFO_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getInputPromocode = (promoCode) => async (dispatch, getState) => {
  try {
    dispatch({
      type: promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/validate-promo-code`,
      {
        promoCode,
      },
      token
    );

    dispatch({
      type: promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: promocodesActions.FETCH_CHECK_INPUT_PROMOCODE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
