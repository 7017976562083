import React from "react";

import "./styles.scss";

import adminNavigationTabs from "../../../constants/adminNavigationTabs";

import InboxLayout from "./inbox-layout/InboxLayout";
import ContractsLayout from "./contracts-layout/ContractsLayout";
import TransferencesLayout from "./transferences-layout/TransferencesLayout";
import LoanersLayout from "./loaners-layout/LoanersLayout";
import DashboardLayout from "./dashboard-layout/DashboardLayout";
import BalanceLayout from "./balance-layout/BalanceLayout";
import PaymentsLayout from "./payments-layout/PaymentsLayout";
import ConciliationLayout from "./conciliation-layout/ConciliationLayout";

function PannelLayout({ activeTab }) {
  switch (activeTab) {
    // 1 - INBOX
    case adminNavigationTabs.INBOX:
      return <InboxLayout />;
    // 2 - DASHBOARD
    case adminNavigationTabs.DASHBOARD:
      return <DashboardLayout />;
    // 3 - CONTRACTS
    case adminNavigationTabs.CONTRACTS:
      return <ContractsLayout />;
    // 4 - TRANSFERENCES FROM CLIENTS
    case adminNavigationTabs.TRANSFERENCES:
      return <TransferencesLayout />;
    // 5 - LOANERS
    case adminNavigationTabs.LOANERS:
      return <LoanersLayout />;
    // 6 - TRANSFERENCES TO CLIENTS
    case adminNavigationTabs.BALANCE:
      return <BalanceLayout />;
    // 7 - PAYMENTS
    case adminNavigationTabs.PAYMENTS:
      return <PaymentsLayout />;
    // 8 - CONCILIATION
    case adminNavigationTabs.CONCILIATION:
      return <ConciliationLayout />;
    default:
      return "";
  }
}

export default PannelLayout;
