import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import SuccessMessage from "../../../../../common/success-message/SuccessMessage";
import { createLoan } from "../../../../../../actions/loan/loan";
import { useTranslation } from "react-i18next";

const HandlePesponseSwitch = ({
  pending,
  error,
  success,
  pendingText,
  errorText,
  successText,
}: *) => {
  return (
    <>
      {pending ? <Spinner text={pendingText} /> : ""}
      {error ? <ErrorMessage text={errorText} /> : ""}
      {success ? <SuccessMessage text={successText} /> : ""}
    </>
  );
};

const ActivationSuccess = ({ prevStep }: *) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    verificationFormFilesPending,
    verificationFormFilesError,
    verificationFormFilesSuccess,
    verificationFormFilesMessage,

    loanSuccessMessage,
    loanErrorMessage,
    newLoanRequestPending,
    newLoanRequestError,
    newLoanRequestSuccess,
  } = useSelector((state) => ({
    verificationFormFilesPending:
      state.userAttachments.verificationFormFiles.verificationFormFilesPending,
    verificationFormFilesError:
      state.userAttachments.verificationFormFiles.verificationFormFilesError,
    verificationFormFilesSuccess:
      state.userAttachments.verificationFormFiles.verificationFormFilesSuccess,
    verificationFormFilesMessage:
      state.userAttachments.verificationFormFilesMessage,

    newLoan: state.loan.newLoan,

    loanSuccessMessage: state.loan.newLoanRequest.message,
    loanErrorMessage: state.loan.newLoanRequest.error,
    newLoanRequestPending: state.loan.newLoanRequest.newLoanRequestPending,
    newLoanRequestError: state.loan.newLoanRequest.newLoanRequestError,
    newLoanRequestSuccess: state.loan.newLoanRequest.newLoanRequestSuccess,

    userId: state.auth.user.id,

    srcUserPhotos: state.userAttachments.verificationFormFiles.srcUserPhotos,

    verificationData: state.verification,
  }));

  React.useEffect(() => {
    if (verificationFormFilesSuccess) {
      dispatch(createLoan());
    }
  }, [verificationFormFilesSuccess, dispatch]);

  return (
    <div className="activation-success-wrapper">
      <div className="content-wrapper">
        <HandlePesponseSwitch
          pending={verificationFormFilesPending}
          error={verificationFormFilesError}
          success={verificationFormFilesSuccess}
          pendingText={`${t("Sending.your.photo.label")}...`}
          errorText={verificationFormFilesMessage}
          successText={t("Your.photo.successfully.submitted.label")}
        />

        <HandlePesponseSwitch
          pending={newLoanRequestPending}
          error={newLoanRequestError}
          success={newLoanRequestSuccess}
          pendingText={`${t("Creating.loan.label")}...`}
          errorText={loanErrorMessage}
          successText={loanSuccessMessage}
        />
        {verificationFormFilesError || newLoanRequestError ? (
          <div className="bth-wrapper prev-step-bth-wrapper">
            <button
              type="button"
              className="bth prev-step-bth"
              onClick={(e) => {
                e.preventDefault();
                prevStep();
              }}
            >
              {t("Try.again.label")}
            </button>
          </div>
        ) : (
          ""
        )}
        {verificationFormFilesSuccess && newLoanRequestSuccess ? (
          <div className="bth-wrapper login-bth-wrapper">
            <button
              type="button"
              className="bth login-bth"
              onClick={() => history.push("/login")}
            >
              {t("To.Loan.label")}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ActivationSuccess;
