import React from "react";
import "./styles.scss";
import { Dialog, DialogContent } from "@material-ui/core";
import closeIcon from "../../../assets/close-icon.svg";
import arrowIcon from "../../../assets/arrow-back-icon.svg";

function ImgModal({ imgSrc, closeModalImg, openModalImg, images }: *) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    if (images.length) {
      setCurrentIndex(images.findIndex((item) => item.src === imgSrc));
    }
  }, [images, imgSrc]);

  const onLeftClick = React.useCallback(() => {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
      return;
    }

    setCurrentIndex(currentIndex - 1);
  }, [currentIndex, setCurrentIndex, images?.length]);

  const onRightClick = React.useCallback(() => {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
      return;
    }

    setCurrentIndex(currentIndex + 1);
  }, [currentIndex, setCurrentIndex, images?.length]);

  return (
    <Dialog
      onClose={closeModalImg}
      open={openModalImg}
      className="modal-img-wrapper"
    >
      <DialogContent className="modal-img-content">
        <div className="arrow-button" onClick={onLeftClick}>
          <img src={arrowIcon} alt="" />
        </div>

        <div className="close-icon-wrapper" onClick={closeModalImg}>
          <img src={closeIcon} alt="" />
        </div>
        <img
          src={images?.[currentIndex]?.src}
          alt={images?.[currentIndex]?.alt}
          className="modal-img"
        />

        <div className="arrow-button rigth-button" onClick={onRightClick}>
          <img src={arrowIcon} alt="" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ImgModal;
