import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../assets/close-icon.svg";
import pencilIcon2 from "../../../../../../assets/pencil-icon2.svg";
import lockIcon from "../../../../../../assets/lock-svgrepo-com.svg";
import unlockIcon from "../../../../../../assets/unlock-svgrepo-com.svg";
import dolarIcon from "../../../../../../assets/dolar-icon.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";
import ChangeLimit from "./change-limit/ChangeLimit";
import EditUser from "./edit-user/EditUser";
import HistoricalUser from "./historical-user/HistoricalUser";
import ContractItem from "../../../contracts-layout/contracts-list/contract-item/ContractItem";

import gender from "../../../../../../constants/gender";
import loanStatusesColorMap from "../../../../../../constants/loanStatusesColorMap";

import { useDispatch, useSelector } from "react-redux";

import {
  removeSuspension,
  getClientById,
  blockClientById,
  unblockClientById,
  changeLoanLimit,
  editUserInfo,
  saveCommentForUser,
  updateCommentForUser,
} from "../../../../../../actions/loaners/loaners";

import { getLimitValues } from "../../../../../../actions/generals/generals";

import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

import ModalReputation from "../../../../../common/modal-reputation/ModalReputation";
import ImgModal from "../../../../../common/img-modal/ImgModal";
import { typesWithCompanyInfo } from "../../../../../../constants/jobTypes";
import userStatuses from "../../../../../../constants/userStatuses";
import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";

const LoansTable = ({ loanerLoans, onShowLoan }: *) => {
  const { t } = useTranslation();

  return (
    <div className="loans-history-content-wrapper">
      <div className="loans-history-table-header">
        <div className="loans-history-table-title">
          {t("Loans.History.label")}
        </div>
      </div>

      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="loans-history-table-head">
            <TableRow>
              <TableCell className="table-head-cell">№</TableCell>
              <TableCell className="table-head-cell">
                {t("Date.of.issue.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Status.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Return.Date.lable")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Refinanced.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Balance.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="loans-history-table-body">
            {!loanerLoans.length ? (
              <div className="loans-history-notification">
                {t("Data.Empty.label")}
              </div>
            ) : (
              <>
                {loanerLoans.map((loan, i) => (
                  <TableRow
                    onClick={() => onShowLoan(loan)}
                    className="loans-history-body-row"
                    key={i}
                  >
                    <TableCell className="table-body-cell">
                      {loan.contractNumber}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: loan.date })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.amount)}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.status}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.returnDate
                        ? getDateByFormat({ date: loan.returnDate })
                        : ""}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.refinancing}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.balance)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const LoanerItem = ({
  openLoanerItem,
  closeLoanerItem,
  loanerInfo,
  allowLoanDetail = false,
}: *) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showHistoricalModal, setShowHistoricalModal] = React.useState(false);
  const [showModalProccess, setShowModalProccess] = React.useState(false);
  const [openModalImg, setActiveModalImg] = React.useState(false);
  const [currentModalImg, setCurrentModalImg] = React.useState({});
  const [reasonDescription, setReasonDescription] = React.useState("");
  const [limitValue, setLimitValue] = React.useState(null);
  const [commentID, setCommentID] = React.useState(null);
  const [editUserValues, setEditUserValues] = React.useState({});
  const [showVerificationModal, setShowVerificationModal] = React.useState(
    false
  );
  const [confirmationAction, setConfirmationAction] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationSubTitle, setConfirmationSubTitle] = React.useState("");
  const [showModifyLimitModal, setShowModifyLimitModal] = React.useState(false);
  const [showReputationModal, setShowReputationModal] = React.useState(false);

  const [showLoanDetail, setShowLoanDetail] = React.useState(false);
  const [selectedLoan, setSelectedLoan] = React.useState(false);

  const {
    clientInfo,
    clientInfoRequestPending,
    clientInfoRequestError,
    clientUpdate,
    clientUpdateRequestPending,
    clientUpdateRequestError,
    clientUpdateRequestSuccess,
  } = useSelector((state) => ({
    clientInfo: state.loaners.loanerDetails,
    clientInfoRequestPending:
      state.loaners.loanerDetailsRequest.loanerDetailsRequestPending,
    clientInfoRequestError:
      state.loaners.loanerDetailsRequest.loanerDetailsRequestError,

    clientUpdate: state.loaners.loanerUpdate,
    clientUpdateRequestPending:
      state.loaners.loanerUpdateRequest.loanerUpdateRequestPending,
    clientUpdateRequestError:
      state.loaners.loanerUpdateRequest.loanerUpdateRequestError,
    clientUpdateRequestSuccess:
      state.loaners.loanerUpdateRequest.loanerUpdateRequestSuccess,
  }));

  React.useEffect(() => {
    dispatch(getClientById(loanerInfo?.clientId));
  }, [dispatch, loanerInfo?.clientId]);

  React.useEffect(() => {
    dispatch(getLimitValues());
  }, [dispatch]);

  const isBlocked = React.useMemo(() => {
    return clientInfo?.status === userStatuses.BLOCKED;
  }, [clientInfo?.status]);

  const isSuspended = React.useMemo(() => {
    return clientInfo?.status === userStatuses.SUSPENDED;
  }, [clientInfo?.status]);

  const onShowProccessClosed = React.useCallback(() => {
    setShowModalProccess(false);
    setShowModifyLimitModal(false);

    if (clientUpdateRequestSuccess) {
      dispatch(getClientById(loanerInfo?.clientId));
    }
  }, [clientUpdateRequestSuccess, loanerInfo?.clientId, dispatch]);

  const modalUserNameTitle = React.useMemo(
    () =>
      clientInfo?.name
        ? `${clientInfo?.name} ${clientInfo?.surname}`
        : clientInfo?.email,
    [clientInfo]
  );

  const onBlockUser = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription("");

    setConfirmationTitle(
      `${t(
        "Loan.rejection.and.block.user.message.title.1"
      )} ${modalUserNameTitle}`
    );

    setConfirmationSubTitle(
      t("Loan.rejection.and.block.user.message.title.4-2")
    );

    setConfirmationAction("block");
  }, [modalUserNameTitle, t]);

  const onUnblockUser = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription("");

    setConfirmationTitle(
      `${t("Admin.unblocking.user.message.title.1")} ${modalUserNameTitle}${t(
        "Admin.unblocking.user.message.title.2"
      )}`
    );

    setConfirmationSubTitle(t("Admin.unblocking.user.message.title.3"));

    setConfirmationAction("unblock");
  }, [modalUserNameTitle, t]);

  const onRemoveUserSuspension = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription("");

    setConfirmationTitle(
      `${t("Remove.user.suspension.message.title.1")} ${modalUserNameTitle}`
    );

    setConfirmationSubTitle(t("Remove.user.suspension.message.title.2"));

    setConfirmationAction("removeSuspension");
  }, [modalUserNameTitle, t]);

  const onModifyLimitConfirmation = React.useCallback(() => {
    setShowVerificationModal(true);

    setConfirmationTitle(
      `${t("Modify.loan.limit.title.1")} ${modalUserNameTitle}`
    );

    setConfirmationSubTitle(t("Modify.loan.limit.title.2"));

    setConfirmationAction("modifyLimit");
  }, [modalUserNameTitle, t]);

  const onUpdateUser = React.useCallback(
    (values) => {
      setEditUserValues(values);
      setShowVerificationModal(true);
      setReasonDescription("");

      setConfirmationTitle(
        `${t("Update.user.message.title.1")} ${modalUserNameTitle}`
      );

      setConfirmationSubTitle(t("Update.user.message.title.2"));

      setConfirmationAction("updateUser");
    },
    [modalUserNameTitle, t]
  );

  const onCommentInfoArrived = React.useCallback(
    ({ commentId, comment, action, title }) => {
      setShowVerificationModal(true);
      setReasonDescription(comment);
      setCommentID(commentId);

      setConfirmationTitle(`${title} ${modalUserNameTitle}`);

      setConfirmationSubTitle(comment);

      setConfirmationAction(action);
    },
    [modalUserNameTitle]
  );

  const closeModal = React.useCallback(() => {
    setShowVerificationModal(false);
  }, []);

  const onPressAccept = React.useCallback(() => {
    setShowVerificationModal(false);
    setShowModalProccess(true);

    switch (confirmationAction) {
      case "block":
        dispatch(
          blockClientById({
            clientId: loanerInfo?.clientId,
            reason: reasonDescription,
          })
        );
        break;
      case "unblock":
        dispatch(
          unblockClientById({
            clientId: loanerInfo?.clientId,
            reason: reasonDescription,
          })
        );
        break;
      case "modifyLimit":
        dispatch(
          changeLoanLimit({
            clientId: loanerInfo?.clientId,
            changeReason: reasonDescription,
            newLimit: limitValue,
            oldLimit: clientInfo?.creditLimit,
          })
        );
        break;
      case "removeSuspension":
        dispatch(
          removeSuspension({
            clientId: loanerInfo?.clientId,
            reason: reasonDescription,
          })
        );
        break;
      case "updateUser":
        dispatch(
          editUserInfo({
            clientId: loanerInfo?.clientId,
            reason: reasonDescription,
            ...editUserValues,
          })
        );
        break;
      case "addComment":
        dispatch(
          saveCommentForUser({
            clientId: loanerInfo?.clientId,
            comment: reasonDescription,
          })
        );
        break;
      case "updateComment":
        dispatch(
          updateCommentForUser({
            clientId: loanerInfo?.clientId,
            commentId: commentID,
            comment: reasonDescription,
          })
        );
        break;
      default:
        return null;
    }
  }, [
    clientInfo,
    commentID,
    confirmationAction,
    dispatch,
    editUserValues,
    limitValue,
    loanerInfo,
    reasonDescription,
  ]);

  const showConfirmationForm = React.useMemo(
    () =>
      ["block", "unblock", "removeSuspension", "updateUser"].includes(
        confirmationAction
      ),
    [confirmationAction]
  );

  const onModifyLimit = React.useCallback(() => {
    setShowModifyLimitModal(true);
  }, []);

  const onModifyLimitAccepted = React.useCallback(
    ({ description, amount }) => {
      setReasonDescription(description);
      setLimitValue(amount);

      onModifyLimitConfirmation();
    },
    [onModifyLimitConfirmation]
  );

  const onEditUser = React.useCallback(() => {
    setShowEditModal(true);
  }, []);

  const onShowHistorical = React.useCallback(() => {
    setShowHistoricalModal(true);
  }, []);

  const openReputationModal = React.useCallback(() => {
    setShowReputationModal(true);
  }, []);

  const closeReputationModal = React.useCallback(() => {
    dispatch(getClientById(loanerInfo?.clientId));
    setShowReputationModal(false);
  }, [dispatch, loanerInfo?.clientId]);

  const onShowLoan = React.useCallback(
    (loan) => {
      if (!allowLoanDetail) {
        return;
      }

      setSelectedLoan(loan);
      setShowLoanDetail(true);
    },
    [allowLoanDetail]
  );

  if (clientInfoRequestError) {
    return (
      <Dialog
        onClose={closeLoanerItem}
        open={openLoanerItem}
        className="modal-loaners-item-wrapper"
      >
        <DialogContent className="loaners-item-content">
          <div className="loaners-item-header-wrapper">
            <div className="loaners-item-actions-wrapper">
              <div className="user-name">{loanerInfo?.client}</div>
            </div>
            <div className="close-img-wrapper" onClick={closeLoanerItem}>
              <img className="close-img" src={closeIcon} alt="" />
            </div>
          </div>
          <ErrorMessage text={t("Some.Error.label")} />
        </DialogContent>
      </Dialog>
    );
  }

  if (clientInfoRequestPending) {
    return (
      <Dialog
        onClose={closeLoanerItem}
        open={openLoanerItem}
        className="modal-loaners-item-wrapper"
      >
        <DialogContent className="loaners-item-content">
          <div className="loaners-item-header-wrapper">
            <div className="loaners-item-actions-wrapper">
              <div className="user-name">{loanerInfo?.client}</div>
            </div>
            <div className="close-img-wrapper" onClick={closeLoanerItem}>
              <img className="close-img" src={closeIcon} alt="" />
            </div>
          </div>

          <div className="loading-content">
            <Spinner text={`${t("Loading.data.label")}...`} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={closeLoanerItem}
      open={openLoanerItem}
      className="modal-loaners-item-wrapper"
    >
      <DialogContent className="loaners-item-content">
        <div className="loaners-item-header-wrapper">
          <div className="loaners-item-actions-wrapper">
            <div
              role="button"
              className="reputation"
              style={{
                background: loanStatusesColorMap.get(clientInfo?.reputation),
              }}
              onClick={openReputationModal}
            >
              R
            </div>
            <div className="user-name">{modalUserNameTitle}</div>
            <div className="loaner-actions">
              <div className="bth-content-wrapper">
                <div
                  className="bth-border-wrapper bth-border-block-loaner-wrapper"
                  onClick={onEditUser}
                >
                  <div className="bth bth-block-loaner">
                    <img
                      src={pencilIcon2}
                      alt={t("Edit.label")}
                      className="icon"
                    />
                    <span>{t("Edit.label")}</span>
                  </div>
                </div>
              </div>

              {!isBlocked && (
                <div className="bth-content-wrapper">
                  <div
                    className="bth-border-wrapper bth-border-block-loaner-wrapper"
                    onClick={onBlockUser}
                  >
                    <div className="bth bth-block-loaner">
                      <img
                        src={lockIcon}
                        alt={t("Block.label")}
                        className="icon"
                      />
                      <span>{t("Block.label")}</span>
                    </div>
                  </div>
                </div>
              )}

              {isBlocked && (
                <div className="bth-content-wrapper">
                  <div
                    className="bth-border-wrapper bth-border-block-loaner-wrapper"
                    onClick={onUnblockUser}
                  >
                    <div className="bth bth-block-loaner">
                      <img
                        src={unlockIcon}
                        alt={t("Unblock.label")}
                        className="icon"
                      />
                      <span>{t("Unblock.label")}</span>
                    </div>
                  </div>
                </div>
              )}

              {isSuspended && (
                <div className="bth-content-wrapper">
                  <div
                    className="bth-border-wrapper bth-border-block-loaner-wrapper"
                    onClick={onRemoveUserSuspension}
                  >
                    <div className="bth bth-block-loaner">
                      <img
                        src={lockIcon}
                        alt={t("Remove.suspension")}
                        className="icon"
                      />
                      <span>{t("Remove.suspension")}</span>
                    </div>
                  </div>
                </div>
              )}

              {!isSuspended && !isBlocked && (
                <div className="bth-content-wrapper">
                  <div
                    className="bth-border-wrapper bth-border-block-loaner-wrapper"
                    onClick={onModifyLimit}
                  >
                    <div className="bth bth-block-loaner">
                      <img
                        src={dolarIcon}
                        alt={t("Modify.loan.limit")}
                        className="icon"
                      />
                      <span>{t("Modify.loan.limit")}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="bth-content-wrapper">
                <div
                  className="bth-border-wrapper bth-border-block-loaner-wrapper"
                  onClick={onShowHistorical}
                >
                  <div className="bth bth-block-loaner">
                    <span>{t("Historical.label")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="close-img-wrapper" onClick={closeLoanerItem}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="left-block-wrapper">
            <div className="user-info-block-wrapper user-photos-block-wrapper">
              <div className="block-title">{t("Photos.label")}</div>
              <div className="photos-list-wrapper">
                <div className="photo-item-wrapper">
                  {clientInfo?.imageFrontIdCard && (
                    <img
                      src={clientInfo?.imageFrontIdCard}
                      alt={t("DNI.Front.label")}
                      onClick={() => {
                        setActiveModalImg(true);
                        setCurrentModalImg({
                          src: clientInfo?.imageFrontIdCard,
                          alt: "DNI.Front.label",
                        });
                      }}
                    />
                  )}
                </div>
                <div className="photo-item-wrapper">
                  {clientInfo?.imageBackIdCard && (
                    <img
                      src={clientInfo?.imageBackIdCard}
                      alt={t("DNI.Back.label")}
                      onClick={() => {
                        setActiveModalImg(true);
                        setCurrentModalImg({
                          src: clientInfo?.imageBackIdCard,
                          alt: "DNI.Back.label",
                        });
                      }}
                    />
                  )}
                </div>
                <div className="photo-item-wrapper">
                  {clientInfo?.imageSelfie && (
                    <img
                      src={clientInfo?.imageSelfie}
                      alt={t("Selfie.with.DUI.label")}
                      onClick={() => {
                        setActiveModalImg(true);
                        setCurrentModalImg({
                          src: clientInfo?.imageSelfie,
                          alt: "Selfie.with.DUI.label",
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="user-info-block-wrapper user-contact-info-block-wrapper">
              <div className="block-title">{t("User.Info.label")}</div>
              <div className="block-content-wrapper">
                <div className="info-item contact-info-item">
                  <div className="title">{t("Registration.date.label")}</div>
                  <div className="value">
                    {getDateByFormat({ date: clientInfo?.registerDate })}
                  </div>
                </div>

                <div className="info-item contact-info-item">
                  <div className="title">{t("Current.status.label")}</div>
                  <div className="value">{clientInfo?.statusDescription}</div>
                </div>

                <div className="info-item contact-info-item">
                  <div className="title">{t("Loan.credit.label")}</div>
                  <div className="value">
                    {clientInfo?.creditLimit
                      ? formatPrice(clientInfo?.creditLimit)
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="user-info-block-wrapper user-contact-info-block-wrapper">
              <div className="block-title">{t("Contact.Info.label")}</div>
              <div className="block-content-wrapper">
                <div className="info-item contact-info-item email-wrapper">
                  <div className="title email-title">{t("Email.label")}</div>
                  <div className="value email-value">{clientInfo?.email}</div>
                </div>
                <div className="info-item contact-info-item personal-phone-wrapper">
                  <div className="title email-title">
                    {t("Personal.Phone.label")}
                  </div>
                  <div className="value email-value">
                    {clientInfo?.phoneNumber}
                  </div>
                </div>
              </div>
            </div>

            <div className="user-info-block-wrapper user-personal-info-block-wrapper">
              <div className="block-title">{t("Personal.Info.label")}</div>
              <div className="block-content-wrapper">
                <div className="info-item contact-info-item first-name-wrapper">
                  <div className="title first-name-title">
                    {t("First.Name.label")}
                  </div>
                  <div className={`value first-name-value`}>
                    {clientInfo?.name}
                  </div>
                </div>
                <div className="info-item contact-info-item second-name-wrapper">
                  <div className="title second-name-title">
                    {t("Second.Name.label")}
                  </div>
                  <div className={`value second-name-value`}>
                    {clientInfo?.surname}{" "}
                  </div>
                </div>
                <div className="info-item contact-info-item gender-wrapper">
                  <div className="title gender-title">{t("Gender.label")}</div>
                  <div className="value gender-value">
                    {t(gender[clientInfo?.gender])}
                  </div>
                </div>
                <div className="info-item contact-info-item birth-date-wrapper">
                  <div className="title birth-date-title">
                    {t("Birth.Date.label")}
                  </div>
                  <div className="value birth-date-value">
                    {getDateByFormat({ date: clientInfo?.birthDate })}
                  </div>
                </div>
                <div className="info-item contact-info-item ife-inc-wrapper">
                  <div className="title ife-inc-title">
                    {t("IFE/INC.label")}
                  </div>
                  <div className={`value ife-inc-value`}>{clientInfo?.dni}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-block-wrapper">
            <div className="user-info-block-wrapper user-additional-info-block-wrapper">
              <div className="block-title">
                {t("Additional.information.label")}
              </div>
              <div className="block-content-wrapper">
                <div className="info-item contact-info-item employment-wrapper">
                  <div className="title employment-title">
                    {t("Type.of.Employment.label")}
                  </div>
                  <div className="value employment-value">
                    {clientInfo?.jobType}
                  </div>
                </div>
                {typesWithCompanyInfo.includes(
                  clientInfo?.jobType?.toLowerCase()
                ) && (
                  <>
                    <div className="info-item contact-info-item company-name-wrapper">
                      <div className="title company-name-title">
                        {t("Company.Name.label")}
                      </div>
                      <div className="value company-name-value">
                        {clientInfo?.companyName}
                      </div>
                    </div>
                    <div className="info-item contact-info-item company-phone-wrapper">
                      <div className="title company-phone-title">
                        {t("Company.Phone.label")}
                      </div>
                      <div className="value company-phone-value">
                        {clientInfo?.companyPhone}
                      </div>
                    </div>
                  </>
                )}

                <div className="info-item contact-info-item net-income-wrapper">
                  <div className="title net-income-title">
                    {t("Net.Income.label")}
                  </div>
                  <div className="value net-income-value">
                    {formatPrice(clientInfo?.netIncome)}
                  </div>
                </div>
                <div className="info-item contact-info-item additional-income-wrapper">
                  <div className="title additional-income-title">
                    {t("Additional.Income.label")}
                  </div>
                  <div className="value additional-income-value">
                    {formatPrice(clientInfo?.additionalIncome)}
                  </div>
                </div>
                <div className="info-item contact-info-item other-loans-credits-wrapper">
                  <div className="title other-loans-credits-title">
                    {t("Other.Loans.and.Credits.label")}
                  </div>
                  <div className="value other-loans-credits-value">
                    {formatPrice(clientInfo?.otherLoan)}
                  </div>
                </div>
              </div>
            </div>

            <div className="user-info-block-wrapper user-adrress-info-block-wrapper">
              <div className="block-title">{t("Address.label")}</div>
              <div className="block-content-wrapper">
                <div className="info-item contact-info-item street-wrapper">
                  <div className="title street-title">{t("Street.label")}</div>
                  <div className="value street-value">{clientInfo?.street}</div>
                </div>
                <div className="info-item contact-info-item city-wrapper">
                  <div className="title city-title">{t("City.label")}</div>
                  <div className="value city-value">
                    {clientInfo?.municipality}
                  </div>
                </div>
                <div className="info-item contact-info-item exterior-number-wrapper">
                  <div className="title exterior-number-title">
                    {t("Exterior.number.label")}
                  </div>
                  <div className="value exterior-number-value">
                    {clientInfo?.streetNumber}
                  </div>
                </div>
                <div className="info-item contact-info-item colonia-wrapper">
                  <div className="title colonia-title">
                    {t("Colonia.label")}
                  </div>
                  <div className="value additional-income-value">
                    {clientInfo?.suburb}
                  </div>
                </div>
                <div className="info-item contact-info-item province-wrapper">
                  <div className="title province-title">
                    {t("Province.label")}
                  </div>
                  <div className="value province-value">
                    {clientInfo?.department}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showEditModal && (
          <EditUser
            closeModal={() => setShowEditModal(false)}
            openContractItem={showEditModal}
            onPressAccept={onUpdateUser}
            loanerInfo={clientInfo}
          />
        )}

        {showHistoricalModal && (
          <HistoricalUser
            closeModal={() => setShowHistoricalModal(false)}
            openContractItem={showHistoricalModal}
            onPressAccept={onCommentInfoArrived}
            loanerInfo={clientInfo}
          />
        )}

        {showModifyLimitModal && (
          <ChangeLimit
            closeModal={() => setShowModifyLimitModal(false)}
            openContractItem={showModifyLimitModal}
            onPressAccept={onModifyLimitAccepted}
            loanerInfo={clientInfo}
          />
        )}

        <LoansTable
          loanerId={loanerInfo.id}
          loanerLoans={clientInfo?.loansHistory || []}
          onShowLoan={onShowLoan}
        />

        {showLoanDetail && (
          <ContractItem
            closeContractItem={() => setShowLoanDetail(false)}
            openContractItem={showLoanDetail}
            contractInfo={selectedLoan}
          />
        )}

        <ImgModal
          imgSrc={currentModalImg.src}
          imgAlt={currentModalImg.alt}
          closeModalImg={() => setActiveModalImg(false)}
          openModalImg={openModalImg}
          images={[
            {
              src: clientInfo?.imageFrontIdCard,
              alt: "DNI.Front.label",
            },
            {
              src: clientInfo?.imageBackIdCard,
              alt: "DNI.Back.label",
            },
            {
              src: clientInfo?.imageSelfie,
              alt: "Selfie.with.DUI.label",
            },
          ]}
        />

        <ConfirmationModal
          openModal={showVerificationModal}
          acceptButtonText={t("Accept.label")}
          onCloseModal={closeModal}
          additionalText={confirmationSubTitle}
          confirmationText={confirmationTitle}
          onPressAccept={onPressAccept}
          buttonDisabled={showConfirmationForm && !reasonDescription}
        >
          {showConfirmationForm && (
            <div className="textarea-wrapper">
              <textarea
                value={reasonDescription}
                onChange={(e) => setReasonDescription(e.target.value)}
              />
            </div>
          )}
        </ConfirmationModal>

        <ProcessModal
          openModalProcess={showModalProccess}
          closeModalProcess={onShowProccessClosed}
          requestPending={clientUpdateRequestPending}
          requestError={clientUpdateRequestError}
          requestSuccess={clientUpdateRequestSuccess}
          fnOnDestroy={() => {
            // dispatch(resetRegisterRequestStore(false));
          }}
          pendingText={`${t("Updating.Loan.Status.label")}...`}
          errorText={clientUpdate?.message}
          successText={clientUpdate?.message}
        />

        <ModalReputation
          openModal={showReputationModal}
          closeModal={closeReputationModal}
          userInfo={loanerInfo}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LoanerItem;
