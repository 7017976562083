const type = "STATE_TRANFERENCES";

const transferencesActions = {
  FETCH_TRANSFERENCES_COUNTERS_PENDING: `${type}_FETCH_TRANSFERENCES_COUNTERS_PENDING`,
  FETCH_TRANSFERENCES_COUNTERS_ERROR: `${type}_FETCH_TRANSFERENCES_COUNTERS_ERROR`,
  FETCH_TRANSFERENCES_COUNTERS_SUCCESS: `${type}_FETCH_TRANSFERENCES_COUNTERS_SUCCESS`,

  FETCH_USER_TRANSFERENCES_PENDING: `${type}_FETCH_USER_TRANSFERENCES_PENDING`,
  FETCH_USER_TRANSFERENCES_ERROR: `${type}_FETCH_USER_TRANSFERENCES_ERROR`,
  FETCH_USER_TRANSFERENCES_SUCCESS: `${type}_FETCH_USER_TRANSFERENCES_SUCCESS`,

  FETCH_GENERATE_FILE_PENDING: `${type}_FETCH_GENERATE_FILE_PENDING`,
  FETCH_GENERATE_FILE_ERROR: `${type}_FETCH_GENERATE_FILE_ERROR`,
  FETCH_GENERATE_FILE_SUCCESS: `${type}_FETCH_GENERATE_FILE_SUCCESS`,

  FETCH_VALIDATE_FILE_PENDING: `${type}_FETCH_VALIDATE_FILE_PENDING`,
  FETCH_VALIDATE_FILE_ERROR: `${type}_FETCH_VALIDATE_FILE_ERROR`,
  FETCH_VALIDATE_FILE_SUCCESS: `${type}_FETCH_VALIDATE_FILE_SUCCESS`,

  FETCH_VALIDATE_SELECTED_TRANSFERENCES_PENDING: `${type}_FETCH_VALIDATE_SELECTED_TRANSFERENCES_PENDING`,
  FETCH_VALIDATE_SELECTED_TRANSFERENCES_ERROR: `${type}_FETCH_VALIDATE_SELECTED_TRANSFERENCES_ERROR`,
  FETCH_VALIDATE_SELECTED_TRANSFERENCES_SUCCESS: `${type}_FETCH_VALIDATE_SELECTED_TRANSFERENCES_SUCCESS`,
};

export default transferencesActions;
