import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import esLocale from "date-fns/locale/es";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Formik, Form, Field } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import closeIcon from "../../../../../../assets/close-icon.svg";
import { useTranslation } from "react-i18next";
import * as moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import loanStatusesColorMap from "../../../../../../constants/loanStatusesColorMap";

import { useDispatch, useSelector } from "react-redux";
import ContractItem from "../../../contracts-layout/contracts-list/contract-item/ContractItem";

import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

import { formatPrice } from "../../../../../../utils/currency";
import { checkOnlyNumbers } from "../../../../../../utils/regex";
import { getJobTypes, getPurposes } from "../../../../../../actions/loan/loan";
import { getDeparments } from "../../../../../../actions/generals/generals";

import {
  rejectLoanByPhotos,
  acceptLoan,
  rejectLoan,
  blockUserAndRejectLoan,
  acceptLoanNoDecision,
  getLoanEvaluationById,
} from "../../../../../../actions/inbox/inbox";
import Spinner from "../../../../../common/spinner/Spinner";
import LoanerReasonBlock from "../../../loaners-layout/loaners-list/loaner-reason-block/LoanerReasonBlock";
import { formatTime } from "../../../../../common/helpers/helpers";
import { getDateByFormat } from "../../../../../../utils/parser";
import ImgModal from "../../../../../common/img-modal/ImgModal";
import ModalReputation from "../../../../../common/modal-reputation/ModalReputation";

import {
  typesWithCompanyInfo,
  typesWithReferencesInfo,
} from "../../../../../../constants/jobTypes";

const regexSymbol = /^[a-zA-Z\s-zñáéíóúü]*$/;

const dniRegex = /[0-9]{8,10}-{1}[0-9]{1}$/;

const Actions = ({
  closeInboxItem,
  loanInfo,
  observations,
  updateLoanStatusData,
  updateLoanStatusRequestError,
  updateLoanStatusRequestPending,
  updateLoanStatusRequestSuccess,
  valuesFromForm,
}: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    openLoanerReasonBlockModal,
    setOpenLoanerReasonBlockModal,
  ] = React.useState(false);

  const [reasonDescription, setReasonDescription] = React.useState(null);

  const [showVerificationModal, setShowVerificationModal] = React.useState(
    false
  );

  const [showResendProccess, setShowResendProccess] = React.useState(false);

  const [confirmationAction, setConfirmationAction] = React.useState(null);

  const [confirmationTitle, setConfirmationTitle] = React.useState(null);

  const [confirmationSubTitle, setConfirmationSubTitle] = React.useState(null);

  const closeModal = React.useCallback(() => {
    setShowVerificationModal(false);
  }, []);

  const onShowProccessClosed = React.useCallback(() => {
    setShowResendProccess(false);

    if (updateLoanStatusRequestSuccess) {
      closeInboxItem();
    }
  }, [updateLoanStatusRequestSuccess, closeInboxItem]);

  const onRejectByPhotos = React.useCallback(() => {
    setShowVerificationModal(true);

    setConfirmationTitle(
      `${t("Loan.rejection.by.photos.message.title.1")} ${loanInfo?.name} ${
        loanInfo?.surname
      } ${t("Loan.rejection.by.photos.message.title.2")} ${getDateByFormat({
        date: loanInfo?.timestampApplication,
      })} ${t("Loan.rejection.by.photos.message.title.3")} ${formatPrice(
        loanInfo?.amount
      )}`
    );

    setConfirmationAction("rejectByPhotos");
  }, [loanInfo, t]);

  const onRejectLoan = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription(null);

    setConfirmationTitle(
      `${t("Loan.rejection.message.title.1")} ${loanInfo?.name} ${
        loanInfo?.surname
      } ${t("Loan.rejection.message.title.2")} ${getDateByFormat({
        date: loanInfo?.timestampApplication,
      })} ${t("Loan.rejection.message.title.3")} ${formatPrice(
        loanInfo?.amount
      )}`
    );

    setConfirmationSubTitle(t("Loan.rejection.message.title.4"));

    setConfirmationAction("reject");
  }, [loanInfo, t]);

  const onSaveNoDecision = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription(null);

    setConfirmationTitle(
      `${t("Loan.accept.no.decision.title.1")} ${loanInfo?.name} ${
        loanInfo?.surname
      } ${t("Loan.accept.no.decision.title.2")} ${getDateByFormat({
        date: loanInfo?.timestampApplication,
      })} ${t("Loan.accept.no.decision.title.3")} ${formatPrice(
        loanInfo?.amount
      )} ${t("Loan.accept.no.decision.title.4")}`
    );

    setConfirmationAction("noDecision");
  }, [loanInfo, t]);

  const onBlockUser = React.useCallback(() => {
    setShowVerificationModal(true);
    setReasonDescription(null);

    setConfirmationTitle(
      `${t("Loan.rejection.and.block.user.message.title.1")} ${
        loanInfo?.name
      } ${loanInfo?.surname} ${t(
        "Loan.rejection.and.block.user.message.title.2"
      )} ${getDateByFormat({ date: loanInfo?.timestampApplication })} ${t(
        "Loan.rejection.and.block.user.message.title.3"
      )} ${formatPrice(loanInfo?.amount)}`
    );

    setConfirmationSubTitle(t("Loan.rejection.and.block.user.message.title.4"));

    setConfirmationAction("block");
  }, [loanInfo, t]);

  const onAcceptALoan = React.useCallback(() => {
    setShowVerificationModal(true);

    setConfirmationTitle(
      `${t("Loan.approbation.message.title.1")} ${loanInfo?.name} ${
        loanInfo?.surname
      } ${t("Loan.approbation.message.title.2")} ${getDateByFormat({
        date: loanInfo?.timestampApplication,
      })} ${t("Loan.approbation.message.title.3")} ${formatPrice(
        loanInfo?.amount
      )}`
    );

    setConfirmationAction("accept");
  }, [loanInfo, t]);

  const showConfirmationForm = React.useMemo(
    () => ["reject", "block"].includes(confirmationAction),
    [confirmationAction]
  );

  const onPressAccept = React.useCallback(() => {
    setShowVerificationModal(false);
    setShowResendProccess(true);

    if (confirmationAction === "accept") {
      dispatch(
        acceptLoan({
          ...loanInfo,
          ...valuesFromForm,
          observations,
        })
      );
      return;
    }

    if (confirmationAction === "rejectByPhotos") {
      dispatch(
        rejectLoanByPhotos({
          ...loanInfo,
          ...valuesFromForm,
          observations,
        })
      );
      return;
    }

    if (confirmationAction === "reject") {
      dispatch(
        rejectLoan({
          ...loanInfo,
          ...valuesFromForm,
          observations,
          rejectionReason: reasonDescription,
        })
      );
      return;
    }

    if (confirmationAction === "block") {
      dispatch(
        blockUserAndRejectLoan({
          ...loanInfo,
          ...valuesFromForm,
          observations,
          blockReason: reasonDescription,
        })
      );
      return;
    }

    if (confirmationAction === "noDecision") {
      dispatch(
        acceptLoanNoDecision({
          ...loanInfo,
          ...valuesFromForm,
          observations,
        })
      );
      return;
    }
  }, [
    dispatch,
    confirmationAction,
    reasonDescription,
    loanInfo,
    valuesFromForm,
    observations,
  ]);

  return (
    <>
      <div className="bths-actions-wrapper">
        <div className="bth-action-wrapper bth-photo-not-match-action-wrapper">
          <button
            type="button"
            className="bth bth-photo-not-match"
            onClick={onRejectByPhotos}
          >
            {t("Photo.not.match.label")}
          </button>
        </div>
        <div className="bth-action-wrapper bth-deny-action-wrapper">
          <button type="button" className="bth bth-deny" onClick={onRejectLoan}>
            {t("Deny.label")}
          </button>
        </div>
        <div className="bth-action-wrapper bth-accept-action-wrapper">
          <button
            type="button"
            className="bth bth-accept"
            onClick={onAcceptALoan}
          >
            {t("Accept.label")}
          </button>
        </div>
        <div className="bth-action-wrapper bth-block-action-wrapper">
          <button type="button" className="bth bth-block" onClick={onBlockUser}>
            {t("Block.label")}
          </button>
        </div>
        <div className="bth-action-wrapper">
          <button
            type="button"
            className="bth bth-accept-no-decision"
            onClick={onSaveNoDecision}
          >
            {t("Save.no.decision.label")}
          </button>
        </div>

        <LoanerReasonBlock
          openLoanerReasonBlockModal={openLoanerReasonBlockModal}
          closeLoanerReasonBlockModal={() =>
            setOpenLoanerReasonBlockModal(false)
          }
          loanerId={null}
        />
      </div>

      <ConfirmationModal
        openModal={showVerificationModal}
        acceptButtonText={t("Accept.label")}
        onCloseModal={closeModal}
        additionalText={showConfirmationForm && confirmationSubTitle}
        confirmationText={confirmationTitle}
        onPressAccept={onPressAccept}
        buttonDisabled={showConfirmationForm && !reasonDescription}
      >
        {showConfirmationForm && (
          <div className="textarea-wrapper">
            <textarea
              value={reasonDescription}
              onChange={(e) => setReasonDescription(e.target.value)}
            />
          </div>
        )}
      </ConfirmationModal>

      <ProcessModal
        openModalProcess={showResendProccess}
        closeModalProcess={onShowProccessClosed}
        requestPending={updateLoanStatusRequestPending}
        requestError={updateLoanStatusRequestError}
        requestSuccess={updateLoanStatusRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetRegisterRequestStore(false));
        }}
        pendingText={`${t("Updating.Loan.Status.label")}...`}
        errorText={updateLoanStatusData?.message}
        successText={updateLoanStatusData?.message}
      />
    </>
  );
};

const HistoryTable = ({ loanItemHistory = [] }: *) => {
  const { t } = useTranslation();

  const [openContractItem, setContractItemState] = React.useState(false);
  const [contractInfo, setContarctInfo] = React.useState(null);

  return (
    <div className="loans-history-content-wrapper">
      <div className="loans-history-table-title">{t("Loan.History.label")}</div>
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="loan-history-table-head">
            <TableRow>
              <TableCell className="table-head-cell"></TableCell>
              <TableCell className="table-head-cell">№</TableCell>
              <TableCell className="table-head-cell">
                {t("Data.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Status.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Return.Date.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Refinanced.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="loan-history-table-body">
            {loanItemHistory.length ? (
              <>
                {loanItemHistory.map((loan) => (
                  <TableRow
                    className="loan-history-body-row"
                    key={loan?.contractNumber}
                    onClick={() => {
                      setContractItemState(true);
                      setContarctInfo(loan);
                    }}
                  >
                    <TableCell className="table-body-cell">
                      <span
                        className="indicator"
                        style={{
                          background: loan.statusColor
                            ? loanStatusesColorMap.get(
                                loan.statusColor.toLowerCase()
                              )
                            : "white",
                        }}
                      ></span>
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan?.contractNumber}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: loan.date })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.amount)}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.status}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan?.returnDate
                        ? getDateByFormat({ date: loan?.returnDate })
                        : ""}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.refinancing}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <div className="empty-data-notifaction">
                {t("No.Data.To.Dispaly.label")}
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {contractInfo && (
        <ContractItem
          closeContractItem={() => setContractItemState(false)}
          openContractItem={openContractItem}
          contractInfo={contractInfo}
        />
      )}
    </div>
  );
};

const InboxItem = ({ openInboxItem, closeInboxItem, loanId }: *) => {
  const {
    loanItemInfo,
    loanItemInfoRequestPending,
    updateLoanStatusRequestPending,
    updateLoanStatusRequestError,
    updateLoanStatusRequestSuccess,
    updateLoanStatusData,
    jobTypes,
    departments,
    purposes,
  } = useSelector((state) => ({
    loanItemInfo: state.inbox?.loanItemInfo,

    loanItemInfoRequestPending:
      state.inbox.loanItemInfoRequest.loanItemInfoRequestPending,
    loanItemInfoRequestError:
      state.inbox.loanItemInfoRequest.loanItemInfoRequestError,
    loanItemInfoRequestSuccess:
      state.inbox.loanItemInfoRequest.loanItemInfoRequestSuccess,

    updateLoanStatusData: state.inbox.updateLoanStatusData,

    updateLoanStatusRequestPending:
      state.inbox.updateLoanStatusRequest.updateLoanStatusRequestPending,

    updateLoanStatusRequestError:
      state.inbox.updateLoanStatusRequest.updateLoanStatusRequestError,

    updateLoanStatusRequestSuccess:
      state.inbox.updateLoanStatusRequest.updateLoanStatusRequestSuccess,

    jobTypes: state.loan.jobTypes,
    purposes: state.loan.purposes,
    departments: state.generals.departments,
  }));

  const [openModalImg, setActiveModalImg] = React.useState(false);
  const [showReputationModal, setShowReputationModal] = React.useState(false);
  const [currentModalImg, setCurrentModalImg] = React.useState({});
  const [comments, setComments] = React.useState({});
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    setComments(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getJobTypes());
    dispatch(getPurposes());
    dispatch(getDeparments());
    dispatch(getLoanEvaluationById(loanId));
  }, [dispatch, loanId]);

  const usersCompanyInfoRequired = React.useMemo(
    () =>
      jobTypes.filter((item) =>
        typesWithCompanyInfo.includes(item.toLowerCase())
      ),
    [jobTypes]
  );

  const AdditionalInfoSchema = Yup.object().shape({
    street: Yup.string().trim().required("Required.label"),
    municipality: Yup.string().trim().required("Required.label"),
    streetNumber: Yup.string().trim().required("Required.label"),
    suburb: Yup.string().trim().required("Required.label"),
    department: Yup.string().trim().required("Required.label"),
    name: Yup.string()
      .trim()
      .matches(regexSymbol, "First.name.must.contain.only.letters.label")
      .required("Required.label"),
    surname: Yup.string()
      .trim()
      .matches(regexSymbol, "Second.name.must.contain.only.letters.label")
      .required("Required.label"),
    gender: Yup.string().required("Required.label"),
    birthDate: Yup.string()
      .nullable()
      .test("Date of Birth", "Input.date.in.format.MM/dd/yyyy", (value) => {
        return moment(value).isValid();
      })
      .test(
        "Date of Birth",
        "Date.should.not.be.before.minimal.date",
        (value) => {
          return !moment(value).isBefore("1900-01-01");
        }
      )
      .test(
        "Date of Birth",
        "You.must.be.at.least.18.years.old.label",
        (value) => {
          return moment().diff(moment(value), "years") >= 18;
        }
      )
      .required("Required.label"),

    dni: Yup.string()
      .matches(dniRegex, "DNI.validation")
      .required("Required.label"),
    phoneNumber: Yup.string().required("Required.label"),
    jobType: Yup.string().required("Required.label"),
    netIncome: Yup.string().required("Required.label"),
    companyName: Yup.string().when("jobType", {
      is: (value) => usersCompanyInfoRequired.includes(value),
      then: Yup.string().required("Required.label"),
    }),

    additionalIncome: Yup.string().required("Required.label"),
    companyPhone: Yup.string().when("jobType", {
      is: (value) => usersCompanyInfoRequired.includes(value),
      then: Yup.string().required("Required.label"),
    }),
    otherLoan: Yup.string().required("Required.label"),
    loanPurpose: Yup.string().required("Required.label"),
    description: Yup.string().required("Required.label"),

    familiarName: Yup.string().when("jobType", {
      is: (value) => typesWithReferencesInfo.includes(value?.toLowerCase()),
      then: Yup.string().required("Required.label"),
    }),

    familiarPhone: Yup.string().when("jobType", {
      is: (value) => typesWithReferencesInfo.includes(value?.toLowerCase()),
      then: Yup.string().required("Required.label"),
    }),

    friendName: Yup.string().when("jobType", {
      is: (value) => typesWithReferencesInfo.includes(value?.toLowerCase()),
      then: Yup.string().required("Required.label"),
    }),
    friendPhone: Yup.string().when("jobType", {
      is: (value) => typesWithReferencesInfo.includes(value?.toLowerCase()),
      then: Yup.string().required("Required.label"),
    }),
  });

  const openReputationModal = React.useCallback(() => {
    setShowReputationModal(true);
  }, []);

  const closeReputationModal = React.useCallback(() => {
    dispatch(getLoanEvaluationById(loanId));
    setShowReputationModal(false);
  }, [dispatch, loanId]);

  React.useEffect(() => {
    if (loanItemInfo?.observations) {
      const contentBlock = htmlToDraft(loanItemInfo?.observations);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const initialValue = EditorState.createWithContent(contentState);
      setComments(draftToHtml(convertToRaw(initialValue.getCurrentContent())));
      setEditorState(initialValue);
    }
  }, [loanItemInfo?.observations]);

  if (loanItemInfoRequestPending) {
    return (
      <Dialog
        onClose={closeInboxItem}
        open={openInboxItem}
        className="modal-inbox-item-wrapper"
      >
        <DialogContent className="inbox-item-content loading-content">
          <Spinner text={`${t("Loading.data.label")} ...`} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={closeInboxItem}
      open={openInboxItem}
      className="modal-inbox-item-wrapper"
    >
      <DialogContent className="inbox-item-content">
        <Formik
          initialValues={{
            description: loanItemInfo?.purposeDescription,
            phoneNumber: loanItemInfo?.phoneNumber,
            name: loanItemInfo?.name,
            surname: loanItemInfo?.surname,
            gender: loanItemInfo?.gender,
            birthDate: loanItemInfo?.birthDate,
            dni: loanItemInfo?.dni,
            jobType: loanItemInfo?.jobType,
            companyName: loanItemInfo?.companyName,
            companyPhone: loanItemInfo?.companyPhone,
            netIncome: loanItemInfo?.netIncome,
            additionalIncome: loanItemInfo?.additionalIncome,
            otherLoan: loanItemInfo?.otherLoan,
            street: loanItemInfo?.street,
            municipality: loanItemInfo?.municipality,
            streetNumber: loanItemInfo?.streetNumber,
            suburb: loanItemInfo?.suburb,
            department: loanItemInfo?.department,
            familiarName: loanItemInfo?.familiarName || "",
            familiarPhone: loanItemInfo?.familiarPhone || "",
            loanPurpose: loanItemInfo?.purpose,
            friendName: loanItemInfo?.friendName || "",
            friendPhone: loanItemInfo?.friendPhone || "",
          }}
          validationSchema={AdditionalInfoSchema}
          validateOnMount={true}
        >
          {({
            errors,
            dirty,
            touched,
            values,
            isValid,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <div className="inbox-item-header-wrapper">
                <div className="user-name-container">
                  <div className="user-name">{`${loanItemInfo?.name} ${loanItemInfo?.surname}`}</div>
                  <div
                    role="button"
                    className="reputation"
                    style={{
                      background: loanStatusesColorMap.get(
                        loanItemInfo.reputation
                      ),
                    }}
                    onClick={openReputationModal}
                  >
                    {t("Reputation.label")}
                  </div>
                </div>
                <div className="inbox-item-header-navigation">
                  <div className="close-img-wrapper" onClick={closeInboxItem}>
                    <img className="close-img" src={closeIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className="main-content-wrapper">
                <div className="left-block-wrapper">
                  <div className="user-info-block-wrapper user-photos-block-wrapper">
                    <div className="block-title">{t("Photos.label")}</div>
                    <div className="photos-list-wrapper">
                      <div className="photo-item-wrapper">
                        <img
                          src={`${loanItemInfo?.imageFrontIdCard}`}
                          alt={t("DNI.Front.label")}
                          onClick={() => {
                            setActiveModalImg(true);
                            setCurrentModalImg({
                              src: loanItemInfo?.imageFrontIdCard,
                              alt: "DNI.Front.label",
                            });
                          }}
                        />
                      </div>
                      <div className="photo-item-wrapper">
                        <img
                          src={loanItemInfo?.imageBackIdCard}
                          alt={t("DNI.Back.label")}
                          onClick={() => {
                            setActiveModalImg(true);
                            setCurrentModalImg({
                              src: loanItemInfo?.imageBackIdCard,
                              alt: "DNI.Back.label",
                            });
                          }}
                        />
                      </div>
                      <div className="photo-item-wrapper">
                        <img
                          src={loanItemInfo?.imageSelfie}
                          alt={t("Selfie.with.DUI.label")}
                          onClick={() => {
                            setActiveModalImg(true);
                            setCurrentModalImg({
                              src: loanItemInfo?.imageSelfie,
                              alt: "Selfie.with.DUI.label",
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="user-info-block-wrapper user-contact-info-block-wrapper">
                    <div className="block-title">{t("Contact.Info.label")}</div>
                    <div className="block-content-wrapper">
                      <div className="info-item contact-info-item email-wrapper">
                        <div className="title email-title">
                          {t("Email.label")}{" "}
                        </div>
                        <div className="value email-value">
                          {loanItemInfo?.email}
                        </div>
                      </div>
                      <div className="info-item contact-info-item personal-phone-wrapper">
                        <div className="title email-title">
                          {t("Personal.Phone.label")}
                        </div>
                        <div className="value email-value">
                          <MuiPhoneInput
                            className="input-item"
                            name="phoneNumber"
                            id="phoneNumber"
                            type="tel"
                            value={values.phoneNumber}
                            onChange={(value) =>
                              setFieldValue("phoneNumber", value)
                            }
                            defaultCountry="sv"
                            countryCodeEditable={false}
                            onBlur={() => setFieldTouched("phoneNumber", true)}
                          />
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <ErrorMessage text={t(errors.phoneNumber)} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="user-info-block-wrapper user-personal-info-block-wrapper">
                    <div className="block-title">
                      {t("Personal.Info.label")}
                    </div>
                    <div className="block-content-wrapper">
                      <div className="info-item contact-info-item first-name-wrapper">
                        <div className="title first-name-title">
                          {t("First.Name.label")}
                        </div>
                        <div className="value first-name-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="name"
                            id="name"
                          />
                          {errors.name && touched.name ? (
                            <ErrorMessage text={t(errors.name)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item second-name-wrapper">
                        <div className="title second-name-title">
                          {t("Second.Name.label")}
                        </div>
                        <div className="value second-name-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="surname"
                            id="surname"
                          />
                          {errors.surname && touched.surname ? (
                            <ErrorMessage text={t(errors.surname)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item gender-wrapper">
                        <div className="title gender-title">
                          {t("Gender.label")}
                        </div>
                        <div className="value gender-value">
                          <div className="radio-group-wrapper">
                            <div className="radio-group-item-wrapper female-radio-item">
                              <Field
                                className="input-item"
                                type="radio"
                                id="female"
                                name="gender"
                                value="f"
                              />
                              <label className="label-item" htmlFor="female">
                                {t("Female.label")}
                              </label>
                            </div>
                            <div className="radio-group-item-wrapper female-radio-item">
                              <Field
                                className="input-item"
                                type="radio"
                                id="male"
                                name="gender"
                                value="m"
                              />
                              <label className="label-item" htmlFor="male">
                                {t("Male.label")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-item contact-info-item birth-date-wrapper">
                        <div className="title birth-date-title">
                          {t("Birth.Date.label")}
                        </div>
                        <div className="value birth-date-value">
                          <div className="flex-container">
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                className="input-item"
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                placeholder="20/12/1993"
                                value={values.birthDate}
                                onChange={(value) =>
                                  setFieldValue("birthDate", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                                allowKeyboardControl={true}
                                invalidDateMessage=""
                                minDateMessage=""
                                maxDateMessage=""
                                onBlur={() => {
                                  setFieldTouched("birthDate", true);
                                }}
                                onAccept={(value) => {
                                  setFieldTouched("birthDate", true);
                                  setFieldValue("birthDate", value);
                                }}
                              />
                            </MuiPickersUtilsProvider>

                            {errors.birthDate && touched.birthDate ? (
                              <ErrorMessage text={t(errors.birthDate)} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="info-item contact-info-item ife-inc-wrapper">
                        <div className="title ife-inc-title">
                          {t("IFE/INC.label")}
                        </div>
                        <div className="value ife-inc-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="dni"
                            id="dni"
                          />
                          {errors.dni && touched.dni ? (
                            <ErrorMessage text={t(errors.dni)} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-block-wrapper">
                  <div className="user-info-block-wrapper user-additional-info-block-wrapper">
                    <div className="block-title">
                      {t("Additional.Info.label")}
                    </div>
                    <div className="block-content-wrapper">
                      <div className="info-item contact-info-item employment-wrapper">
                        <div className="title employment-title">
                          {t("Type.of.Employment.label")}
                        </div>
                        <div className="value employment-value">
                          {values?.jobType && (
                            <FormControl variant="filled">
                              <Select
                                name="jobType"
                                id="jobType"
                                value={values?.jobType}
                                onChange={(value) => {
                                  setFieldValue(
                                    "jobType",
                                    value?.target?.value
                                  );
                                }}
                                onClose={() => setFieldTouched("jobType", true)}
                              >
                                <MenuItem value="">
                                  <em>{t("Choose.Type.label")}</em>
                                </MenuItem>
                                {jobTypes?.map((item) => (
                                  <MenuItem value={item} key={item}>
                                    {t(item)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {errors.jobType && touched.jobType ? (
                            <ErrorMessage text={t(errors.jobType)} />
                          ) : null}
                        </div>
                      </div>

                      {typesWithCompanyInfo.includes(
                        values?.jobType.toLowerCase()
                      ) && (
                        <>
                          <div className="info-item contact-info-item company-name-wrapper">
                            <div className="title company-name-title">
                              {t("Company.Name.label")}
                            </div>
                            <div className="value company-name-value">
                              <Field
                                className="input-item"
                                type="text"
                                name="companyName"
                                id="companyName"
                              />
                              {errors.companyName && touched.companyName ? (
                                <ErrorMessage text={t(errors.companyName)} />
                              ) : null}
                            </div>
                          </div>
                          <div className="info-item contact-info-item company-phone-wrapper">
                            <div className="title company-phone-title">
                              {t("Company.Phone.label")}
                            </div>
                            <div className="value company-phone-value">
                              <MuiPhoneInput
                                className="input-item"
                                name="companyPhone"
                                id="companyPhone"
                                type="tel"
                                value={values.companyPhone}
                                onChange={(value) =>
                                  setFieldValue("companyPhone", value)
                                }
                                defaultCountry="sv"
                                countryCodeEditable={false}
                                onBlur={() =>
                                  setFieldTouched("companyPhone", true)
                                }
                              />
                              {errors.companyPhone && touched.companyPhone ? (
                                <ErrorMessage text={t(errors.companyPhone)} />
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="info-item contact-info-item net-income-wrapper">
                        <div className="title net-income-title">
                          {" "}
                          {t("Net.Income.label")}{" "}
                        </div>
                        <div className="value net-income-value">
                          <input
                            className="input-item"
                            type="text"
                            name="netIncome"
                            id="netIncome"
                            value={values?.netIncome}
                            onBlur={() => {
                              setFieldTouched("netIncome", true);
                            }}
                            onChange={(e) => {
                              if (!checkOnlyNumbers(e.target.value)) {
                                e.preventDefault();
                                return;
                              }

                              setFieldValue("netIncome", e.target.value);
                            }}
                          />
                          {errors.netIncome && touched.netIncome ? (
                            <ErrorMessage text={t(errors.netIncome)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item additional-income-wrapper">
                        <div className="title additional-income-title">
                          {t("Additional.Income.label")}
                        </div>
                        <div className="value additional-income-value">
                          <input
                            className="input-item"
                            type="text"
                            name="additionalIncome"
                            id="additionalIncome"
                            value={values?.additionalIncome}
                            onBlur={() => {
                              setFieldTouched("additionalIncome", true);
                            }}
                            onChange={(e) => {
                              if (!checkOnlyNumbers(e.target.value)) {
                                e.preventDefault();
                                return;
                              }

                              setFieldValue("additionalIncome", e.target.value);
                            }}
                          />
                          {errors.additionalIncome &&
                          touched.additionalIncome ? (
                            <ErrorMessage text={t(errors.additionalIncome)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item other-loans-credits-wrapper">
                        <div className="title other-loans-credits-title">
                          {t("Other.loans.credits.label")}
                        </div>
                        <div className="value other-loans-credits-value">
                          <input
                            className="input-item"
                            type="text"
                            name="otherLoan"
                            id="otherLoan"
                            value={values?.otherLoan}
                            onBlur={() => {
                              setFieldTouched("otherLoan", true);
                            }}
                            onChange={(e) => {
                              if (!checkOnlyNumbers(e.target.value)) {
                                e.preventDefault();
                                return;
                              }

                              setFieldValue("otherLoan", e.target.value);
                            }}
                          />
                          {errors.otherLoan && touched.otherLoan ? (
                            <ErrorMessage text={t(errors.otherLoan)} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="user-info-block-wrapper user-adrress-info-block-wrapper">
                    <div className="block-title">{t("Address.label")}</div>
                    <div className="block-content-wrapper">
                      <div className="info-item contact-info-item street-wrapper">
                        <div className="title street-title">
                          {t("Street.label")}
                        </div>
                        <div className="value street-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="street"
                            id="street"
                          />
                          {errors.street && touched.street ? (
                            <ErrorMessage text={t(errors.street)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item city-wrapper">
                        <div className="title city-title">
                          {t("City.label")}
                        </div>
                        <div className="value city-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="municipality"
                            id="municipality"
                          />
                          {errors.municipality && touched.municipality ? (
                            <ErrorMessage text={t(errors.municipality)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item exterior-number-wrapper">
                        <div className="title exterior-number-title">
                          {t("Exterior.number.label")}
                        </div>
                        <div className="value exterior-number-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="streetNumber"
                            id="streetNumber"
                          />
                          {errors.streetNumber && touched.streetNumber ? (
                            <ErrorMessage text={t(errors.streetNumber)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item colonia-wrapper">
                        <div className="title colonia-title">
                          {t("Colonia.label")}
                        </div>
                        <div className="value additional-income-value">
                          <Field
                            className="input-item"
                            type="text"
                            name="suburb"
                            id="suburb"
                          />
                          {errors.suburb && touched.suburb ? (
                            <ErrorMessage text={t(errors.suburb)} />
                          ) : null}
                        </div>
                      </div>
                      <div className="info-item contact-info-item province-wrapper">
                        <div className="title province-title">
                          {t("Province.label")}
                        </div>
                        <div className="value province-value">
                          {values?.department && (
                            <FormControl
                              variant="filled"
                              className="loan-department-form-control"
                            >
                              <Select
                                className="input-item"
                                name="department"
                                id="department"
                                value={values?.department}
                                onChange={(value) => {
                                  setFieldValue(
                                    "department",
                                    value?.target?.value
                                  );
                                }}
                                onClose={() =>
                                  setFieldTouched("department", true)
                                }
                              >
                                {departments?.map((item) => (
                                  <MenuItem value={item} key={item}>
                                    {t(item)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {errors.department && touched.department ? (
                            <ErrorMessage text={t(errors.department)} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="loan-content-wrapper">
                <div className="loan-item-info-wrapper loan-purpose-info">
                  <div className="loan-item-sub-info">
                    <div className="title loan-purpose-title">
                      {t("Loan.amount.label")}
                    </div>
                    <div className="value loan-purpose-value">
                      {formatPrice(loanItemInfo?.amount)}
                    </div>
                  </div>
                  <div className="loan-item-sub-info">
                    <div className="title loan-purpose-title">
                      {t("Deadline.label")}
                    </div>
                    <div className="value loan-purpose-value">
                      {`${loanItemInfo?.deadline} ${t("days.label")}`}
                    </div>
                  </div>
                  <div className="loan-item-sub-info">
                    <div className="title loan-purpose-title">
                      {t("Request.date.label")}
                    </div>
                    <div className="value loan-purpose-value">
                      {getDateByFormat({
                        date: loanItemInfo?.timestampApplication,
                      })}
                    </div>
                  </div>
                  <div className="loan-item-sub-info">
                    <div className="title loan-purpose-title">
                      {t("Request.time.label")}
                    </div>
                    <div className="value loan-purpose-value">
                      {formatTime(loanItemInfo?.timestampApplication)}
                    </div>
                  </div>
                </div>

                <div className="loan-item-info-wrapper loan-purpose-info">
                  <div className="title loan-purpose-title">
                    {t("Loan.purpose.label")}
                  </div>
                  <div className="value loan-purpose-value">
                    {values?.loanPurpose && (
                      <FormControl
                        variant="filled"
                        className="loan-purpose-form-control"
                      >
                        <Select
                          name="loanPurpose"
                          id="loanPurpose"
                          value={values?.loanPurpose}
                          onChange={(event) => {
                            setFieldValue("loanPurpose", event?.target?.value);
                          }}
                          onClose={() => setFieldTouched("loanPurpose", true)}
                        >
                          {purposes?.map((item) => (
                            <MenuItem value={item} key={item}>
                              {t(item)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {errors.loanPurpose && touched.loanPurpose ? (
                      <ErrorMessage text={t(errors.loanPurpose)} />
                    ) : null}
                  </div>
                </div>
                <div className="loan-item-info-wrapper loan-purpose-info">
                  <div className="title description-title">
                    {t("Description.label")}
                  </div>
                  <div className="value description-value">
                    <textarea
                      className="input-item"
                      type="textarea"
                      name="description"
                      id="description"
                      value={values?.description}
                      onChange={(event) => {
                        setFieldValue("description", event?.target?.value);
                      }}
                      onBlur={() => setFieldTouched("description", true)}
                    ></textarea>
                    {errors.description && touched.description ? (
                      <ErrorMessage text={t(errors.description)} />
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="loan-content-wrapper">
                <span className="reference-title">{t("References.label")}</span>

                <div className="loan-item-info-wrapper loan-purpose-info">
                  <div className="title description-title">
                    {t("Family.label")}
                  </div>
                  <div className="value description-value">
                    <div>
                      <Field
                        className="input-item"
                        type="text"
                        name="familiarName"
                        id="familiarName"
                      />
                      {errors.familiarName && touched.familiarName ? (
                        <ErrorMessage text={t(errors.familiarName)} />
                      ) : null}
                    </div>
                    <div>
                      <MuiPhoneInput
                        className="input-item"
                        name="familiarPhone"
                        id="familiarPhone"
                        type="tel"
                        value={values?.familiarPhone}
                        onChange={(value) =>
                          setFieldValue("familiarPhone", value)
                        }
                        defaultCountry="sv"
                        countryCodeEditable={false}
                        onBlur={() => setFieldTouched("familiarPhone", true)}
                      />
                      {errors.familiarPhone && touched.familiarPhone ? (
                        <ErrorMessage text={t(errors.familiarPhone)} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="loan-item-info-wrapper loan-purpose-info">
                  <div className="title description-title">
                    {t("Friend.label")}
                  </div>
                  <div className="value description-value">
                    <div>
                      <Field
                        className="input-item"
                        type="text"
                        name="friendName"
                        id="friendName"
                      />
                      {errors.friendName && touched.friendName ? (
                        <ErrorMessage text={t(errors.friendName)} />
                      ) : null}
                    </div>

                    <div>
                      <MuiPhoneInput
                        className="input-item"
                        name="friendPhone"
                        id="friendPhone"
                        type="tel"
                        value={values?.friendPhone}
                        onChange={(value) =>
                          setFieldValue("friendPhone", value)
                        }
                        defaultCountry="sv"
                        countryCodeEditable={false}
                        onBlur={() => setFieldTouched("friendPhone", true)}
                      />
                      {errors.friendPhone && touched.friendPhone ? (
                        <ErrorMessage text={t(errors.friendPhone)} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <HistoryTable loanItemHistory={loanItemInfo?.loansHistory} />

              <div className="editor-wrapper">
                <div className="title">{t("Comments.label")}</div>
                <div className="editor-container">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
              </div>

              <Actions
                closeInboxItem={closeInboxItem}
                loanInfo={loanItemInfo}
                updateLoanStatusData={updateLoanStatusData}
                updateLoanStatusRequestPending={updateLoanStatusRequestPending}
                updateLoanStatusRequestError={updateLoanStatusRequestError}
                updateLoanStatusRequestSuccess={updateLoanStatusRequestSuccess}
                valuesFromForm={values}
                observations={comments}
                isValid={isValid && dirty}
              />
            </Form>
          )}
        </Formik>

        <ModalReputation
          openModal={showReputationModal}
          closeModal={closeReputationModal}
          userInfo={loanItemInfo}
        />

        <ImgModal
          imgSrc={currentModalImg.src}
          imgAlt={currentModalImg.alt}
          closeModalImg={() => setActiveModalImg(false)}
          openModalImg={openModalImg}
          images={[
            {
              src: loanItemInfo?.imageFrontIdCard,
              alt: "DNI.Front.label",
            },
            {
              src: loanItemInfo?.imageBackIdCard,
              alt: "DNI.Back.label",
            },
            {
              src: loanItemInfo?.imageSelfie,
              alt: "Selfie.with.DUI.label",
            },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InboxItem;
