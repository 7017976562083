import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../../../assets/close-icon.svg";

import { useSelector, useDispatch } from "react-redux";

import {
  setLoanTabLayout,
  acceptLoanFirstStep,
  setLoanTabLayoutStep,
} from "../../../../../../../../actions/loan/loan";

import loanLayout from "../../../../../../../../constants/loanLayout";

import { useTranslation } from "react-i18next";

const AcceptPolicyModal = ({
  openAcceptPolicyModal,
  closeAcceptPolicyModal,
}: *) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(acceptLoanFirstStep());
  }, [dispatch]);

  const { contract } = useSelector((state) => ({
    contract: state?.loan?.loanContract,
  }));

  const { t } = useTranslation();

  return (
    <Dialog
      onClose={closeAcceptPolicyModal}
      open={openAcceptPolicyModal}
      className="modal-accept-policy-block-wrapper"
    >
      <DialogContent className="accept-policy-content">
        <div className="close-icon-wrapper" onClick={closeAcceptPolicyModal}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="main-content-wrapper">
          <div className="title">{t("Loan.privacy.title")}</div>

          {contract && (
            <div
              className="main-text"
              dangerouslySetInnerHTML={{ __html: contract }}
            ></div>
          )}

          <div className="bth-wrapper sms-verify-bth-wrapper">
            <button
              className="bth next-step-bth"
              onClick={() => {
                dispatch(setLoanTabLayout(loanLayout.LOAN_GET_MONEY));
                dispatch(setLoanTabLayoutStep(1));
              }}
            >
              {t("Next.step.label")}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptPolicyModal;
