import React from "react";
import "./styles.scss";

import daggerIcon from "../../../../../../../assets/dagger-icon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateByFormat } from "../../../../../../../utils/parser";

const LoanStatusRefused = () => {
  const { t } = useTranslation();

  const { suspendedDate, remainingSuspendedDays } = useSelector((state) => ({
    remainingSuspendedDays: state.loan?.loanInfo?.remainingSuspendedDays,
    suspendedDate: state.loan?.loanInfo?.suspendedDate,
  }));

  return (
    <div className="loan-status-wrapper">
      <div className="status-indicator-wrapper refused-loan">
        <span className="dagger-wrapper">
          <img src={daggerIcon} alt="" />
        </span>
        <span>
          {`${t(
            "Based.on.the.analysis.of.the.data.you.provided.you.were.refused.a.loan.label"
          )} ${getDateByFormat({ date: suspendedDate })}.`}
          <br></br>
          {`${t(
            "We.are.sincerely.sorry.to.refuse.you.but.you.can.re-apply.for.a.new.request.within.14.days.label"
          )} ${remainingSuspendedDays} ${t("days.label")}`}
        </span>
      </div>
    </div>
  );
};

export default LoanStatusRefused;
