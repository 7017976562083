import notificationsActions from "./notifications-types";
import { tokenConfig } from "../auth/auth";
import axios from "axios";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: notificationsActions[actionType],
    payload: data,
  };
}

export const getUserNotification = () => (dispatch, getState) => {
  dispatch({ type: notificationsActions.FETCH_USER_NOTIFICATIONS_PENDING });

  axios
    .get(
      `${process.env.REACT_APP_API_SERVER}/client/notifications`,
      tokenConfig(getState)
    )
    .then(({ data }) => {
      dispatch({
        type: notificationsActions.FETCH_USER_NOTIFICATIONS_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: notificationsActions.FETCH_USER_NOTIFICATIONS_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const markAllNotificationsAsRead = () => (dispatch, getState) => {
  dispatch({ type: notificationsActions.FETCH_MARK_ALL_AS_READ_PENDING });

  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/client/read-all-notifications`,
      {},
      tokenConfig(getState)
    )
    .then(() => {
      dispatch(getUserNotification());
      dispatch({ type: notificationsActions.FETCH_MARK_ALL_AS_READ_SUCCESS });
    })
    .catch((err) => {
      dispatch({
        type: notificationsActions.FETCH_MARK_ALL_AS_READ_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};

export const markNotificationAsRead = (id) => (dispatch, getState) => {
  dispatch({
    type: notificationsActions.FETCH_MARK_NOTIFICATION_AS_READ_PENDING,
  });

  axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/client/read-notification/${id}`,
      {},
      tokenConfig(getState)
    )
    .then(() => {
      dispatch(getUserNotification());
      dispatch({
        type: notificationsActions.FETCH_MARK_NOTIFICATION_AS_READ_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: notificationsActions.FETCH_MARK_NOTIFICATION_AS_READ_FAILED,
        payload: {
          ...errorMapper(err, dispatch),
        },
      });
    });
};
// ****************************

// on/off email notification
export const switchEmailNotification = (value, userId) => (
  dispatch,
  getState
) => {
  const body = { is_notify_email: value };
  dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_PENDING", true));
  dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_FAILED", false));
  dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_SUCCESS", false));
  axios
    .patch(
      `${process.env.REACT_APP_API_SERVER}/account/users/${userId}/`,
      body,
      tokenConfig(getState)
    )
    .then(() => {
      dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_PENDING", false));
      dispatch({
        type: "UPDATE_EMAIL_SWITCH_STATE",
        payload: value,
      });
    })
    .catch(() => {
      dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_PENDING", false));
      dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_FAILED", true));

      dispatch(actionCreator("NOTIFICATION_EMAIL_SWITCH_FAILED", false));
    });
};

// on/off SMS notification
export const switchSmsNotification = (value, userId) => (
  dispatch,
  getState
) => {
  const body = { is_notify_sms: value };
  dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_PENDING", true));
  dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_FAILED", false));
  dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_SUCCESS", false));
  axios
    .patch(
      `${process.env.REACT_APP_API_SERVER}/account/users/${userId}/`,
      body,
      tokenConfig(getState)
    )
    .then(() => {
      dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_PENDING", false));
      dispatch({
        type: "UPDATE_SMS_SWITCH_STATE",
        payload: value,
      });
    })
    .catch(() => {
      dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_PENDING", false));
      dispatch(actionCreator("NOTIFICATION_SMS_SWITCH_FAILED", true));
    });
};

//  user notification reset store
export const updateNotificationsReadedStatus = () => (dispatch, getState) => {
  const notification = getState().notifications.userNotifications.map(
    (item) => ({
      ...item,
      marked_as_read: !item.marked_as_read
        ? !item.marked_as_read
        : item.marked_as_read,
    })
  );
  dispatch(actionCreator("UPDATE_NOTIFICATIONS_READED_STATUS", notification));
};
