import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../assets/close-icon.svg";
import LoanContent from "../../../../user-profile-page/tabs/loan-tab-panel/loan-getting-permission-process/loan-content/LoanContent";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  prolongTermsLoan,
  resetProlongTermsLoanRequestStore,
  getLoan,
} from "../../../../../actions/loan/loan";
import ProcessModal from "../../../../common/process-modal/ProcessModal";

const ChangeLoanTerms = ({
  openChangeLoanTermsForm,
  closeChangeLoanTermsForm,
}: *) => {
  const {
    prolongTermsLoanRequestPending,
    prolongTermsLoanRequestError,
    prolongTermsLoanRequestSuccess,
    loanId,
  } = useSelector((state) => ({
    prolongTermsLoanRequestPending:
      state.loan.prolongTermsLoanRequest.prolongTermsLoanRequestPending,
    prolongTermsLoanRequestError:
      state.loan.prolongTermsLoanRequest.prolongTermsLoanRequestError,
    prolongTermsLoanRequestSuccess:
      state.loan.prolongTermsLoanRequest.prolongTermsLoanRequestSuccess,
    loanId: state.loan.loanInfo.contractNumber,
  }));
  const dispatch = useDispatch();
  const [openModalProcess, setOpenModalProcess] = React.useState();
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={closeChangeLoanTermsForm}
      aria-labelledby="customized-dialog-title"
      open={openChangeLoanTermsForm}
      className="modal-change-loan-terms-wrapper"
    >
      <DialogContent className="content-dialog">
        <img
          className="close-img"
          src={closeIcon}
          alt=""
          onClick={closeChangeLoanTermsForm}
        />
        <div className="form-title">{t("Change.Loan.Terms.label")}</div>
        <LoanContent useInModalWindowChangeLoanTerms={true} />
        <div className="calculator__slider__wrapper"></div>
        <div className="bth-submit-wrapper">
          <div className="bth-description">{t("By.Clicking.label")}</div>
          <button
            type="button"
            className="bth-request-changes"
            onClick={() => {
              setOpenModalProcess(true);
              dispatch(prolongTermsLoan(loanId));
            }}
          >
            {t("Request.Changes.label")}
          </button>
        </div>
        {openModalProcess ? (
          <ProcessModal
            openModalProcess={openModalProcess}
            closeModalProcess={() => setOpenModalProcess(false)}
            requestPending={prolongTermsLoanRequestPending}
            requestError={prolongTermsLoanRequestError}
            requestSuccess={prolongTermsLoanRequestSuccess}
            fnOnDestroy={() => {
              closeChangeLoanTermsForm();
              dispatch(getLoan());
              dispatch(resetProlongTermsLoanRequestStore(false));
            }}
            pendingText={`${t("Saving.details.label")}...`}
            errorText={`${t("Some.Error.label")}`}
            successText={`${t("Loan.Terms.Updated.label")}`}
          />
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChangeLoanTerms;
