import React from "react";
import * as moment from "moment";
import "./styles.scss";

import helpTabIcon from "../../../../../../../assets/help-tab-icon.svg";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RemainingBalanceSlider from "../../../../../../common/remaining-balance-slider/RemainingBalanceSlider";
import loanInterest from "../../../../../../../constants/loanInterest";
import loanStatuses from "../../../../../../../constants/loanStatuses";
import { formatPrice } from "../../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../../utils/parser";

const classOpacity = (useInModalWindow) => {
  if (useInModalWindow) {
    return "opacity";
  }
  return "";
};

const classRedColor = (loanStatus) => {
  if (loanStatus === loanStatuses.OVERDUE) {
    return "red-text";
  }
};

const getDaysCount = (loanDateIssue, loanDateReturn) => {
  const loanDateIssueInMs = new Date(loanDateIssue).getTime();
  const loanDateReturnInMs = new Date(loanDateReturn).getTime();
  var diff = moment.duration(loanDateReturnInMs - loanDateIssueInMs);
  const daysCount = Math.floor(diff.asDays());
  return daysCount;
};

const getInterestRate = (dayCount) => {
  let interestValue;
  let iva;
  const daysLimit = loanInterest[dayCount];
  if (!daysLimit) {
    for (const limit in loanInterest) {
      const interval = loanInterest[limit].INTERVAL;
      if (dayCount >= interval[0] && dayCount <= interval[1]) {
        interestValue = loanInterest[limit].INTEREST;
        iva = loanInterest[limit].IVA;
        break;
      }
    }
  } else {
    interestValue = daysLimit.INTEREST;
    iva = daysLimit.IVA;
  }

  return { interestValue, iva };
};

const LoanData = ({ useInModalWindowChangeLoanTerms }: *) => {
  const { t } = useTranslation();
  const [, setIva] = React.useState("");

  const {
    administrativeAmount,
    concepts,
    isAdmin,
    ivaAmount,
    loanAmount,
    loanAmountReturn,
    loanDateIssue,
    loanDateReturn,
    loanRate,
    loanRemainingAmount,
    initialReturnScale,
    loanStatus,
  } = useSelector((state) => ({
    concepts: state.loan.loanInfo.conceptsAmount || [],
    loanAmount: state.loan.loanInfo.loanAmount,
    loanRate: state.loan.loanInfo.interestAmount,
    ivaAmount: state.loan.loanInfo.ivaAmount,
    administrativeAmount: state.loan.loanInfo.administrativeAmount,
    loanAmountReturn: state.loan.loanInfo.totalRefundAmount,
    loanDateIssue: state.loan.loanInfo.loanDate,
    loanDateReturn: state.loan.loanInfo.loanReturnDate,
    loanStatus: state.loan.loanInfo.loanStatus,
    loanRemainingAmount: state.loan.loanInfo.restAmount,
    isAdmin: state.auth.user.isAdmin,
    initialReturnScale: state.loan.loanInfo.initialReturnScale,
  }));

  React.useEffect(() => {
    const daysCount = getDaysCount(loanDateIssue, loanDateReturn);
    const { iva } = getInterestRate(daysCount);
    setIva(iva);
  }, [loanDateIssue, loanDateReturn]);

  return (
    <div className="loan-content-wrapper">
      <RemainingBalanceSlider />
      <div
        className={`loan-content-item loan-amount ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Loan.amount.label")}</span>
        <span className="value">{formatPrice(loanAmount)}</span>
      </div>
      <div
        className={`loan-content-item interest-rate ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Interest.rate.label")}</span>
        <span className="value">{formatPrice(loanRate)}</span>
      </div>
      <div
        className={`loan-content-item amount-return ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Administrative.rate.label")}</span>
        <span className="value">
          <span>{formatPrice(administrativeAmount)}</span>
          <span className="questionIcon__wrapper">
            <div className="questionInfo">
              {concepts.map((item, index) => (
                <div key={`concepts-${index}`} className="concept-item">
                  <span key={`concepts-${index}`}>
                    {item.title} {" - "}
                  </span>
                  <span>{formatPrice(item.amount)}</span>
                </div>
              ))}
            </div>
            <img src={helpTabIcon} alt="" />
          </span>
        </span>
      </div>
      <div
        className={`loan-content-item interest-rate ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("IVA.label")}</span>
        <span className="value">{formatPrice(ivaAmount)}</span>
      </div>
      <div
        className={`loan-content-item amount-return ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Amount.to.return.label")}</span>
        <span className="value">
          <span>{formatPrice(loanAmountReturn)}</span>
        </span>
      </div>
      <div
        className={`loan-content-item remaining-amount ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Remaining.amount.label")}</span>
        <span className="value">
          <strong>{formatPrice(loanRemainingAmount)}</strong>
        </span>
      </div>
      <div
        className={`loan-content-item date-issue ${classOpacity(
          useInModalWindowChangeLoanTerms
        )}`}
      >
        <span className="description">{t("Date.of.issue.label")}</span>
        <span className="value">
          {getDateByFormat({ date: loanDateIssue })}
        </span>
      </div>
      <div
        className={`loan-content-item return-date ${classRedColor(loanStatus)}`}
      >
        <span className="description">{t("Return.Date.label")}</span>
        <span className="value">
          {getDateByFormat({ date: loanDateReturn })}
        </span>
      </div>

      {isAdmin && initialReturnScale && (
        <div className="initial-balance">
          <div className="balance-title">
            {t("Return.scale.since.first.day")}
          </div>

          <RemainingBalanceSlider isAdmin={true} />
        </div>
      )}
    </div>
  );
};

export default LoanData;
