const type = "STATE_AUTH";

const authActions = {
  GET_USER: "GET_USER",
  USER_LOADING: "USER_LOADING",
  USER_LOADED: "USER_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_PENDING: "LOGOUT_PENDING",
  RECOVER_PASSWORD: "RECOVER_PASSWORD",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILED: "CONTACT_US_FAILED",
  ACTIVATE_SUCCESS: "ACTIVATE_SUCCESS",
  ACTIVATE_FAILED: "ACTIVATE_FAILED",
  REQUEST_SMS_SUCCESS: "REQUEST_SMS_SUCCESS",
  REQUEST_SMS_FAILED: "REQUEST_SMS_FAILED",
  PHONE_VERIFIED: "PHONE_VERIFIED",
  PHONE_VERIFICATION_FAILED: "PHONE_VERIFICATION_FAILED",

  ACTIVATION_EMAIL_PENDING: "ACTIVATION_EMAIL_PENDING",
  ACTIVATION_EMAIL_SUCCESS: "ACTIVATE_EMAIL_SUCCESS",
  ACTIVATION_EMAIL_FAILED: "ACTIVATION_EMAIL_FAILED",

  RESET_ACTIVATION_EMAIL_REQUEST_STORE: "RESET_ACTIVATION_EMAIL_REQUEST_STORE",

  VERIFICATION_FORM_DATA_PENDING: "VERIFICATION_FORM_DATA_PENDING",
  VERIFICATION_FORM_DATA_FAILED: "VERIFICATION_FORM_DATA_FAILED",
  VERIFICATION_FORM_DATA_SUCCESS: "VERIFICATION_FORM_DATA_SUCCESS",
  VERIFICATION_FORM_DATA_SUCCESS_UPDATE_USER_INFO:
    "VERIFICATION_FORM_DATA_SUCCESS_UPDATE_USER_INFO",
  VERIFICATION_FORM_DATA_REQUEST_STATE_UPDATE:
    "VERIFICATION_FORM_DATA_REQUEST_STATE_UPDATE",

  SEND_VERIFICATION__PHONE_CODE_PENDING:
    "SEND_VERIFICATION__PHONE_CODE_PENDING",
  SEND_VERIFICATION__PHONE_CODE_ERROR: "SEND_VERIFICATION__PHONE_CODE_ERROR",
  SEND_VERIFICATION__PHONE_CODE_SUCCESS:
    "SEND_VERIFICATION__PHONE_CODE_SUCCESS",
  SET_VERIFICATION__PHONE_ID_REQUEST: "SET_VERIFICATION__PHONE_ID_REQUEST",

  UPDATE_FACEBOOK_CONECTING_STATE: "UPDATE_FACEBOOK_CONECTING_STATE",

  UPDATE_EMAIL_SWITCH_STATE: "UPDATE_EMAIL_SWITCH_STATE",
  UPDATE_SMS_SWITCH_STATE: "UPDATE_SMS_SWITCH_STATE",

  UPDATE_USER_INFO_STATE: "UPDATE_USER_INFO_STATE",

  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILED: "REGISTER_FAILED",
  REGISTER_PENDING: "REGISTER_PENDING",

  RESET_REGISTER_REQUEST_STORE: "RESET_REGISTER_REQUEST_STORE",

  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_PENDING: "LOGIN_PENDING",

  LOGIN_ERROR_MESSAGE_UPDATE: "LOGIN_ERROR_MESSAGE_UPDATE",

  FBLOGIN_SUCCESS: "FBLOGIN_SUCCESS",
  FBLOGIN_FAILED: "FBLOGIN_FAILED",
  FBLOGIN_PENDING: "FBLOGIN_PENDING",

  // ***************
  FETCH_REGISTER_SUCCESS: `${type}_FETCH_REGISTER_SUCCESS`,
  FETCH_REGISTER_FAILED: `${type}_FETCH_REGISTER_FAILED`,
  FETCH_REGISTER_PENDING: `${type}_FETCH_REGISTER_PENDING`,

  FETCH_LOGIN_SUCCESS: `${type}_FETCH_LOGIN_SUCCESS`,
  FETCH_LOGIN_FAILED: `${type}_FETCH_LOGIN_FAILED`,
  FETCH_LOGIN_PENDING: `${type}_FETCH_LOGIN_PENDING`,

  FETCH_USER_INFO_SUCCESS: `${type}_FETCH_USER_INFO_SUCCESS`,
  FETCH_USER_INFO_FAILED: `${type}_FETCH_USER_INFO_FAILED`,
  FETCH_USER_INFO_PENDING: `${type}_FETCH_USER_INFO_PENDING`,

  FETCH_UPDATE_USER_PHONE_SUCCESS: `${type}_FETCH_UPDATE_USER_PHONE_SUCCESS`,
  FETCH_UPDATE_USER_PHONE_FAILED: `${type}_FETCH_UPDATE_USER_PHONE_FAILED`,
  FETCH_UPDATE_USER_PHONE_PENDING: `${type}_FETCH_UPDATE_USER_PHONE_PENDING`,
  FETCH_UPDATE_USER_PHONE_CLEAR: `${type}_FETCH_UPDATE_USER_PHONE_CLEAR`,

  FETCH_UPDATE_USER_ADDRESS_SUCCESS: `${type}_FETCH_UPDATE_USER_ADDRESS_SUCCESS`,
  FETCH_UPDATE_USER_ADDRESS_FAILED: `${type}_FETCH_UPDATE_USER_ADDRESS_FAILED`,
  FETCH_UPDATE_USER_ADDRESS_PENDING: `${type}_FETCH_UPDATE_USER_ADDRESS_PENDING`,
  FETCH_UPDATE_USER_ADDRESS_CLEAR: `${type}_FETCH_UPDATE_USER_ADDRESS_CLEAR`,

  FETCH_UPDATE_USER_PASSWORD_SUCCESS: `${type}_FETCH_UPDATE_USER_PASSWORD_SUCCESS`,
  FETCH_UPDATE_USER_PASSWORD_FAILED: `${type}_FETCH_UPDATE_USER_PASSWORD_FAILED`,
  FETCH_UPDATE_USER_PASSWORD_PENDING: `${type}_FETCH_UPDATE_USER_PASSWORD_PENDING`,
  FETCH_UPDATE_USER_PASSWORD_CLEAR: `${type}_FETCH_UPDATE_USER_PASSWORD_CLEAR`,

  FETCH_LOG_OUT_USER_SUCCESS: `${type}_FETCH_LOG_OUT_USER_SUCCESS`,
  FETCH_LOG_OUT_USER_FAILED: `${type}_FETCH_LOG_OUT_USER_FAILED`,
  FETCH_LOG_OUT_USER_PENDING: `${type}_FETCH_LOG_OUT_USER_PENDING`,

  FETCH_RECOVERY_SUCCESS: `${type}_FETCH_RECOVERY_SUCCESS`,
  FETCH_RECOVERY_FAILED: `${type}_FETCH_RECOVERY_FAILED`,
  FETCH_RECOVERY_PENDING: `${type}_FETCH_RECOVERY_PENDING`,

  FETCH_RECOVERY_CHANGE_PASSWORD_SUCCESS: `${type}_FETCH_RECOVERY_CHANGE_PASSWORD_SUCCESS`,
  FETCH_RECOVERY_CHANGE_PASSWORD_FAILED: `${type}_FETCH_RECOVERY_CHANGE_PASSWORD_FAILED`,
  FETCH_RECOVERY_CHANGE_PASSWORD_PENDING: `${type}_FETCH_RECOVERY_CHANGE_PASSWORD_PENDING`,

  FETCH_RESEND_VERIFICATION_EMAIL_SUCCESS: `${type}_FETCH_RESEND_VERIFICATION_EMAIL_SUCCESS`,
  FETCH_RESEND_VERIFICATION_EMAIL_FAILED: `${type}_FETCH_RESEND_VERIFICATION_EMAIL_FAILED`,
  FETCH_RESEND_VERIFICATION_EMAIL_PENDING: `${type}_FETCH_RESEND_VERIFICATION_EMAIL_PENDING`,

  FETCH_CHANGE_PASSWORD_PENDING: `${type}_FETCH_CHANGE_PASSWORD_PENDING`,
  FETCH_CHANGE_PASSWORD_SUCCESS: `${type}_FETCH_CHANGE_PASSWORD_SUCCESS`,
  FETCH_CHANGE_PASSWORD_ERROR: `${type}_FETCH_CHANGE_PASSWORD_ERROR`,
  RESET_CHANGE_PASSWORD: `${type}_RESET_CHANGE_PASSWORD`,
  RESET_SHOW_VERIFICATION_MODAL: `${type}_RESET_SHOW_VERIFICATION_MODAL`,
};

export default authActions;
