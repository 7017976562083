import axios from "axios";
import { tokenConfig } from "../auth/auth";
import contactFormActions from "./contact-form-types";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: contactFormActions[actionType],
    payload: data,
  };
}

export const resetSendContactFormDataRequestState = (value) => async (
  dispatch
) => {
  dispatch(actionCreator("RESET_SEND_CONTACT_FORM_DATA_STORE", value));
};

export const sendContactFormData = ({ message, file }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: contactFormActions.SEND_CONTACT_FORM_DATA_PENDING });

    const token = await tokenConfig(getState);

    const headers = {
      "Content-Type": "multipart/form-data",
      ...token,
    };

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("text", message);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/client/contact`,
      formData,
      headers
    );

    dispatch({
      type: contactFormActions.SEND_CONTACT_FORM_DATA_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: contactFormActions.SEND_CONTACT_FORM_DATA_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
