import React, { useState } from "react";
import "./styles.scss";

import { useSelector, useDispatch } from "react-redux";
import { setLoanTabLayout } from "../../../../../../../actions/loan/loan";
import { useTranslation } from "react-i18next";
import ChangeLoanTerms from "../../../../../../account/forms/modals/change-loan-terms/ChangeLoanTerms";
import InfoModal from "../../../../../../common/info-modal/InfoModal";
import loanStatuses from "../../../../../../../constants/loanStatuses";

const PaymentOptions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loanLayoutStatus } = useSelector((state) => ({
    loanLayoutStatus: state.loan?.loanLayoutStatus,
  }));

  const [changeLoanTermsActive, setChangeLoanTermsActive] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  return (
    <div className="payment-options-wrapper">
      <div className="payment-bths-wrapper">
        <div className="bth-wrapper make-payment-bth-wrapper">
          <button
            className="bth make-payment-bth"
            onClick={() => dispatch(setLoanTabLayout(2))}
          >
            {t("Make.a.payment.label")}
          </button>
        </div>
      </div>
      {loanLayoutStatus === loanStatuses.OVERDUE ? (
        <div className="notication-text">
          {t(
            "If.you.do.not.pay.you risk.permanently.losing.the.trust.of.the.credit.company.and.spoil.your.credit.history.label"
          )}
        </div>
      ) : (
        ""
      )}
      <InfoModal
        openModalInfo={openModalInfo}
        closeModalInfo={() => setOpenModalInfo(false)}
        infoText={t("Your.loan.is.already.overdue.label")}
      />
      <ChangeLoanTerms
        openChangeLoanTermsForm={changeLoanTermsActive}
        closeChangeLoanTermsForm={() => setChangeLoanTermsActive(false)}
      />
    </div>
  );
};

export default PaymentOptions;
