import React from "react";
import "./styles.scss";
import gender from "../../../../../constants/gender";
import { useTranslation } from "react-i18next";
import { formatDate as formatDateHelper } from "../../../../common/helpers/helpers";
import { useSelector } from "react-redux";

function formatDate(date) {
  if (!date) return "-";
  return formatDateHelper(date);
}

function checkavAilabilityProperty(property) {
  return property || "-";
}

const PersonalInfo = () => {
  const { t } = useTranslation();

  const {
    userFirstName,
    userSecondName,
    userGender,
    userBirthDate,
    userDniNumber,
  } = useSelector((state) => ({
    userFirstName: state?.auth?.user?.name,
    userSecondName: state?.auth?.user?.surname,
    userGender: state?.auth?.user?.profile?.gender,
    userBirthDate: state?.auth?.user?.profile?.birthDate,
    userDniNumber: state?.auth?.user?.profile?.idNumber,
  }));

  return (
    <div className="personal-info-block-wrapper">
      <div className="title-wrapper">
        <div className="text">{t("Personal.Info.label")}</div>
      </div>
      <div className="description">
        {t(
          "To.change.your.general.personal.info.please.contact.our.support.team.and.provide.photo.of.DNI.and.changes.needs.to.be.made.label"
        )}
      </div>
      <div className="personal-info-list">
        <div className="personal-info-item first-name-info-wrapper">
          <div className="title first-name-title">{t("First.Name.label")}</div>
          <div className="value first-name-value">
            {checkavAilabilityProperty(userFirstName)}
          </div>
        </div>
        <div className="personal-info-item second-name-info-wrapper">
          <div className="title second-name-title">
            {t("Second.Name.label")}
          </div>
          <div className="value second-name-value">
            {checkavAilabilityProperty(userSecondName)}
          </div>
        </div>
        <div className="personal-info-item gender-info-wrapper">
          <div className="title gender-title">{t("Gender.label")}</div>
          <div className="value gender-value">{t(gender[userGender])}</div>
        </div>
        <div className="personal-info-item birth-date-info-wrapper">
          <div className="title birth-date-title">{t("Birthday.label")}</div>
          <div className="value birth-date-value">
            {formatDate(userBirthDate)}
          </div>
        </div>
        <div className="personal-info-item ife-inc-info-wrapper">
          <div className="title ife-inc-title">{t("IFE/INC.label")}</div>
          <div className="value ife-inc-value">
            {checkavAilabilityProperty(userDniNumber)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
