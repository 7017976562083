import loanHistoryActions from "./loan-history-types";
import axios from "axios";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getUserLoanHistory = () => async (dispatch, getState) => {
  dispatch({ type: loanHistoryActions.FETCH_LOAN_HISTORY_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/loans-history`,
      tokenConfig(getState)
    );

    return dispatch({
      type: loanHistoryActions.FETCH_LOAN_HISTORY_SUCCESS,
      payload: [...data.data],
    });
  } catch (err) {
    dispatch({
      type: loanHistoryActions.FETCH_LOAN_HISTORY_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getHistoryLoanById = (id) => async (dispatch, getState) => {
  dispatch({ type: loanHistoryActions.FETCH_LOAN_DETAILS_PENDING });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/loan-detail/${id}`,
      tokenConfig(getState)
    );

    return dispatch({
      type: loanHistoryActions.FETCH_LOAN_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: loanHistoryActions.FETCH_LOAN_DETAILS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
