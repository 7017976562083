import React from "react";
import "./styles.scss";
import tickIcon from "../../../../../../assets/tick-icon.svg";

import { useSelector } from "react-redux";

import loanStatuses from "../../../../../../constants/loanStatuses";
import userStatuses from "../../../../../../constants/userStatuses";
import { useTranslation } from "react-i18next";

const LoanTitle = () => {
  const { loanLayoutStatus, contractId, userStatus } = useSelector((state) => ({
    loanLayoutStatus: state.loan?.loanLayoutStatus,
    userStatus: state.auth?.user?.status,
    contractId: state.loan?.loanInfo?.contractNumber,
  }));

  const isSuspended = userStatus === userStatuses.SUSPENDED;
  const isBlocked = userStatus === userStatuses.BLOCKED;

  const value = isSuspended
    ? loanStatuses.SUSPENDED
    : isBlocked
    ? loanLayoutStatus.BLOCKED
    : loanLayoutStatus;

  let showContractIdentificator = false;
  switch (value) {
    // 6 - ACTIVE
    case loanStatuses.ACTIVE:
    case loanStatuses.PAID:
    case loanStatuses.OVERDUE:
      showContractIdentificator = true;
      break;
    // 1 - UNDER_CONSIDERATION
    case loanStatuses.UNDER_CONSIDERATION:
    case loanStatuses.BAD_PHOTO:
    case loanStatuses.BLOCKED:
    case loanStatuses.SUSPENDED:
    case loanStatuses.CANCELED:
      showContractIdentificator = false;
      break;
    default:
      showContractIdentificator = false;
      break;
  }
  const { t } = useTranslation();
  if (loanLayoutStatus == loanStatuses.LOAN_RETURN) {
    return "";
  }
  return (
    <div className="tab-panel-title">
      <span className="contract-title">{t("My.Loan.label")}</span>
      <div className="contract-number-container">
        {showContractIdentificator && (
          <span className="contract-identificator">
            ({t("Loan.Contract.label")} № {contractId})
          </span>
        )}

        {loanLayoutStatus === 5 && !isSuspended && !isBlocked && (
          <span className="tick-wrapper">
            <img src={tickIcon} alt="" />
          </span>
        )}
      </div>
    </div>
  );
};

export default LoanTitle;
