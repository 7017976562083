import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import closeIcon from "../../../assets/close-icon.svg";

const ConfirmationModal = ({
  big = false,
  children,
  buttonDisabled = false,
  acceptButtonText,
  additionalText,
  cancelButtonText,
  confirmationText,
  onCloseModal,
  onPressAccept,
  openModal,
}: {
  big: boolean,
  buttonDisabled: boolean,
  children: *,
  acceptButtonText?: string,
  additionalText?: string,
  cancelButtonText?: string,
  onCloseModal: () => {},
  openModal: boolean,
  onPressAccept: () => {},
  confirmationText: string,
}) => {
  const { t } = useTranslation();

  const [acceptText] = React.useState(
    acceptButtonText || t("confirmation.modal.accept")
  );

  const [cancelText] = React.useState(
    cancelButtonText || t("confirmation.modal.cancel")
  );

  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className={`confirmation-modal-wrapper ${big && "big-modal"}`}
    >
      <DialogContent className="confirmation-content">
        <div className="close-icon-wrapper" onClick={onCloseModal}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="info-text">{confirmationText}</div>

        {additionalText && (
          <div className="additional-text">{additionalText}</div>
        )}

        {children}

        <div className="buttons-container">
          <div className="dismiss-bths-wrapper">
            <div className="bth-wrapper make-payment-bth-wrapper">
              <button
                className="bth dismiss-request-bth"
                onClick={onCloseModal}
              >
                {cancelText}
              </button>
            </div>
          </div>
          <div className="payment-options-wrapper">
            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  disabled={buttonDisabled}
                  onClick={onPressAccept}
                >
                  {acceptText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
