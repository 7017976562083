import axios from "axios";

import userAttachmentsActions from "./user-attachments-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: userAttachmentsActions[actionType],
    payload: data,
  };
}

export const attachmentsUpload = (files) => async (dispatch, getState) => {
  dispatch({ type: userAttachmentsActions.VERIFICATION_FORM_FILES_PENDING });

  try {
    const images = await Promise.all(
      files.map((file) => {
        const formData = new FormData();
        formData.append("imageFile", file.file, file.photoName);
        formData.append("imageType", file.photoName);

        return axios.post(
          `${process.env.REACT_APP_API_SERVER}/client/upload-images`,
          formData,
          tokenConfig(getState)
        );
      })
    );

    const uploadedImages = images.map(({ data }) => data?.data?.imageUrl);

    const photos = uploadedImages.reduce(
      (acc, value, index) => ({
        ...acc,
        [files[index].photoName]: value,
      }),
      {}
    );

    dispatch({
      type: userAttachmentsActions.SET_SRC_USER_PHOTOS,
      payload: photos,
    });
    dispatch({ type: userAttachmentsActions.VERIFICATION_FORM_FILES_SUCCESS });
  } catch (error) {
    dispatch({
      type: userAttachmentsActions.VERIFICATION_FORM_FILES_FAILED,
      payload: {
        ...errorMapper(error),
      },
    });
  }
};

export const attachmentSelfiDNIUpload = (file, directory) => (
  dispatch,
  getState
) => {
  const token = getState().auth.accessToken;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  config.headers["Authorization"] = `Bearer ${token}`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("directory", directory);
  dispatch(actionCreator("DNI_SELFI_FILE_PENDING", true));
  dispatch(actionCreator("DNI_SELFI_FILE_FAILED", false));
  dispatch(actionCreator("DNI_SELFI_FILE_SUCCESS", false));
  axios
    .post(`${process.env.REACT_APP_API_SERVER}/attachment/`, formData, config)
    .then(() => {
      dispatch(actionCreator("DNI_SELFI_FILE_PENDING", false));
      dispatch(actionCreator("DNI_SELFI_FILE_SUCCESS", true));
    })
    .catch(() => {
      dispatch(actionCreator("DNI_SELFI_FILE_PENDING", false));
      dispatch(actionCreator("DNI_SELFI_FILE_FAILED", true));
    });
};
