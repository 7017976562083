import bankAppLogo from "../assets/bank-app-logo.svg";
import tigoMoneyLogo from "../assets/tigo-money-logo.svg";
import bankTransferLogo from "../assets/bank-transfer-logo.svg";

export const BANCO_AGRICOLA = "ba";
export const OTROS_BANCOS = "ob";

export const availableBanks = [
  {
    id: "ba",
    key: "agricola",
  },
  {
    id: "ob",
    key: "others",
  },
];

export const banks = [
  {
    name: "BANCO AGRICOLA COMERCIAL",
    dni: null,
  },
  {
    name: "DAVIVIENDA",
    dni: 55,
  },
  {
    name: "SCOTIABANK",
    dni: 217,
  },
  {
    name: "BANKO CUSCATLAN",
    dni: 97,
  },
  {
    name: "BAC",
    dni: 259,
  },
  {
    name: "BANKO INDUSTRIAL",
    dni: 327,
  },
  {
    name: "G & T Continental",
    dni: 301,
  },
  {
    name: "CITI NA EL SALVADOR",
    dni: 372,
  },
  {
    name: "PROMERICA",
    dni: 181,
  },
  {
    name: "SAC GENTE S A",
    dni: 369,
  },
  {
    name: "BANKO AZUL",
    dni: 35,
  },
  {
    name: "BANKO ATLANDITA",
    dni: 29,
  },
];

export const returnMoneyVariants = [
  {
    titleLabel: "Bank.Transfer.label",
    commissionLabel: "Varies.label",
    fixedCommission: false,
    workHoursLabel: "Depends.on.Bank.label",
    icon: bankTransferLogo,
    beneficiaryDataLayout: 1,
    haveInstructions: false,
    id: 1,
  },
  {
    titleLabel: "Bank.App.label",
    commissionLabel: "Varies.label",
    fixedCommission: false,
    workHoursLabel: "Depends.on.Bank.label",
    icon: bankAppLogo,
    beneficiaryDataLayout: 2,
    haveInstructions: true,
    id: 2,
  },
  {
    titleLabel: "Tigo.Money.label",
    commissionLabel: "0.68",
    fixedCommission: true,
    workHoursLabel: "Depends.on.Agent.label",
    icon: tigoMoneyLogo,
    beneficiaryDataLayout: 3,
    haveInstructions: false,
    id: 3,
  },
];

export const beneficiaryData = {
  recipientLabel: "PISTO.SL.label",
  accountTypeLabel: "SL.label",
  accountNumber: 646180142225387889,
  beneficiaryBankLabel: "BANAMEX.label",
  paymentConceptLabel: "Contract.Payment.label",
};

export const beneficiaryDataTiagoMoney = {
  colector: "Colector",
  varinar5: "Pagos y donaciones, luego",
  varinar7: "Otros servicios,  y por último",
  varinar2: "ALEJANDDRIA",
};
