import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

function TransferencesHeader() {
  const { t } = useTranslation();
  return (
    <div className="dashboard-header-wrapper">
      <div className="dashboard-title-wrapper">
        {t("Client.transferences.label")}
      </div>
    </div>
  );
}

export default TransferencesHeader;
