import React from "react";
import { useSelector } from "react-redux";
import PhotoVerification from "./form-step/photo-verification-step/PhotoVerification";
import ActivationSuccess from "./form-step/activation-success-step/ActivationSuccess";
import "./styles.scss";
import VerificationPageHeader from "./header/VerificationPageHeader";
import loanStatuses from "../../../../constants/loanStatuses";
import { useHistory } from "react-router-dom";
import { useAnalitycs } from "../../../../hooks/useAnalytics";

const FormStepTemplate = ({ step, nextStep, prevStep }) => {
  switch (step) {
    case 1:
      return (
        <div className="step-form-wrapper">
          <PhotoVerification
            nextStep={() => nextStep()}
            prevStep={() => prevStep()}
          />
        </div>
      );
    case 2:
      return <ActivationSuccess prevStep={() => prevStep()} />;
    default:
      return "";
  }
};

const VerificationForm = () => {
  useAnalitycs("ReloadPhotos");
  const [step, setStep] = React.useState(1);
  const history = useHistory();

  const { loanStatus } = useSelector((state) => ({
    loanStatus: state.loan.loanInfo.loanStatus,
  }));

  React.useEffect(() => {
    if (loanStatus !== loanStatuses.BAD_PHOTO) {
      history.push("login");
    }
  }, [history, loanStatus]);

  return (
    <div className="upload-photos-container">
      <VerificationPageHeader />
      <FormStepTemplate
        step={step}
        nextStep={() => setStep((value) => value + 1)}
        prevStep={() => setStep((value) => value - 1)}
      />
    </div>
  );
};

export default VerificationForm;
