import paymentActions from "../../actions/payment/payment-types";

const initialState = {
  paymentProcessors: [],
  paymentProcessorsRequest: {
    paymentProcessorsRequestPending: false,
    paymentProcessorsRequestError: false,
    paymentProcessorsRequestSuccess: false,
  },

  processPayment: [],
  processPaymentRequest: {
    processPaymentRequestPending: false,
    processPaymentRequestError: false,
    processPaymentRequestSuccess: false,
  },
};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentActions.FETCH_PAYMENT_PROCESSORS_PENDING:
      return {
        ...state,
        paymentProcessors: [],
        paymentProcessorsRequest: {
          paymentProcessorsRequestPending: true,
          paymentProcessorsRequestError: false,
          paymentProcessorsRequestSuccess: false,
        },
      };
    case paymentActions.FETCH_PAYMENT_PROCESSORS_ERROR:
      return {
        ...state,
        paymentProcessors: [],
        paymentProcessorsRequest: {
          paymentProcessorsRequestPending: false,
          paymentProcessorsRequestError: true,
          paymentProcessorsRequestSuccess: false,
        },
      };
    case paymentActions.FETCH_PAYMENT_PROCESSORS_SUCCESS:
      return {
        ...state,
        paymentProcessors: action.payload,
        paymentProcessorsRequest: {
          paymentProcessorsRequestPending: false,
          paymentProcessorsRequestError: false,
          paymentProcessorsRequestSuccess: true,
        },
      };
    case paymentActions.FETCH_PROCESS_PAYMENT_PENDING:
      return {
        ...state,
        processPayment: [],
        processPaymentRequest: {
          processPaymentRequestPending: true,
          processPaymentRequestError: false,
          processPaymentRequestSuccess: false,
        },
      };
    case paymentActions.FETCH_PROCESS_PAYMENT_ERROR:
      return {
        ...state,
        processPayment: action.payload,
        processPaymentRequest: {
          processPaymentRequestPending: false,
          processPaymentRequestError: true,
          processPaymentRequestSuccess: false,
        },
      };
    case paymentActions.FETCH_PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        processPayment: action.payload,
        processPaymentRequest: {
          processPaymentRequestPending: false,
          processPaymentRequestError: false,
          processPaymentRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}

export default paymentReducer;
