import React from "react";
import "./styles.scss";

import { Dialog, DialogContent } from "@material-ui/core";
import "date-fns";

import closeIcon from "../../../assets/close-icon.svg";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import ProcessModal from "../process-modal/ProcessModal";

import loanStatusesColorMap from "../../../constants/loanStatusesColorMap";

import {
  getClientReputationById,
  changeClientReputation,
} from "../../../actions/loaners/loaners";
import Spinner from "../spinner/Spinner";
import { getDateByFormat } from "../../../utils/parser";

const reputationValues = ["yellow", "green", "red", "orange"];

const mappedReputationValues = reputationValues.map((item) => ({
  value: item,
  color: loanStatusesColorMap.get(item),
}));

const ReputationTable = ({ items = [] }: *) => {
  const { t } = useTranslation();

  return (
    <div className="reputation-content-wrapper">
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="reputation-table-head">
            <TableRow>
              <TableCell className="table-head-cell">
                {t("Reputation.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Date.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Observations.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Analyst.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="reputation-table-body">
            {!items.length ? (
              <TableRow className="reputation-body-row">
                <TableCell
                  className="table-body-cell reputation-notification"
                  colSpan={4}
                >
                  <div className="reputation-notification">
                    {t("No.Data.To.Display.label")}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {items.map((item, i) => (
                  <TableRow className="reputation-body-row" key={i}>
                    <TableCell className="table-body-cell">
                      <div
                        className="reputation-dot"
                        style={{
                          background: loanStatusesColorMap.get(item?.color),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: item.date })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {item.observations}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {item.analyst}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const ModalReputation = ({ openModal, closeModal = {}, userInfo }: *) => {
  const [
    openConfirmReputationModal,
    setOpenConfirmReputationModal,
  ] = React.useState(false);

  const [openConfirmChangeModal, setOpenConfirmChangeModal] = React.useState(
    false
  );

  const [showProcessModal, setShowProcessModal] = React.useState(false);

  const [description, setDescription] = React.useState("");
  const [selectedColor, setSelectedColor] = React.useState(null);

  const {
    userReputation,
    userReputationRequestPending,
    updateUserReputation,
    updateUserReputationRequestPending,
    updateUserReputationRequestError,
    updateUserReputationRequestSuccess,
  } = useSelector((state) => ({
    userReputation: state.loaners.userReputation,
    userReputationRequestPending:
      state.loaners.userReputationRequest.userReputationRequestPending,

    updateUserReputation: state.loaners.updateUserReputation,
    updateUserReputationRequestPending:
      state.loaners.updateUserReputationRequest
        .updateUserReputationRequestPending,
    updateUserReputationRequestError:
      state.loaners.updateUserReputationRequest
        .updateUserReputationRequestError,
    updateUserReputationRequestSuccess:
      state.loaners.updateUserReputationRequest
        .updateUserReputationRequestSuccess,
  }));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (userInfo?.clientId) {
      dispatch(getClientReputationById(userInfo?.clientId));
    }
  }, [userInfo?.clientId, dispatch]);

  const onColorClicked = React.useCallback((color) => {
    setSelectedColor(color);
    setOpenConfirmReputationModal(true);
  }, []);

  const onCloseConfirmReputationModal = React.useCallback(() => {
    setOpenConfirmReputationModal(false);
  }, []);

  const onCloseConfirmChangeModal = React.useCallback(() => {
    setOpenConfirmChangeModal(false);
  }, []);

  const onChangeReputation = React.useCallback(() => {
    setOpenConfirmChangeModal(true);
  }, []);

  const onConfirmChange = React.useCallback(() => {
    setShowProcessModal(true);

    dispatch(
      changeClientReputation({
        clientId: userReputation?.clientId,
        reputation: selectedColor,
        observations: description,
      })
    );
  }, [dispatch, userReputation?.clientId, selectedColor, description]);

  const onCloseProcessModal = React.useCallback(() => {
    if (updateUserReputationRequestSuccess) {
      onCloseConfirmReputationModal();
      onCloseConfirmChangeModal();
    }

    setShowProcessModal(false);
  }, [
    updateUserReputationRequestSuccess,
    onCloseConfirmReputationModal,
    onCloseConfirmChangeModal,
  ]);

  if (userReputationRequestPending) {
    return (
      <Dialog
        onClose={closeModal}
        open={openModal}
        className="modal-reputation-item-wrapper"
      >
        <DialogContent className="reputation-item-content loading-content">
          <Spinner text={`${t("Loading.data.label")} ...`} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={closeModal}
      open={openModal}
      className="modal-reputation-item-wrapper"
    >
      <DialogContent className="reputation-item-content">
        <div className="reputation-item-header-wrapper">
          <div className="user-name-container">
            <div
              className="reputation"
              style={{
                background: loanStatusesColorMap.get(
                  userReputation?.currentReputation
                ),
              }}
            >
              R
            </div>
            <div className="user-name">{`${userReputation?.name} ${userReputation?.surname}`}</div>
          </div>

          <div className="modal-title">{t("Historical.reputation.title")}</div>

          <div className="reputation-item-header-navigation">
            <div className="close-img-wrapper" onClick={closeModal}>
              <img className="close-img" src={closeIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="main-content-wrapper">
          <div className="reputation-changer">
            <div className="reputation-changer-title">Cambiar reputacion</div>
            <div className="reputation-list">
              {mappedReputationValues
                .filter(
                  (item) => item.value !== userReputation?.currentReputation
                )
                .map((value, index) => (
                  <div
                    role="button"
                    onClick={() => onColorClicked(value.value)}
                    key={index}
                    className="reputation-item"
                    style={{
                      background: value.color,
                    }}
                  ></div>
                ))}
            </div>
          </div>

          <ReputationTable items={userReputation?.reputation} />
        </div>
      </DialogContent>

      <ConfirmationModal
        openModal={openConfirmReputationModal}
        onCloseModal={onCloseConfirmReputationModal}
        buttonDisabled={!description}
        big={true}
        onPressAccept={onChangeReputation}
      >
        <div className="modal-header-component">
          <div className="modal-header-title">
            {`${t("Reputation.change.title")} ${userReputation?.name} ${
              userReputation?.surname
            }`}
          </div>

          <div className="textarea-content">
            <div className="reputation-item-container">
              <div
                className="confirmation-reputation-item"
                style={{
                  background: selectedColor,
                }}
              ></div>
            </div>
            <span>{t("Reputation.change.message-1")}</span>
            <textarea
              className="input-item"
              type="textarea"
              name="description"
              id="description"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            ></textarea>
          </div>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        openModal={openConfirmChangeModal}
        onCloseModal={onCloseConfirmChangeModal}
        big={true}
        onPressAccept={onConfirmChange}
      >
        <div className="modal-header-component">
          <div className="modal-header-title">
            {`${t("Reputation.change.confirmation-1")} ${
              userReputation?.name
            } ${userReputation?.surname} ${t(
              "Reputation.change.confirmation-2"
            )}:`}
          </div>

          <div className="textarea-content">
            <div className="reputation-item-container">
              <div
                className="confirmation-reputation-item"
                style={{
                  background: selectedColor,
                }}
              ></div>
            </div>
            <span>{t("Reputation.change.confirmation-3")}:</span>
            <span>{description}</span>
          </div>
        </div>
      </ConfirmationModal>

      <ProcessModal
        openModalProcess={showProcessModal}
        closeModalProcess={onCloseProcessModal}
        requestPending={updateUserReputationRequestPending}
        requestError={updateUserReputationRequestError}
        requestSuccess={updateUserReputationRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetRegisterRequestStore(false));
        }}
        pendingText={`${t("Processing.information")}...`}
        errorText={updateUserReputation?.message}
        successText={updateUserReputation?.message}
      />
    </Dialog>
  );
};

export default ModalReputation;
