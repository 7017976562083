import userAttachmentsActions from "../../actions/user-attachments/user-attachments-types";

const initialState = {
  verificationFormFilesMessage: null,
  verificationFormFiles: {
    verificationFormFilesPending: false,
    verificationFormFilesError: false,
    verificationFormFilesSuccess: false,

    srcUserPhotos: {},
  },
  dniSelfiFile: {
    dniSelfiFilePending: false,
    dniSelfiFileError: false,
    dniSelfiFileSuccess: false,
  },
};

export default function userAttachmentsReducer(state = initialState, action) {
  switch (action.type) {
    // verification form action
    case userAttachmentsActions.VERIFICATION_FORM_FILES_PENDING:
      return {
        ...state,
        verificationFormFiles: {
          verificationFormFilesPending: true,
          verificationFormFilesError: false,
          verificationFormFilesSuccess: false,
        },
      };
    case userAttachmentsActions.VERIFICATION_FORM_FILES_FAILED:
      return {
        ...state,
        verificationFormFilesMessage: action.payload.message,
        verificationFormFiles: {
          verificationFormFilesPending: false,
          verificationFormFilesError: true,
          verificationFormFilesSuccess: false,
        },
      };
    case userAttachmentsActions.VERIFICATION_FORM_FILES_SUCCESS:
      return {
        ...state,
        verificationFormFiles: {
          ...state.verificationFormFiles,
          verificationFormFilesPending: false,
          verificationFormFilesError: false,
          verificationFormFilesSuccess: true,
        },
      };
    // selfi dni photo upload actions
    case userAttachmentsActions.DNI_SELFI_FILE_SUCCESS:
      return {
        ...state,
        dniSelfiFile: {
          ...state.dniSelfiFile,
          dniSelfiFileSuccess: action.payload,
        },
      };
    case userAttachmentsActions.DNI_SELFI_FILE_PENDING:
      return {
        ...state,
        dniSelfiFile: {
          ...state.dniSelfiFile,
          dniSelfiFilePending: action.payload,
        },
      };

    case userAttachmentsActions.DNI_SELFI_FILE_FAILED:
      return {
        ...state,
        dniSelfiFile: {
          ...state.dniSelfiFile,
          dniSelfiFileError: action.payload,
        },
      };

    case userAttachmentsActions.SET_SRC_USER_PHOTOS:
      return {
        ...state,
        verificationFormFiles: {
          ...state.verificationFormFiles,
          srcUserPhotos: {
            ...state.srcUserPhotos,
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
}
