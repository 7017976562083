import React from "react";
import DashboardHeader from "./dashboard-header/DashboardHeader";
import DashboardContent from "./dashboard-content/DashboardContent";

import "./styles.scss";

function DashboardLayout() {
  return (
    <div className="dashboard-layout-wrapper">
      <DashboardHeader />
      <DashboardContent />
    </div>
  );
}

export default DashboardLayout;
