import React from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";

export const useAnalitycs = (section: string) => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname, null, section);
    ReactPixel.pageView();
    ReactPixel.track(location.pathname, section);
  }, [location, section]);
};
