import axios from "axios";
import generalActions from "./generals-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

const mockedData = {
  data: [40, 70, 100, 130, 150],
};

export const getFAQS = () => async (dispatch, getState) => {
  try {
    dispatch({ type: generalActions.GET_FAQS_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/help`,
      token
    );

    dispatch({ type: generalActions.GET_FAQS_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({
      type: generalActions.GET_FAQS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getPersonalDataPolicy = () => async (dispatch, getState) => {
  try {
    dispatch({ type: generalActions.GET_PERSONAL_DATA_POLICY_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/download-personal-data-policy`,
      token
    );

    dispatch({
      type: generalActions.GET_PERSONAL_DATA_POLICY_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_PERSONAL_DATA_POLICY_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getTermsOfService = () => async (dispatch, getState) => {
  try {
    dispatch({ type: generalActions.GET_TERMS_OF_SERVICE_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/client/download-terms-of-service`,
      token
    );

    dispatch({
      type: generalActions.GET_TERMS_OF_SERVICE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_TERMS_OF_SERVICE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getDeparments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: generalActions.GET_DEPARTMENTS_PENDING });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/departments`,
      token
    );

    dispatch({
      type: generalActions.GET_DEPARTMENTS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_DEPARTMENTS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getPosts = () => async (dispatch) => {
  try {
    dispatch({ type: generalActions.GET_POSTS_PENDING });

    const { data } = await axios.get(`${process.env.REACT_APP_API_BLOG}/posts`);

    dispatch({
      type: generalActions.GET_POSTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_POSTS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getLandingFAQs = () => async (dispatch) => {
  try {
    dispatch({ type: generalActions.GET_LANDING_FAQS_PENDING });

    const { data } = await axios.get(`${process.env.REACT_APP_API_BLOG}/faqs`);

    const faqs = data.map((item) => ({
      question: item.title,
      answer: item.content,
    }));

    dispatch({
      type: generalActions.GET_LANDING_FAQS_SUCCESS,
      payload: faqs,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_LANDING_FAQS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getLimitValues = () => async (dispatch) => {
  try {
    dispatch({ type: generalActions.GET_LIMIT_VALUES_PENDING });

    const data = mockedData;

    dispatch({
      type: generalActions.GET_LIMIT_VALUES_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: generalActions.GET_LIMIT_VALUES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
