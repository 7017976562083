import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";

import clipIcon from "../../../../../assets/clip-icon.svg";
import closeIcon from "../../../../../assets/close-icon.svg";

import { useTranslation } from "react-i18next";
import "./styles.scss";
import ProcessModal from "../../../../common/process-modal/ProcessModal";

import { sendContactFormData } from "../../../../../actions/contact-form/contact-form";

function ContactUs({ openContactForm, closeContactForm }: *) {
  const [message, setMessage] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [
    openSendDataModalProcess,
    setOpenSendDataModalProcess,
  ] = React.useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    lastName,
    name,
    sendContactFormDataRequestData,
    sendContactFormDataRequestError,
    sendContactFormDataRequestPending,
    sendContactFormDataRequestSuccess,
    userEmail,
  } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    name: state?.auth?.user?.name,
    lastName: state?.auth?.user?.surname,

    sendContactFormDataRequestData:
      state.contactForm.sendContactFormDataRequestData,

    sendContactFormDataRequestPending:
      state.contactForm.sendContactFormDataRequest
        .sendContactFormDataRequestPending,
    sendContactFormDataRequestError:
      state.contactForm.sendContactFormDataRequest
        .sendContactFormDataRequestError,
    sendContactFormDataRequestSuccess:
      state.contactForm.sendContactFormDataRequest
        .sendContactFormDataRequestSuccess,

    userEmail: state.auth.user.email,
  }));

  const emailFrom = React.useMemo(
    () => (name ? `${name} ${lastName}` : userEmail),
    [name, userEmail, lastName]
  );

  const onFormSubmitted = React.useCallback(
    (e) => {
      e.preventDefault();
      setOpenSendDataModalProcess(true);
      dispatch(sendContactFormData({ message, file }));
    },
    [message, file, dispatch]
  );

  const onCloseModal = React.useCallback(() => {
    setOpenSendDataModalProcess(false);

    if (sendContactFormDataRequestSuccess) {
      history.push("/login");
    }
  }, [sendContactFormDataRequestSuccess, history]);

  return (
    <Dialog
      open={openContactForm}
      onClose={closeContactForm}
      aria-labelledby="customized-dialog-title"
      className="modal-contact-us-wrapper"
    >
      <DialogContent className="content-dialog">
        <img
          className="close-img"
          src={closeIcon}
          alt=""
          onClick={closeContactForm}
        />
        <div className="form-title">{t("Contact.us.bth.label")}</div>
        <form className="form-contact-us" onSubmit={onFormSubmitted}>
          <div className="user-info">
            <span>{emailFrom}</span>
          </div>

          <fieldset className="form-item message-item">
            <textarea
              className="input-item"
              type="text"
              name="message"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </fieldset>
          <div className="form-item attach-files-block-wrapper">
            <div className="attach-file-bth-wrapper">
              <label htmlFor="files">
                <img className="clip-img" src={clipIcon} alt="" />
                <span className="text">{t("Attach.File.label")}</span>
              </label>
              <input
                onChange={(e) => {
                  const file = e.target.files[0];
                  const pattern = /(\.jpg|\.jpeg|\.png|\.pdf)$/;

                  if (!file.name.match(pattern)) {
                    setFile(null);
                    return;
                  }

                  setFile(e.target.files[0]);
                }}
                type="file"
                hidden
                id="files"
                name="files"
              />
              <div
                className={`attach-files-list ${file?.name ? "active" : ""}`}
              >
                {file?.name && (
                  <>
                    <div className="attach-file-item-wrapper">
                      <div className="attach-file-name">{file?.name}</div>
                      <button
                        type="button"
                        className="attach-file-delete-bth"
                        onClick={() => setFile({})}
                      >
                        {t("Delete.label")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="bth-action-wrapper bth-submit-wrapper">
            <button
              disabled={!message}
              type="submit"
              className={`bth-action bth-submit ${
                message ? "active" : "disabled"
              }`}
            >
              {t("Send.bth.label")}
            </button>
          </div>
        </form>
        {openSendDataModalProcess && (
          <ProcessModal
            openModalProcess={openSendDataModalProcess}
            closeModalProcess={onCloseModal}
            requestPending={sendContactFormDataRequestPending}
            requestError={sendContactFormDataRequestError}
            requestSuccess={sendContactFormDataRequestSuccess}
            fnOnDestroy={() => {
              // dispatch(resetSendContactFormDataRequestState(false));
            }}
            pendingText={`${t("Sending.Data.label")}`}
            errorText={sendContactFormDataRequestData?.message}
            successText={sendContactFormDataRequestData?.message}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ContactUs;
