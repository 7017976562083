import React from "react";
import "./styles.scss";

import daggerIcon from "../../../assets/dagger-icon.svg";
import tickIcon from "../../../assets/tick-icon.svg";
import { useTranslation } from "react-i18next";

function StatusIcon({ validatorStatus }: *) {
  return validatorStatus ? (
    <img src={tickIcon} alt="" className="status-icon tick-icon" />
  ) : (
    <img src={daggerIcon} alt="" className="status-icon dagger-icon" />
  );
}

export function PasswordValidatorMessage({
  minLength,
  containNumbers,
  containUpperLetters,
  containLowerLetters,
  containSpecialCaracters,
}: {
  minLength: () => boolean,
  containNumbers: () => boolean,
  containUpperLetters: () => boolean,
  containLowerLetters: () => boolean,
  containSpecialCaracters: () => boolean,
}) {
  const { t } = useTranslation();

  return (
    <div className="password-validator-message-wrapper">
      <div className="title">{t("The.password.must.be.at.least.label")}:</div>
      <div className="list-validators-wrapper">
        <div className="item-validator-wrapper min-password-length">
          <StatusIcon validatorStatus={minLength} />
          <span>{t("8.characters.label")}</span>
        </div>
        <div className="item-validator-wrapper min-password-length">
          <StatusIcon validatorStatus={containUpperLetters} />
          <span>{t("1.upper.letter.label")}</span>
        </div>
        <div className="item-validator-wrapper min-password-length">
          <StatusIcon validatorStatus={containLowerLetters} />
          <span>{t("1.lower.letter.label")}</span>
        </div>
        <div className="item-validator-wrapper contain-number">
          <StatusIcon validatorStatus={containNumbers} />
          <span>{t("1.number.label")}</span>
        </div>
        <div className="item-validator-wrapper contait-letter">
          <StatusIcon validatorStatus={containSpecialCaracters} />
          <span>{t("1.special.caracter.label")}</span>
        </div>
      </div>
    </div>
  );
}

export default PasswordValidatorMessage;
