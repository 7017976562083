import React from "react";
import ConciliationContent from "./conciliation-content/ConciliationContent";
import ConciliationHeader from "./conciliation-header/ConciliationHeader";

import "./styles.scss";

function ConciliationLayout() {
  return (
    <div className="payments-layout-wrapper">
      <ConciliationHeader />
      <ConciliationContent />
    </div>
  );
}

export default ConciliationLayout;
