import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import closeIcon from "../../../../../../assets/close-icon.svg";

import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";

import { useDispatch, useSelector } from "react-redux";

import {
  fetchLoanInfoByContractNumber,
  cancelRefinancement,
} from "../../../../../../actions/conciliations/conciliations";

import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";

const LoansTable = ({ loanerLoans = [] }: *) => {
  const { t } = useTranslation();

  return (
    <div className="loans-history-content-wrapper">
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="loans-history-table-head">
            <TableRow>
              <TableCell className="table-head-cell">
                {t("Date.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Operation.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Balance.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="loans-history-table-body">
            {!loanerLoans.length ? (
              <div className="loans-history-notification">
                {t("Data.Empty.label")}
              </div>
            ) : (
              <>
                {loanerLoans.map((loan, i) => (
                  <TableRow className="loans-history-body-row" key={i}>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: loan.date })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loan.operation}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.amount)}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.balance)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const CancelRefinancement = ({ openModal, onCloseModal }: *) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reasonDescription, setReasonDescription] = React.useState("");
  const [contractNumber, setcontractNumber] = React.useState("");
  const [showProcessModal, setShowProcessModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const {
    refinancedLoanData,
    refinancedLoanDataRequestPending,
    refinancedLoanDataRequestError,
    refinancedLoanDataRequestSuccess,
    cancelRefinancementData,
    cancelRefinancementRequestPending,
    cancelRefinancementRequestError,
    cancelRefinancementRequestSuccess,
  } = useSelector((state) => ({
    refinancedLoanData: state.conciliations.refinancedLoanData,
    refinancedLoanDataRequestPending:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestPending,
    refinancedLoanDataRequestError:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestError,
    refinancedLoanDataRequestSuccess:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestSuccess,

    cancelRefinancementData: state.conciliations.cancelRefinancement,
    cancelRefinancementRequestPending:
      state.conciliations.cancelRefinancementRequest
        .cancelRefinancementRequestPending,
    cancelRefinancementRequestError:
      state.conciliations.cancelRefinancementRequest
        .cancelRefinancementRequestError,
    cancelRefinancementRequestSuccess:
      state.conciliations.cancelRefinancementRequest
        .cancelRefinancementRequestSuccess,
  }));

  const onCloseCancellationModal = React.useCallback(() => {
    setcontractNumber("");
    setReasonDescription("");
    onCloseModal();
  }, [onCloseModal]);

  const closeConfirmationModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
    setcontractNumber("");
    setReasonDescription("");
  }, []);

  const onCancelRefinancement = React.useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const onCheckLoan = React.useCallback(() => {
    dispatch(fetchLoanInfoByContractNumber(contractNumber));
  }, [dispatch, contractNumber]);

  const onCloseProcessModal = React.useCallback(() => {
    setShowProcessModal(false);

    if (cancelRefinancementRequestSuccess) {
      setcontractNumber("");
      setOpenConfirmationModal(false);
      onCloseModal();
    }
  }, [cancelRefinancementRequestSuccess, onCloseModal]);

  const onProcessCancellation = React.useCallback(() => {
    setShowProcessModal(true);

    dispatch(
      cancelRefinancement({
        description: reasonDescription,
        loanId: refinancedLoanData?.loanId,
      })
    );
  }, [dispatch, refinancedLoanData?.loanId, reasonDescription]);

  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className="modal-emergency-item-wrapper"
    >
      <DialogContent className="emergency-item-content">
        <div className="emergency-item-header-wrapper">
          <div className="emergency-item-actions-wrapper">
            <div className="emergency-item-title">
              {t("Cancel.refinancement.title")}
            </div>
          </div>
          <div className="close-img-wrapper" onClick={onCloseCancellationModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="input-container">
            <input
              className="input-item"
              placeholder={t("Loan.id.label")}
              value={contractNumber}
              onChange={(e) => {
                const value = e.target.value;

                if (!/^[\d*-]*\d*$/.test(value) && value !== "") {
                  return;
                }

                setcontractNumber(e.target.value);
              }}
            />

            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  disabled={!contractNumber}
                  onClick={onCheckLoan}
                >
                  {t("Search.loan.label")}
                </button>
              </div>
            </div>
          </div>

          {refinancedLoanDataRequestPending && (
            <div className="loading-wrapper">
              <Spinner text={`${t("Loading.data.label")}...`} />
            </div>
          )}

          {refinancedLoanDataRequestError && (
            <ErrorMessage text={refinancedLoanData?.message} />
          )}

          {refinancedLoanDataRequestSuccess && (
            <>
              <div className="loan-data">
                <div>{`${t("Client.label")}: ${
                  refinancedLoanData?.client
                }`}</div>
                <div>{`${t("Contract.No.label")}: ${
                  refinancedLoanData?.contractNumber
                }`}</div>
              </div>
              <LoansTable loanerLoans={refinancedLoanData?.operations} />

              <div className="buttons-actions">
                <div className="payment-options-wrapper">
                  <div className="payment-bths-wrapper">
                    <div className="bth-wrapper make-payment-bth-wrapper">
                      <button
                        className="bth make-payment-bth"
                        onClick={onCancelRefinancement}
                      >
                        {t("Cancel.refinancement.label")}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="payment-options-wrapper">
                  <div className="payment-bths-wrapper">
                    <div className="bth-wrapper make-payment-bth-wrapper">
                      <button
                        className="bth make-payment-bth"
                        onClick={onCloseCancellationModal}
                      >
                        {t("Cancel.label")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <ConfirmationModal
          openModal={openConfirmationModal}
          onCloseModal={closeConfirmationModal}
          big={true}
          onPressAccept={onProcessCancellation}
          buttonDisabled={!reasonDescription}
        >
          <div className="modal-header-component">
            <div className="modal-header-title">
              {`${t("Cancel.refinancement.message-1")} ${
                refinancedLoanData?.contractNumber
              } ${t("Cancel.refinancement.message-2")} ${
                refinancedLoanData?.client
              }`}
            </div>

            <div className="modal-header-subtitle">
              {t("Cancel.refinancement.message-3")}
            </div>

            <div className="textarea-content">
              <span>{t("Cancel.refinancement.description")}</span>
              <textarea
                className="input-item"
                type="textarea"
                name="description"
                id="description"
                value={reasonDescription}
                onChange={(event) => {
                  setReasonDescription(event.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </ConfirmationModal>

        <ProcessModal
          openModalProcess={showProcessModal}
          closeModalProcess={onCloseProcessModal}
          requestPending={cancelRefinancementRequestPending}
          requestError={cancelRefinancementRequestError}
          requestSuccess={cancelRefinancementRequestSuccess}
          fnOnDestroy={() => {
            // dispatch(resetRegisterRequestStore(false));
          }}
          pendingText={`${t("Processing.information")}...`}
          errorText={cancelRefinancementData?.message}
          successText={cancelRefinancementData?.message}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CancelRefinancement;
