import React from "react";
import "./styles.scss";

import BanksList from "./banks-list/BanksList";
import BankDetails from "./bank-details/BankDetails";
import { useSelector } from "react-redux";

const LoanGettingMoneyProcess = () => {
  const { layoutStep } = useSelector((state) => ({
    layoutStep: state.loan.loanLayout.layoutStep,
  }));

  switch (layoutStep) {
    // 1 - BANKS LIST
    case 1:
      return <BanksList />;
    // 2 -  BANK DATAILS
    case 2:
      return <BankDetails />;
    default:
      return "";
  }
};

export default LoanGettingMoneyProcess;
