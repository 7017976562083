import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import ValidateTransferences from "./validate-transferences/ValidateTransferences";

import {
  getTransactionFileName,
  getDateByFormat,
} from "../../../../../utils/parser";

import "./styles.scss";

import {
  transferencesCounter,
  clientTransferences,
  generateFile,
  validateFile,
  fetchValidateTransferences,
} from "../../../../../actions/transferences/transferences.js";

import { formatPrice } from "../../../../../utils/currency";

import { availableBanks } from "../../../../../constants/banks";

import cancelIcon from "../../../../../assets/delete-cancel-svgrepo-com.svg";
import checkIcon from "../../../../../assets/accept-check-good-mark-ok-tick-svgrepo-com.svg";
import downloadIcon from "../../../../../assets/download-svgrepo-com.svg";
import calendarIcon from "../../../../../assets/mdi_calendar_today.svg";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../common/spinner/Spinner";

import ProcessModal from "../../../../common/process-modal/ProcessModal";

const defaultFilters = {
  "page[size]": 5,
  "page[number]": 1,
};

const BankInfoHeader = ({ bank, counters, onGenerateFile }: *) => {
  const { t } = useTranslation();

  const quantity = counters?.[`${bank.key}Quantity`];
  const amount = counters?.[`${bank.key}Amount`];

  return (
    <div className="bank-header">
      <div className="bank-title">{t(`bank.name.${bank.key}`)}</div>
      <div className="counters">
        <div className="counter">
          <span>{t("Pending.transferences.quantity")}:</span>
          <span>{quantity}</span>
        </div>
        <div className="counter">
          <span>{t("Pending.transferences.amount")}:</span>
          <span>{formatPrice(amount)}</span>
        </div>

        <div className="buttons-container">
          <div className="payment-options-wrapper">
            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  disabled={quantity === 0}
                  onClick={() => onGenerateFile(bank.id)}
                >
                  {t("Generate.file.label")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BalanceContent = () => {
  const dispatch = useDispatch();
  const downloadRef = React.useRef(null);
  const [openProcessModal, setOpenProcessModal] = React.useState(false);
  const [
    openProcessValidationModal,
    setOpenProcessValidationModal,
  ] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState({});
  const [openValidationModal, setOpenValidationModal] = React.useState(false);
  const [currentBank, setCurrentBank] = React.useState(null);

  const [activeFilters, setActiveFilters] = React.useState(
    availableBanks.reduce((acc, value) => {
      return {
        ...acc,
        [value.id]: defaultFilters,
      };
    }, {})
  );

  const {
    userTranferencesData,
    userTranferencesPagination,
    counterTranferencesData,
    balanceReportRequestSuccess,
    filesData,
    filesDataRequestSuccess,
    filesDataRequestPending,
    filesDataRequestError,
    userTranferencesDataRequest,
    validateTransferences,
    validateTransferencesRequestPending,
    validateTransferencesRequestError,
    validateTransferencesRequestSuccess,
  } = useSelector((state) => ({
    counterTranferencesData: state.transferences.counterTranferencesData,
    userTranferencesData: state.transferences.userTranferencesData,
    userTranferencesPagination: state.transferences.userTranferencesPagination,

    userTranferencesDataRequest:
      state.transferences.userTranferencesDataRequest,

    filesData: state.transferences.filesData,
    filesDataRequestSuccess:
      state.transferences.filesDataRequest.filesDataRequestSuccess,
    filesDataRequestPending:
      state.transferences.filesDataRequest.filesDataRequestPending,
    filesDataRequestError:
      state.transferences.filesDataRequest.filesDataRequestError,

    validateTransferences: state.transferences.validateTransferences,
    validateTransferencesRequestPending:
      state.transferences.validateTransferencesRequest
        .validateTransferencesRequestPending,
    validateTransferencesRequestError:
      state.transferences.validateTransferencesRequest
        .validateTransferencesRequestError,
    validateTransferencesRequestSuccess:
      state.transferences.validateTransferencesRequest
        .validateTransferencesRequestSuccess,
  }));

  React.useEffect(() => {
    if (balanceReportRequestSuccess) {
      document.getElementById("dowload-balace-report-link").click();
    }
  }, [balanceReportRequestSuccess]);

  React.useEffect(() => {
    dispatch(transferencesCounter());
  }, [dispatch]);

  const { t } = useTranslation();

  const onChangeFilter = React.useCallback(
    ({ filter, value, id }) => {
      const resetFilters = {};

      if (filter !== "page[number]") {
        resetFilters["page[number]"] = 1;
      }

      setActiveFilters({
        ...activeFilters,
        [id]: {
          ...activeFilters[id],
          ...resetFilters,
          [filter]: value,
        },
      });
    },
    [activeFilters]
  );

  React.useEffect(() => {
    const keys = Object.keys(activeFilters?.ba);

    if (keys.length) {
      let query = "?";

      keys.forEach((key, index) => {
        if (!activeFilters?.ba[key]) {
          return;
        }

        if (index === 0) {
          query = `${query}${key}=${activeFilters?.ba[key]}`;
        } else {
          query = `${query}&${key}=${activeFilters?.ba[key]}`;
        }
      });

      dispatch(clientTransferences({ bankId: "ba", query }));
    }
  }, [activeFilters?.ba, dispatch]);

  React.useEffect(() => {
    const keys = Object.keys(activeFilters?.ob);

    if (keys.length) {
      let query = "?";

      keys.forEach((key, index) => {
        if (!activeFilters?.ob[key]) {
          return;
        }

        if (index === 0) {
          query = `${query}${key}=${activeFilters?.ob[key]}`;
        } else {
          query = `${query}&${key}=${activeFilters?.ob[key]}`;
        }
      });

      dispatch(clientTransferences({ bankId: "ob", query }));
    }
  }, [activeFilters?.ob, dispatch]);

  const onCloseProcessModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (validateTransferencesRequestSuccess) {
      setOpenValidationModal(false);
      return;
    }

    if (filesDataRequestSuccess) {
      downloadRef.current.click();

      setActiveFilters({
        ...activeFilters,
        [currentBank]: {
          ...defaultFilters,
        },
      });

      dispatch(transferencesCounter());
    }
  }, [
    filesDataRequestSuccess,
    currentBank,
    activeFilters,
    dispatch,
    validateTransferencesRequestSuccess,
  ]);

  const onCloseProcessValidationModal = React.useCallback(() => {
    setOpenProcessModal(false);

    if (validateTransferencesRequestSuccess) {
      setOpenProcessValidationModal(false);
      setOpenValidationModal(false);

      setActiveFilters({
        ...activeFilters,
        [currentBank]: {
          ...defaultFilters,
        },
      });

      dispatch(transferencesCounter());
    }
  }, [
    currentBank,
    activeFilters,
    dispatch,
    validateTransferencesRequestSuccess,
  ]);

  const onFilterDateChanged = React.useCallback(
    ({ value, id }) => {
      const date = value
        ? getDateByFormat({
            date: value,
            format: "YYYY/MM/DD",
          })
        : "";

      setFilterDate((filters) => ({
        ...filters,
        [id]: value || null,
      }));

      onChangeFilter({
        filter: "filter[date]",
        value: date,
        id,
      });
    },
    [onChangeFilter]
  );

  const onPaginationChanged = React.useCallback(
    ({ value, id }) => {
      onChangeFilter({
        filter: "page[number]",
        value,
        id,
      });
    },
    [onChangeFilter]
  );

  const onValidateFile = React.useCallback(
    ({ bankId, file }) => {
      dispatch(validateFile(file.id));
      setOpenValidationModal(true);
      setCurrentBank(bankId);
    },
    [dispatch]
  );

  const onValidateTransferences = React.useCallback(
    (data) => {
      dispatch(fetchValidateTransferences(data));
      setOpenProcessValidationModal(true);
    },
    [dispatch]
  );

  const onGenerateFile = React.useCallback(
    (bank) => {
      setCurrentBank(bank);
      setOpenProcessModal(true);
      dispatch(generateFile(bank));
    },
    [dispatch]
  );

  // eslint-disable-next-line react/display-name
  const DateInput = React.forwardRef(({ value, onClick, onChange }: *, ref) => {
    return (
      <div className="input-date-container">
        <input
          ref={ref}
          placeholder={t("Filter.by.date.label")}
          className="input-item"
          readOnly
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onClick={onClick}
        />
        {!value && (
          <img
            className="input-calendar"
            src={calendarIcon}
            onClick={onClick}
          />
        )}
      </div>
    );
  });

  const onFileClicked = React.useCallback((file) => {
    const fileName = file.substring(file.lastIndexOf("/") + 1);

    axios({
      url: file, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }, []);

  const onCloseValidationModal = React.useCallback(() => {
    setOpenValidationModal(false);
  }, []);

  const renderBankInfo = React.useCallback(
    (bank) => {
      const filesList = userTranferencesData?.[bank.id];
      const pagination = userTranferencesPagination?.[bank.id];

      return (
        <div className="bank-info-wrapper" key={bank.id}>
          <BankInfoHeader
            bank={bank}
            counters={counterTranferencesData}
            onGenerateFile={onGenerateFile}
          />

          <div className="input-filter-container">
            <div className="filter-container">
              <DatePicker
                maxDate={new Date()}
                selectsRange={false}
                dateFormat="dd/MM/yyyy"
                locale="es"
                selected={filterDate?.[bank.id]}
                value={filterDate?.[bank.id]}
                onChange={(value) => {
                  onFilterDateChanged({ value, id: bank.id });
                }}
                startDate={filterDate?.[bank.id]}
                customInput={<DateInput />}
                isClearable={true}
              />
            </div>
          </div>
          <TableContainer
            className={`table-container ${
              !filesList?.length ? "empty-table" : ""
            }`}
          >
            <Table aria-label="customized table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell className="table-head-cell">
                    {t("File.name.in.bank")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    <span>{t("Date.label")} </span>
                  </TableCell>
                  <TableCell className="table-head-cell">
                    {t("Transferences.quantity")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    <span>{t("Total.amount")} </span>
                  </TableCell>
                  <TableCell className="table-head-cell">
                    {t("Download.label")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    <span>{t("File.loaded.in.bank")} </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {userTranferencesDataRequest?.[bank.id]
                  ?.userTranferencesDataRequestPending && (
                  <TableRow className="table-body-row" rowSpan="6">
                    <TableCell className="table-body-cell" colSpan="6">
                      <Spinner text={`${t("Loading.data.label")}...`} />
                    </TableCell>
                  </TableRow>
                )}

                {userTranferencesDataRequest?.[bank.id]
                  ?.userTranferencesDataRequestSuccess &&
                  !filesList?.length && (
                    <TableRow className="table-body-row" rowSpan="6">
                      <TableCell className="table-body-cell" colSpan="6">
                        <div className="empty-data">
                          {t("No.Data.To.Dispaly.label")}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}

                {userTranferencesDataRequest?.[bank.id]
                  ?.userTranferencesDataRequestSuccess &&
                  filesList?.length > 0 && (
                    <>
                      {filesList.map((item) => (
                        <TableRow className="table-body-row" key={item.id}>
                          <TableCell className="table-body-cell">
                            {getTransactionFileName(item.name)}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {getDateByFormat({ date: item.date })}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.quantity}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {formatPrice(item.amount)}
                          </TableCell>
                          <TableCell className="table-body-cell">
                            <div className="transaction-status-icon">
                              <img
                                role="button"
                                src={downloadIcon}
                                onClick={() => onFileClicked(item.name)}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="table-body-cell">
                            {item.processed === 0 ? (
                              <span
                                onClick={() =>
                                  onValidateFile({
                                    bankId: bank.id,
                                    file: item,
                                  })
                                }
                              >
                                <div className="transaction-status-icon cancel-icon">
                                  <img src={cancelIcon} />
                                  {t("Validate.file.label")}
                                </div>
                              </span>
                            ) : (
                              <div className="transaction-status-icon cancel-icon">
                                <img src={checkIcon} />
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="pagination-wrapper">
            <Pagination
              count={pagination?.last_page}
              defaultPage={1}
              variant="outlined"
              color="secondary"
              onChange={(e, value) =>
                onPaginationChanged({ value, id: bank.id })
              }
            />
          </div>
        </div>
      );
    },
    [
      t,
      userTranferencesData,
      userTranferencesDataRequest,
      onFilterDateChanged,
      onValidateFile,
      onGenerateFile,
      counterTranferencesData,
      onPaginationChanged,
      userTranferencesPagination,
      onFileClicked,
      filterDate,
    ]
  );

  return (
    <div className="balance-content-wrapper">
      <div>{availableBanks.map((item) => renderBankInfo(item))}</div>

      {openValidationModal && (
        <ValidateTransferences
          openModal={openValidationModal}
          onCloseModal={onCloseValidationModal}
          onFetchValidateTransferences={onValidateTransferences}
        />
      )}

      <a
        ref={downloadRef}
        href={filesData?.url}
        rel="noreferrer"
        target="_blank"
      />

      <ProcessModal
        openModalProcess={openProcessModal}
        closeModalProcess={onCloseProcessModal}
        requestPending={filesDataRequestPending}
        requestError={filesDataRequestError}
        requestSuccess={filesDataRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetLoadCsvDataStore(false));
        }}
        pendingText={`${t("Saving.Data.label")}`}
        errorText={filesData?.message}
        successText={filesData?.message}
      />

      <ProcessModal
        openModalProcess={openProcessValidationModal}
        closeModalProcess={onCloseProcessValidationModal}
        requestPending={validateTransferencesRequestPending}
        requestError={validateTransferencesRequestError}
        requestSuccess={validateTransferencesRequestSuccess}
        fnOnDestroy={() => {
          // dispatch(resetLoadCsvDataStore(false));
        }}
        pendingText={`${t("Saving.Data.label")}`}
        errorText={validateTransferences?.message}
        successText={validateTransferences?.message}
      />
    </div>
  );
};

export default BalanceContent;
