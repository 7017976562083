const type = "STATE_LOAN_HISTORY";

const loanHistoryActions = {
  FETCH_LOAN_HISTORY_PENDING: `${type}_FETCH_LOAN_HISTORY_PENDING`,
  FETCH_LOAN_HISTORY_ERROR: `${type}_FETCH_LOAN_HISTORY_ERROR`,
  FETCH_LOAN_HISTORY_SUCCESS: `${type}_FETCH_LOAN_HISTORY_SUCCESS`,

  FETCH_LOAN_DETAILS_PENDING: `${type}_FETCH_LOAN_DETAILS_PENDING`,
  FETCH_LOAN_DETAILS_ERROR: `${type}_FETCH_LOAN_DETAILS_ERROR`,
  FETCH_LOAN_DETAILS_SUCCESS: `${type}_FETCH_LOAN_DETAILS_SUCCESS`,
};

export default loanHistoryActions;
