import React from "react";

import "./styles.scss";

import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import tick2Icon from "../../../../../assets/tick2-icon.svg";
import InboxItem from "./inbox-item/InboxItem";
import { formatPrice } from "../../../../../utils/currency";

import { useDispatch, useSelector } from "react-redux";

import { getInboxLoans } from "../../../../../actions/inbox/inbox";

import Spinner from "../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../common/error-message/ErrorMessage";
import { formatDate, formatTime } from "../../../../common/helpers/helpers";

const sortReverse = (inboxList) => {
  return inboxList.reverse();
};

const InboxList = () => {
  const [openInboxItem, setInboxItemState] = React.useState(false);

  const [loanId, setLoanId] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [arrowPosition, setArrowPosition] = React.useState(null);

  const { loansListError, loansListPending, loansInboxList } = useSelector(
    (state) => ({
      loansListError: state.inbox?.inboxListRequest?.inboxListRequestError,
      loansListPending: state.inbox?.inboxListRequest?.inboxListRequestPending,
      loansInboxList: state.inbox?.inboxList,
    })
  );

  const [loansInboxListState, setLoansInboxListState] = React.useState(
    loansInboxList
  );

  React.useEffect(() => {
    dispatch(getInboxLoans());
  }, [dispatch]);

  React.useEffect(() => {
    setArrowPosition(null);
    setLoansInboxListState(loansInboxList);
  }, [loansInboxList]);

  const onItemSelected = React.useCallback((loan) => {
    setLoanId(loan?.loanId);
    setInboxItemState(true);
  }, []);

  const onCloseModal = React.useCallback(() => {
    setInboxItemState(false);
    dispatch(getInboxLoans());
  }, [dispatch]);

  if (loansListError) {
    return (
      <div className="inbox-table-wrapper">
        <ErrorMessage text={t("Some.Error.label")} />
      </div>
    );
  }

  if (loansListPending) {
    return (
      <div className="inbox-table-wrapper">
        <Spinner text={`${t("Loans.Data.Loading")}...`} />
      </div>
    );
  }
  return (
    <div className="inbox-table-wrapper">
      <TableContainer className="table-container">
        <Table aria-label="customized table">
          <TableHead className="inbox-table-head">
            <TableRow>
              <TableCell className="table-head-cell">
                <span>{t("Name.label")}</span>
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Date.label")}/{t("Time.label")}
                <div
                  className="sort-bth-wrapper"
                  onClick={() => {
                    setArrowPosition((state) => {
                      if (state === "down") {
                        return "up";
                      } else {
                        return "down";
                      }
                    });
                    setLoansInboxListState(sortReverse(loansInboxListState));
                  }}
                >
                  <img
                    src={tick2Icon}
                    className={`sort-icon ${
                      arrowPosition === "down" ? "up" : "down"
                    }`}
                    alt="Sort"
                  />
                </div>
              </TableCell>
              <TableCell className="table-head-cell">
                {t("Amount.label")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="inbox-table-body">
            {!loansInboxListState.length ? (
              <div className="inbox-empty-notification">
                {t("Inbox.Empty.label")}
              </div>
            ) : (
              <>
                {loansInboxListState.map((loan, i) => (
                  <TableRow
                    className="inbox-table-body-row"
                    key={i}
                    onClick={() => onItemSelected(loan)}
                  >
                    <TableCell className="table-body-cell">{`${loan.name} ${loan.surname}`}</TableCell>
                    <TableCell className="table-body-cell">
                      {formatDate(loan.timeStamp)} {formatTime(loan.timeStamp)}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {formatPrice(loan.loanAmount)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openInboxItem && (
        <InboxItem
          openInboxItem={openInboxItem}
          closeInboxItem={onCloseModal}
          loanId={loanId}
        />
      )}
    </div>
  );
};

export default InboxList;
