import React from "react";
import { useSelector } from "react-redux";
import ReactInputVerificationCode from "react-input-verification-code";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./styles.scss";
import resendIcon from "../../../../../assets/resend-icon.svg";
import Spinner from "../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../common/error-message/ErrorMessage";
import closeIcon from "../../../../../assets/close-icon.svg";
import SuccessMessage from "../../../../common/success-message/SuccessMessage";
import {
  sendCodeToPhone,
  setCodeLiveStatus,
  resetSendCodeToPhoneState,
  resetRequestIdState,
  setShowExpiredTemplate,
  verifyCode,
  resetRequestVerifyCode,
} from "../../../../../actions/sms-verification/sms-verification";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const timeCodeLifeMinutes = 5;

const EMPTY_CODE = "····";

const HandlePesponse = ({
  pending,
  error,
  pendingText,
  errorText,
  success,
  successText,
  phone,
  invalidNumber,
  invalidNumberText,
}: *) => {
  return (
    <>
      {pending && <Spinner text={pendingText} />}

      {error && (
        <>
          <ErrorMessage text={errorText} /> <SendCodeBth phone={phone} />
        </>
      )}

      {success && <SuccessMessage text={successText} />}

      {invalidNumber && <ErrorMessage text={invalidNumberText} />}
    </>
  );
};

const getCurrentTimerData = (endDateVerificationCodeLife, currentTimeInMs) => {
  const timeRestInMs = endDateVerificationCodeLife - currentTimeInMs;
  const minutes = Math.floor(timeRestInMs / 1000 / 60);
  const seconds = Math.floor((timeRestInMs / 1000) % 60);
  return {
    minutes,
    seconds,
  };
};

const formateTimerData = (minutes, seconds) => {
  const formatedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formatedSeconds = seconds < 10 ? "0" + seconds : seconds;
  return {
    formatedMinutes,
    formatedSeconds,
  };
};

const intervalFn = (endDateVerificationCodeLife) => {
  const { minutes, seconds } = getCurrentTimerData(
    endDateVerificationCodeLife,
    new Date().getTime()
  );

  const { formatedMinutes, formatedSeconds } = formateTimerData(
    minutes,
    seconds
  );

  return {
    minutes,
    seconds,
    formatedMinutes,
    formatedSeconds,
  };
};

const SendCodeBth = ({ phone }: *) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <button
      className="send-code-bth"
      onClick={() => dispatch(sendCodeToPhone(phone))}
    >
      {t("Send.code.again.label")}
    </button>
  );
};

const ExpiredCodeTemplate = ({ phone }: *) => {
  const { t } = useTranslation();
  return (
    <div className="code-expired-info-wrapper">
      <div className="message">{t("Code.expired.label")}</div>
      <div className="send-code-bth-wrapper">
        <div className="img-wrapper">
          <img src={resendIcon} alt="" />
        </div>
        <SendCodeBth phone={phone} />
      </div>
    </div>
  );
};

const Timer = () => {
  const [timerData, setTimerData] = React.useState({
    minutes: null,
    seconds: null,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    const endDateVerificationCodeLife =
      localStorage.getItem("edvcL") ||
      new Date().getTime() + timeCodeLifeMinutes * 60 * 1000;

    localStorage.setItem("edvcL", endDateVerificationCodeLife);

    const { formatedMinutes, formatedSeconds } = intervalFn(
      endDateVerificationCodeLife
    );

    setTimerData({ minutes: formatedMinutes, seconds: formatedSeconds });

    let interval;
    interval = setInterval(() => {
      const { formatedMinutes, formatedSeconds, minutes, seconds } = intervalFn(
        endDateVerificationCodeLife
      );
      setTimerData({ minutes: formatedMinutes, seconds: formatedSeconds });
      if (minutes <= 0 && seconds <= 0) {
        localStorage.removeItem("edvcL");
        localStorage.removeItem("vpcID");
        clearInterval(interval);
        dispatch(setCodeLiveStatus(false));
        dispatch(resetSendCodeToPhoneState(false));
        dispatch(resetRequestIdState(false));
        dispatch(setShowExpiredTemplate(true));
        dispatch(resetRequestVerifyCode(false));
      }
    }, 1000);
  }, [dispatch]);

  return (
    <>
      {
        <div className="timer-wrapper">{`${timerData.minutes}:${timerData.seconds}`}</div>
      }
    </>
  );
};

const InputCodeTemplate = ({
  requestId,
  verifyCodeRequestPending,
  verifyCodeRequestError,
  verifyCodeRequestSuccess,
  closeFn,
}: *) => {
  const [code, setCode] = React.useState("");
  const timeOut = React.useRef();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (verifyCodeRequestSuccess) {
      closeFn();
      dispatch(setCodeLiveStatus(false));
      dispatch(resetSendCodeToPhoneState(false));
      dispatch(resetRequestIdState(false));
      localStorage.removeItem("edvcL");
      localStorage.removeItem("vpcID");
    }
  }, [verifyCodeRequestSuccess, closeFn, dispatch]);

  React.useEffect(() => {
    if (verifyCodeRequestError) {
      setCode(EMPTY_CODE);
    }
  }, [verifyCodeRequestError]);

  const clearState = React.useCallback(() => {
    clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      setCode(EMPTY_CODE);
    }, 200);
  }, []);

  const onCodeComplete = React.useCallback(
    (value) => {
      const finalValue = value.replace("·", "");

      if (value.charAt(0) === "·" && value !== EMPTY_CODE) {
        clearState();
      }

      if (finalValue.length > 3) {
        const idFromLocal = localStorage.getItem("vpcID");
        const id = requestId || idFromLocal;
        dispatch(verifyCode(id, value));
      }
    },
    [dispatch, requestId, clearState]
  );

  const { t } = useTranslation();

  return (
    <>
      <div id="alert-dialog-title">
        {t("A.verification.code.was.sent.to.your.phone.number.label")}
      </div>
      <div className="verification-code-styles">
        <ReactInputVerificationCode
          onCompleted={onCodeComplete}
          onChange={setCode}
          value={code}
        />
      </div>

      {verifyCodeRequestPending && (
        <div className="info-pending-block">
          {t("Checking.your.code.label")}...
        </div>
      )}

      {verifyCodeRequestError && (
        <div className="info-error-block">
          {t("Invalid.code.Try.again.label")}
        </div>
      )}
    </>
  );
};

const SmsVerify = ({ openModal, closeModal, phone: phoneFromProps }: *) => {
  const { t } = useTranslation();

  const {
    sendCodeToPhoneRequestPending,
    sendCodeToPhoneRequestError,
    phone,
    codeLive,
    requestId,
    showExpiredTemplate,
    verifyCodeRequestPending,
    verifyCodeRequestError,
    verifyCodeRequestSuccess,
    invalidNumber,
  } = useSelector((state) => ({
    sendCodeToPhoneRequestPending:
      state.smsVerification.sendCodeToPhoneRequest
        .sendCodeToPhoneRequestPending,
    sendCodeToPhoneRequestError:
      state.smsVerification.sendCodeToPhoneRequest.sendCodeToPhoneRequestError,
    sendCodeToPhoneRequestSuccess:
      state.smsVerification.sendCodeToPhoneRequest
        .sendCodeToPhoneRequestSuccess,

    codeLive: state.smsVerification.codeLive,
    requestId: state.smsVerification.requestId,
    phone: state.auth.user.phone,

    showExpiredTemplate: state.smsVerification.showExpiredTemplate,

    verifyCodeRequestPending:
      state.smsVerification.verifyCodeRequest.verifyCodeRequestPending,
    verifyCodeRequestError:
      state.smsVerification.verifyCodeRequest.verifyCodeRequestError,
    verifyCodeRequestSuccess:
      state.smsVerification.verifyCodeRequest.verifyCodeRequestSuccess,

    invalidNumber: state.smsVerification.invalidNumber,
  }));

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="sms-modal-vertification-wrapper"
    >
      <DialogContent className="content-dialog">
        <div className="close-icon-wrapper">
          <img src={closeIcon} alt="" onClick={() => closeModal()} />
        </div>

        <HandlePesponse
          pending={sendCodeToPhoneRequestPending}
          error={sendCodeToPhoneRequestError}
          pendingText={t("Sending.code.to.your.phone.label")}
          errorText={t("Some.Error.label")}
          phone={phone}
          invalidNumber={invalidNumber}
          invalidNumberText={t("Invalid.number.label")}
        />

        {codeLive && (
          <>
            <InputCodeTemplate
              requestId={requestId}
              verifyCodeRequestPending={verifyCodeRequestPending}
              verifyCodeRequestError={verifyCodeRequestError}
              verifyCodeRequestSuccess={verifyCodeRequestSuccess}
              closeFn={closeModal}
            />
            <Timer />
          </>
        )}

        {showExpiredTemplate && (
          <ExpiredCodeTemplate phone={phoneFromProps || phone} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SmsVerify;
