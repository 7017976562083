import React from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import logoutIcon from "../../../../assets/logout-icon.svg";
import userIcon from "../../../../assets/user-icon.svg";
import { logout } from "../../../../actions/auth/auth";
import ConfirmationModal from "../../../common/confirmation-modal/ConfirmationModal";
import { useTranslation } from "react-i18next";

const UserHeaderInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    false
  );

  const { burgerMenuState, userInfo } = useSelector((state) => ({
    userInfo: state.auth.user,
    burgerMenuState: state.burgerMenu.activeBurgerMenu,
  }));

  const onLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onPressLogOut = React.useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const userName = React.useMemo(() => {
    if (!userInfo?.email) {
      return;
    }

    return userInfo?.surname && userInfo?.name
      ? `${userInfo?.name} ${userInfo?.surname}`
      : userInfo?.email;
  }, [userInfo]);

  return (
    <div className="profile-header-user-info">
      <div className="username-wrapper">
        <img src={userIcon} alt="" />
        <span className="username">{userName}</span>
      </div>
      <div className="logout-bth-wrapper" onClick={onPressLogOut}>
        <img src={logoutIcon} alt="" />
        {burgerMenuState && <span>{t("Log.Out.label")}</span>}
      </div>

      <ConfirmationModal
        openModal={showConfirmationModal}
        onCloseModal={closeModal}
        confirmationText={t("logout.confirmation.message")}
        onPressAccept={onLogout}
      />
    </div>
  );
};

export default UserHeaderInfo;
