import React, { useEffect } from "react";
import "./styles.scss";
import { Dialog, DialogContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import closeIcon from "../../../../../../assets/close-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryLoanById } from "../../../../../../actions/loan-history/loan-history";
import {
  getLoanContract,
  getLoanRequest,
} from "../../../../../../actions/loan/loan";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import Spinner from "../../../../../common/spinner/Spinner";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../../../../utils/currency";
import { getDateByFormat } from "../../../../../../utils/parser";
import DownloadLoanPdfs from "../../download-loan-pdfs/DownloadLoanPdfs";

const LoanLoading = ({ closeLoanHistoryItem, openLoanHistoryItem }: *) => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={closeLoanHistoryItem}
      aria-labelledby="customized-dialog-title"
      open={openLoanHistoryItem}
      className="modal-loan-history-item-wrapper"
    >
      <DialogContent className="content-dialog">
        <img
          className="close-img"
          src={closeIcon}
          alt=""
          onClick={closeLoanHistoryItem}
        />
        <Spinner text={`${t("Loan.Data.Loading.label")}...`} />
      </DialogContent>
    </Dialog>
  );
};

const LoanError = ({ closeLoanHistoryItem, openLoanHistoryItem }: *) => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={closeLoanHistoryItem}
      aria-labelledby="customized-dialog-title"
      open={openLoanHistoryItem}
      className="modal-loan-history-item-wrapper"
    >
      <DialogContent className="content-dialog">
        <img
          className="close-img"
          src={closeIcon}
          alt=""
          onClick={closeLoanHistoryItem}
        />
        <ErrorMessage text={`${t("Some.Error.label")}`} />
      </DialogContent>
    </Dialog>
  );
};

const LoanHistoryItemModal = ({
  openLoanHistoryItem,
  closeLoanHistoryItem,
  electLoan,
}: *) => {
  const dispatch = useDispatch();

  const {
    currentLoanHistory,
    loansHistoryItemRequestPending,
    loansHistoryItemRequestError,
  } = useSelector((state) => ({
    dataPolicyPDF: state?.generals?.dataPolicy?.url,
    currentLoanHistory: state.loansHistory.currentLoanHistory,
    loansHistoryItemRequestPending:
      state.loansHistory.loansHistoryItemRequest.loansHistoryItemRequestPending,
    loansHistoryItemRequestError:
      state.loansHistory.loansHistoryItemRequest.loansHistoryItemRequestError,
  }));

  useEffect(() => {
    dispatch(getHistoryLoanById(electLoan.loanId));
    dispatch(getLoanContract(electLoan.loanId));
    dispatch(getLoanRequest(electLoan.loanId));
  }, [dispatch, electLoan?.loanId]);

  const { t } = useTranslation();

  if (loansHistoryItemRequestPending) {
    return (
      <LoanLoading
        closeLoanHistoryItem={closeLoanHistoryItem}
        openLoanHistoryItem={openLoanHistoryItem}
      />
    );
  }
  if (loansHistoryItemRequestError) {
    return (
      <LoanError
        closeLoanHistoryItem={closeLoanHistoryItem}
        openLoanHistoryItem={openLoanHistoryItem}
      />
    );
  }

  return (
    <Dialog
      onClose={closeLoanHistoryItem}
      aria-labelledby="customized-dialog-title"
      open={openLoanHistoryItem}
      className="modal-loan-history-item-wrapper"
    >
      <DialogContent className="content-dialog">
        <img
          className="close-img"
          src={closeIcon}
          alt=""
          onClick={closeLoanHistoryItem}
        />

        <div className="modal-title">
          {t("Loan.label")} № {t(electLoan.contractNumber)}
        </div>

        <div className="loan-history-item-table-wrapper">
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead className="loan-history-table-head">
                <TableRow>
                  <TableCell className="table-head-cell">
                    {t("Data.label")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    {t("Operation.label")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    {t("Amount.label")}
                  </TableCell>
                  <TableCell className="table-head-cell">
                    {t("Balance.label")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="loan-history-table-body">
                {currentLoanHistory.map((loanAction, i) => (
                  <TableRow className="loan-history-table-body-row" key={i}>
                    <TableCell className="table-body-cell">
                      {getDateByFormat({ date: loanAction.date })}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      {loanAction.operation}
                    </TableCell>
                    <TableCell className="table-body-cell">
                      <span>
                        {loanAction.amount
                          ? formatPrice(loanAction.amount)
                          : "-"}
                      </span>
                    </TableCell>
                    <TableCell className="table-body-cell">
                      <span>
                        {loanAction.balance
                          ? formatPrice(loanAction.balance)
                          : "-"}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <DownloadLoanPdfs />
      </DialogContent>
    </Dialog>
  );
};

export default LoanHistoryItemModal;
