import axios from "axios";
import paymentsActions from "./payments-types";
import { tokenConfig } from "../auth/auth";
import errorMapper from "../../utils/errorMapper";

export const getPaymentOperators = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: paymentsActions.FETCH_PAYMENT_OPERATORS_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/operator-payments`,
      tokenConfig(getState)
    );

    dispatch({
      type: paymentsActions.FETCH_PAYMENT_OPERATORS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: paymentsActions.FETCH_PAYMENT_OPERATORS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getPaymentFiles = ({ query = "" }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: paymentsActions.FETCH_PAYMENT_FILES_PENDING,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/operator-files${query}`,
      tokenConfig(getState)
    );

    dispatch({
      type: paymentsActions.FETCH_PAYMENT_FILES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: paymentsActions.FETCH_PAYMENT_FILES_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const processPaymentFile = ({ file, operator }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: paymentsActions.UPLOAD_PAYMENT_FILE_PENDING,
    });

    const headers = {
      "Content-Type": "multipart/form-data",
      ...tokenConfig(getState),
    };

    const formData = new FormData();

    formData.append("operatorId", operator);
    formData.append("operatorFile", file);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/admin/upload-operator-files`,
      formData,
      headers
    );

    dispatch({
      type: paymentsActions.UPLOAD_PAYMENT_FILE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: paymentsActions.UPLOAD_PAYMENT_FILE_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};
