import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.scss";

function LoanStatusActive() {
  const { t } = useTranslation();

  const { specialNotification, refinancingNotification } = useSelector(
    (state) => ({
      specialNotification: state.loan.loanInfo.specialNotification,
      refinancingNotification: state.loan.loanInfo.refinancingNotification,
    })
  );

  return (
    <div className={`loan-status-wrapper`}>
      <div className="status-indicator-wrapper active-loan">
        <span className="dot"></span>
        <span>{t("Active.loan.label")}</span>
      </div>

      {refinancingNotification && (
        <div className="status-indicator-wrapper refinanced-loan">
          <span>{refinancingNotification}</span>
        </div>
      )}

      {specialNotification && (
        <div className="status-indicator-wrapper refinanced-loan">
          <span>{specialNotification}</span>
        </div>
      )}
    </div>
  );
}

export default LoanStatusActive;
