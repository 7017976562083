import React from "react";
import "./styles.scss";

import pencilIcon from "../../../../../assets/pencil-icon.svg";
import ChangePassword from "./change-password/ChangePassword";

import { useSelector, useDispatch } from "react-redux";

import MuiPhoneInput from "material-ui-phone-number";
import SmsVerify from "../../../../account/forms/modals/verify/SmsVerify";

import {
  sendCodeToPhone,
  setCodeLiveStatus,
  setRequestId,
  resetRequestVerifyCode,
} from "../../../../../actions/sms-verification/sms-verification";
import {
  updateUserPhone,
  clearUpdatePhoneState,
} from "../../../../../actions/auth/auth";
import ProcessModal from "../../../../common/process-modal/ProcessModal";
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
  const [activeChangePassword, setChangePasswordActive] = React.useState(false);

  const onPasswordChanged = () => {
    setChangePasswordActive(false);
  };

  const changePassword = activeChangePassword ? (
    <ChangePassword onPasswordChanged={onPasswordChanged} />
  ) : (
    ""
  );

  const [
    activeChangeEmailAndPhone,
    setActiveChangeEmailAndPhone,
  ] = React.useState(false);

  const dispatch = useDispatch();

  const {
    codeLive,
    updatePhoneData,
    updatePhoneRequestError,
    updatePhoneRequestPending,
    updatePhoneRequestSuccess,
    userEmail,
    userPhone,
    verifyCodeRequestSuccess,
  } = useSelector(
    (state) => ({
      userEmail: state.auth.user.email,
      userPhone: state?.auth?.user?.profile?.phoneNumber,

      userId: state.auth.user.id,

      changePasswordRequestSuccess:
        state.auth.changePasswordRequest.changePasswordRequestSuccess,

      updatePhoneData: state?.auth.updatePhoneData,
      updatePhoneRequestPending:
        state?.auth.updatePhoneRequest.updatePhoneRequestPending,
      updatePhoneRequestError:
        state?.auth.updatePhoneRequest.updatePhoneRequestError,
      updatePhoneRequestSuccess:
        state?.auth.updatePhoneRequest.updatePhoneRequestSuccess,

      codeLive: state.smsVerification.codeLive,
      verifyCodeRequestSuccess:
        state?.smsVerification?.verifyCodeRequest?.verifyCodeRequestSuccess,
    }),
    []
  );

  const [inputEmail, setInputEmail] = React.useState(userEmail);

  const [openSmsModal, setOpenSmsModal] = React.useState(false);

  const [userInputPhone, setUserInputPhone] = React.useState(null);
  const [openPhoneModalProcess, setOpenPhoneModalProcess] = React.useState(
    false
  );

  const onCloseModal = React.useCallback(() => {
    setOpenPhoneModalProcess(false);
    setActiveChangeEmailAndPhone(false);

    dispatch(clearUpdatePhoneState());
    dispatch(resetRequestVerifyCode());
  }, [dispatch]);

  React.useEffect(() => {
    if (verifyCodeRequestSuccess) {
      setOpenSmsModal(false);
    }
  }, [verifyCodeRequestSuccess]);

  const onPhoneButtonPressed = () => {
    if (verifyCodeRequestSuccess) {
      setOpenPhoneModalProcess(true);
      dispatch(updateUserPhone(userInputPhone));
      return;
    }

    const edvcL = localStorage.getItem("edvcL");
    const vpcID = localStorage.getItem("vpcID");

    if (codeLive || edvcL) {
      setOpenSmsModal(true);
      dispatch(setCodeLiveStatus(true));
      dispatch(setRequestId(vpcID, true));
    } else {
      setOpenSmsModal(true);
      dispatch(sendCodeToPhone(userInputPhone));
    }
  };

  const { t } = useTranslation();

  return (
    <div className="contact-info-block-wrapper">
      <div className="title-wrapper">
        <div className="text">{t("Contact.Info.label")}</div>
      </div>
      <div className="contact-info-list">
        <div className="contact-info-item email-info-wrapper">
          <div className="item-content">
            <div className="title email-title">{t("Email.label")}</div>
            <input
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              disabled={!activeChangeEmailAndPhone}
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              className="value email-value disabled"
            ></input>
          </div>
        </div>
        <div className="contact-info-item personal-phone-info-wrapper">
          <div className="item-content">
            <div className="title personal-phone-title">
              {t("Personal.Phone.label")}
              <button
                className="change-bth"
                onClick={() => setActiveChangeEmailAndPhone((state) => !state)}
              >
                <span className="bth-text">{t("Change.label")}</span>
                <img src={pencilIcon} alt="" />
              </button>
            </div>
            <MuiPhoneInput
              className={`value personal-phone-value ${
                !activeChangeEmailAndPhone ? "disabled" : "active"
              }`}
              id="phone"
              name="phone"
              type="tel"
              disabled={!activeChangeEmailAndPhone}
              value={userPhone}
              onChange={(value) => setUserInputPhone(value)}
              defaultCountry="sv"
              countryCodeEditable={false}
            />
          </div>
          {activeChangeEmailAndPhone ? (
            <div className="verify-new-number-bth-wrapper">
              <div
                className={`bth-border-wrapper ${
                  userInputPhone ? "active" : "disabled"
                }`}
              >
                <button
                  disabled={!userInputPhone}
                  onClick={onPhoneButtonPressed}
                >
                  {verifyCodeRequestSuccess
                    ? t("Save.Changes.label")
                    : t("Verify.number.label")}
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="contact-info-item password-info-wrapper">
          <div className="change-bth-wrapper">
            <div className="title password-title">{t("Password.label")}</div>
            <button
              className="change-bth"
              onClick={() => setChangePasswordActive((state) => !state)}
            >
              <span className="bth-text">{t("Change.Password.label")}</span>
              <img src={pencilIcon} alt="" />
            </button>
          </div>
          {changePassword}
        </div>
      </div>

      {openSmsModal && (
        <SmsVerify
          phone={userInputPhone}
          openModal={openSmsModal}
          closeModal={() => setOpenSmsModal(false)}
        />
      )}

      {openPhoneModalProcess && (
        <ProcessModal
          openModalProcess={openPhoneModalProcess}
          closeModalProcess={onCloseModal}
          requestPending={updatePhoneRequestPending}
          requestError={updatePhoneRequestError}
          requestSuccess={updatePhoneRequestSuccess}
          pendingText={`${t("Updating.Phone.label")}...`}
          errorText={updatePhoneData?.message}
          successText={updatePhoneData?.message}
        />
      )}
    </div>
  );
};

export default ContactInfo;
