import React from "react";
import "./styles.scss";
import * as moment from "moment";

import arrowIcon from "../../../../../../assets/arrow-icon.svg";

import { useSelector, useDispatch } from "react-redux";

import {
  getPaymentProcessors,
  processPayment,
} from "../../../../../../actions/payment/payment";

import { setLoanTabLayout } from "../../../../../../actions/loan/loan";

import questionIcon from "../../../../../../assets/question-icon.svg";
import { formatPrice } from "../../../../../../utils/currency";

import { useTranslation } from "react-i18next";
import AlertModal from "../../../../../common/alert-modal/AlertModal";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import esLocale from "date-fns/locale/es";

import ProcessModal from "../../../../../common/process-modal/ProcessModal";

const LoanPaymentOptions = () => {
  const dispatch = useDispatch();

  const {
    amountReturnNow,
    amountSavedReturnNow,
    paymentProcessors,
    processPayment: processPaymentResult,
    processPaymentRequestError,
    processPaymentRequestPending,
    processPaymentRequestSuccess,
    restAmount,
    showReturnNow,
  } = useSelector((state) => ({
    amountReturnNow: state.loan.loanInfo.amountReturnNow,
    amountSavedReturnNow: state.loan.loanInfo.amountSavedReturnNow,
    bank: "state.returnMoney.bank",
    restAmount: state.loan.loanInfo.restAmount,
    paymentProcessors: state.payment.paymentProcessors,
    showReturnNow: state?.loan.loanInfo?.showReturnNow,

    processPayment: state.payment.processPayment,
    processPaymentRequestPending:
      state.payment.processPaymentRequest.processPaymentRequestPending,
    processPaymentRequestError:
      state.payment.processPaymentRequest.processPaymentRequestError,
    processPaymentRequestSuccess:
      state.payment.processPaymentRequest.processPaymentRequestSuccess,
  }));

  React.useEffect(() => {
    dispatch(getPaymentProcessors());
  }, [dispatch]);

  const [userReturnSum, setUserReturnSum] = React.useState(null);
  const [transferReference, setTransferReference] = React.useState(null);
  const [processor, setProcessor] = React.useState(null);
  const [validDate, setValidDate] = React.useState(null);
  const [showAlertModal, setShowAlertModal] = React.useState(true);
  const [date, setDate] = React.useState(null);
  const [dateTouched, setDateTouched] = React.useState(false);
  const [openPaymentProcessModal, setOpenPaymentProcessModal] = React.useState(
    false
  );

  React.useEffect(() => {
    if (paymentProcessors?.length === 1) {
      setProcessor(paymentProcessors?.[0]);
    }
  }, [paymentProcessors]);

  const toggleAlertModal = React.useCallback(() => {
    setShowAlertModal(!showAlertModal);
  }, [showAlertModal]);

  const alertContent = React.useMemo(
    () => (
      <div>
        <p>
          PISTO recibe pagos a través de los operadores y bancos listados a
          continuación, la información de pago le fue suministrada en el correo
          electrónico enviado desde PISTO cuando el dinero de su préstamo fue
          transferido.
        </p>
        {paymentProcessors.some(
          (item) => item?.operator_type === "o" && item.active
        ) && (
          <>
            <p>
              <strong>En caso de pagar mediante operador:</strong> En cualquiera
              de los agentes autorizados podrá realizar su pago a la cuenta que
              se muestra para cada operador. En el momento de formalizar el pago
              en cualquiera de estas empresas deberá indicar su DUI para que
              nuestro sistema pueda realizar la verificación y descontar el
              referido pago de su deuda actual (este proceso puede demorar unas
              horas), guarde el comprobante de pago para resolver cualquier
              inconveniente posterior que se pueda presentar.
            </p>
            <p>
              Recuerde incluir el pago de la comisión del operador para que la
              cantidad que llegue a la cuenta de PISTO sea la correcta, los
              montos de estas comisiones están especificados en la ficha de cada
              operador.
            </p>
            <p>
              Una vez realizado el pago con cualquiera de estos operadores,
              rellene la información solicitada, que consiste en el monto exacto
              del pago que realizó (nuestro sistema descontará la comisión del
              operador) y la fecha en la que realizó el pago.
            </p>
          </>
        )}
        {paymentProcessors.some(
          (item) => item?.operator_type === "b" && item.active
        ) && (
          <>
            <p>
              <strong>En caso de pagar mediante transferencia bancaria:</strong>{" "}
              Podrá pagar mediante transferencia bancaria en los bancos
              mostrados a continuación, con sus respectivos tipos y números de
              cuenta. Deberá conservar el número de referencia de la
              transferencia para facilitarlo a PISTO con la finalidad de poder
              verificar la información de pago. Guarde la captura o el
              comprobante de la transferencia para resolver cualquier
              inconveniente posterior que se pueda presentar.
            </p>
            <p>
              En el caso de pagos mediante transferencia bancaria no existe
              comisión, por lo que el monto transferido será reconocido por
              PISTO en su totalidad.
            </p>
            <p>
              Una vez realizado el pago mediante transferencia bancaria, rellene
              la información solicitada, que consiste en el monto exacto del
              pago que realizó, la fecha en la que realizó el pago y la
              referencia de la transferencia bancaria.
            </p>
          </>
        )}
      </div>
    ),
    [paymentProcessors]
  );

  const onDateChanged = React.useCallback((date) => {
    const isValid = moment(date).isValid();

    const isBefore = moment(date).isBefore(moment());

    if (isValid && isBefore) {
      setDate(date);
    }

    setValidDate(isValid && isBefore);
  }, []);

  const getComission = React.useCallback((item) => {
    const comission =
      item.commission_type === "amount"
        ? formatPrice(item.commission_value_amount)
        : `${item.commission_value_percentage} %`;

    return <span className="value">{comission}</span>;
  }, []);

  const onProcessorSelected = React.useCallback((item) => {
    setProcessor(item);
  }, []);

  const onCloseModal = React.useCallback(() => {
    if (processPaymentRequestSuccess) {
      dispatch(setLoanTabLayout(1));
    }

    setOpenPaymentProcessModal(false);
  }, [processPaymentRequestSuccess, dispatch]);

  const finalAmount = React.useMemo(
    () => userReturnSum - processor?.commission_value_amount,
    [userReturnSum, processor]
  );

  const onProcessPayment = React.useCallback(() => {
    setOpenPaymentProcessModal(true);

    dispatch(
      processPayment({
        date: moment(date).format("YYYY-MM-DD"),
        amount: finalAmount,
        operatorId: processor.id,
        operatorType: processor.operator_type,
        reference: transferReference,
      })
    );
  }, [dispatch, finalAmount, date, transferReference, processor]);

  const { t } = useTranslation();
  return (
    <div className="loan-payment-options-wrapper">
      <div className="payment-block-header">
        <div className="header-desciption">
          {t("Make.a.loan.payment.label")}

          <span className="question-icon-wrapper" onClick={toggleAlertModal}>
            <img src={questionIcon} alt="" />
          </span>
        </div>
        <div className="header-loan-amount-return">
          <span>{formatPrice(restAmount)}</span>
          <span>{t("Rest.amount.label")}</span>
        </div>
      </div>

      {showReturnNow && (
        <div className="current-sum-return-wrapper">
          {t("Return.today.label-1")}{" "}
          <span>{formatPrice(amountReturnNow)}</span>
          {t("Return.today.label-2")}{" "}
          <span>{formatPrice(amountSavedReturnNow)}</span>
        </div>
      )}

      {paymentProcessors.length > 1 && (
        <span className="title-process">{t("Select.payment.label")}</span>
      )}

      <div className="return-money-variants">
        {paymentProcessors.map((item, i) => (
          <div
            key={i}
            className={`return-variant-wrapper ${
              processor?.name === item.name ? "active" : "disabled"
            }`}
            onClick={() => onProcessorSelected(item)}
          >
            <div className="title">{item.name}</div>
            <div className="logo-icon-wrapper">
              <img src={item.logo} alt={item.name} />
            </div>
            <div className="conditionals-list-wrapper">
              <div className="conditional-block-wrapper conditional-commission-wrapper">
                <span className="title">
                  {t("Payment.processor.account.name")}
                </span>
                <span className="value">{item.account_name}</span>
              </div>
              {item?.operator_type === "b" && (
                <div className="conditional-block-wrapper conditional-commission-wrapper">
                  <span className="title">{t("Account.number.label")}</span>
                  <span className="value">{item.account_number}</span>
                </div>
              )}
              {item?.operator_type === "o" && (
                <div className="conditional-block-wrapper conditional-commission-wrapper">
                  <span className="title">{t("Commission.label")}</span>
                  {getComission(item)}
                </div>
              )}
              {item?.operator_type === "o" && (
                <div className="conditional-block-wrapper conditional-work-hours-wrapper">
                  <span className="title">{t("Work.Hours.label")}</span>
                  <span className="value">{t(item.working_hours)}</span>
                </div>
              )}

              {item?.operator_type === "b" && (
                <div className="conditional-block-wrapper conditional-work-hours-wrapper">
                  <span className="title">{t("Account.type.label")}</span>
                  <span className="value">{t(item.account_type)}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="loan-info">
        <div className="loan-info-item">
          <div className="block-title">{t("Return.amount.label")}</div>
          <div className="block-content-wrapper">
            <div className="input-amount-wrapper">
              <input
                type="number"
                onKeyDown={(e) =>
                  (e.keyCode === 69 || e.keyCode === 189) && e.preventDefault()
                }
                value={userReturnSum}
                onChange={(e) => setUserReturnSum(() => e.target.value)}
              />
              <span className="dolar">$</span>
            </div>
          </div>
        </div>

        <div className="loan-info-item">
          <div className="block-title">{t("Return.date.label")}</div>
          <div
            className={`block-content-wrapper ${
              !validDate && dateTouched ? "error" : ""
            }`}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={date}
                onChange={onDateChanged}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                maxDate={moment()}
                disableFuture={true}
                allowKeyboardControl={true}
                invalidDateMessage=""
                minDateMessage=""
                maxDateMessage=""
                onBlur={() => {
                  setDateTouched(true);
                }}
                onAccept={(value) => {
                  setDate(value);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>

      {processor?.operator_type === "b" && (
        <div className="loan-info-second">
          <div className="loan-info-item">
            <div className="block-title">{t("Transfer.number.label")}</div>
            <div className="block-content-wrapper">
              <div className="input-amount-wrapper">
                <input
                  type="number"
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 189) &&
                    e.preventDefault()
                  }
                  value={transferReference}
                  onChange={(e) => setTransferReference(() => e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {processor && ![0, "0", null, undefined, ""].includes(userReturnSum) && (
        <>
          <div className="total-payment-block-wrapper">
            <div className="title">{t("Process.loan.payment.button")}</div>
            <div className="value">{formatPrice(finalAmount)}</div>
          </div>

          <div className="bth-wrapper pay-loan-bth-wrapper">
            <button
              disabled={
                !validDate ||
                !processor ||
                (processor?.operator_type === "b" && !transferReference)
              }
              className={`bth pay-loan-bth ${
                !validDate ||
                !processor ||
                (processor?.operator_type === "b" && !transferReference)
                  ? "disabled"
                  : "active"
              }`}
              onClick={onProcessPayment}
            >
              <span>{t("Register.payment.label")}</span>
              <img className="arrow-icon" src={arrowIcon} alt="" />
            </button>
          </div>
        </>
      )}

      {openPaymentProcessModal && (
        <ProcessModal
          openModalProcess={openPaymentProcessModal}
          closeModalProcess={onCloseModal}
          requestPending={processPaymentRequestPending}
          requestError={processPaymentRequestError}
          requestSuccess={processPaymentRequestSuccess}
          pendingText={`${t("Sending.payment.information")}...`}
          errorText={processPaymentResult?.message}
          successText={processPaymentResult?.message}
        />
      )}

      <AlertModal
        openModal={showAlertModal}
        onCloseModal={toggleAlertModal}
        title={t("Important.info.label")}
        onPressAccept={toggleAlertModal}
        content={alertContent}
      />
    </div>
  );
};

export default LoanPaymentOptions;
