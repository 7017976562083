import React from "react";

import PaymentOptions from "./payment-options/PaymentOptions";
import DismissOptions from "./dismiss-options/DismissOptions";
import ChooseAnotherLoanOption from "./choose-another-loan-option/ChooseAnotherLoanOption";
import GetMoneyOptions from "./get-money-options/GetMoneyOptions";

import { useSelector } from "react-redux";

import loanStatuses from "../../../../../../constants/loanStatuses";
import userStatuses from "../../../../../../constants/userStatuses";

function LoanActions() {
  const { loanLayoutStatus, userStatus } = useSelector((state) => ({
    loanLayoutStatus: state.loan?.loanLayoutStatus,
    userStatus: state.auth?.user?.status,
  }));

  const isSuspended = userStatus === userStatuses.SUSPENDED;

  const value = isSuspended ? loanStatuses.SUSPENDED : loanLayoutStatus;

  switch (value) {
    // 2 - ACTIVE
    case loanStatuses.ACCEPTED:
      return <GetMoneyOptions />;
    // 6 - ACTIVE
    case loanStatuses.ACTIVE:
      return <PaymentOptions />;
    // 1 - UNDER_CONSIDERATION
    case loanStatuses.UNDER_CONSIDERATION:
      return <DismissOptions />;
    // 8 - BAD_PHOTO
    case loanStatuses.BAD_PHOTO:
      return <DismissOptions />;
    // 4 - CANCELED
    case loanStatuses.CANCELED:
      return "";
    // 7 - OVERDUE
    case loanStatuses.OVERDUE:
      return <PaymentOptions />;
    // 5- PAID
    case loanStatuses.PAID:
      return <ChooseAnotherLoanOption />;
    default:
      return "";
  }
}

export default LoanActions;
