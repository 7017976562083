import React from "react";
import "./styles.scss";

import logoBlackIcon from "../../../assets/logo-black-icon.svg";

import unionIcon from "../../../assets/union-icon.svg";
import tick3Icon from "../../../assets/tick3-icon.svg";

import { useDispatch, connect } from "react-redux";

import { useHistory } from "react-router-dom";

import NotificationsBlock from "../notifications-block/NotificationsBlock";
import {
  setLoanTabLayoutStep,
  setLoanTabLayout,
} from "../../../actions/loan/loan";

import { setBurgetMenuState } from "../../../actions/burger-menu/burger-menu";
import UserHeaderInfo from "./user-header-info/UserHeaderInfo";
import { useTranslation } from "react-i18next";

function navigateBack(prevLoanLayout, prevlayoutStep, dispatch) {
  if (prevlayoutStep) {
    dispatch(setLoanTabLayoutStep(prevlayoutStep));
  } else {
    dispatch(setLoanTabLayout(prevLoanLayout));
  }
}

function UserProfileHeader({
  clickNotificationCb,
  userAuth,
  prevLoanLayout,
  prevlayoutStep,
  layout,
  burgerMenuState,
  userNotifications,
}: *) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeNotificationBlock, setActiveNotificationBlock] = React.useState(
    false
  );
  const { t } = useTranslation();
  const [activeNavigationBackBth, setActiveNavigationBackBth] = React.useState(
    layout !== 1
  );

  if (!userAuth) {
    history.push("/login");
  }

  React.useEffect(() => {
    function handleClickOutside(event) {
      const checboxNotificationsBlockWrapper = event.target.closest(
        ".notifications-block-wrapper"
      );
      const notificationUserBthS = event.target.closest(
        ".notifications-icon-wrapper"
      );
      if (!checboxNotificationsBlockWrapper && !notificationUserBthS) {
        setActiveNotificationBlock(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    setActiveNavigationBackBth(layout !== 1);
  }, [layout]);

  return (
    <div className="header-wrapper">
      <div className="burger-menu__content">
        <div
          id="burger-menu__wrapper"
          onClick={() => dispatch(setBurgetMenuState(!burgerMenuState))}
          className={burgerMenuState ? "open" : ""}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="logo-wrapper">
        <img src={logoBlackIcon} alt="" />
      </div>
      <div
        className={`header-navigation-wrapper ${
          activeNavigationBackBth ? "s-b" : "f-e"
        }`}
      >
        {activeNavigationBackBth ? (
          <div
            className="back-bth-wrapper"
            onClick={() =>
              navigateBack(prevLoanLayout, prevlayoutStep, dispatch)
            }
          >
            <img src={tick3Icon} alt="" />
            <span>{t("Back.label")}</span>
          </div>
        ) : (
          ""
        )}

        <div className="left-block">
          <div
            className="notifications-icon-wrapper"
            onClick={() => setActiveNotificationBlock((state) => !state)}
          >
            <img src={unionIcon} alt="" onClick={clickNotificationCb} />
            <span className="notification-count">
              {userNotifications.filter((item) => !item.readAt).length}
            </span>
          </div>
          <div className="user-header-info-wrapper">
            <UserHeaderInfo />
          </div>
        </div>
      </div>
      <NotificationsBlock activeNotificationBlock={activeNotificationBlock} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.isAuthenticated,
    userInfo: state.auth.user,

    prevLoanLayout: null,
    prevlayoutStep: null,

    layout: {},
    burgerMenuState: state.burgerMenu.activeBurgerMenu,

    userNotifications: state.notifications.userNotifications,
  };
};

export default connect(mapStateToProps, null)(UserProfileHeader);
