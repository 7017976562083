import generalsActions from "../../actions/generals/generals-types";

const initialState = {
  faqs: [],
  faqsRequest: {
    faqsRequestPending: false,
    faqsRequestError: false,
    faqsRequestSuccess: false,
  },

  landingFaqs: [],
  landingFaqsRequest: {
    landingFaqsRequestPending: false,
    landingFaqsRequestError: false,
    landingFaqsRequestSuccess: false,
  },

  departments: [],
  departmentsRequest: {
    departmentsRequestPending: false,
    departmentsRequestError: false,
    departmentsRequestSuccess: false,
  },

  posts: [],
  postsRequest: {
    postsRequestPending: false,
    postsRequestError: false,
    postsRequestSuccess: false,
  },

  creditLimits: [],
  creditLimitsRequest: {
    creditLimitsPending: false,
    creditLimitsError: false,
    creditLimitsSuccess: false,
  },

  dataPolicy: null,
  termsOfService: null,
};

function generalsReducer(state = initialState, action) {
  switch (action.type) {
    case generalsActions.GET_FAQS_PENDING:
      return {
        ...state,
        faqs: [],
        faqsRequest: {
          faqsRequestPending: true,
          faqsRequestError: false,
          faqsRequestSuccess: false,
        },
      };
    case generalsActions.GET_FAQS_ERROR:
      return {
        ...state,
        faqs: [],
        faqsRequest: {
          faqsRequestPending: false,
          faqsRequestError: true,
          faqsRequestSuccess: false,
        },
      };
    case generalsActions.GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        faqsRequest: {
          faqsRequestPending: false,
          faqsRequestError: false,
          faqsRequestSuccess: true,
        },
      };
    case generalsActions.GET_LANDING_FAQS_PENDING:
      return {
        ...state,
        landingFaqs: [],
        landingFaqsRequest: {
          landingFaqsRequestPending: true,
          landingFaqsRequestError: false,
          landingFaqsRequestSuccess: false,
        },
      };
    case generalsActions.GET_LANDING_FAQS_ERROR:
      return {
        ...state,
        landingFaqs: [],
        landingFaqsRequest: {
          landingFaqsRequestPending: false,
          landingFaqsRequestError: true,
          landingFaqsRequestSuccess: false,
        },
      };
    case generalsActions.GET_LANDING_FAQS_SUCCESS:
      return {
        ...state,
        landingFaqs: action.payload,
        landingFaqsRequest: {
          landingFaqsRequestPending: false,
          landingFaqsRequestError: false,
          landingFaqsRequestSuccess: true,
        },
      };
    case generalsActions.GET_DEPARTMENTS_PENDING:
      return {
        ...state,
        departments: [],
        departmentsRequest: {
          departmentsRequestPending: true,
          departmentsRequestError: false,
          departmentsRequestSuccess: false,
        },
      };
    case generalsActions.GET_DEPARTMENTS_ERROR:
      return {
        ...state,
        departments: [],
        departmentsRequest: {
          departmentsRequestPending: false,
          departmentsRequestError: true,
          departmentsRequestSuccess: false,
        },
      };
    case generalsActions.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload,
        departmentsRequest: {
          departmentsRequestPending: false,
          departmentsRequestError: false,
          departmentsRequestSuccess: true,
        },
      };
    case generalsActions.GET_LIMIT_VALUES_PENDING:
      return {
        ...state,
        creditLimits: null,
        creditLimitsRequest: {
          creditLimitsPending: true,
          creditLimitsError: false,
          creditLimitsSuccess: false,
        },
      };
    case generalsActions.GET_LIMIT_VALUES_ERROR:
      return {
        ...state,
        creditLimits: null,
        creditLimitsRequest: {
          creditLimitsPending: false,
          creditLimitsError: true,
          creditLimitsSuccess: false,
        },
      };
    case generalsActions.GET_LIMIT_VALUES_SUCCESS:
      return {
        ...state,
        creditLimits: action.payload,
        creditLimitsRequest: {
          creditLimitsPending: false,
          creditLimitsError: false,
          creditLimitsSuccess: true,
        },
      };
    case generalsActions.GET_POSTS_PENDING:
      return {
        ...state,
        posts: [],
        postsRequest: {
          postsRequestPending: true,
          postsRequestError: false,
          postsRequestSuccess: false,
        },
      };
    case generalsActions.GET_POSTS_ERROR:
      return {
        ...state,
        posts: [],
        postsRequest: {
          postsRequestPending: false,
          postsRequestError: true,
          postsRequestSuccess: false,
        },
      };
    case generalsActions.GET_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload,
        postsRequest: {
          postsRequestPending: false,
          postsRequestError: false,
          postsRequestSuccess: false,
        },
      };
    case generalsActions.GET_PERSONAL_DATA_POLICY_SUCCESS:
      return {
        ...state,
        dataPolicy: action.payload,
      };

    case generalsActions.GET_TERMS_OF_SERVICE_SUCCESS:
      return {
        ...state,
        termsOfService: action.payload,
      };
    default:
      return state;
  }
}

export default generalsReducer;
