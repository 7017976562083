import React from 'react';
import ContractsHeader from './contracts-header/ContractsHeader';
import ContractsList from './contracts-list/ContractsList';

import './styles.scss';

function ContractsLayout() {
  return (
    <div className="contracts-layout-wrapper">
      <ContractsHeader />
      <ContractsList />
    </div>
  );
}

export default ContractsLayout;
