import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../../../assets/logo.svg";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function VerificationPageHeader() {
  const { t } = useTranslation();
  return (
    <header className="verification-page-header">
      <div className="content container-width">
        <div className="verification-page-logo__wrapper">
          <Link to="/" className="nav_link">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="nav__wrapper">
          <div className="bth-login__wrapper">
            <button className="bth bth-login">
              <Link to="/login" className="nav_link">
                {t("Client.Zone.label")}
              </Link>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default VerificationPageHeader;
