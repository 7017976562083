import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "@material-ui/core";

import closeIcon from "../../../../../../assets/close-icon.svg";

import LoanData from "../../../../../user-profile-page/tabs/loan-tab-panel/loan-getting-permission-process/loan-content/loan-data/LoanData";
import LoanStatus from "../../../../../user-profile-page/tabs/loan-tab-panel/loan-getting-permission-process/loan-status/LoanStatus";
import LoanTitle from "../../../../../user-profile-page/tabs/loan-tab-panel/loan-getting-permission-process/loan-title/LoanTitle";

import { useDispatch, useSelector } from "react-redux";

import { fetchLoanReturnScaleByContractNumber } from "../../../../../../actions/conciliations/conciliations";

import Spinner from "../../../../../common/spinner/Spinner";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";

const CheckLoanTime = ({ openModal, onCloseModal }: *) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contractNumber, setcontractNumber] = React.useState("");

  const {
    refinancedLoanData,
    refinancedLoanDataRequestPending,
    refinancedLoanDataRequestError,
    refinancedLoanDataRequestSuccess,
    checkReturnScaleData,
    checkReturnScaleRequestPending,
    checkReturnScaleRequestError,
    checkReturnScaleRequestSuccess,
  } = useSelector((state) => ({
    refinancedLoanData: state.conciliations.refinancedLoanData,
    refinancedLoanDataRequestPending:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestPending,
    refinancedLoanDataRequestError:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestError,
    refinancedLoanDataRequestSuccess:
      state.conciliations.refinancedLoanDataRequest
        .refinancedLoanDataRequestSuccess,

    checkReturnScaleData: state.conciliations.checkReturnScale,
    checkReturnScaleRequestPending:
      state.conciliations.checkReturnScaleRequest
        .checkReturnScaleRequestPending,
    checkReturnScaleRequestError:
      state.conciliations.checkReturnScaleRequest.checkReturnScaleRequestError,
    checkReturnScaleRequestSuccess:
      state.conciliations.checkReturnScaleRequest
        .checkReturnScaleRequestSuccess,
  }));

  const onCloseCancellationModal = React.useCallback(() => {
    setcontractNumber("");
    onCloseModal();
  }, [onCloseModal]);

  const onCheckLoan = React.useCallback(() => {
    dispatch(fetchLoanReturnScaleByContractNumber(contractNumber));
  }, [dispatch, contractNumber]);

  return (
    <Dialog
      onClose={onCloseModal}
      open={openModal}
      className="modal-emergency-item-wrapper"
    >
      <DialogContent className="emergency-item-content">
        <div className="emergency-item-header-wrapper">
          <div className="emergency-item-actions-wrapper">
            <div className="emergency-item-title">
              {t("Check.loan.time.title")}
            </div>
          </div>
          <div className="close-img-wrapper" onClick={onCloseCancellationModal}>
            <img className="close-img" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="input-container">
            <input
              className="input-item"
              placeholder={t("Loan.id.label")}
              value={contractNumber}
              onChange={(e) => {
                const value = e.target.value;

                if (!/^[\d*-]*\d*$/.test(value) && value !== "") {
                  return;
                }

                setcontractNumber(e.target.value);
              }}
            />

            <div className="payment-bths-wrapper">
              <div className="bth-wrapper make-payment-bth-wrapper">
                <button
                  className="bth make-payment-bth"
                  disabled={!contractNumber}
                  onClick={onCheckLoan}
                >
                  {t("Search.loan.label")}
                </button>
              </div>
            </div>
          </div>

          {refinancedLoanDataRequestPending && (
            <div className="loading-wrapper">
              <Spinner text={`${t("Loading.data.label")}...`} />
            </div>
          )}

          {refinancedLoanDataRequestError && (
            <ErrorMessage text={refinancedLoanData?.message} />
          )}

          {refinancedLoanDataRequestSuccess && (
            <>
              <div className="loan-data">
                <div>{`${t("Client.label")}: ${
                  refinancedLoanData?.client
                }`}</div>
                <div>{`${t("Contract.No.label")}: ${
                  refinancedLoanData?.contractNumber
                }`}</div>
              </div>
            </>
          )}
        </div>

        {checkReturnScaleRequestPending && (
          <Spinner text={`${t("Loan.Data.Loading.label")}...`} />
        )}

        {checkReturnScaleRequestError && checkReturnScaleData && (
          <ErrorMessage text={checkReturnScaleData?.message} />
        )}

        {checkReturnScaleRequestSuccess && (
          <div className="loan-container">
            <LoanTitle />
            <LoanStatus />
            <LoanData />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CheckLoanTime;
