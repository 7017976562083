import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { recoverPassword } from "../../actions/auth/auth";
import { useTranslation } from "react-i18next";
import eyeIcon from "../../assets/eye-icon.svg";
import eyeIconStrike from "../../assets/eye-icon-strike.svg";
import PasswordValidatorMessage from "../common/password-validator-message/PasswordValidatorMessage";
import ProcessModal from "../common/process-modal/ProcessModal";

import {
  checkPasswordLength,
  checkPasswordContainsNumbers,
  checkPasswordContainsLowerLetters,
  checkPasswordContainsUpperLetters,
  checkPasswordContainsSpecialCharacter,
  comparePasswordInputs,
} from "../../utils/regex";
import { useAnalitycs } from "../../hooks/useAnalytics";

const SetRecoverPasswordPage = ({ location }: *) => {
  useAnalitycs("ChangePassword");

  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [password, setPassword] = React.useState("");
  const [passwordTouched, setPasswordTouched] = React.useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordComparing, setPasswordComparing] = React.useState(null);
  const [validForm, setValidForm] = React.useState(false);
  const [
    showPasswordValidatorMessage,
    setShowPasswordValidatorMessage,
  ] = React.useState(null);
  const [openModalProcess, setOpenModalProcess] = React.useState(false);
  const history = useHistory();
  const [showNavigateBth, setShowNavigateBth] = React.useState(false);

  const {
    recoverPasswordRequestPending,
    recoverPasswordRequestError,
    recoverPasswordRequestSuccess,
    recoverPasswordRequestErrorMessage,
    recoverPasswordRequestSuccessMessage,
  } = useSelector((state) => ({
    recoverPasswordRequestErrorMessage:
      state.auth.recoverPasswordRequestErrorMessage,
    recoverPasswordRequestSuccessMessage:
      state.auth.recoverPasswordRequestSuccessMessage,
    recoverPasswordRequestPending:
      state.auth.recoverPasswordRequest.recoverPasswordRequestPending,
    recoverPasswordRequestError:
      state.auth.recoverPasswordRequest.recoverPasswordRequestError,
    recoverPasswordRequestSuccess:
      state.auth.recoverPasswordRequest.recoverPasswordRequestSuccess,
  }));

  React.useEffect(() => {
    if (passwordTouched) {
      setShowPasswordValidatorMessage(() => {
        const validPasswordLength = checkPasswordLength(password);
        const containsNumbers = checkPasswordContainsNumbers(password);
        const containsSpecialCharacter = checkPasswordContainsSpecialCharacter(
          password
        );
        const containsLowerLetters = checkPasswordContainsLowerLetters(
          password
        );
        const containsUpperLetters = checkPasswordContainsUpperLetters(
          password
        );
        return (
          validPasswordLength &&
          containsNumbers &&
          containsLowerLetters &&
          containsUpperLetters &&
          containsSpecialCharacter
        );
      });
    }
  }, [password, passwordTouched]);

  React.useEffect(() => {
    setValidForm(() => {
      return showPasswordValidatorMessage && passwordComparing;
    });
  }, [passwordConfirmation, showPasswordValidatorMessage, passwordComparing]);

  React.useEffect(() => {
    if (recoverPasswordRequestSuccess) {
      setShowNavigateBth(true);
    }
  }, [recoverPasswordRequestSuccess]);

  const token = React.useMemo(() => {
    return params.token;
  }, [params.token]);

  const email = React.useMemo(() => {
    const address = location?.search || "";
    return address.replace("?email=", "");
  }, [location?.search]);

  const emptyPassword = React.useMemo(() => password.length === 0, [password]);

  const onNewPassChange = React.useCallback(
    (e) => {
      setPasswordTouched(true);
      setPassword(() => {
        setPasswordComparing(
          comparePasswordInputs(e.target.value, passwordConfirmation)
        );
        return e.target.value;
      });
    },
    [passwordConfirmation]
  );

  const onConfirmChange = React.useCallback(
    (e) => {
      setPasswordConfirmation(() => {
        setPasswordComparing(comparePasswordInputs(password, e.target.value));
        return e.target.value;
      });
    },
    [password]
  );

  const onClickSubmit = React.useCallback(() => {
    setOpenModalProcess(true);
    dispatch(recoverPassword({ email, token, password, passwordConfirmation }));
  }, [email, token, passwordConfirmation, password, dispatch]);

  return (
    <div className="recover-password-page-wrapper">
      <div className="content">
        <div className="activation-title">
          {t("Recover.Password.Page.label")}
        </div>
        <div className="form-content">
          {!showNavigateBth ? (
            <>
              <div className="password-input-wrapper new-password-input-wrapper">
                <label htmlFor="newPassword">{t("New.password.label")} </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  value={password}
                  onChange={onNewPassChange}
                />
                {emptyPassword && passwordTouched && (
                  <div className="info-wrapper">{t("Required.label")}</div>
                )}
                <div
                  className={`eye-icon__wrapper ${
                    showPassword ? "" : "visible"
                  }`}
                >
                  <img
                    className="eye-icon"
                    src={showPassword ? eyeIcon : eyeIconStrike}
                    alt=""
                    onClick={() => setShowPassword((state) => !state)}
                  />
                </div>

                {showPasswordValidatorMessage === false ? (
                  <PasswordValidatorMessage
                    minLength={checkPasswordLength(password)}
                    containNumbers={checkPasswordContainsNumbers(password)}
                    containSpecialCaracters={checkPasswordContainsSpecialCharacter(
                      password
                    )}
                    containUpperLetters={checkPasswordContainsUpperLetters(
                      password
                    )}
                    containLowerLetters={checkPasswordContainsLowerLetters(
                      password
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="password-input-wrapper new-password-input-wrapper">
                <label htmlFor="newPasswordConfirmation">
                  {t("Confirm.password.label")}
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="newPasswordConfirmation"
                  id="newPasswordConfirmation"
                  value={passwordConfirmation}
                  onChange={onConfirmChange}
                />
                <div
                  className={`eye-icon__wrapper ${
                    showConfirmPassword ? "" : "visible"
                  }`}
                >
                  <img
                    className="eye-icon"
                    src={showConfirmPassword ? eyeIcon : eyeIconStrike}
                    alt=""
                    onClick={() => setShowConfirmPassword((state) => !state)}
                  />
                </div>
                {passwordComparing === false ? (
                  <div className="info-wrapper">
                    {t("Your.new.password.does.not.match.label")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="bth-wrapper set-password-bth-wrapper">
                <button
                  onClick={onClickSubmit}
                  disabled={!validForm}
                  className={`bth set-password-bth ${
                    validForm ? "active" : "disabled"
                  }`}
                >
                  {t("Set.Password.label")}
                </button>
              </div>
            </>
          ) : (
            <div className="bth-wrapper continue-bth-wrapper">
              <button
                type="button"
                className="bth continue-bth"
                onClick={() => history.push("/login")}
              >
                {t("Go.to.profile.label")}
              </button>
            </div>
          )}
        </div>
        {openModalProcess ? (
          <ProcessModal
            openModalProcess={openModalProcess}
            closeModalProcess={() => setOpenModalProcess(false)}
            requestPending={recoverPasswordRequestPending}
            requestError={recoverPasswordRequestError}
            requestSuccess={recoverPasswordRequestSuccess}
            fnOnDestroy={() => {
              // dispatch(reseRecoverPasswordRequestState(false));
            }}
            pendingText={`${t("Updating.password.label")}...`}
            errorText={recoverPasswordRequestErrorMessage}
            successText={recoverPasswordRequestSuccessMessage}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SetRecoverPasswordPage;
