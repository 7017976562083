import React from "react";
import PasswordValidatorMessage from "../../../../../common/password-validator-message/PasswordValidatorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  resetChangePassword,
} from "../../../../../../actions/auth/auth";

import ProcessModal from "../../../../../common/process-modal/ProcessModal";
import eyeIcon from "../../../../../../assets/eye-icon.svg";
import eyeIconStrike from "../../../../../../assets/eye-icon-strike.svg";
import { useTranslation } from "react-i18next";

import {
  checkPasswordLength,
  checkPasswordContainsNumbers,
  checkPasswordContainsLowerLetters,
  checkPasswordContainsUpperLetters,
  checkPasswordContainsSpecialCharacter,
  compareOldAndNewPasswords,
  compareNewPasswordsConfirmation,
} from "../../../../../../utils/regex";

const ChangePassword = ({ onPasswordChanged }: *) => {
  const dispatch = useDispatch();

  const [passwords, setPasswords] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  React.useEffect(() => {
    dispatch(resetChangePassword());
  }, [dispatch]);

  const [validNewPassword, setValidationNewPassword] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  const [
    showPasswordValidatorMessage,
    setPasswordValidatorMessageActive,
  ] = React.useState(false);

  const {
    changePasswordRequestPending,
    changePasswordRequestError,
    changePasswordRequestSuccess,
    changePasswordRequestMsg,
  } = useSelector((state) => ({
    changePasswordRequestPending:
      state.auth.changePasswordRequest.changePasswordRequestPending,
    changePasswordRequestError:
      state.auth.changePasswordRequest.changePasswordRequestError,
    changePasswordRequestSuccess:
      state.auth.changePasswordRequest.changePasswordRequestSuccess,
    changePasswordRequestMsg: state.auth.changePasswordRequestMsg,
  }));

  const checkPasswordLengthValid = checkPasswordLength(passwords.newPassword);
  const checkPasswordContaintNumbersValid = checkPasswordContainsNumbers(
    passwords.newPassword
  );

  const compareOldAndNewPasswordsValid = compareOldAndNewPasswords(
    passwords.oldPassword,
    passwords.newPassword
  );

  const compareNewPasswordsConfirmationValid = compareNewPasswordsConfirmation(
    passwords.newPassword,
    passwords.newPasswordConfirmation
  );

  const toggleVisibilityPassword = (target) => {
    setShowPassword({
      ...showPassword,
      [target]: !showPassword[target],
    });
  };

  const onCloseModal = () => {
    setOpenPasswordModalProcess(false);
    onPasswordChanged();
  };

  const [
    openPasswordModalProcess,
    setOpenPasswordModalProcess,
  ] = React.useState(false);

  React.useEffect(() => {
    setValidationNewPassword(
      checkPasswordLengthValid &&
        checkPasswordContaintNumbersValid &&
        !compareOldAndNewPasswordsValid &&
        compareNewPasswordsConfirmationValid &&
        checkPasswordContainsLowerLetters(passwords.newPassword) &&
        checkPasswordContainsUpperLetters(passwords.newPassword) &&
        checkPasswordContainsSpecialCharacter(passwords.newPassword)
    );
    if (changePasswordRequestSuccess) {
      setPasswords({
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      });
    }
  }, [
    passwords.oldPassword,
    passwords.newPassword,
    passwords.newPasswordConfirmation,
    changePasswordRequestSuccess,
    checkPasswordLengthValid,
    compareNewPasswordsConfirmationValid,
    compareOldAndNewPasswordsValid,
    dispatch,
    checkPasswordContaintNumbersValid,
  ]);

  const { t } = useTranslation();
  return (
    <div className="changing-password-inputs-wrapper">
      <div className="password-input-wrapper old-password-input-wrapper">
        <label htmlFor="oldPassword">{t("Old.Password.label")} </label>
        <div className="input-container">
          <input
            type={showPassword.oldPassword ? "text" : "password"}
            name="oldPassword"
            id="oldPassword"
            value={passwords.oldPassword}
            onChange={(e) => {
              setPasswords((passwords) => ({
                ...passwords,
                oldPassword: e.target.value,
              }));
            }}
          />

          <div
            className={`eye-icon__wrapper ${
              showPassword?.oldPassword ? "" : "visible"
            }`}
          >
            <img
              className="eye-icon"
              src={showPassword ? eyeIcon : eyeIconStrike}
              alt=""
              onClick={() => toggleVisibilityPassword("oldPassword")}
            />
          </div>
        </div>
      </div>
      <div className="password-input-wrapper new-password-input-wrapper">
        <label htmlFor="newPassword">{t("New.Password.label")} </label>
        <div className="input-container">
          <input
            type={showPassword.newPassword ? "text" : "password"}
            name="newPassword"
            id="newPassword"
            value={passwords.newPassword}
            onChange={(e) => {
              setPasswords((passwords) => ({
                ...passwords,
                newPassword: e.target.value,
              }));
            }}
            onBlur={() =>
              setPasswordValidatorMessageActive(
                !checkPasswordLengthValid ||
                  !checkPasswordContaintNumbersValid ||
                  !checkPasswordContainsLowerLetters(passwords.newPassword) ||
                  !checkPasswordContainsUpperLetters(passwords.newPassword) ||
                  !checkPasswordContainsSpecialCharacter(passwords.newPassword)
              )
            }
          />
          <div
            className={`eye-icon__wrapper ${
              showPassword.newPassword ? "" : "visible"
            }`}
          >
            <img
              className="eye-icon"
              src={showPassword ? eyeIcon : eyeIconStrike}
              alt=""
              onClick={() => toggleVisibilityPassword("newPassword")}
            />
          </div>
        </div>
        {showPasswordValidatorMessage ? (
          <PasswordValidatorMessage
            minLength={checkPasswordLengthValid}
            containNumbers={checkPasswordContaintNumbersValid}
            containLowerLetters={checkPasswordContainsLowerLetters(
              passwords.newPassword
            )}
            containUpperLetters={checkPasswordContainsUpperLetters(
              passwords.newPassword
            )}
            containSpecialCaracters={checkPasswordContainsSpecialCharacter(
              passwords.newPassword
            )}
          />
        ) : (
          ""
        )}

        {compareOldAndNewPasswordsValid &&
        passwords.oldPassword & passwords.newPassword ? (
          <div className="info-wrapper">
            {t("New.and.old.password.are.the.same.label")}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="password-input-wrapper new-password-confirmation-input-wrapper">
        <label htmlFor="newPasswordConfirmation">
          {t("New.Password.Confirmation.label")}
        </label>
        <div className="input-container">
          <input
            type={showPassword.newPasswordConfirmation ? "text" : "password"}
            name="newPasswordConfirmation"
            id="newPasswordConfirmation"
            value={passwords.newPasswordConfirmation}
            onChange={(e) => {
              setPasswords((passwords) => ({
                ...passwords,
                newPasswordConfirmation: e.target.value,
              }));
            }}
          />
          <div
            className={`eye-icon__wrapper ${
              showPassword.newPasswordConfirmation ? "" : "visible"
            }`}
          >
            <img
              className="eye-icon"
              src={showPassword ? eyeIcon : eyeIconStrike}
              alt=""
              onClick={() =>
                toggleVisibilityPassword("newPasswordConfirmation")
              }
            />
          </div>
        </div>
        {!compareNewPasswordsConfirmationValid &&
        passwords.newPassword &&
        passwords.newPasswordConfirmation ? (
          <div className="info-wrapper">
            {t("Your.new.password.does.not.match.label")}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="save-password-bth-wrapper">
        <div
          className={`bth-border-wrapper ${
            !validNewPassword ? "disabled" : "active"
          }`}
        >
          <button
            disabled={!validNewPassword}
            onClick={() => {
              dispatch(changePassword(passwords));
              setOpenPasswordModalProcess(true);
            }}
          >
            {t("Save.Changes.label")}
          </button>
        </div>
      </div>

      {openPasswordModalProcess && (
        <ProcessModal
          openModalProcess={openPasswordModalProcess}
          closeModalProcess={onCloseModal}
          requestPending={changePasswordRequestPending}
          requestError={changePasswordRequestError}
          requestSuccess={changePasswordRequestSuccess}
          fnOnDestroy={() => {
            // dispatch(resetChangePasswordRequestState(false));
          }}
          pendingText={`${t("Updating.password.label")}...`}
          errorText={changePasswordRequestMsg?.message}
          successText={changePasswordRequestMsg?.message}
        />
      )}
    </div>
  );
};

export default ChangePassword;
