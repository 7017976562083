const type = "STATE_ATTACH_FILES";

const userAttachmentsActions = {
  VERIFICATION_FORM_FILES_PENDING: `${type}_VERIFICATION_FORM_FILES_PENDING`,
  VERIFICATION_FORM_FILES_SUCCESS: `${type}_VERIFICATION_FORM_FILES_SUCCESS`,
  VERIFICATION_FORM_FILES_FAILED: `${type}_VERIFICATION_FORM_FILES_FAILED`,
  VERIFICATION_FORM_FILES_REQUEST_STATE_UPDATE: `${type}_VERIFICATION_FORM_FILES_REQUEST_STATE_UPDATE`,

  SET_SRC_USER_PHOTOS: `${type}_SET_SRC_USER_PHOTOS`,

  DNI_SELFI_FILE_PENDING: `${type}_DNI_SELFI_FILE_PENDING`,
  DNI_SELFI_FILE_SUCCESS: `${type}_DNI_SELFI_FILE_SUCCESS`,
  DNI_SELFI_FILE_FAILED: `${type}_DNI_SELFI_FILE_FAILED`,
};
export default userAttachmentsActions;
