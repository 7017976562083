const type = "STATE_LOANERS";

const loanersActions = {
  SET_LOANERS_SEARCH_STRING: "SET_LOANERS_SEARCH_STRING",

  GET_LOANERS_PENDING: "GET_LOANERS_PENDING",
  GET_LOANERS_ERROR: "GET_LOANERS_ERROR",
  GET_LOANERS_SUCCESS: "GET_LOANERS_SUCCESS",

  GET_LOANER_LOANS_PENDING: "GET_LOANER_LOANS_PENDING",
  GET_LOANER_LOANS_ERROR: "GET_LOANER_LOANS_ERROR",
  GET_LOANER_LOANS_SUCCESS: "GET_LOANER_LOANS_SUCCESS",

  BLOCK_LOANER_PENDING: "BLOCK_LOANER_PENDING",
  BLOCK_LOANER_ERROR: "BLOCK_LOANER_ERROR",
  BLOCK_LOANER_SUCCESS: "BLOCK_LOANER_SUCCESS",

  UNBLOCK_LOANER_PENDING: "UNBLOCK_LOANER_PENDING",
  UNBLOCK_LOANER_ERROR: "UNBLOCK_LOANER_ERROR",
  UNBLOCK_LOANER_SUCCESS: "UNBLOCK_LOANER_SUCCESS",

  LOANER_LOANER_HISTORY_PENDING: "LOANER_LOANER_HISTORY_PENDING",
  LOANER_LOANER_HISTORY_ERROR: "LOANER_LOANER_HISTORY_ERROR",
  LOANER_LOANER_HISTORY_SUCCESS: "LOANER_LOANER_HISTORY_SUCCESS",

  UPDATE_FILTER_STATUSES: "UPDATE_FILTER_STATUSES",

  FETCH_CLIENTS_PENDING: `${type}_FETCH_CLIENTS_PENDING`,
  FETCH_CLIENTS_ERROR: `${type}_FETCH_CLIENTS_ERROR`,
  FETCH_CLIENTS_SUCCESS: `${type}_FETCH_CLIENTS_SUCCESS`,

  FETCH_CLIENT_BY_ID_PENDING: `${type}_FETCH_CLIENT_BY_ID_PENDING`,
  FETCH_CLIENT_BY_ID_ERROR: `${type}_FETCH_CLIENT_BY_ID_ERROR`,
  FETCH_CLIENT_BY_ID_SUCCESS: `${type}_FETCH_CLIENT_BY_ID_SUCCESS`,

  FETCH_BLOCK_CLIENT_PENDING: `${type}_FETCH_BLOCK_CLIENT_PENDING`,
  FETCH_BLOCK_CLIENT_ERROR: `${type}_FETCH_BLOCK_CLIENT_ERROR`,
  FETCH_BLOCK_CLIENT_SUCCESS: `${type}_FETCH_BLOCK_CLIENT_SUCCESS`,

  FETCH_UNBLOCK_CLIENT_PENDING: `${type}_FETCH_UNBLOCK_CLIENT_PENDING`,
  FETCH_UNBLOCK_CLIENT_ERROR: `${type}_FETCH_UNBLOCK_CLIENT_ERROR`,
  FETCH_UNBLOCK_CLIENT_SUCCESS: `${type}_FETCH_UNBLOCK_CLIENT_SUCCESS`,

  CHANGE_LOAN_CREDIT_LIMIT_PENDING: `${type}_CHANGE_LOAN_CREDIT_LIMIT_PENDING`,
  CHANGE_LOAN_CREDIT_LIMIT_ERROR: `${type}_CHANGE_LOAN_CREDIT_LIMIT_ERROR`,
  CHANGE_LOAN_CREDIT_LIMIT_SUCCESS: `${type}_CHANGE_LOAN_CREDIT_LIMIT_SUCCESS`,

  REMOVE_USER_SUSPENSION_PENDING: `${type}_REMOVE_USER_SUSPENSION_PENDING`,
  REMOVE_USER_SUSPENSION_ERROR: `${type}_REMOVE_USER_SUSPENSION_ERROR`,
  REMOVE_USER_SUSPENSION_SUCCESS: `${type}_REMOVE_USER_SUSPENSION_SUCCESS`,

  UPDATE_USER_DATA_PENDING: `${type}_UPDATE_USER_DATA_PENDING`,
  UPDATE_USER_DATA_ERROR: `${type}_UPDATE_USER_DATA_ERROR`,
  UPDATE_USER_DATA_SUCCESS: `${type}_UPDATE_USER_DATA_SUCCESS`,

  FETCH_COMMENTS_BY_ID_PENDING: `${type}_FETCH_COMMENTS_BY_ID_PENDING`,
  FETCH_COMMENTS_BY_ID_ERROR: `${type}_FETCH_COMMENTS_BY_ID_ERROR`,
  FETCH_COMMENTS_BY_ID_SUCCESS: `${type}_FETCH_COMMENTS_BY_ID_SUCCESS`,

  FETCH_SAVE_COMMENT_PENDING: `${type}_FETCH_SAVE_COMMENT_PENDING`,
  FETCH_SAVE_COMMENT_ERROR: `${type}_FETCH_SAVE_COMMENT_ERROR`,
  FETCH_SAVE_COMMENT_SUCCESS: `${type}_FETCH_SAVE_COMMENT_SUCCESS`,

  UPDATE_COMMENT_PENDING: `${type}_UPDATE_COMMENT_PENDING`,
  UPDATE_COMMENT_ERROR: `${type}_UPDATE_COMMENT_ERROR`,
  UPDATE_COMMENT_SUCCESS: `${type}_UPDATE_COMMENT_SUCCESS`,

  FETCH_COMMENTS_PDF_PENDING: `${type}_FETCH_COMMENTS_PDF_PENDING`,
  FETCH_COMMENTS_PDF_ERROR: `${type}_FETCH_COMMENTS_PDF_ERROR`,
  FETCH_COMMENTS_PDF_SUCCESS: `${type}_FETCH_COMMENTS_PDF_SUCCESS`,

  FETCH_CLIENT_REPUTATION_PENDING: `${type}_FETCH_CLIENT_REPUTATION_PENDING`,
  FETCH_CLIENT_REPUTATION_ERROR: `${type}_FETCH_CLIENT_REPUTATION_ERROR`,
  FETCH_CLIENT_REPUTATION_SUCCESS: `${type}_FETCH_CLIENT_REPUTATION_SUCCESS`,

  UPDATE_CLIENT_REPUTATION_PENDING: `${type}_UPDATE_CLIENT_REPUTATION_PENDING`,
  UPDATE_CLIENT_REPUTATION_ERROR: `${type}_UPDATE_CLIENT_REPUTATION_ERROR`,
  UPDATE_CLIENT_REPUTATION_SUCCESS: `${type}_UPDATE_CLIENT_REPUTATION_SUCCESS`,
};

export default loanersActions;
