import axios from "axios";
import inboxActions from "./inbox-types";
import { tokenConfig } from "../auth/auth";
import loanStatuses from "../../constants/loanStatuses";
import errorMapper from "../../utils/errorMapper";

function actionCreator(actionType, data) {
  return {
    type: inboxActions[actionType],
    payload: data,
  };
}

export const getInboxLoans = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: inboxActions.FETCH_INBOX_LOANS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/inbox?filter[loan-status]=1`,
      token
    );

    dispatch({
      type: inboxActions.FETCH_INBOX_LOANS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.FETCH_INBOX_LOANS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const getLoanEvaluationById = (loanId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: inboxActions.FETCH_LOAN_EVALUATION_BY_ID_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/admin/loan-evaluation/${loanId}`,
      token
    );

    dispatch({
      type: inboxActions.FETCH_LOAN_EVALUATION_BY_ID_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.FETCH_LOAN_EVALUATION_BY_ID_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const rejectLoanByPhotos = (loanData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/admin/image-rejection`,
      {
        ...loanData,
      },
      token
    );

    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const acceptLoan = (loanData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/admin/loan-approval`,
      {
        ...loanData,
      },
      token
    );

    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const acceptLoanNoDecision = (loanInfo) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/admin/loan-no-decision`,
      { ...loanInfo },
      token
    );

    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const rejectLoan = (loanData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/admin/loan-rejection`,
      {
        ...loanData,
      },
      token
    );

    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

export const blockUserAndRejectLoan = (loanData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_PENDING,
    });

    const token = await tokenConfig(getState);
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_SERVER}/admin/block-user-loan`,
      {
        ...loanData,
      },
      token
    );

    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({
      type: inboxActions.UPDATE_LOAN_STATUS_ERROR,
      payload: {
        ...errorMapper(err, dispatch),
      },
    });
  }
};

// *********************************************

export const setCurrentInboxItemId = (value) => async (dispatch) => {
  dispatch(actionCreator("SET_CURRENT_INBOX_ITEM_ID", value));
};

export const getInboxLoanHistory = (loanId) => async (dispatch, getState) => {
  try {
    dispatch(
      actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_SUCCESS", {
        history: [],
        requestStatus: false,
      })
    );
    dispatch(actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_PENDING", true));
    dispatch(actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_ERROR", false));

    const token = await tokenConfig(getState);
    const loanHistory = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/loan-history/${loanId}/get_list_loan_history/`,
      token
    );
    dispatch(actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_PENDING", false));
    dispatch(
      actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_SUCCESS", {
        history: loanHistory.data,
        requestStatus: true,
      })
    );
  } catch (err) {
    dispatch(actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_PENDING", false));
    dispatch(actionCreator("GET_INBOX_ITEM_LOAN_HISTORY_ERROR", true));
  }
};

export const updateLoanStatusRequestSuccessState = (value) => (dispatch) => {
  dispatch(
    actionCreator("UPDATE_INBOX_ITEM_LOAN_STATUS_REQUEST_SUCCESS_STATE", value)
  );
};

export const updateLoanStatus = (
  loanId,
  status,
  deniedDescription,
  deniedReason
) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_PENDING", true));
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_ERROR", false));
    const token = await tokenConfig(getState);
    const body = {
      status,
    };
    if ((status = loanStatuses.DENIED)) {
      body.denied_description = deniedDescription;
      body.denied_reason = deniedReason;
    }
    await axios.patch(
      `${process.env.REACT_APP_API_SERVER}/loan/${loanId}/`,
      body,
      token
    );
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_PENDING", false));
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_SUCCESS", true));
  } catch (err) {
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_PENDING", false));
    dispatch(actionCreator("UPDATE_INBOX_ITEM_LOAN_ERROR", true));
  }
};

export const reseDownloadPhotosRequesState = (value) => async (dispatch) => {
  dispatch(actionCreator("RESET_DOWNLOAD_PHOTOS_REQUES_STORE", value));
};

export const getPhoto = (
  path,
  imgAction,
  pendingAction,
  successAction,
  errorAction
) => async (dispatch, getState) => {
  try {
    dispatch(actionCreator(pendingAction, true));
    dispatch(actionCreator(errorAction, false));
    dispatch(actionCreator(successAction, false));
    const token = await tokenConfig(getState);
    const res = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/download-file/?path=media/private/${path}`,
      { ...token, responseType: "blob" }
    );
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result;
      dispatch(actionCreator(imgAction, base64data));
    };
    reader.readAsDataURL(res.data);
    dispatch(actionCreator(pendingAction, false));
    dispatch(actionCreator(successAction, true));
  } catch (err) {
    dispatch(actionCreator(pendingAction, false));
    dispatch(actionCreator(errorAction, true));
  }
};
