import axios from "axios";
import { tokenConfig } from "../auth/auth";
import gettingMoneyActions from "./getting-money-types";

function actionCreator(actionType, data) {
  return {
    type: gettingMoneyActions[actionType],
    payload: data,
  };
}

export const setActiveBank = (value) => async (dispatch) => {
  dispatch(actionCreator("SET_ACTIVE_BANK", value));
};

export const resetUpdateBankDetailsRequestState = (value) => async (
  dispatch
) => {
  dispatch(actionCreator("RESET_UPDATE_BANK_DETAILS_REQUEST_STATE", value));
};

export const updateLoanBankInfo = (
  loanId,
  bankAccount,
  bankName,
  status
) => async (dispatch, getState) => {
  const body = {
    bank_name: bankName,
    bank_account: bankAccount,
    status,
  };
  try {
    dispatch(actionCreator("UPDATE_BANK_DETAILS_PENDING", true));
    dispatch(actionCreator("UPDATE_BANK_DETAILS_ERROR", false));
    const token = await tokenConfig(getState);
    const contractHistoryData = await axios.patch(
      `${process.env.REACT_APP_API_SERVER}/loan/${loanId}/`,
      body,
      token
    );

    dispatch(actionCreator("UPDATE_BANK_DETAILS_PENDING", false));
    dispatch(
      actionCreator("UPDATE_BANK_DETAILS_SUCCESS", contractHistoryData.data)
    );
  } catch (err) {
    dispatch(actionCreator("UPDATE_BANK_DETAILS_PENDING", false));
    dispatch(actionCreator("UPDATE_BANK_DETAILS_ERROR", true));
  }
};
