const userActions = {
  CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  RESET_CHANGE_PASSWORD_REQUEST_STATE: "RESET_CHANGE_PASSWORD_REQUEST_STATE",

  CONTACT_US_REFRESH: "CONTACT_US_REFRESH",

  SEND_EMAIL_FOR_REVOVER_PASSWORD_PENDING:
    "SEND_EMAIL_FOR_REVOVER_PASSWORD_PENDING",
  SEND_EMAIL_FOR_REVOVER_PASSWORD_ERROR:
    "SEND_EMAIL_FOR_REVOVER_PASSWORD_ERROR",
  SEND_EMAIL_FOR_REVOVER_PASSWORD_SUCCESS:
    "SEND_EMAIL_FOR_REVOVER_PASSWORD_SUCCESS",

  RESET_SEND_EMAIL_FOR_REVOVER_PASSWORD_REQUEST_STATE:
    "RESET_SEND_EMAIL_FOR_REVOVER_PASSWORD_REQUEST_STATE",

  RECOVER_PASSWORD_PENDING: "RECOVER_PASSWORD_PENDING",
  RECOVER_PASSWORD_ERROR: "RECOVER_PASSWORD_ERROR",
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",

  RESET_RECOVER_PASSWORD_REQUEST_STATE: "RESET_RECOVER_PASSWORD_REQUEST_STATE",
};

export default userActions;
