const type = "STATE_GENERALS";

const analyticsActions = {
  GET_FAQS_PENDING: `${type}_GET_FAQS_PENDING`,
  GET_FAQS_ERROR: `${type}_GET_FAQS_ERROR`,
  GET_FAQS_SUCCESS: `${type}_GET_FAQS_SUCCESS`,

  GET_PERSONAL_DATA_POLICY_PENDING: `${type}_GET_PERSONAL_DATA_POLICY_PENDING`,
  GET_PERSONAL_DATA_POLICY_ERROR: `${type}_GET_PERSONAL_DATA_POLICY_ERROR`,
  GET_PERSONAL_DATA_POLICY_SUCCESS: `${type}_GET_PERSONAL_DATA_POLICY_SUCCESS`,

  GET_TERMS_OF_SERVICE_PENDING: `${type}_GET_TERMS_OF_SERVICE_PENDING`,
  GET_TERMS_OF_SERVICE_ERROR: `${type}_GET_TERMS_OF_SERVICE_ERROR`,
  GET_TERMS_OF_SERVICE_SUCCESS: `${type}_GET_TERMS_OF_SERVICE_SUCCESS`,

  GET_DEPARTMENTS_PENDING: `${type}_GET_DEPARTMENTS_PENDING`,
  GET_DEPARTMENTS_ERROR: `${type}_GET_DEPARTMENTS_ERROR`,
  GET_DEPARTMENTS_SUCCESS: `${type}_GET_DEPARTMENTS_SUCCESS`,

  GET_LIMIT_VALUES_PENDING: `${type}_GET_LIMIT_VALUES_PENDING`,
  GET_LIMIT_VALUES_ERROR: `${type}_GET_LIMIT_VALUES_ERROR`,
  GET_LIMIT_VALUES_SUCCESS: `${type}_GET_LIMIT_VALUES_SUCCESS`,

  GET_POSTS_PENDING: `${type}_GET_POSTS_PENDING`,
  GET_POSTS_ERROR: `${type}_GET_POSTS_ERROR`,
  GET_POSTS_SUCCESS: `${type}_GET_POSTS_SUCCESS`,

  GET_LANDING_FAQS_PENDING: `${type}_GET_LANDING_FAQS_PENDING`,
  GET_LANDING_FAQS_ERROR: `${type}_GET_LANDING_FAQS_ERROR`,
  GET_LANDING_FAQS_SUCCESS: `${type}_GET_LANDING_FAQS_SUCCESS`,
};

export default analyticsActions;
