import * as moment from "moment";

export const getTransactionFileName = (fileName) => {
  if (!fileName) {
    return "";
  }

  const splittedName = fileName.split("/");
  return splittedName[splittedName.length - 1];
};

export const getDateByFormat = ({ date, format = "DD-MM-YYYY" }) => {
  if (!date) {
    return "";
  }

  return moment(date).format(format);
};
