const type = "STATE_PAYMENT";

const paymentActions = {
  FETCH_PAYMENT_PROCESSORS_PENDING: `${type}_FETCH_PAYMENT_PROCESSORS_PENDING`,
  FETCH_PAYMENT_PROCESSORS_ERROR: `${type}_FETCH_PAYMENT_PROCESSORS_ERROR`,
  FETCH_PAYMENT_PROCESSORS_SUCCESS: `${type}_FETCH_PAYMENT_PROCESSORS_SUCCESS`,

  FETCH_PROCESS_PAYMENT_PENDING: `${type}_FETCH_PROCESS_PAYMENT_PENDING`,
  FETCH_PROCESS_PAYMENT_ERROR: `${type}_FETCH_PROCESS_PAYMENT_ERROR`,
  FETCH_PROCESS_PAYMENT_SUCCESS: `${type}_FETCH_PROCESS_PAYMENT_SUCCESS`,
};

export default paymentActions;
