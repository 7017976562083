import React from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

const LoanStatusOverdue = () => {
  const { specialNotification, refinancingNotification } = useSelector(
    (state) => ({
      specialNotification: state.loan.loanInfo.specialNotification,
      refinancingNotification: state.loan.loanInfo.refinancingNotification,
    })
  );

  return (
    <div className="loan-status-wrapper">
      <div className="status-indicator-wrapper overdue-loan">
        <span>{refinancingNotification}</span>
      </div>

      <div className="status-indicator-wrapper overdue-loan">
        <span className="dot"></span>
        <span>{specialNotification}</span>
      </div>
    </div>
  );
};

export default LoanStatusOverdue;
