import React from "react";

import tick3Icon from "../../../../../assets/tick3-icon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrice } from "../../../../../utils/currency";
import { getDateByFormat } from "../../../../../utils/parser";
import "./styles.scss";
import LoanHistoryItem from "./loan-history-item/LoanHistoryItem";

const LoansHistoryTable = ({ setActiveLayout, setElectLoan }: *) => {
  const { t } = useTranslation();

  const { loansHistory } = useSelector((state) => ({
    loansHistory: state.loansHistory.loansHistory,
  }));

  return (
    <div className="loans-history-table-mobile-version-wrapper">
      <div className="table-wrapper">
        {loansHistory.map((loan, i) => (
          <div className="table-item-wrapper" key={i}>
            <div className="table-row-wrapper header">
              <div className="title">№ {loan.contractNumber}</div>
              <div
                className="value more-bth"
                onClick={() => {
                  setActiveLayout(2);
                  setElectLoan(loan);
                }}
              >
                <span>{t("More.label")}</span>
                <img src={tick3Icon} alt="" className="tick" />
              </div>
            </div>
            <div className="table-row-wrapper">
              <div className="title">{t("You.get.label")}</div>
              <div className="value">{formatPrice(loan.loanAmount)}</div>
            </div>
            <div className="table-row-wrapper">
              <div className="title">{t("Return.Date.label")}</div>
              <div className="value">
                {getDateByFormat({ date: loan.returnDate })}
              </div>
            </div>
            <div className="table-row-wrapper">
              <div className="title">{t("Amount.label")}</div>
              <div className="value"> {formatPrice(loan.returnAmount)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Content = ({
  layout,
  loansHistory,
  setActiveLayout,
  setElectLoan,
  electLoan,
}) => {
  switch (layout) {
    // Loans History table
    case 1:
      return (
        <LoansHistoryTable
          loansHistory={loansHistory}
          setActiveLayout={setActiveLayout}
          setElectLoan={setElectLoan}
        />
      );
    // Loans History table Item
    case 2:
      return (
        <LoanHistoryItem
          loansHistory={loansHistory}
          setActiveLayout={setActiveLayout}
          electLoan={electLoan}
        />
      );
    default:
      return "";
  }
};

const MobileLayout = ({ loansHistory }: *) => {
  const [activeLayout, setActiveLayout] = React.useState(1);
  const [electLoan, setElectLoan] = React.useState(null);

  return (
    <div className="loans-history-content-mobile-version-wrapper">
      <Content
        layout={activeLayout}
        loansHistory={loansHistory}
        setActiveLayout={setActiveLayout}
        setElectLoan={setElectLoan}
        electLoan={electLoan}
      />
    </div>
  );
};

export default MobileLayout;
