import React from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart, BarChart, GaugeChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import "./styles.scss";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  PieChart,
  BarChart,
  GaugeChart,
  CanvasRenderer,
]);

const Chart = ({ data, isLoading }: *) => {
  const loadingOption = {
    text: "Loading...",
    color: "#4413c2",
    textColor: "#270240",
    zlevel: 0,
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={data}
      style={{ height: 400, width: "100%" }}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      loadingOption={loadingOption}
      showLoading={isLoading}
    />
  );
};

export default Chart;
