import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../../../common/error-message/ErrorMessage";
import { setAddressInfo } from "../../../../../../actions/verification/verification";
import { useSelector, useDispatch } from "react-redux";
import { getDeparments } from "../../../../../../actions/generals/generals";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const AdressInfoSchema = Yup.object().shape({
  street: Yup.string().trim().required("Required.label"),
  city: Yup.string().trim().required("Required.label"),
  exteriorNumber: Yup.string().trim().required("Required.label"),
  colonia: Yup.string().trim().required("Required.label"),
  province: Yup.string().trim().required("Required.label"),
});

const BthsTemplate = ({ continueCb, backCb, aciveBth, values }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="bths-wrapper">
      <div className="bth-action bth-back-wrapper">
        <button type="button" onClick={() => backCb()}>
          {t("Back.label")}
        </button>
      </div>
      <div className="bth-action bth-continue-wrapper">
        <button
          onClick={() => {
            continueCb();
            dispatch(setAddressInfo(values));
          }}
          disabled={!aciveBth}
          className={`bth-continue ${!aciveBth ? "disabled" : "active"}`}
        >
          {t("Continue.label")}
        </button>
      </div>
    </div>
  );
};

const UserAdressInfo = ({ nextStep, prevStep }: *) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDeparments());
  }, [dispatch]);

  const { verificationData = {}, departments } = useSelector((state) => ({
    verificationData: state.verification,
    departments: state.generals.departments,
  }));

  const { street, city, exteriorNumber, colonia, province } = verificationData;
  return (
    <div className="form-step-wrapper address-info-step">
      <Formik
        initialValues={{
          street: street,
          city: city,
          exteriorNumber: exteriorNumber,
          colonia: colonia,
          province: province,
        }}
        validationSchema={AdressInfoSchema}
        validateOnMount={true}
      >
        {({
          errors,
          touched,
          values,
          isValid,
          setFieldValue,
          setFieldTouched /* validateForm */,
        }: *) => (
          <Form className="form-step form-address-info">
            <div className="required-fields">
              {t("Required.fields.label")} <span className="start">&#42;</span>
            </div>
            {/* Street */}
            <fieldset className="form-item street-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="street">
                  {t("Street.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="street"
                  id="street"
                />
              </div>
              {errors.street && touched.street ? (
                <ErrorMessage text={t(errors.street)} />
              ) : null}
            </fieldset>
            {/* City */}
            <fieldset className="form-item city-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="city">
                  {t("City.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="city"
                  id="city"
                />
              </div>
              {errors.city && touched.city ? (
                <ErrorMessage text={t(errors.city)} />
              ) : null}
            </fieldset>
            {/* Exterior Number */}
            <fieldset className="form-item exteriorNumber-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="exteriorNumber">
                  {t("Exterior.number.label")}{" "}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="exteriorNumber"
                  id="exteriorNumber"
                />
              </div>
              {errors.exteriorNumber && touched.exteriorNumber ? (
                <ErrorMessage text={t(errors.exteriorNumber)} />
              ) : null}
            </fieldset>
            {/* Colonia */}
            <fieldset className="form-item colonia-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="colonia">
                  {t("Colonia.label")}
                  <span className="start">&#42;</span>
                </label>
                <Field
                  className="input-item"
                  type="text"
                  name="colonia"
                  id="colonia"
                />
              </div>
              {errors.colonia && touched.colonia ? (
                <ErrorMessage text={t(errors.colonia)} />
              ) : null}
            </fieldset>
            {/* Province */}
            <fieldset className="form-item province-item">
              <div className="flex-container">
                <label className="label-item" htmlFor="province">
                  {t("Province.label")} <span className="start">&#42;</span>
                </label>
                <Select
                  className="input-item"
                  name="province"
                  id="province"
                  onChange={(value) => {
                    setFieldValue("province", value.target.value);
                  }}
                  onClose={() => setFieldTouched("province", true)}
                >
                  {departments.map((item) => (
                    <MenuItem value={item} key={item}>
                      {t(item)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {errors.province && touched.province ? (
                <ErrorMessage text={t(errors.province)} />
              ) : null}
            </fieldset>
            <BthsTemplate
              continueCb={() => nextStep()}
              backCb={() => prevStep()}
              aciveBth={isValid}
              values={values}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserAdressInfo;
