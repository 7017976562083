import notificationsActions from "../../actions/notifications/notifications-types";

const initialState = {
  emailNotification: {
    emailNotificationPending: false,
    emailNotificationError: false,
    emailNotificationSuccess: false,
  },
  smsNotification: {
    smsNotificationPending: false,
    smsNotificationError: false,
    smsNotificationSuccess: false,
  },

  userNotifications: [],
  getUserNotificationsRequest: {
    getUserNotificationsRequestPending: false,
    getUserNotificationsRequestError: false,
    getUserNotificationsRequestSuccess: false,
  },
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    // get user notification actions
    case notificationsActions.GET_USER_NOTIFICATIONS_PENDING:
      return {
        ...state,
        getUserNotificationsRequest: {
          getUserNotificationsRequestPending: true,
          getUserNotificationsRequestError: false,
          getUserNotificationsRequestSuccess: false,
        },
      };
    case notificationsActions.FETCH_USER_NOTIFICATIONS_FAILED:
      return {
        ...state,
        getUserNotificationsRequest: {
          getUserNotificationsRequestPending: false,
          getUserNotificationsRequestError: true,
          getUserNotificationsRequestSuccess: false,
        },
      };
    case notificationsActions.FETCH_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        userNotifications: action.payload,
        getUserNotificationsRequest: {
          getUserNotificationsRequestPending: false,
          getUserNotificationsRequestError: false,
          getUserNotificationsRequestSuccess: true,
        },
      };
    default:
      return state;
  }
}
