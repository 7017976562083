import React from "react";
import "./styles.scss";

import LoanData from "./loan-data/LoanData";
import Caclculator from "../../../../../common/calculator/Caclculator";

import { useSelector } from "react-redux";

import loanStatuses from "../../../../../../constants/loanStatuses";
import userStatuses from "../../../../../../constants/userStatuses";

const LoanContent = () => {
  const { loanLayoutStatus, userStatus } = useSelector((state) => ({
    loanLayoutStatus: state.loan?.loanLayoutStatus,
    userStatus: state.auth?.user?.status,
  }));

  const isSuspended = userStatus === userStatuses.SUSPENDED;

  const value = isSuspended ? loanStatuses.SUSPENDED : loanLayoutStatus;

  switch (value) {
    case loanStatuses.NEW_LOAN_CALCULATOR:
      return <Caclculator />;

    case loanStatuses.UNDER_CONSIDERATION:
    case loanStatuses.BAD_PHOTO:
    case loanStatuses.ACCEPTED:
    case loanStatuses.ACTIVE:
    case loanStatuses.OVERDUE:
      return <LoanData />;
    default:
      return "";
  }
};

export default LoanContent;
