import loanersActions from "../../actions/loaners/loaners-types";

const initialState = {
  searchString: "",
  filterStatuses: [],
  loanersList: [],
  loanersPagination: null,
  loanersRequest: {
    loanersRequestPending: false,
    loanersRequestError: false,
    loanersRequestSuccess: false,
  },

  loanerDetails: null,
  loanerDetailsRequest: {
    loanerDetailsRequestPending: false,
    loanerDetailsRequestError: false,
    loanerDetailsRequestSuccess: false,
  },

  loanerUpdate: null,
  loanerUpdateRequest: {
    loanerUpdateRequestPending: false,
    loanerUpdateRequestError: false,
    loanerUpdateRequestSuccess: false,
  },

  userComments: null,
  userCommentsRequest: {
    userCommentsRequestPending: false,
    userCommentsRequestError: false,
    userCommentsRequestSuccess: false,
  },

  userCommentsPDF: null,
  userCommentsPDFRequest: {
    userCommentsPDFRequestPending: false,
    userCommentsPDFRequestError: false,
    userCommentsPDFRequestSuccess: false,
  },

  userReputation: null,
  userReputationRequest: {
    userReputationRequestPending: false,
    userReputationRequestError: false,
    userReputationRequestSuccess: false,
  },

  updateUserReputation: null,
  updateUserReputationRequest: {
    updateUserReputationRequestPending: false,
    updateUserReputationRequestError: false,
    updateUserReputationRequestSuccess: false,
  },
};

function loanersReducer(state = initialState, action) {
  switch (action.type) {
    case loanersActions.FETCH_CLIENTS_PENDING:
      return {
        ...state,
        loanersList: [],
        loanersRequest: {
          loanersRequestPending: true,
          loanersRequestError: false,
          loanersRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENTS_ERROR:
      return {
        ...state,
        loanersList: [],
        loanersPagination: null,
        loanersRequest: {
          loanersRequestPending: false,
          loanersRequestError: true,
          loanersRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        loanersList: action.payload.data,
        loanersPagination: action.payload.meta,
        loanersRequest: {
          loanersRequestPending: false,
          loanersRequestError: false,
          loanersRequestSuccess: true,
        },
      };
    case loanersActions.FETCH_CLIENT_BY_ID_PENDING:
      return {
        ...state,
        loanerDetails: null,
        userCommentsPDF: null,
        loanerDetailsRequest: {
          loanerDetailsRequestPending: true,
          loanerDetailsRequestError: false,
          loanerDetailsRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENT_BY_ID_ERROR:
      return {
        ...state,
        loanerDetails: null,
        loanerDetailsRequest: {
          loanerDetailsRequestPending: false,
          loanerDetailsRequestError: true,
          loanerDetailsRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        loanerDetails: action.payload,
        loanerDetailsRequest: {
          loanerDetailsRequestPending: false,
          loanerDetailsRequestError: false,
          loanerDetailsRequestSuccess: true,
        },
      };
    case loanersActions.FETCH_COMMENTS_BY_ID_PENDING:
      return {
        ...state,
        userComments: null,
        userCommentsPDF: null,
        userCommentsRequest: {
          userCommentsRequestPending: true,
          userCommentsRequestError: false,
          userCommentsRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_COMMENTS_BY_ID_ERROR:
      return {
        ...state,
        userComments: null,
        userCommentsPDF: null,
        userCommentsRequest: {
          userCommentsRequestPending: false,
          userCommentsRequestError: true,
          userCommentsRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_COMMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        userComments: action.payload,
        userCommentsPDF: null,
        userCommentsRequest: {
          userCommentsRequestPending: false,
          userCommentsRequestError: false,
          userCommentsRequestSuccess: true,
        },
      };
    case loanersActions.FETCH_COMMENTS_PDF_PENDING:
      return {
        ...state,
        userCommentsPDF: null,
        userCommentsPDFRequest: {
          userCommentsPDFRequestPending: true,
          userCommentsPDFRequestError: false,
          userCommentsPDFRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_COMMENTS_PDF_ERROR:
      return {
        ...state,
        userCommentsPDF: null,
        userCommentsPDFRequest: {
          userCommentsPDFRequestPending: false,
          userCommentsPDFRequestError: true,
          userCommentsPDFRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_COMMENTS_PDF_SUCCESS:
      return {
        ...state,
        userCommentsPDF: action.payload,
        userCommentsPDFRequest: {
          userCommentsPDFRequestPending: false,
          userCommentsPDFRequestError: false,
          userCommentsPDFRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENT_REPUTATION_PENDING:
      return {
        ...state,
        userReputation: null,
        userReputationRequest: {
          userReputationRequestPending: true,
          userReputationRequestError: false,
          userReputationRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENT_REPUTATION_ERROR:
      return {
        ...state,
        userReputation: null,
        userReputationRequest: {
          userReputationRequestPending: false,
          userReputationRequestError: true,
          userReputationRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_CLIENT_REPUTATION_SUCCESS:
      return {
        ...state,
        userReputation: action.payload,
        userReputationRequest: {
          userReputationRequestPending: false,
          userReputationRequestError: false,
          userReputationRequestSuccess: true,
        },
      };
    case loanersActions.UPDATE_CLIENT_REPUTATION_PENDING:
      return {
        ...state,
        updateUserReputation: null,
        updateUserReputationRequest: {
          updateUserReputationRequestPending: true,
          updateUserReputationRequestError: false,
          updateUserReputationRequestSuccess: false,
        },
      };
    case loanersActions.UPDATE_CLIENT_REPUTATION_ERROR:
      return {
        ...state,
        updateUserReputation: action.payload,
        updateUserReputationRequest: {
          updateUserReputationRequestPending: false,
          updateUserReputationRequestError: true,
          updateUserReputationRequestSuccess: false,
        },
      };
    case loanersActions.UPDATE_CLIENT_REPUTATION_SUCCESS:
      return {
        ...state,
        updateUserReputation: action.payload,
        updateUserReputationRequest: {
          updateUserReputationRequestPending: false,
          updateUserReputationRequestError: false,
          updateUserReputationRequestSuccess: true,
        },
      };
    case loanersActions.FETCH_BLOCK_CLIENT_PENDING:
    case loanersActions.FETCH_UNBLOCK_CLIENT_PENDING:
    case loanersActions.CHANGE_LOAN_CREDIT_LIMIT_PENDING:
    case loanersActions.REMOVE_USER_SUSPENSION_PENDING:
    case loanersActions.UPDATE_USER_DATA_PENDING:
    case loanersActions.FETCH_SAVE_COMMENT_PENDING:
    case loanersActions.UPDATE_COMMENT_PENDING:
      return {
        ...state,
        loanerUpdate: null,
        loanerUpdateRequest: {
          loanerUpdateRequestPending: true,
          loanerUpdateRequestError: false,
          loanerUpdateRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_BLOCK_CLIENT_ERROR:
    case loanersActions.FETCH_UNBLOCK_CLIENT_ERROR:
    case loanersActions.CHANGE_LOAN_CREDIT_LIMIT_ERROR:
    case loanersActions.REMOVE_USER_SUSPENSION_ERROR:
    case loanersActions.UPDATE_USER_DATA_ERROR:
    case loanersActions.FETCH_SAVE_COMMENT_ERROR:
    case loanersActions.UPDATE_COMMENT_ERROR:
      return {
        ...state,
        loanerUpdate: action.payload,
        loanerUpdateRequest: {
          loanerUpdateRequestPending: false,
          loanerUpdateRequestError: true,
          loanerUpdateRequestSuccess: false,
        },
      };
    case loanersActions.FETCH_BLOCK_CLIENT_SUCCESS:
    case loanersActions.FETCH_UNBLOCK_CLIENT_SUCCESS:
    case loanersActions.CHANGE_LOAN_CREDIT_LIMIT_SUCCESS:
    case loanersActions.REMOVE_USER_SUSPENSION_SUCCESS:
    case loanersActions.UPDATE_USER_DATA_SUCCESS:
    case loanersActions.FETCH_SAVE_COMMENT_SUCCESS:
    case loanersActions.UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        loanerUpdate: action.payload,
        loanerUpdateRequest: {
          loanerUpdateRequestPending: false,
          loanerUpdateRequestError: false,
          loanerUpdateRequestSuccess: true,
        },
      };

    default:
      return state;
  }
}

export default loanersReducer;
