import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

function InboxHeader() {
  const { t } = useTranslation();
  return (
    <div className="inbox-tittle-wrapper">
      <div className="title">{t('Inbox.label')}</div>
    </div>
  );
}

export default InboxHeader;
