import React from "react";
import { useDispatch } from "react-redux";

export const useFilters = ({ activeFilters, action }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const keys = Object.keys(activeFilters);

    if (keys.length) {
      let query = "?";

      keys.forEach((key, index) => {
        if (!activeFilters[key]) {
          return;
        }

        if (index === 0) {
          query = `${query}${key}=${activeFilters[key]}`;
        } else {
          query = `${query}&${key}=${activeFilters[key]}`;
        }
      });

      dispatch(action({ query }));
    }
  }, [activeFilters, action, dispatch]);
};
