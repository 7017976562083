import React from "react";
import "./styles.scss";

import { useHistory } from "react-router-dom";

import arrowIcon from "../../../../../../assets/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../../../common/confirmation-modal/ConfirmationModal";
import ProcessModal from "../../../../../common/process-modal/ProcessModal";
import {
  setLoanTabLayout,
  acceptLoanSecondStep,
  getUserLoanInfo,
} from "../../../../../../actions/loan/loan";
import loanLayout from "../../../../.../../../../constants/loanLayout";
import { resetUpdateBankDetailsRequestState } from "../../../../../../actions/getting-money/getting-money";
import { useTranslation } from "react-i18next";

const BankDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModalProcess, setOpenModalProcess] = React.useState(false);
  const [touchedInput, setTouchedInput] = React.useState(false);
  const [bankAccount, setBankAccount] = React.useState("");
  const [accountType, setAccontType] = React.useState("ahorro");
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );

  const {
    acceptLoanError,
    acceptLoanPending,
    acceptLoanSuccess,
    bank,
    firstName,
    lastName,
    loanAmount,
    acceptLoanRequestData,
    phone,
  } = useSelector((state) => ({
    firstName: state.auth.user.name,
    lastName: state.auth.user.surname,
    phone: state.auth.user.profile.phoneNumber,
    loanAmount: state.loan.loanInfo.loanAmount,

    bank: state.loan.selectedBank || {},

    acceptLoanRequestData: state.loan.acceptLoanRequestData,
    acceptLoanPending: state.loan.acceptLoanRequest.acceptLoanPending,
    acceptLoanError: state.loan.acceptLoanRequest.acceptLoanError,
    acceptLoanSuccess: state.loan.acceptLoanRequest.acceptLoanSuccess,
  }));

  const { t } = useTranslation();

  const minMaxValues = React.useMemo(() => {
    if (accountType === "ahorro") {
      return {
        min: bank?.min_digits_saving,
        max: bank?.max_digits_saving,
      };
    }

    return {
      min: bank?.min_digits_checking,
      max: bank?.max_digits_checking,
    };
  }, [bank, accountType]);

  const onContinue = React.useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const closeConfirmationModal = React.useCallback(() => {
    setOpenConfirmationModal(false);
  }, []);

  const onLoanAccepted = React.useCallback(() => {
    dispatch(
      acceptLoanSecondStep({
        bankId: bank?.id,
        accountNumber: bankAccount,
        accountType,
      })
    );
    setOpenModalProcess(true);
  }, [dispatch, bankAccount, bank?.id, accountType]);

  const onCloseModal = React.useCallback(() => {
    if (acceptLoanSuccess) {
      dispatch(getUserLoanInfo());
      dispatch(setLoanTabLayout(loanLayout.LOAN_PROCCESS));
      history.push("/login");
    }

    setOpenModalProcess(false);
  }, [acceptLoanSuccess, dispatch, history]);

  const checkAccountNumber = React.useMemo(
    () =>
      bankAccount.length >= minMaxValues.min &&
      bankAccount.length <= minMaxValues.max,
    [bankAccount, minMaxValues]
  );

  const canContinue = React.useMemo(
    () => bankAccount && accountType && checkAccountNumber,
    [bankAccount, accountType, checkAccountNumber]
  );

  const renderErrorMessage = React.useMemo(() => {
    if (minMaxValues.min === minMaxValues.max) {
      return (
        <>
          {`${t("Error-account-number-1")} ${bank.name} ${t(  
            "Error-account-number-2"
          )} ${minMaxValues.max}`}
        </>
      );
    }

    return (
      <>
        {`${t("Error-account-number-1")} ${bank.name} ${t(
          "Error-account-number-3"
        )} ${minMaxValues.min} ${t("Error-account-number-4")} ${
          minMaxValues.max
        }`}
      </>
    );
  }, [minMaxValues, bank, t]);

  return (
    <div className="bank-details-content-wrapper">
      <div className="bank-details-block-header">
        <div className="header-decription">{t("Choose.Bank.label")}</div>
        <div className="header-info-get-money">
          <span>{t("You.will.get.money.label")}*:</span>
          <span className="date-info">{t("Tomorrow.label")} 16:00</span>
        </div>
      </div>
      <div className="loan-data-wrapper">
        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-name">
            {t("Name.label")}
          </div>
          <div className="input-wrapper">
            <input
              disabled={true}
              className="loan-item-data-value value-name disabled"
              value={`${firstName} ${lastName}`}
            />
          </div>
        </div>
        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-phone">
            {t("Phone.number.label")}
          </div>
          <div className="input-wrapper">
            <input
              disabled={true}
              className="loan-item-data-value value-phone disabled"
              value={`${phone}`}
            />
          </div>
        </div>
        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-loan-amount">
            {t("Loan.Amount.label")}
          </div>
          <div className="input-wrapper">
            <input
              disabled={true}
              className="loan-item-data-value value-loan-amount  disabled"
              value={`${loanAmount}`}
            />
            <span className="char-dolar">$</span>
          </div>
        </div>

        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-loan-bank-name">
            {t("Bank.Name.label")}
          </div>
          <div className="input-wrapper">
            <input
              disabled={true}
              className="loan-item-data-value value-bank-name disabled"
              value={`${bank.name}`}
            />
          </div>
        </div>

        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-loan-bank-account">
            {t("Bank.account.label")}
          </div>
          <div className="input-wrapper">
            <input
              value={bankAccount}
              className="loan-item-data-value value-bank-account active"
              onBlur={() => setTouchedInput(true)}
              onChange={(e) => {
                const value = e.target.value;

                if (
                  !/^[0-9]*$/.test(value) ||
                  value.length > minMaxValues.max
                ) {
                  e.preventDefault();
                  return;
                }

                setBankAccount(value);
              }}
            />
          </div>
        </div>

        {!checkAccountNumber && touchedInput && (
          <div className="errorContainer">{renderErrorMessage}</div>
        )}

        <div className="loan-item-data-wrapper">
          <div className="loan-item-data-title title-loan-bank-account">
            {t("Bank.account-type.label")}
          </div>

          <div className="radio-group-wrapper">
            <div className="radio-group-item-wrapper account-radio-item">
              <input
                className="input-item"
                type="radio"
                defaultChecked
                id={t("Bank.account-type.savings.label")}
                name="accountType"
                value="ahorro"
                onChange={(e) => setAccontType(e.target.value)}
              />
              <label
                className="label-item"
                htmlFor={t("Bank.account-type.savings.label")}
              >
                {t("Bank.account-type.savings.label")}
              </label>
            </div>
            <div className="radio-group-item-wrapper account-radio-item">
              <input
                className="input-item"
                type="radio"
                id={t("Bank.account-type.checking.label")}
                name="accountType"
                value="corriente"
                onChange={(e) => setAccontType(e.target.value)}
              />
              <label
                className="label-item"
                htmlFor={t("Bank.account-type.checking.label")}
              >
                {t("Bank.account-type.checking.label")}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="bth-wrapper bth-bank-details-wrapper">
        <button
          disabled={!canContinue}
          className={`bth bank-details-bth ${
            !canContinue ? "disabled" : "active"
          }`}
          onClick={onContinue}
        >
          <span>{t("Continue.label")}</span>
          <img className="arrow-icon" src={arrowIcon} alt="" />
        </button>
      </div>

      <div
        className="additional-info"
        onClick={() => setOpenModalProcess(true)}
      >
        <span>
          {t("*Out-of-hours.bank.transfers.are.processed.the.next.day.label")}
        </span>
        <span>{t("Banks.do.not.work.weekends.label")}</span>
      </div>
      {openModalProcess && (
        <ProcessModal
          openModalProcess={openModalProcess}
          closeModalProcess={onCloseModal}
          requestPending={acceptLoanPending}
          requestError={acceptLoanError}
          requestSuccess={acceptLoanSuccess}
          fnOnDestroy={() => {
            dispatch(resetUpdateBankDetailsRequestState(false));
          }}
          pendingText={`${t("Saving.details.label")}...`}
          errorText={acceptLoanRequestData?.message}
          successText={acceptLoanRequestData?.message}
        />
      )}

      <ConfirmationModal
        openModal={openConfirmationModal}
        onCloseModal={closeConfirmationModal}
        confirmationText={t("Confirm.account.details.label")}
        big={true}
        onPressAccept={onLoanAccepted}
      >
        <div className="bank-account-confirmation">
          <span>{t("Process.bank.details.message.1")}</span>
          <span className="highlighted">
            {accountType === "ahorro" ? "de ahorros" : accountType}
          </span>
          <span>{t("Process.bank.details.message.2")}</span>
          <span className="highlighted">{bankAccount}</span>
          <span>{t("Process.bank.details.message.3")}</span>
          <span className="highlighted">{bank.name}?</span>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default BankDetails;
